import React, { RefObject } from 'react';
import { Button, Space} from 'antd';
import { RoutePath } from '../../../route/path';
import { ColumnsType } from 'antd/lib/table/Table';
import { mailApi } from '../../../api/console/gm.mail';
import { formatDate } from '../../../util/toolbox';
import GmMailEdit from './GmMailEdit';
import { RouteComponentProps } from 'react-router';
import { StdItemPageLimit } from '../../../part/StdItemPageLimit';
import moment from 'moment';
import Configure from '../../../api/Configure';

export default class GmMailEditIndex extends StdItemPageLimit<any> {
    private editor: RefObject<GmMailEdit>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
    }

    state = {
        itemTotal: undefined,
        queryPagination: {
            page: 1,
            pageSize: 10
        },
        itemList: [],
    }

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1118,'id'),
                dataIndex: ['gmMailEntity','id'],
                width: 80,
            },
            {
                title: Configure.Gl(1124,'标题'),
                dataIndex: ['gmMailEntity','title'],
                width: 240,
            },
            {
                title: Configure.Gl(1200,'奖励'),
                dataIndex: ['gmMailEntity','reward'],
                width: 150,
            },
            {
                title: Configure.Gl(1088,'状态'),
                dataIndex: ['gmMailEntity','status'],
                width: 80,
                render: (value: any) => value === 1 ? Configure.Gl(1201,"未审批") : value === 2 ? Configure.Gl(1202,"同意") : Configure.Gl(1203,"拒绝")
            },
            {
                title: Configure.Gl(1057,'操作'),
                dataIndex: ['gmMailEntity','operate'],
                width: 120,
                render: (value: any, record: any, index: number) => {
                    return record.gmMailEntity.status === 1 ? 
                     (<Space>
                        <Button type="default" onClick={() => {
                            console.log("record=",record);
                            this.editor.current?.handleModalCancel();
                            if(record.gmMailEntity.invalidTime){
                                record.gmMailEntity.invalidTime = moment(record.gmMailEntity.invalidTime);
                            }
                            this.editor.current?.setState({
                                initData: record, 
                                masterShow: record.gmMailEntity.type === 2, 
                                srvShow: record.gmMailEntity.type === 1, 
                                reward: record.gmMailEntity.reward,
                                masterSelected: record.gmMailEntity.type === 2? record.gmMailTars: undefined,
                                srvGameSelected: record.gmMailEntity.type === 1? record.gmMailTars: undefined,
                            },()=>{this.editor.current?.showCreate();});
                        }}>
                            {Configure.Gl(1132,'修改')}
                        </Button>
                    </Space>) : "";
                },
            },
            {
                title: Configure.Gl(1205,'提交人用户名'),
                width: 80,
                dataIndex: ['gmMailEntity','submitUsername'],
            },
            {
                title: Configure.Gl(1206,'提交人昵称'),
                width: 120,
                dataIndex: ['gmMailEntity','submitNickname'],
            },
            {
                title: Configure.Gl(1207,'提交时间'),
                width: 120,
                dataIndex: ['gmMailEntity','submitTime'],
                render: (v, r) => formatDate(v)
            },
            {
                title: Configure.Gl(1208,'审核人用户名'),
                width: 80,
                dataIndex: ['gmMailEntity','approveUsername'],
            },
            {
                title: Configure.Gl(1209,'审核人昵称'),
                width: 120,
                dataIndex: ['gmMailEntity','approveNickname'],
            },
            {
                title: Configure.Gl(1210,'审核时间'),
                width: 120,
                dataIndex: ['gmMailEntity','approveTime'],
                render: (v, r) => formatDate(v)
            },
            {
                title: Configure.Gl(1125,'内容'),
                width: 1200,
                dataIndex: ['gmMailEntity','content'],
            }
        ];
    }

    getFilterUI() {
        return (
            <Button type="primary" onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1230,'编辑新邮件')}</Button>
        );
    }

    getEditorUI() {
        return (<GmMailEdit ref={this.editor} reload={() => this.reload()}></GmMailEdit>);
    }

    getPath() { return RoutePath.GM_MAIL_EDIT_INDEX; }

    async reloadForData(query: any){
        let data = await mailApi.list(query) as any;
        console.log("data=",data);
        this.addKeyToData(data.records);
        return data;
    }

    doAccept(item : any) {
        mailApi.complete({status: 2,id: item.id}).then(() => {
          this.reload();
        });
    }

    doRefuse(item : any) {
        mailApi.complete({status: 3, id: item.id}).then(() => {
          this.reload();
        });
    }
}