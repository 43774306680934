import React from 'react';
import { DatePicker, Input, Select } from 'antd';
import { AbsFilter, StdItemFilter } from '../../../part/StdItemFilter';
import Configure from '../../../api/Configure';

class Filter extends AbsFilter {
    srvCode?: string = undefined;
    gameId?: string = undefined;
    date?: string = undefined;
    groupBySrv?: boolean = false;
}

export default class NewDailyBriefingFilter extends StdItemFilter {

    state = {
        srvCode: undefined as string | undefined,
        gameId: undefined as string | undefined,
        date: undefined as moment.Moment | undefined,
        groupBySrv: false,
    };

    filter: Filter = new Filter();

    getFilter() {
        return this.filter;
    }

    componentDidMount() {
        //
    }

    render() {
        return (
            <span>
                <Input placeholder={Configure.Gl(1079, "游戏服编码")} value={this.state.srvCode} onChange={e => this.setValue('srvCode', e.target.value)} style={{ width: 180 }} />
                <Input placeholder={Configure.Gl(1123, "GameID")} value={this.state.gameId} onChange={e => this.setValue('gameId', e.target.value)} style={{ width: 180 }} />
                <Select placeholder={Configure.Gl(Configure.GL_UNDEFINED, "仅按服务器聚合")}
                    style={{ width: 200 }}
                    onChange={this.doSelect}
                >
                    <Select.Option value="true">{Configure.Gl(1185, "是")}</Select.Option>
                    <Select.Option value="false">{Configure.Gl(1186, "否")}</Select.Option>
                </Select>
                <DatePicker placeholder={Configure.Gl(1865, "查询日期")} value={this.state.date} onChange={e => this.setValue('date', e)} style={{ width: 120 }} showToday />
                { this.renderCmd()}
            </span>
        );
    }

    protected doSelect = (value: any) => {
        this.setValue('groupBySrv', value)
    }
}