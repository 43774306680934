import React from 'react';
import HomeIndex from '../page/home/HomeIndex';
import LoginIndex from '../page/login/LoginIndex';
import GlobalConfIndex from '../page/other/GlobalConfIndex';
import EmergencyIndex from '../page/other/EmergencyIndex';
import UrlMapIndex from '../page/other/UrlMapIndex';
import NotFound from '../page/NotFound';

import PlatformAppIndex from '../page/platform/PlatformAppIndex';

import CloudDeviceIndex from '../page/cloud/CloudDeviceIndex';
import CloudMqIndex from '../page/cloud/CloudMqIndex';
import CloudMysqlIndex from '../page/cloud/CloudMysqlIndex';
import CloudMongoIndex from '../page/cloud/CloudMongoIndex';

import StaffIndex from '../page/staff/StaffIndex';
import StaffRoleIndex from '../page/staff/StaffRoleIndex';
import StaffRolePermissionIndex from '../page/staff/StaffRolePermissionIndex';
import StaffRoleSrvGroupIndex from '../page/staff/StaffRoleSrvGroupIndex';

import SrvGameIndex from '../page/srv/game/SrvGameIndex';
import SrvGameAccessIndex from '../page/srv/game/access/SrvGameAccessIndex';
import SrvGameExtIndex from '../page/srv/game/ext/SrvGameExtIndex';
import SrvStatusPlanIndex from '../page/srv/SrvStatusPlanIndex';
import SrvGroupIndex from '../page/srv/SrvGroupIndex';
import SrvCrossIndex from '../page/srv/cross/SrvCrossIndex';
import SrvCrossPlanIndex from '../page/srv/cross/plan/SrvCrossPlanIndex';
import SrvMergeIndex from '../page/srv/merge/SrvMergeIndex';
import SrvGroupConfIndex from "../page/srv/SrvGroupConfIndex";

import GameRtIndex from '../page/game/rt/GameRtIndex';

import GmAnnounceIndex from '../page/gm/announce/GmAnnounceIndex';
import GmFreezeIndex from '../page/gm/freeze/GmFreezeIndex';
import GmChatForbidIndex from '../page/gm/chat/GmChatForbidIndex';
import GiftcodeBuildIndex from '../page/gm/giftcode/GiftcodeBuildIndex';
import GiftcodeSendIndex from '../page/gm/giftcode/GiftcodeSendIndex';
import GmPayManualIndex from '../page/gm/pay/GmPayManualIndex';
import GmPayApproveIndex from '../page/gm/pay/GmPayApproveIndex';
import GmMailApproveIndex from '../page/gm/mail/GmMailApproveIndex';
import GmMailEditIndex from '../page/gm/mail/GmMailEditIndex';
import RebateApproveIndex from '../page/gm/rebate/RebateApproveIndex';
import RebateEditIndex from '../page/gm/rebate/RebateEditIndex';
import GmMailSpeApproveIndex from '../page/gm/mail/GmMailSpeApproveIndex';
import GmOrderQueryIndex from '../page/gm/order/GmOrderQueryIndex';
import GmItemQueryIndex from '../page/gm/item/GmItemQueryIndex';

import RoleBagIndex from '../page/role/bag/RoleBagIndex';
import RoleWalletIndex from '../page/role/wallet/RoleWalletIndex';

import StatPartConfIndex from '../page/stat/partconf/StatPartConfIndex';
import StatPayRankIndex from '../page/stat/pay/StatPayRankIndex';
import StatRoleKeyNumIndex from '../page/stat/role/RoleKeyNumIndex';
import StatLoginFlowIndex from '../page/stat/login/StatLoginFlowIndex';
import StatHeroLineupIndex from '../page/stat/hero/StatHeroLineupIndex';

import PayStatGsLifeIndex from '../page/pay/PayStatGsLifeIndex';

import SysTaskIndex from '../page/sys/task/SysTaskIndex';

import CCTestIndex from '../page/lab/cc/CCTestIndex';
import DemoEntityIndex from '../page/lab/curd/DemoEntityIndex';

import { RoutePath } from './path';
import { UserOutlined, LaptopOutlined, NotificationOutlined, CoffeeOutlined, GoogleOutlined, PicLeftOutlined, HighlightOutlined, 
    ClockCircleOutlined, SlackSquareOutlined, CodepenCircleOutlined, StopOutlined, AliwangwangOutlined, TwitterOutlined, 
    HeatMapOutlined, DashboardOutlined, CodeSandboxOutlined, ToolFilled, DropboxOutlined, AndroidOutlined, 
    AliyunOutlined, MediumOutlined, CloudServerOutlined, ExperimentOutlined, BgColorsOutlined, ContainerFilled, ProfileFilled,
    CarOutlined, LoginOutlined, EyeFilled, OrderedListOutlined, BoxPlotOutlined, RedditOutlined, CustomerServiceOutlined,
    PhoneOutlined, CheckSquareOutlined, UnderlineOutlined, FunnelPlotOutlined } from '@ant-design/icons';
import SuperviseReportIndex from '../page/game/supervise/SuperviseReportIndex';
import Configure from '../api/Configure';
import FunParticipateIndex from '../page/stat/transfer/FunParticipateIndex';
import CustomerVipIndex from '../page/gm/vip/CustomerVipIndex';
import CleanMemoryIndex from '../page/other/CleanMemoryIndex';
import StaffRoleLimitIndex from '../page/staff/StaffRoleLimitIndex';
import heroSeachIndex from '../page/gm/hero/heroSeachIndex';
import UserLifeCycleValueIndex from '../page/stat/ltv/UserLifeCycleValueIndex';
import NewPreserverIndex from '../page/stat/preserve/NewPreserverIndex';
import NewDailyBriefingIndex from '../page/game/briefing/NewDailyBriefingIndex';

export function getRoutes() {
    return [
        { path: RoutePath.HOME_INDEX, component: HomeIndex },
        { path: RoutePath.LOGIN_INDEX, component: LoginIndex },
        { path: RoutePath.GLOBAL_CONF_INDEX, component: GlobalConfIndex },
        { path: RoutePath.EMERGENCY_INDEX, component: EmergencyIndex },
        { path: RoutePath.URL_MAP_INDEX, component: UrlMapIndex },
        { path: RoutePath.OTHER_CHLEAN_MEMORY_INDEX, component: CleanMemoryIndex },

        { path: RoutePath.PLATFORM_APP_INDEX, component: PlatformAppIndex },

        { path: RoutePath.CLOUD_DEVICE_INDEX, component: CloudDeviceIndex },
        { path: RoutePath.CLOUD_MQ_INDEX, component: CloudMqIndex },
        { path: RoutePath.CLOUD_MYSQL_INDEX, component: CloudMysqlIndex },
        { path: RoutePath.CLOUD_MONGO_INDEX, component: CloudMongoIndex },

        { path: RoutePath.STAFF_INDEX, component: StaffIndex },
        { path: RoutePath.STAFF_ROLE_INDEX, component: StaffRoleIndex },
        { path: RoutePath.STAFF_ROLE_PERMISSION_INDEX, component: StaffRolePermissionIndex },
        { path: RoutePath.STAFF_ROLE_SRV_GROUP_INDEX, component: StaffRoleSrvGroupIndex },
        { path: RoutePath.STAFF_GAMEID_LIMIT_INDEX, component: StaffRoleLimitIndex },

        { path: RoutePath.SRV_GAME_INDEX, component: SrvGameIndex },
        { path: RoutePath.SRV_GAME_ACCESS_INDEX, component: SrvGameAccessIndex },
        { path: RoutePath.SRV_GAME_EXT_INDEX, component: SrvGameExtIndex },
        { path: RoutePath.SRV_STATUS_PLAN_INDEX, component: SrvStatusPlanIndex },
        { path: RoutePath.SRV_GROUP_INDEX, component: SrvGroupIndex },
        { path: RoutePath.SRV_GROUP_CONF_INDEX, component: SrvGroupConfIndex},
        { path: RoutePath.SRV_CROSS_INDEX, component: SrvCrossIndex },
        { path: RoutePath.SRV_CROSS_PLAN_INDEX, component: SrvCrossPlanIndex },
        { path: RoutePath.SRV_MERGE_INDEX, component: SrvMergeIndex },

        { path: RoutePath.GAME_RT_INDEX, component: GameRtIndex },
        { path: RoutePath.SUPERVISE_REPORT_INDEX, component: SuperviseReportIndex },
        { path: RoutePath.NEW_DAILY_BRIEFING_INDEX, component: NewDailyBriefingIndex },

        { path: RoutePath.GM_FREEZE_INDEX, component: GmFreezeIndex },
        { path: RoutePath.GM_CHAT_FORBID_INDEX, component: GmChatForbidIndex },
        { path: RoutePath.GM_ANNOUNCE_INDEX, component: GmAnnounceIndex},
        { path: RoutePath.GIFT_CODE_BUILD_INDEX, component: GiftcodeBuildIndex},
        { path: RoutePath.GIFT_CODE_SEND_INDEX, component: GiftcodeSendIndex},
        { path: RoutePath.GM_PAY_MANUAL_INDEX, component: GmPayManualIndex},
        { path: RoutePath.GM_PAY_APPROVE_INDEX, component: GmPayApproveIndex},
        { path: RoutePath.GM_MAIL_APPROVE_INDEX, component: GmMailApproveIndex},
        { path: RoutePath.GM_MAIL_SPE_APPROVE_INDEX, component: GmMailSpeApproveIndex},
        { path: RoutePath.GM_MAIL_EDIT_INDEX, component: GmMailEditIndex},
        { path: RoutePath.REBATE_APPROVE_INDEX, component: RebateApproveIndex},
        { path: RoutePath.REBATE_EDIT_INDEX, component: RebateEditIndex},
        { path: RoutePath.GM_ORDER_QUERY_INDEX, component: GmOrderQueryIndex},
        { path: RoutePath.GM_ITEM_QUERY_INDEX, component: GmItemQueryIndex},
        { path: RoutePath.GM_VIP_CUSTOMER_INDEX,component:CustomerVipIndex},
        { path: RoutePath.GM_HERO_SEACH_INDEX,component:heroSeachIndex},

        { path: RoutePath.ROLE_BAG_INDEX, component: RoleBagIndex},
        { path: RoutePath.ROLE_WALLET_INDEX, component: RoleWalletIndex},

        { path: RoutePath.STAT_COIN_MALL_INDEX, component: StatPartConfIndex },
        { path: RoutePath.STAT_PART_CONF_INDEX, component: StatPartConfIndex },
        { path: RoutePath.STAT_PAY_RANK_INDEX, component: StatPayRankIndex},
        { path: RoutePath.STAT_ROLE_KEY_NUM_INDEX, component: StatRoleKeyNumIndex},
        { path: RoutePath.STAT_LOGIN_FLOW_INDEX, component: StatLoginFlowIndex},
        { path: RoutePath.STAT_HERO_LINEUP_INDEX, component: StatHeroLineupIndex},
        { path: RoutePath.STAT_FUNC_PART_INDEX,component:FunParticipateIndex},
        { path: RoutePath.STAT_NEW_PRESERVER_INDEX,component:NewPreserverIndex},
        { path: RoutePath.STAT_USER_LTV_INDEX,component:UserLifeCycleValueIndex},
        { path: RoutePath.PAY_STAT_GS_LIFE_INDEX, component: PayStatGsLifeIndex },

        { path: RoutePath.SYS_TASK_INDEX, component: SysTaskIndex },

        { path: RoutePath.LAB_CC_TEST_INDEX, component: CCTestIndex },
        { path: RoutePath.LAB_DEMO_ENTITY_INDEX, component: DemoEntityIndex },

        { path: "/*", component: NotFound },
    ];
}

interface MenuCfg {
    title: string;
    icon: React.ReactElement;
    key: string;
    itemList: MenuItemCfg[];
}

interface MenuItemCfg {
    path: string;
    name: string;
    icon: React.ReactElement;
    require: string;
    visible: boolean;
}



export function getMenus(permission?: any) {
    function C(X: any) {
        return React.createElement(X);
    }
    let menus: MenuCfg[] = [
        {
            title:Configure.Gl(1002,"组织权限12121"), icon: C(UserOutlined), key:"B", itemList: [
                { path: RoutePath.STAFF_INDEX, name: Configure.Gl(1001,"员工"), icon: C(LaptopOutlined), require: 'p1', visible: true },
                { path: RoutePath.STAFF_ROLE_INDEX, name: Configure.Gl(1000,"角色"), icon: C(NotificationOutlined), require: 'p1', visible: true },
                { path: RoutePath.STAFF_ROLE_PERMISSION_INDEX, name: Configure.Gl(1003,"角色权限"), icon: C(SlackSquareOutlined), require: 'p1', visible: true },
                { path: RoutePath.STAFF_ROLE_SRV_GROUP_INDEX, name: Configure.Gl(1004,"角色服务器组"), icon: C(CodepenCircleOutlined), require: 'p1', visible: true },
                { path: RoutePath.STAFF_GAMEID_LIMIT_INDEX, name: Configure.Gl(1851,"员工GameID限定"), icon: C(CodepenCircleOutlined), require: 'p1', visible: true },
            ]
        },
        {
            title:Configure.Gl(1006,"平台"), icon: C(DropboxOutlined), key: "C", itemList: [
                { path: RoutePath.PLATFORM_APP_INDEX, name: Configure.Gl(1005,"应用配置"), icon: C(AndroidOutlined), require: 'admin', visible: true },
            ]
        },
        {
            title: Configure.Gl(1007,"云服务"), icon: C(AliyunOutlined), key: "D", itemList: [
                { path: RoutePath.CLOUD_DEVICE_INDEX, name: Configure.Gl(1008,"云服务器"), icon: C(ContainerFilled), require: 'p1', visible: true },
                { path: RoutePath.CLOUD_MQ_INDEX, name: Configure.Gl(1009,"云MQ"), icon: C(MediumOutlined), require: 'p1', visible: true },
                { path: RoutePath.CLOUD_MYSQL_INDEX, name: Configure.Gl(1010,"云Mysql"), icon: C(CloudServerOutlined), require: 'p1', visible: true },
                { path: RoutePath.CLOUD_MONGO_INDEX, name: Configure.Gl(1011,"云Mongo"), icon: C(ExperimentOutlined), require: 'p1', visible: true },
            ]
        },
        {
            title: Configure.Gl(1012,"游戏配置"), icon: C(CoffeeOutlined), key:"E", itemList: [
                { path: RoutePath.GLOBAL_CONF_INDEX, name: Configure.Gl(1013,"全局配置"), icon: C(CodeSandboxOutlined), require: 'p1', visible: true },
                { path: RoutePath.SRV_GAME_INDEX, name: Configure.Gl(1014,"游戏服"), icon: C(GoogleOutlined), require: 'p1', visible: true },
                { path: RoutePath.SRV_GAME_ACCESS_INDEX, name: Configure.Gl(1015,"访问许可"), icon: C(CarOutlined), require: 'p1', visible: true },
                { path: RoutePath.SRV_GAME_EXT_INDEX, name: Configure.Gl(1016,"游戏服扩展配置"), icon: C(BgColorsOutlined), require: 'p1', visible: true },
                { path: RoutePath.SRV_STATUS_PLAN_INDEX, name: Configure.Gl(1017,"游戏服状态变更计划"), icon: C(DashboardOutlined), require: 'p1', visible: true },
                { path: RoutePath.SRV_GROUP_INDEX, name: Configure.Gl(1018,"游戏服组"), icon: C(PicLeftOutlined), require: 'p1', visible: true },
                { path: RoutePath.SRV_GROUP_CONF_INDEX, name: Configure.Gl(1950,"游戏服组配置"), icon:C(AndroidOutlined), require: 'p1', visible: true},
                { path: RoutePath.SRV_CROSS_INDEX, name: Configure.Gl(1019,"跨服组"), icon: C(LaptopOutlined), require: 'p1', visible: true },
                { path: RoutePath.SRV_CROSS_PLAN_INDEX, name: Configure.Gl(1020,"跨服组计划"), icon: C(HighlightOutlined), require: 'p1', visible: true },
                { path: RoutePath.SRV_MERGE_INDEX, name: Configure.Gl(1021,"合服配置"), icon: C(HeatMapOutlined), require: 'p1', visible: true },
            ]

        },
        {
            title: Configure.Gl(1022,"游戏"), icon: C(CustomerServiceOutlined), key: "F", itemList: [
                { path: RoutePath.GAME_RT_INDEX, name: Configure.Gl(1023,"实时状态"), icon: C(PhoneOutlined), require: 'p22', visible: true },
                { path: RoutePath.SUPERVISE_REPORT_INDEX, name: Configure.Gl(1024,"监控汇报"), icon: C(FunnelPlotOutlined), require: 'p23', visible: true },
                { path: RoutePath.NEW_DAILY_BRIEFING_INDEX, name: Configure.Gl(Configure.GL_UNDEFINED,"新版每日简报"), icon: C(FunnelPlotOutlined), require: 'p29', visible: true },
            ]
        },
        {
            title: Configure.Gl(1025,"GM"), icon: C(AliwangwangOutlined), key: "G", itemList: [
                { path: RoutePath.GM_FREEZE_INDEX, name: Configure.Gl(1026,"角色冻结"), icon: C(StopOutlined), require: 'p2', visible: true },
                { path: RoutePath.GM_CHAT_FORBID_INDEX, name: Configure.Gl(1027,"角色禁言"), icon: C(TwitterOutlined), require: 'p2', visible: true },
                { path: RoutePath.GM_ANNOUNCE_INDEX, name: Configure.Gl(1028,"全服公告"), icon: C(TwitterOutlined), require: 'p11', visible: true},
                { path: RoutePath.GIFT_CODE_BUILD_INDEX, name: Configure.Gl(1029,"礼包码生成"), icon: C(TwitterOutlined), require: 'p3', visible: true},
                { path: RoutePath.GIFT_CODE_SEND_INDEX, name: Configure.Gl(1030,"礼包码已发送"), icon: C(TwitterOutlined), require: 'p3', visible: true},
                { path: RoutePath.GM_PAY_MANUAL_INDEX, name: Configure.Gl(1031,"手工支付"), icon: C(TwitterOutlined), require: 'p5', visible: true},
                { path: RoutePath.GM_PAY_APPROVE_INDEX, name: Configure.Gl(1032,"手工支付审核"), icon: C(TwitterOutlined), require: 'p6', visible: true},
                { path: RoutePath.GM_MAIL_EDIT_INDEX, name: Configure.Gl(1033,"邮件编辑"), icon: C(TwitterOutlined), require: 'p8', visible: true},
                { path: RoutePath.GM_MAIL_APPROVE_INDEX, name: Configure.Gl(1034,"邮件审核"), icon: C(TwitterOutlined), require: 'p9', visible: true},
                { path: RoutePath.GM_MAIL_SPE_APPROVE_INDEX, name: Configure.Gl(1035,"无附件邮件审核"),icon: C(TwitterOutlined), require: 'p15', visible: true},
                { path: RoutePath.REBATE_EDIT_INDEX, name: Configure.Gl(1036,"充值返利编辑"), icon: C(TwitterOutlined), require: 'p13', visible: true},
                { path: RoutePath.REBATE_APPROVE_INDEX, name:Configure.Gl(1037,"充值返利审核"), icon: C(TwitterOutlined), require: 'p14', visible: true},
                { path: RoutePath.GM_VIP_CUSTOMER_INDEX, name:Configure.Gl(1804,"VIP客服功能"), icon: C(TwitterOutlined), require: 'p28', visible: true},
                { path: RoutePath.GM_HERO_SEACH_INDEX, name:Configure.Gl(1806,"英雄查询"), icon: C(TwitterOutlined), require: 'p30', visible: true},
            ]
        },
        {
            title: Configure.Gl(1793,"角色操作"), icon: C(CustomerServiceOutlined), key: "H", itemList: [
                { path: RoutePath.ROLE_BAG_INDEX, name: Configure.Gl(1794,"背包物品"), icon: C(PhoneOutlined), require: 'p24', visible: true },
                { path: RoutePath.ROLE_WALLET_INDEX, name: Configure.Gl(1795,"钱包数值"), icon: C(FunnelPlotOutlined), require: 'p26', visible: true },
            ]
        },
        {
            title:Configure.Gl(1040,"统计"), icon: C(NotificationOutlined), key: "I", itemList: [
                { path: RoutePath.STAT_PART_CONF_INDEX, name: Configure.Gl(1041,"统计分片配置"), icon: C(ProfileFilled), require: 'p1', visible: true },
                { path: RoutePath.STAT_PAY_RANK_INDEX, name: Configure.Gl(1042,"单服充值排行"), icon: C(OrderedListOutlined), require: 'p18', visible: true},
                { path: RoutePath.STAT_ROLE_KEY_NUM_INDEX, name: Configure.Gl(1043,"角色关键数据"), icon: C(EyeFilled), require: 'p33', visible: true},
                { path: RoutePath.STAT_LOGIN_FLOW_INDEX, name: Configure.Gl(1044,"登录流程打点"), icon: C(BoxPlotOutlined), require: 'p12', visible: true},
                { path: RoutePath.STAT_HERO_LINEUP_INDEX, name: Configure.Gl(1045,"英雄阵容统计"), icon: C(RedditOutlined), require: 'p12', visible: true},
                { path: RoutePath.STAT_FUNC_PART_INDEX, name:Configure.Gl(1764,'功能参与'),icon:C(TwitterOutlined),require:'p21',visible:true},
                { path: RoutePath.STAT_NEW_PRESERVER_INDEX, name:Configure.Gl(Configure.GL_UNDEFINED,"新留存数据"),icon:C(ToolFilled),require:'p20',visible:true},
                { path: RoutePath.GM_ORDER_QUERY_INDEX, name: Configure.Gl(1038,"订单查询"), icon: C(TwitterOutlined), require: 'p16', visible: true},
                { path: RoutePath.GM_ITEM_QUERY_INDEX, name: Configure.Gl(1039,"产出消耗查询"), icon: C(TwitterOutlined), require: 'p17', visible: true},
                { path: RoutePath.STAT_USER_LTV_INDEX, name: Configure.Gl(1807,"LTV统计"), icon: C(TwitterOutlined), require: 'p31', visible: true},
                { path: RoutePath.STAT_COIN_MALL_INDEX, name: Configure.Gl(1808,"元宝商城统计"), icon: C(TwitterOutlined), require: 'p32', visible: true},
            ]
        }, 
        {
            title: Configure.Gl(1046,"支付"), icon: C(NotificationOutlined), key: "J", itemList: [
                { path: RoutePath.PAY_STAT_GS_LIFE_INDEX, name: Configure.Gl(1054,"游戏服生命周期"), icon: C(ClockCircleOutlined), require: 'p1', visible: true },
            ]
        },
        {
            title: Configure.Gl(1050,"嘿嘿嘿"), icon: C(NotificationOutlined), key: "A", itemList: [
                { path: RoutePath.EMERGENCY_INDEX, name: Configure.Gl(1047,"应急通道"), icon: C(ToolFilled), require: 'p19', visible: true },
                { path: RoutePath.SYS_TASK_INDEX, name: Configure.Gl(1048,"操作任务"), icon: C(CheckSquareOutlined), require: 'p1', visible: true },
                { path: RoutePath.URL_MAP_INDEX, name: Configure.Gl(1049,"网址大全"), icon: C(UnderlineOutlined), require: 'p1', visible: true },
                { path: RoutePath.OTHER_CHLEAN_MEMORY_INDEX, name: Configure.Gl(1818,"清理数据"), icon: C(UnderlineOutlined), require: 'p1', visible: true },
            ]
        },
        {
            title: Configure.Gl(1051,"实验室"), icon: C(NotificationOutlined), key: "X", itemList: [
                { path: RoutePath.LAB_CC_TEST_INDEX, name: Configure.Gl(1052,"CreateContext测试"), icon: C(LoginOutlined), require: 'p1', visible: true },
                { path: RoutePath.LAB_DEMO_ENTITY_INDEX, name: Configure.Gl(1053,"实体CURD"), icon: C(LaptopOutlined), require: 'p1', visible: true },
            ]
        },
    ];
    if(permission) {
        menus.forEach((group) => {
            group.itemList.forEach(item => {
                setVisibleByPath(item, permission);
            })
        })
    } else {
        menus = [];
    }
    return menus;
}

function setVisibleByPath(item: any, permission: any) {
    //console.log('权限数据是', permission);
    if(item.require) {
        //console.log("permission[item.require]=", permission[item.require]);
        if(permission[item.require]) {
            item.visible = true;
        } else {
            //console.log("不满足");
            item.visible = false;
        }
        // item.visible = permission[item.require] ? true : false;
    } else {
        item.visible = true;
    }
}