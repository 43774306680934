import { consoleApi } from '../../util/http';

import { ApiBase } from '../api';
import Configure from '../Configure';

class SrvStatusPlanApi extends ApiBase {

    create(item: any) {
        return consoleApi.post({
            path: 'srv/status/plan/create',
            param: item,
            title: Configure.Gl(1705,'新建游戏服状态变更计划')
        });
    }

    update(item: any) {
        return consoleApi.post({
            path: 'srv/status/plan/update',
            param: item,
            title: Configure.Gl(1706,'修改游戏服状态变更计划')
        });
    }

    delete(id: number) {
        return consoleApi.get({
            path: 'srv/status/plan/delete?id='+id,
            title: Configure.Gl(1707,"删除游戏服状态变更计划"),
            quiet: true
        });
    }

    list() {
        return new Promise((resolve, reject) => {
            consoleApi.get({
                path: 'srv/status/plan/list',
                title: Configure.Gl(1704,'列表'),
                quiet: true
            }).then((data:any) => {
                (data as any[]).forEach(item => {
                    item.changeTime = this.parseZoneTime(item.changeTime);
                });
                resolve(data);
            });
        });
    }
}

export const srvStatusPlanApi: SrvStatusPlanApi = new SrvStatusPlanApi();