import React from 'react';
import { DatePicker, Divider, Input } from 'antd';
import { AbsFilter, StdItemFilter } from '../../../part/StdItemFilter';
import Configure from '../../../api/Configure';
import moment from 'moment';

class Filter extends AbsFilter {
    createTimeBegin?: moment.Moment = undefined;
    createTimeEnd?: moment.Moment = undefined;
    updateTimeBegin?: moment.Moment = undefined;
    updateTimeEnd?: moment.Moment = undefined;
    gameId?: string = undefined;
}

export default class StatLoginFlowFilter extends StdItemFilter {

    state = {
        createTimeBegin: undefined as moment.Moment | undefined,
        createTimeEnd: undefined as moment.Moment | undefined,
        updateTimeBegin: undefined as moment.Moment | undefined,
        updateTimeEnd: undefined as moment.Moment | undefined,
        gameId: undefined,
    };

    filter: Filter = new Filter();

    getFilter() {
        return this.filter;
    }

    render() {
        return (
            <span>
                <Divider />
                <DatePicker placeholder={Configure.Gl(1939, "数据创建开始时间")} value={this.state.createTimeBegin} showTime onChange={e => this.setValue('createTimeBegin', e)} />
                <DatePicker placeholder={Configure.Gl(1940, "数据创建结束时间")} value={this.state.createTimeEnd} showTime onChange={e => this.setValue('createTimeEnd', e)} />
                <Divider />
                <DatePicker placeholder={Configure.Gl(1941, "数据更新开始时间")} value={this.state.updateTimeBegin} showTime onChange={e => this.setValue('updateTimeBegin', e)} />
                <DatePicker placeholder={Configure.Gl(1942, "数据更新结束时间")} value={this.state.updateTimeEnd} showTime onChange={e => this.setValue('updateTimeEnd', e)} />
                <Input placeholder={Configure.Gl(1123, "GameID")} value={this.state.gameId} onChange={e => this.setValue('gameId', e.target.value)} style={{ width: 100 }} />
                <Divider />
                {this.renderCmd()}
                <Divider />
            </span>
        );
    }

}