//import moment from 'moment';
import moment from 'moment-timezone';
import { GmcEnv } from './env';

interface BeautyParam {
    /** 显示原串 */
    appendOrigin: boolean;
    /** 忽略毫秒 */
    ignMs: boolean;
    /** 忽略时区 */
    ignTimezone: boolean;
}

export default class DateTool {

    public static beautifyRaw(dtStr: string) {
        return dtStr;
    }

    public static beautifyLocal(dtStr: string) {
        if(!dtStr) { return null; }
        moment.tz.setDefault(GmcEnv.getInstance().timezone);
        return moment(dtStr).format('YYYY-MM-DD HH:mm:ss')
    }

    public static beautifyLocalDate(dtStr: string) {
        if(!dtStr) { return null; }
        moment.tz.setDefault(GmcEnv.getInstance().timezone);
        return moment(dtStr).format('YYYY-MM-DD')
    }

    public static beautifyIgnMsTimezone(dtStr: string) {
        return DateTool.beautify(dtStr, {appendOrigin: false, ignMs: true, ignTimezone: true});
    }

    public static beautifyIgnTimezone(dtStr: string) {
        return DateTool.beautify(dtStr, {appendOrigin: false, ignMs: false, ignTimezone: true});
    }

    public static beautifyIgnMs(dtStr: string) {
        return DateTool.beautify(dtStr, {appendOrigin: false, ignMs: true, ignTimezone: false});
    }

    public static beautify(dtStr: string, p: BeautyParam|null): string {
        if(!dtStr) return dtStr;
        let s = dtStr.replace("T", " ");
        s = s.replace("+0000", "");
        s = s.replace(".000", "");
        if(p && p.ignMs) {
            let msIdxBeg = 9999;
            let msIdxEnd = 9999;
            let sn = "";
            for(let i = 0; i < s.length; i++) {
                let ch = s.charAt(i);
                if(ch === '.') {
                    msIdxBeg = i;
                    msIdxEnd = i+3;
                    sn += " ";
                }
                if(i < msIdxBeg || i > msIdxEnd) {
                    sn += ch;
                }
            }
            s = sn;
        }

        if(p && p.ignTimezone) {
            let timezoneIdxBeg = 9999;
            let timezoneIdxEnd = 9999;
            let sn = "";
            for(let i = 0; i < s.length; i++) {
                let ch = s.charAt(i);
                if(ch === '+') {
                    timezoneIdxBeg = i;
                    timezoneIdxEnd = i+5;
                    sn += " ";
                }
                if(i < timezoneIdxBeg || i > timezoneIdxEnd) {
                    sn += ch;
                }
            }
            s = sn;
        }

        if(p && p.appendOrigin) {
            s += " 原始日期串：" + dtStr;
        }
        return s;
    }
} 