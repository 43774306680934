import React, { RefObject } from 'react';
import { Button, Space, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/Table';

import { RoutePath } from '../../../route/path';
import { labApi } from '../../../api/console/lab';
import DemoEntityEdit from './DemoEntityEdit';
import DemoEntityFilter from './DemoEntityFilter';
import { IPagingState, StdItemPageLimit } from '../../../part/StdItemPageLimit';
import DateTool from '../../../util/DateTool';
import Configure from '../../../api/Configure';

interface IState extends IPagingState {
    sth: string;
}

export default class DemoEntityIndex extends StdItemPageLimit<IState> {

    private editor: RefObject<DemoEntityEdit> = React.createRef();;

    state = {
        ...this.getDefaultState(),
        sth:  Configure.Gl(1755,'演示条目')
    };

    getCols(): ColumnsType<any> {
        return [
            { title: Configure.Gl(1055,'ID'), dataIndex: 'id', width: 80, fixed: 'left' },
            { title: Configure.Gl(1499,"名称"), dataIndex: 'name',  width: 180, fixed: 'left' },
            { title: 'double值', dataIndex: 'valDouble',  width: 100 },
            { title: 'float值', dataIndex: 'valFloat',  width: 100 },
            { title: 'int值', dataIndex: 'valInt',  width: 100 },
            { title: 'long值', dataIndex: 'valLong',  width: 100 },
            { title: 'short值', dataIndex: 'valShort',  width: 100 },
            { title: 'byte值', dataIndex: 'valByte',  width: 100 },
            { title: 'boolean值', dataIndex: 'valBoolean',  width: 100, render: (value) => value?'√':'x' },
            { title: 'dtDate', dataIndex: 'dtDate',  width: 200, render: DateTool.beautifyRaw },
            { title: 'dtTimestamp', dataIndex: 'dtTimestamp',  width: 200, render: DateTool.beautifyRaw },
            { title: 'dtLocalDateTime', dataIndex: 'dtLocalDateTime',  width: 200, render: DateTool.beautifyRaw },
            { title: 'dtZonedDateTime', dataIndex: 'dtZonedDateTime',  width: 200, render: DateTool.beautifyRaw },
            { title: 'tsDate', dataIndex: 'tsDate',  width: 200, render: DateTool.beautifyRaw },
            { title: 'tsTimestamp', dataIndex: 'tsTimestamp',  width: 200, render: DateTool.beautifyRaw },
            { title: 'tsLocalDateTime', dataIndex: 'tsLocalDateTime',  width: 200, render: DateTool.beautifyRaw },
            { title: 'tsZonedDateTime', dataIndex: 'tsZonedDateTime',  width: 200, render: DateTool.beautifyRaw },
            {
                title: Configure.Gl(1754,'编辑操作'),
                width: 180,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117,"编辑")}</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133,'删除')}</Button>
                    </Space>);
                },
            },
        ];
    }

    getFilterUI() {
        return (<Space>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389,'新增')}{this.state.sth}</Button>
            <DemoEntityFilter search={(f: any) => this.onFilterChange(f)}></DemoEntityFilter>
        </Space>);
    }

    getEditorUI() {
        return (<DemoEntityEdit ref={this.editor} reload={() => this.reload()} ></DemoEntityEdit>);
    }

    getPath() { return RoutePath.LAB_DEMO_ENTITY_INDEX; }

    async reloadForData(query: any) {
        let data = await labApi.listDemo(query) as any;
        return data;
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1391,'你确定要删除') + item.id + ' (' + item.name + ')?',
            onOk() {
                labApi.deleteDemo(item.id).then(d => that.reload());
            }
        });
    }

}