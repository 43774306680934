import React from 'react';
import { Modal, Select } from 'antd';
import moment from 'moment';
import Configure from '../api/Configure';
import { SelectOpt } from '../def/CommonDef';

export interface IProps {
    reload: Function
}

export interface IState {
    visible: boolean;
    createMode: boolean;
    item: any
}

export class StdItemEdit<P extends IProps, S extends IState> extends React.Component<P, S> {

    buildInitState() {
        return {
            visible: false,
            createMode: true,
            item: {} as any
        }
    }

    componentDidMount() {
        console.log("StdItemEdit::componentDidMount")
    }

    render() {
        const { visible, createMode } = this.state;
        return (
            <Modal title={createMode ? Configure.Gl(1116,"新建") : Configure.Gl(1117,"编辑")} visible={visible} 
                onOk={this.handleModalOk} onCancel={this.handleModalCancel} footer={null} destroyOnClose>
                {this.renderForm()}
            </Modal>
        );
    }

    renderForm() {
        return (<div></div>);
    }

    handleModalOk = () => {
        this.setState({ visible: false });
    }

    handleModalCancel = () => {
        this.setState({ visible: false });
    }
    
    onFormFinishFailed = (errorInfo: any) => {
       console.error('onFormFinishFailed:', errorInfo);
    }

    getDefaultItem = () => { return {}; }

    showCreate = (item?: any) => {
        let a = null;
        if(item == null) {
            a = this.getDefaultItem();
        } else {
            a = {...item};
            a.id = null;
        }
        this.setState({ visible: true, createMode: true, item: a });
    }

    showUpdate = (item: any) => {
        console.log('showUpdate item=', item);
        this.setState({ visible: true, createMode: false, item: item });
    }

    parseZone(dtStr:string) {
        if(!dtStr) return "";
        return moment.parseZone(dtStr);
    }

    protected optUI(optList: SelectOpt[]) {
        let ui:any[] = [];
        optList.forEach((opt: SelectOpt) => ui.push(<Select.Option key={opt.value} value={opt.value}>{opt.label}</Select.Option>));
        return ui;
    }
}