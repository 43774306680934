import React from 'react';
import { Form, Button, Switch, Select } from 'antd';
import { StdItemEdit, IProps, IState } from '../../part/StdItemEdit';
import { staffRoleApi } from '../../api/console/staff.role';
import Configure from '../../api/Configure';

interface ThisState extends IState {
    roleOpt: any[],
}

export default class StaffRolePermissionEdit extends StdItemEdit<IProps, ThisState> {

    state = {
        ...super.buildInitState(),
        roleOpt: [] as any[],
    }

    constructor(props: IProps, context: any) {
        super(props, context);
        staffRoleApi.optionList().then(d => this.setState({ roleOpt: d }))
    }

    renderForm() {
        const { createMode, item } = this.state;
        console.log("拿到的item=", item);
        const roleOptUi: any[] = [];
        const { roleOpt } = this.state;
        roleOpt.forEach(opt => {
            roleOptUi.push(<Select.Option value={opt.value} key={opt.value}>{opt.label}</Select.Option>);
        })
        return (
            <Form
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 20 }}
                name="basic"
                initialValues={item}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}
            >
                <Form.Item label={Configure.Gl(1000, "角色")} name="roleId">
                    <Select>{roleOptUi}</Select>
                </Form.Item>
                <Form.Item label={Configure.Gl(1535, "管理员")} name="admin" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1539, "基础权限")} name="p0" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1538, "运维权限")} name="p1" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1540, "角色冻结与禁言")} name="p2" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1541, "礼包码权限")} name="p3" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1542, "基础统计权限")} name="p4" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1544, "手动支付权限")} name="p5" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1545, "支付数据读取权限")} name="p6" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1548, "邮件读取权限")} name="p7" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1549, "邮件撰写权限")} name="p8" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1550, "邮件审批权限")} name="p9" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1551, "游戏通知权限")} name="p10" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1552, "全服公告权限")} name="p11" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1553, "高级统计权限")} name="p12" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1554, "单日充值返利邮件编辑")} name="p13" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1555, "单日充值返利邮件审核")} name="p14" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1556, "无附件邮件审核")} name="p15" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1557, "订单查询")} name="p16" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1039, "产出消耗查询")} name="p17" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1559, "单服充值排行")} name="p18" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1560, "应急通道")} name="p19" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1796, "留存分析")} name="p20" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1797, "功能参与分析")} name="p21" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1798, "游戏实时状态")} name="p22" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1799, "游戏监控汇报")} name="p23" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1800, "角色背包物品查询")} name="p24" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1801, "角色背包物品扣减")} name="p25" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1802, "角色钱包数值查询")} name="p26" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1803, "角色钱包数值扣减")} name="p27" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1804, "vip客服功能")} name="p28" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1805, "每日简报")} name="p29" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1806, "英雄查询")} name="p30" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1807, "LTV统计")} name="p31" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1808, "元宝商城统计")} name="p32" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(Configure.GL_UNDEFINED, "角色关键数据")} name="p33" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit"> {createMode ? Configure.Gl(1387, "保存新增") : Configure.Gl(1388, "保存编辑")} </Button>
                </Form.Item>
            </Form>
        );
    }


    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        //if(true) return;
        const { createMode } = this.state;
        if (createMode) {
            staffRoleApi.createPermission(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            });
        } else {
            staffRoleApi.updatePermission(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            })
        }
    }

}