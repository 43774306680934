import React, { Key, RefObject } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Input, InputNumber, message, Select, Space, Divider, Avatar } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import SrvGameSelectEdit from '../../srv/SrvGameSelectEdit';
import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import Configure from '../../../api/Configure';
import { StdItemPage } from '../../../part/StdItemPage';
import TextArea from 'antd/lib/input/TextArea';
import { gmVipApi } from '../../../api/console/gm.vip';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { UserOutlined } from '@ant-design/icons';
import { SearchOutlined, ApiOutlined } from '@ant-design/icons';
import UpDataImage from './UpDataImage';

export default class CustomerVipIndex extends StdItemPage<any> {

    private editor: RefObject<SrvGameSelectEdit>;
    private updateEditor: RefObject<UpDataImage>;
    input: any;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
        this.updateEditor = React.createRef();
    }

    state = {
        srvCodes: [],
        dimension: 'ip',
        srvGroupId: 0,
        id: 0,
        channelCode: '',
        picId: 0,
        mainShow: false,
        money: 0,
        pageContent: '',
        systemShow: false,
        mainStr: "",
        systemStr: "",
        contactContent: "",
        itemTotal: undefined,
        itemList: [],
        queryFilter: {
            srvCodes: [],
            channelCode: '',
        },
        queryPagination: {
            page: 1,
            pageSize: 100
        }
    }
    formRef = React.createRef<FormInstance>();
    render() {
        const { pageContent } = this.state;
        return (
            <HomeLayout path={RoutePath.GM_VIP_CUSTOMER_INDEX}>
                <Form
                    ref={this.formRef}
                    layout="vertical"
                    name="basic"
                    onFinish={this.onFormFinish}
                    onFinishFailed={this.onFormFinishFailed}
                    initialValues={{
                        beginTime: moment().startOf('day'),
                    }}
                >
                    <Form.Item>
                        <Space>
                            <Form.Item style={{ float: 'left' }}>
                                <SrvGameSelectEdit ref={this.editor} selectType={"checkbox"} handleOk={(v: any[]) => this.handleOk(v)} ></SrvGameSelectEdit>
                            </Form.Item>
                            <Form.Item name="srvCodes" style={{ float: 'left', width: 200 }}>
                                <Input placeholder={Configure.Gl(1198, "选择游戏服务器(srvCode)")} />
                            </Form.Item>
                            <Form.Item name="channelCode" style={{ float: 'left', width: 200 }}>
                                <Input addonBefore={Configure.Gl(1812, "选择渠道")} placeholder={Configure.Gl(1810, "请填写渠道ID")} onChange={e => this.changeChannelID(e.target.value)} />
                            </Form.Item>
                            <Form.Item name="contactContent" style={{ float: 'left', width: 300 }}>
                                <Input addonBefore={Configure.Gl(1813, "联系信息")} placeholder={Configure.Gl(1811, "请填写(联系信息)")} />
                            </Form.Item>
                            <Form.Item style={{ float: 'right' }}>
                                <Button type="primary" shape="round" icon={<SearchOutlined />} onClick={this.doQuery}>{Configure.Gl(1180, '查询')}</Button>
                            </Form.Item>
                            <Form.Item name="id" style={{ float: 'right', width: 10 }} >
                                <Input addonBefore={Configure.Gl(1055, "id")} placeholder={Configure.Gl(1055, "id")} disabled={true} />
                            </Form.Item>
                        </Space>
                    </Form.Item>

                    <Form.Item name="mainShow" label={Configure.Gl(1814, "主界面显示")}>
                        <Select placeholder={Configure.Gl(1826, "选择是否展示在主界面")}
                            style={{ width: 200 }}
                            onChange={this.handleSelectMain}
                        >
                            <Select.Option value="true">{Configure.Gl(1185, "是")}</Select.Option>
                            <Select.Option value="false">{Configure.Gl(1186, "否")}</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="systemShow" label={Configure.Gl(1815, "系统界面显示")}>
                        <Select placeholder={Configure.Gl(1827, "选择是否展示在系统界面")}
                            style={{ width: 200 }}
                            onChange={this.handleSelectSystem}
                        >
                            <Select.Option value="true">{Configure.Gl(1185, "是")}</Select.Option>
                            <Select.Option value="false">{Configure.Gl(1186, "否")}</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="money" label={Configure.Gl(1809, "充值金额")}>
                        <InputNumber style={{ width: 100 }} min={0} max={99999999} defaultValue={0} onChange={this.setDefValue} onBlur={this.onBlur} />
                    </Form.Item>

                    <Form.Item name="pageContent" label={Configure.Gl(1816, "客服页面信息")}>
                        <TextArea
                            style={{ width: 500 }}
                            value={pageContent}
                            rows={8}
                            onChange={e => this.onControlled(e.target.value)}
                            placeholder={Configure.Gl(1825, '描述信息...')}
                            showCount maxLength={2000} />
                    </Form.Item>
                    <Form.Item name="picId" label={Configure.Gl(1823, "缩略图(默认为数字编号)")} style={{ float: 'left', width: 200 }}>
                        <Input placeholder={Configure.Gl(1824, "缩略图编号")} />
                    </Form.Item>
                    <Divider />
                    {/* <Form.Item style={{ float: 'left' }}>
                        <UpDataImage ref={this.updateEditor} ></UpDataImage>
                    </Form.Item> */}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" onClick={() => this.doCreate(this.formRef.current?.getFieldsValue(), this.state.itemList.length)} >{Configure.Gl(1868, '创建')}</Button>

                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" icon={<ApiOutlined />} onClick={() => this.vipmodify(this.formRef.current?.getFieldsValue())}>{Configure.Gl(1869, '修改保存')}</Button>
                    </Form.Item>
                </Form>
                <Divider />
                {this.renderTable()}
                <Divider />
            </HomeLayout>
        );
    }


    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1006, "平台"),
                width: 200,
                dataIndex: 'id',
            },
            {
                title: Configure.Gl(1844, '渠道'),
                dataIndex: 'channelCode',
                width: 100,
            },
            {
                title: Configure.Gl(1234, '服务器'),
                dataIndex: 'srvCodes',
                width: 100,
            },
            {
                title: Configure.Gl(1814, "主界面显示"),
                dataIndex: 'mainStr',
                width: 90,
            },
            {
                title: Configure.Gl(1815, "系统界面显示"),
                dataIndex: 'systemStr',
                width: 80,
            },
            {
                title: Configure.Gl(1843, "金额"),
                dataIndex: 'money',
                width: 100,
            },
            {
                title: Configure.Gl(1813, "联系信息"),
                dataIndex: 'contactContent',
                width: 100,
            },
            {
                title: Configure.Gl(1817, "缩略图"),
                dataIndex: 'picId',
                render: (_, record) =>
                    <>
                        <Avatar
                            icon={<UserOutlined />}
                            shape="square"
                            size={32}
                            src={"https://client.fs.yezixigame.com/cli/houtai/VIPkefu/touxiang" + record.picId + ".png"} />
                    </>,
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
            },
            {
                title: Configure.Gl(1057, '操作'),
                dataIndex: 'operation',
                render: (_, record) =>
                    <>
                        <Button onClick={() => this.doModify(record)}>{Configure.Gl(1132, '修改')}</Button>
                        <Button onClick={() => this.doDelete(record)}>{Configure.Gl(1133, '删除')}</Button>
                    </>,
                ellipsis: {
                    showTitle: false,
                },
                width: 220,
            },
            {
                title: Configure.Gl(1842, "描述"),
                dataIndex: 'pageContent',
                width: 100,
            },
        ];
    }

    renderTable() {
        const { itemTotal, queryPagination } = this.state;
        const columns: ColumnsType<any> = this.getCols();
        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            defaultPageSize: queryPagination.pageSize,
            total: itemTotal
        };

        return (<Form>
            <Table dataSource={this.state.itemList} columns={columns}
                pagination={pagination} scroll={{ x: this.getColsWidth(columns), y: 512 }} onChange={(p, f, s, e) => { this.onPageChange(p, f, s, e) }} />
        </Form>);
    }


    changeChannelID = (id: any) => {
        this.setState({ channelCode: id });
    };

    onPageChange(pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        this.setState({
            queryPagination: {
                page: pagination.current,
                pageSize: pagination.pageSize
            }
        }, () => {
            this.reload();
        });
    }

    async vipmodify(values: any) {
        //let values = this.formRef.current?.getFieldsValue();
        if (values.channelCode === undefined) {
            message.error(Configure.Gl(1890, "channelID格式不对"));
            return;
        } else if (values.contactContent === undefined) {
            message.error(Configure.Gl(1891, "contactContent格式不对"));
            return;
        } else if (values.srvCodes.length <= 0) {
            message.error(Configure.Gl(1892, "srvCodes格式不对"));
            return;
        }
        if (values.mainShow === Configure.Gl(1185, "是")) {
            values.mainShow = true;
        } else if (values.mainShow === Configure.Gl(1186, "否")) {
            values.mainShow = false;
        }
        if (values.systemShow === Configure.Gl(1185, "是")) {
            values.systemShow = true;
        } else if (values.systemShow === Configure.Gl(1186, "否")) {
            values.systemShow = false;
        }
        let r = await gmVipApi.update(values) as any;
        console.log("当前数据是:", r);
        this.reload();

    }

    //回填
    backfill(record: any) {
        this.formRef.current?.setFieldsValue({ id: record.id });
        this.formRef.current?.setFieldsValue({ channelCode: record.channelCode });
        this.formRef.current?.setFieldsValue({ srvCodes: record.srvCodes });
        this.formRef.current?.setFieldsValue({ mainShow: record.mainShow });
        this.formRef.current?.setFieldsValue({ systemShow: record.systemShow });
        this.formRef.current?.setFieldsValue({ contactContent: record.contactContent });
        this.formRef.current?.setFieldsValue({ money: record.money });
        this.formRef.current?.setFieldsValue({ picId: record.picId });
        this.formRef.current?.setFieldsValue({ pageContent: record.pageContent });
    }

    async reload() {
        const { queryPagination, queryFilter } = this.state;
        queryFilter.channelCode = this.state.channelCode;
        queryFilter.srvCodes = this.state.srvCodes;
        const query = { ...queryPagination, ...queryFilter };
        console.log("请求参数为：", query);
        if (this.state.channelCode === undefined || this.state.channelCode.length <= 0) {
            message.error('请填写渠道ID!');
            return;
        }
        this.setState({ spinLoad: true })
        let data = await gmVipApi.list(query) as any;
        for (let i = 0; i < data.records.length; i++) {
            data.records[i].mainStr = data.records[i].mainShow === true ? Configure.Gl(1185, "是") : Configure.Gl(1186, "否");
            data.records[i].systemStr = data.records[i].systemShow === true ? Configure.Gl(1185, "是") : Configure.Gl(1186, "否");
        }
        this.addKeyToData(data.records);
        this.setState({ itemList: data.records });
        this.setState({ spinLoad: false });
    }

    doQuery = async () => {
        this.reload();
        console.log('dfdfd');
    }

    doCreate = async (values: any, maxnum: number) => {
        console.log("VIP服务待提交", values);
        if (values.channelCode === undefined) {
            message.error(Configure.Gl(1890, "channelID格式不对"));
            return;
        } else if (values.contactContent === undefined) {
            message.error(Configure.Gl(1891, "contactContent格式不对"));
            return;
        } else if (values.srvCodes === undefined) {
            message.error(Configure.Gl(1892, "srvCodes格式不对"));
            return;
        }
        values.id = 0;
        let r = await gmVipApi.create(values);
        console.log("VIP服务提交结果：", r);
        this.reload();
    }

    //修改
    doModify = (record: any) => {
        this.backfill(record);
    }

    //删除
    doDelete = async (record: any) => {
        let r = await gmVipApi.delete(record) as any;
        console.log("删除结果：", r);
        this.reload();
    }

    setShowInter(value: any, record: any) {
        console.log('');
    }

    //设置默value值
    setDefValue = (e: any) => {
        if (e > 0) {
            this.setState({ money: e });
        } else {
            message.error(Configure.Gl(1828, "数值不能未负数!"));
        }
    }

    onBlur = (e: any) => {
        if (e.target.defaultValue > 0) {
            this.setState({ money: e.target.defaultValue });
        }
    }

    onControlled = (value: any) => {
        this.setState({ pageContent: value });
    };


    handleSelectMain = (value: any) => {
        this.setState({ mainShow: value });
    }

    handleSelectSystem = (value: any) => {
        this.setState({ systemShow: value });
    }

    saveInputRef = (input: any) => {
        this.input = input;
    }

    handleOk(v: any[]) {
        if (v.length > 0) {
            let codes = [];
            for (let i = 0; i < v.length; i++) {
                codes.push(v[i].code);
            }
            this.formRef.current?.setFieldsValue({ srvCodes: codes });
            this.setState({ srvCodes: codes });
        }
    }

    onFormFinish = async () => {

    }

    onFormFinishFailed = (errorInfo: any) => {
        console.error('onFormFinishFailed:', errorInfo);
    }

}