import React from 'react';
import { Input, Select } from 'antd';
import { AbsFilter, StdItemFilter } from '../../../part/StdItemFilter';
import { srvGroupApi } from '../../../api/console/srv.group';
import Configure from '../../../api/Configure';

class Filter extends AbsFilter {
    srvCode?: string = undefined;
    srvName?: string = undefined;
    srvIndex?: number = undefined;
    srvEaten?: string = undefined;
    groupId?: number = undefined;
    delaySecondsMin?: number = undefined;
}

export default class GameRtFilter extends StdItemFilter {

    state = {
        srvCode: undefined as string | undefined,
        srvName: undefined as string | undefined,
        srvIndex: undefined as number | undefined,
        srvEaten: undefined as string | undefined,
        groupId: undefined as number | undefined,
        groupOpt: [] as any[],
        delaySecondsMin: undefined as number | undefined,
    };

    filter: Filter = new Filter();

    getFilter() { 
        return this.filter;
    }

    componentDidMount() {
        srvGroupApi.optionList().then(d => this.setState({ groupOpt: d }))
    }

    render() {
        const { groupOpt } = this.state;
        const groupOptUi: any[] = this.optUI(groupOpt);
        return (
            <span>
                <Input placeholder={Configure.Gl(1079,"游戏服编码")} value={this.state.srvCode} onChange={e => this.setValue('srvCode', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1080,"游戏服名")} value={this.state.srvName} onChange={e => this.setValue('srvName', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1081,"游戏服序号")} value={this.state.srvIndex} onChange={e => this.setValue('srvIndex', e.target.value)} style={{width: 120}}/>
                <Select placeholder={Configure.Gl(1082,"被合服吞并")} value={this.state.srvEaten} onChange={v => this.setValue('srvEaten', v)}>
                    <Select.Option key="0" value="">所有</Select.Option>
                    <Select.Option key="1" value="true">吞并</Select.Option>
                    <Select.Option key="2" value="false">独立</Select.Option>
                </Select>
                <Select placeholder={Configure.Gl(1083,"服务器组")} value={this.state.groupId} onChange={v => this.setValue('groupId', v)} style={{width: 180}}>{groupOptUi}</Select>
                <Input placeholder={Configure.Gl(1084,"延迟大于?秒")} value={this.state.delaySecondsMin} onChange={e => this.setValue('delaySecondsMin', e.target.value)} style={{width: 120}}/>
                { this.renderCmd()}
            </span>
        );
    }

}