import React, { RefObject } from 'react';
import { Button, Space} from 'antd';
import { RoutePath } from '../../../route/path';
import { ColumnsType } from 'antd/lib/table/Table';
import { formatDate } from '../../../util/toolbox';
import RebateEdit from './RebateEdit';
import { RouteComponentProps } from 'react-router';
import { StdItemPageLimit } from '../../../part/StdItemPageLimit';
import { rebateApi } from '../../../api/console/gm.rebate';
import Search from 'antd/lib/input/Search';
import Configure from '../../../api/Configure';

export default class RebateEditIndex extends StdItemPageLimit<any> {
    private editor: RefObject<RebateEdit>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
    }

    state = {
        itemTotal: undefined,
        queryPagination: {
            page: 1,
            pageSize: 10
        },
        itemList: [],
    }

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1118,'id'),
                dataIndex: ['gmRebateEntity','id'],
                width: 80,
            },
            {
                title: Configure.Gl(1124,'标题'),
                dataIndex: ['gmRebateEntity','title'],
                width: 240,
            },
            {
                title: Configure.Gl(1212,'类型'),
                dataIndex: ['gmRebateEntity','type'],
                width: 80,
                render: (value: any) => value === 1 ? Configure.Gl(1275,"单日累计充值") : Configure.Gl(1276,"累计充值")
            },
            {
                title: Configure.Gl(1088,'状态'),
                dataIndex: ['gmRebateEntity','status'],
                width: 80,
                render: (value: any) => value === 1 ? Configure.Gl(1201,"未审批") : value === 2 ? Configure.Gl(1202,"同意") : Configure.Gl(1203,"拒绝")
            },
            // {
            //     title: '玩家信息',
            //     dataIndex: 'gmRebateTars',
            //     width: 600,
            //     render: (value: any[]) => "玩家id: " + value[0].masterIdHex + ", 玩家姓名：" + value[0].name + ", 服务器编号: " + value[0].srvCode
            // },
            {
                title: Configure.Gl(1057,'操作'),
                dataIndex: ['gmRebateEntity','operate'],
                width: 120,
                render: (value: any, record: any, index: number) => {
                    return <Space>
                        <Button type="default" onClick={() => {
                            let master = Configure.Gl(1277,"发送玩家: ");
                            let masters = record.gmRebateTars as any[];
                            masters.forEach(v => master += v.masterIdHex + "  " + v.name + "  " + v.srvCode + "\n");
                            let previewContent = `${master}发送奖励: ${record.gmRebateEntity.rewardDesc}\n邮件标题: ${record.gmRebateEntity.title}\n邮件正文: ${record.gmRebateEntity.content}\n`;
                            alert(previewContent);
                        }}>{Configure.Gl(1204,"查看详情")}</Button>
                    </Space>;
                },
            },
            {
                title: Configure.Gl(1279,'玩家信息'),
                dataIndex: 'gmRebateTars',
                width: 240,
                render: (value: any[]) => value[0].masterIdHex + " " + value[0].name + " " + value[0].srvCode,
            },
            {
                title: Configure.Gl(1205,'提交人用户名'),
                width: 80,
                dataIndex: ['gmRebateEntity','submitUsername'],
            },
            {
                title: Configure.Gl(1206,'提交人昵称'),
                width: 120,
                dataIndex: ['gmRebateEntity','submitNickname'],
            },
            {
                title: Configure.Gl(1207,'提交时间'),
                width: 120,
                dataIndex: ['gmRebateEntity','submitTime'],
                render: (v, r) => formatDate(v)
            },
            {
                title: Configure.Gl(1208,'审核人用户名'),
                width: 80,
                dataIndex: ['gmRebateEntity','approveUsername'],
            },
            {
                title: Configure.Gl(1209,'审核人昵称'),
                width: 120,
                dataIndex: ['gmRebateEntity','approveNickname'],
            },
            {
                title: Configure.Gl(1210,'审核时间'),
                width: 120,
                dataIndex: ['gmRebateEntity','approveTime'],
                render: (v, r) => formatDate(v)
            },
            {
                title: Configure.Gl(1125,'内容'),
                width: 1200,
                dataIndex: ['gmRebateEntity','content'],
            }
        ];
    }

    getFilterUI() {
        return (
            <Space>
                <Button type="primary" onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1230,'编辑新邮件')}</Button>
                <Search placeholder={Configure.Gl(1280,"输入要搜索的玩家id")} onSearch={v=>{this.onSearchMaster(v)}} style={{ width: 250 }} />
            </Space>
        );
    }

    onSearchMaster(v: any){
        let filter = v && v.length > 0?{masterIdHex: v}: {}; 
        this.setState({queryFilter: filter}, ()=> {
            this.reload();
        });
    }

    getEditorUI() {
        return (<RebateEdit ref={this.editor} reload={() => this.reload()}></RebateEdit>);
    }

    getPath() { return RoutePath.REBATE_EDIT_INDEX; }

    async reloadForData(query: any){
        let data = await rebateApi.list(query) as any;
        this.addKeyToData(data.records);
        return data;
    }

    doAccept(item : any) {
        rebateApi.complete({status: 2,id: item.id}).then(() => {
          this.reload();
        });
    }

    doRefuse(item : any) {
        rebateApi.complete({status: 3, id: item.id}).then(() => {
          this.reload();
        });
    }
}