import React from 'react';
import { Input, DatePicker } from 'antd';
import { AbsFilter, StdItemFilter } from '../../../part/StdItemFilter';
import Configure from '../../../api/Configure';

class Filter extends AbsFilter {
    type?: string = undefined;
    status?: string = undefined;
    tag1?: string = undefined;
    tag2?: string = undefined;
    tag3?: string = undefined;
    createTimeBegin?: moment.Moment = undefined;
    createTimeEnd?: moment.Moment = undefined;
}

export default class SysTaskFilter extends StdItemFilter {

    state = {
        type: undefined as string | undefined,
        status: undefined as string | undefined,
        tag1: undefined as string | undefined,
        tag2: undefined as string | undefined,
        tag3: undefined as string | undefined,
        createTimeBegin: undefined as moment.Moment | undefined,
        createTimeEnd: undefined as moment.Moment | undefined,
    };

    filter:Filter = new Filter();

    getFilter() { return this.filter; }

    async componentDidMount() {
    }

    render() {
        return (
            <span>
                <Input placeholder={Configure.Gl(1212,"类型")} value={this.state.type} onChange={e => this.setValue('type', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1088,'状态')} value={this.state.status} onChange={e => this.setValue('status', e.target.value)} style={{width: 120}}/>
                <Input placeholder={Configure.Gl(1636,"标签1")} value={this.state.tag1} onChange={e => this.setValue('tag1', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1637,"标签2")} value={this.state.tag2} onChange={e => this.setValue('tag2', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1638,"标签3")} value={this.state.tag3} onChange={e => this.setValue('tag3', e.target.value)} style={{width: 180}}/>
                <DatePicker placeholder={Configure.Gl(1635,"创建开始时间")} value={this.state.createTimeBegin} showTime onChange={e => this.setValue('createTimeBegin', e)} />
                <DatePicker placeholder={Configure.Gl(1639,"创建结束时间")} value={this.state.createTimeEnd} showTime onChange={e => this.setValue('createTimeEnd', e)} />
                { this.renderCmd() }
            </span>
        );
    }

}