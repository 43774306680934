import React, { Key, RefObject } from 'react';
import { Button, Divider, Form, Input, message, Spin, Table } from 'antd';
import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import Configure from '../../../api/Configure';
import SrvGameSelectEdit from '../../srv/SrvGameSelectEdit';
import { RouteComponentProps } from 'react-router';
import { FormInstance } from 'antd/lib/form';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { StdItemPage } from '../../../part/StdItemPage';
import ClipBoard from '../../../util/clipboard';
import { SearchOutlined } from '@ant-design/icons';
import { heroSeachApi } from '../../../api/stat/heroSeach';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import ExcelEdit from '../../stat/preserve/ExcelEdit';

export default class heroSeachIndex extends StdItemPage<any> {

    private editor: RefObject<SrvGameSelectEdit>;
    private excel: RefObject<ExcelEdit>;
    private screenArr: any[] = [];
    state = {
        srvCode: [],
        heroName: "",
        heroTid: "",
        spinLoad: false,
        itemTotal: undefined,
        queryPagination: {
            page: 1,
            pageSize: 100
        },
        itemList: [],
        note: ""
    }

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
        this.excel = React.createRef();
    }

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1915, "武将名称"),
                width: 180,
                dataIndex: 'name',
            },
            {
                title: Configure.Gl(Configure.GL_UNDEFINED, "武将中文名称"),
                width: 180,
                dataIndex: 'nameZH',
            },
            {
                title: Configure.Gl(1916, "累计获得人数"),
                dataIndex: 'allstar',
                width: 100,
            },
            { title: Configure.Gl(1917, "累计5星获得人数"), dataIndex: 'star5Sum', width: 100 },
            { title: Configure.Gl(1918, "累计6星获得人数"), dataIndex: 'star6Sum', width: 100 },
            { title: Configure.Gl(1919, "累计7星获得人数"), dataIndex: 'star7Sum', width: 100 },
            { title: Configure.Gl(1920, "累计8星获得人数"), dataIndex: 'star8Sum', width: 100 },
            { title: Configure.Gl(1921, "累计9星获得人数"), dataIndex: 'star9Sum', width: 100 },
            { title: Configure.Gl(1922, "累计10星获得人数"), dataIndex: 'star10Sum', width: 100 },
            { title: Configure.Gl(1923, "累计11星获得人数"), dataIndex: 'star11Sum', width: 100 },
            { title: Configure.Gl(1924, "累计12星获得人数"), dataIndex: 'star12Sum', width: 100 },
            { title: Configure.Gl(1925, "累计13星获得人数"), dataIndex: 'star13Sum', width: 100 },
            { title: Configure.Gl(1926, "累计14星获得人数"), dataIndex: 'star14Sum', width: 100 },
        ];
    }

    formRef = React.createRef<FormInstance>();
    render() {
        const { itemTotal, queryPagination } = this.state;
        const columns: ColumnsType<any> = this.getCols();
        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            defaultPageSize: queryPagination.pageSize,
            total: itemTotal
        };
        return (<HomeLayout path={RoutePath.GM_HERO_SEACH_INDEX}>
            <Spin spinning={this.state.spinLoad} tip={Configure.Gl(1774, '获取服务器数据中...')}>
                <Form
                    ref={this.formRef}
                    style={{ float: 'right' }}
                    layout="vertical"
                    name="basic"
                    onFinish={this.onFormFinish}
                    onFinishFailed={this.onFormFinishFailed}
                >
                    <Button type="primary" shape="round" icon={<SearchOutlined />} onClick={this.doQuery}>{Configure.Gl(1180, '查询')}</Button>
                    <Form.Item style={{ float: 'left' }}>
                        <SrvGameSelectEdit ref={this.editor} selectType={"checkbox"} handleOk={(v: any[]) => this.handleOk(v)} ></SrvGameSelectEdit>
                    </Form.Item>
                    <Form.Item name="srvCode" style={{ float: 'left', width: '30%' }}>
                        <Input placeholder={Configure.Gl(1198, "选择游戏服务器(srvCode)")} disabled />
                    </Form.Item>
                    <Form.Item name="heroId" style={{ float: 'left', width: '20%' }}>
                        <Input placeholder={Configure.Gl(1912, '英雄ID')} onChange={e => this.onChangeID(e.target.value)} />
                    </Form.Item>
                    <Form.Item name="heroName" style={{ float: 'left', width: '20%' }}>
                        <Input placeholder={Configure.Gl(1913, '英雄名称')} onChange={e => this.onChangeName(e.target.value)} />
                    </Form.Item>
                    <Form.Item style={{ float: 'left' }}>
                        <ExcelEdit ref={this.excel} onExcelprot={(v: any[]) => this.handleExeclOk(v)}></ExcelEdit>
                    </Form.Item>
                </Form>
                <Divider />
                <Table dataSource={this.state.itemList} columns={columns}
                    pagination={pagination} scroll={{ x: this.getColsWidth(columns), y: 512 }} onChange={(p, f, s, e) => { this.onPageChange(p, f, s, e) }} />
            </Spin>

        </HomeLayout>);
    }

    onChangeID = (e: any) => {
        this.setState({ heroTid: e });
    }

    onChangeName = (e: any) => {
        this.setState({ heroName: e });
    }

    onPageChange(pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        this.setState({
            queryPagination: {
                page: pagination.current,
                pageSize: pagination.pageSize
            }
        }, () => {
            this.reload();
        });
    }

    onFormFinish = (values: any) => {
        // if(!values.srvCode){
        //     alert(Configure.Gl(1199,"请先选择游戏服务器"));
        //     return;
        // }
        this.state.srvCode = values.srvCode;
        //this.setState({queryFilter:filter}, () => this.reload());
    }

    onFormFinishFailed = (errorInfo: any) => {
        console.error('onFormFinishFailed:', errorInfo);
    }

    async reload() {
    }

    handleExeclOk = (value: any) => {
        console.log('eee');
    }

    onControlled = (value: any) => {
        this.setState({ note: value });
    };

    handleChange = (SelectOption: any) => {

    }

    componentDidMount() {
        console.log("RoleBagIndex::componentDidMount()");
    }

    handleOk(v: any[]) {
        if (v.length > 0) {
            let codes = [];
            for (let i = 0; i < v.length; i++) {
                codes.push(v[i].code);
            }
            this.formRef.current?.setFieldsValue({ srvCode: codes });
            this.setState({ srvCode: codes });
        }
    }

    doQuery = async (query: any) => {
        this.setState({ spinLoad: true });
        this.setState({ itemList: [] });
        let filters = {
            svrCode: [],
        };
        filters.svrCode = this.state.srvCode;
        if (filters.svrCode.length <= 0) {
            message.error(Configure.Gl(1834, '服务器不能为空'));
            this.setState({ spinLoad: false });
            return;
        }

        this.screenArr.length = 0;
        if (filters.svrCode.length > 1) {
            this.MultipleQuery(filters.svrCode)
        } else {
            this.SingleQuery(filters);
        }

    }

    async MultipleQuery(codes: any) {
        let TempArr: any[] = [];
        for (let i = 0; i < codes.length; i++) {
            let filters = {
                svrCode: "",
            };
            filters.svrCode = codes[i];
            let data = await heroSeachApi.heroSeach({ filters }) as any;
            if (TempArr.length <= 0) {
                data.forEach((element: any) => {
                    let heroData: any = {
                        srvCode: element.srvCode,
                        name: element.name,
                        nameZH: Configure.GlExcel(element.heroTid),
                        heroTid: element.heroTid,
                        heroPid: element.heroPid,
                        star5Sum: element.star5Sum,
                        star6Sum: element.star6Sum,
                        star7Sum: element.star7Sum,
                        star8Sum: element.star8Sum,
                        star9Sum: element.star9Sum,
                        star10Sum: element.star10Sum,
                        star11Sum: element.star11Sum,
                        star12Sum: element.star12Sum,
                        star13Sum: element.star13Sum,
                        star14Sum: element.star14Sum,
                        allstar: element.star5Sum + element.star6Sum + element.star7Sum + element.star8Sum + element.star9Sum + element.star10Sum + element.star11Sum + element.star12Sum + element.star13Sum + element.star14Sum,
                    };
                    TempArr.push(heroData);
                });
            } else {
                data.forEach((element: any) => {
                    let heroData: any = {
                        srvCode: element.srvCode,
                        name: element.name,
                        nameZH: Configure.GlExcel(element.heroTid),
                        color: Configure.GlStarExcel(element.heroTid),
                        heroTid: element.heroTid,
                        heroPid: element.heroPid,
                        star5Sum: element.star5Sum,
                        star6Sum: element.star6Sum,
                        star7Sum: element.star7Sum,
                        star8Sum: element.star8Sum,
                        star9Sum: element.star9Sum,
                        star10Sum: element.star10Sum,
                        star11Sum: element.star11Sum,
                        star12Sum: element.star12Sum,
                        star13Sum: element.star13Sum,
                        star14Sum: element.star14Sum,
                        allstar: element.star5Sum + element.star6Sum + element.star7Sum + element.star8Sum + element.star9Sum + element.star10Sum + element.star11Sum + element.star12Sum + element.star13Sum + element.star14Sum,
                    };
                    this.arrSuperposition(TempArr, heroData);
                });

            }

        }

        let copyarr: any[] = [];
        if (this.state.heroName !== "") {
            TempArr.forEach((element: any) => {
                if (element.name === this.state.heroName) {
                    let heroData: any = {
                        srvCode: element.srvCode,
                        name: element.name,
                        nameZH: Configure.GlExcel(element.heroTid),
                        color: Configure.GlStarExcel(element.heroTid),
                        heroTid: element.heroTid,
                        star5Sum: element.star5Sum,
                        star6Sum: element.star6Sum,
                        star7Sum: element.star7Sum,
                        star8Sum: element.star8Sum,
                        star9Sum: element.star9Sum,
                        star10Sum: element.star10Sum,
                        star11Sum: element.star11Sum,
                        star12Sum: element.star12Sum,
                        star13Sum: element.star13Sum,
                        star14Sum: element.star14Sum,
                        allstar: element.star5Sum + element.star6Sum + element.star7Sum + element.star8Sum + element.star9Sum + element.star10Sum + element.star11Sum + element.star12Sum + element.star13Sum + element.star14Sum,
                    };
                    copyarr.push(heroData);
                }

            });
        } else if (this.state.heroTid !== "") {
            TempArr.forEach((element: any) => {
                if (element.heroTid === this.state.heroTid) {
                    let heroData: any = {
                        srvCode: element.srvCode,
                        name: element.name,
                        nameZH: Configure.GlExcel(element.heroTid),
                        color: Configure.GlStarExcel(element.heroTid),
                        heroTid: element.heroTid,
                        star5Sum: element.star5Sum,
                        star6Sum: element.star6Sum,
                        star7Sum: element.star7Sum,
                        star8Sum: element.star8Sum,
                        star9Sum: element.star9Sum,
                        star10Sum: element.star10Sum,
                        star11Sum: element.star11Sum,
                        star12Sum: element.star12Sum,
                        star13Sum: element.star13Sum,
                        star14Sum: element.star14Sum,
                        allstar: element.star5Sum + element.star6Sum + element.star7Sum + element.star8Sum + element.star9Sum + element.star10Sum + element.star11Sum + element.star12Sum + element.star13Sum + element.star14Sum,
                    };
                    copyarr.push(heroData);
                }
            });
        }

        if (copyarr.length <= 0) {
            this.addKeyToData(TempArr);
            this.setState({ itemList: TempArr });
            this.setState({ spinLoad: false });
            this.excel.current?.setOdataSource(TempArr, this.getCols());
        } else {
            this.addKeyToData(copyarr);
            this.setState({ itemList: copyarr });
            this.setState({ spinLoad: false });
            this.excel.current?.setOdataSource(copyarr, this.getCols());
        }
    }

    arrSuperposition(TempArr: any[], heroData: any) {
        let isNew: Boolean = false;
        TempArr.forEach((element: any) => {
            if (element.heroPid === heroData.heroPid) {
                element.star5Sum += heroData.star5Sum;
                element.star6Sum += heroData.star6Sum;
                element.star7Sum += heroData.star7Sum;
                element.star8Sum += heroData.star8Sum;
                element.star9Sum += heroData.star9Sum;
                element.star10Sum += heroData.star10Sum;
                element.star11Sum += heroData.star11Sum;
                element.star12Sum += heroData.star12Sum;
                element.star13Sum += heroData.star13Sum;
                element.star14Sum += heroData.star14Sum;
                element.allstar += heroData.allstar;
                isNew = true;
                return;
            }
        });
        if (!isNew) {
            TempArr.push(heroData);
        }
    }

    async SingleQuery(filters: any) {

        let finalarr: any[] = [];
        let data = await heroSeachApi.heroSeach({ filters }) as any;

        data.forEach((element: any) => {
            let heroData: any = {
                srvCode: element.srvCode,
                name: element.name,
                nameZH: Configure.GlExcel(element.heroTid),
                color: Configure.GlStarExcel(element.heroTid),
                heroTid: element.heroTid,
                star5Sum: element.star5Sum,
                star6Sum: element.star6Sum,
                star7Sum: element.star7Sum,
                star8Sum: element.star8Sum,
                star9Sum: element.star9Sum,
                star10Sum: element.star10Sum,
                star11Sum: element.star11Sum,
                star12Sum: element.star12Sum,
                star13Sum: element.star13Sum,
                star14Sum: element.star14Sum,
                allstar: element.star5Sum + element.star6Sum + element.star7Sum + element.star8Sum + element.star9Sum + element.star10Sum + element.star11Sum + element.star12Sum + element.star13Sum + element.star14Sum,
            };
            this.screenArr.push(heroData);
        });
        finalarr = this.copyArr(this.screenArr);
        console.log("英雄查询结果：", data);

        let copyarr: any[] = [];
        if (this.state.heroName !== "") {
            this.screenArr.forEach((element: any) => {
                if (element.name === this.state.heroName) {
                    let heroData: any = {
                        srvCode: element.srvCode,
                        name: element.name,
                        nameZH: Configure.GlExcel(element.heroTid),
                        color: Configure.GlStarExcel(element.heroTid),
                        heroTid: element.heroTid,
                        star5Sum: element.star5Sum,
                        star6Sum: element.star6Sum,
                        star7Sum: element.star7Sum,
                        star8Sum: element.star8Sum,
                        star9Sum: element.star9Sum,
                        star10Sum: element.star10Sum,
                        star11Sum: element.star11Sum,
                        star12Sum: element.star12Sum,
                        star13Sum: element.star13Sum,
                        star14Sum: element.star14Sum,
                        allstar: element.star5Sum + element.star6Sum + element.star7Sum + element.star8Sum + element.star9Sum + element.star10Sum + element.star11Sum + element.star12Sum + element.star13Sum + element.star14Sum,
                    };
                    copyarr.push(heroData);
                }

            });
            finalarr.length = 0;
        } else if (this.state.heroTid !== "") {
            this.screenArr.forEach((element: any) => {
                if (element.heroTid === this.state.heroTid) {
                    let heroData: any = {
                        srvCode: element.srvCode,
                        name: element.name,
                        nameZH: Configure.GlExcel(element.heroTid),
                        heroTid: element.heroTid,
                        star5Sum: element.star5Sum,
                        star6Sum: element.star6Sum,
                        star7Sum: element.star7Sum,
                        star8Sum: element.star8Sum,
                        star9Sum: element.star9Sum,
                        star10Sum: element.star10Sum,
                        star11Sum: element.star11Sum,
                        star12Sum: element.star12Sum,
                        star13Sum: element.star13Sum,
                        star14Sum: element.star14Sum,
                        allstar: element.star5Sum + element.star6Sum + element.star7Sum + element.star8Sum + element.star9Sum + element.star10Sum + element.star11Sum + element.star12Sum + element.star13Sum + element.star14Sum,
                    };
                    copyarr.push(heroData);
                }
            });
            finalarr.length = 0;
        }
        if (copyarr.length > 0) {
            finalarr = copyarr;
        }
        this.addKeyToData(finalarr);
        this.setState({ itemList: finalarr });
        this.setState({ spinLoad: false });
        this.excel.current?.setOdataSource(finalarr, this.getCols());
    }

    copyArr(org: any): any[] {
        let r: any[] = [];
        org.forEach((element: any) => {
            let heroData: any = {
                srvCode: element.srvCode,
                name: element.name,
                nameZH: Configure.GlExcel(element.heroTid),
                color: Configure.GlStarExcel(element.heroTid),
                heroTid: element.heroTid,
                star5Sum: element.star5Sum,
                star6Sum: element.star6Sum,
                star7Sum: element.star7Sum,
                star8Sum: element.star8Sum,
                star9Sum: element.star9Sum,
                star10Sum: element.star10Sum,
                star11Sum: element.star11Sum,
                star12Sum: element.star12Sum,
                star13Sum: element.star13Sum,
                star14Sum: element.star14Sum,
                allstar: element.star5Sum + element.star6Sum + element.star7Sum + element.star8Sum + element.star9Sum + element.star10Sum + element.star11Sum + element.star12Sum + element.star13Sum + element.star14Sum,
            };
            r.push(heroData);
        });
        return r;
    }

    doShearPlate = () => {
        let srvcode: string = ClipBoard.getSrvCode();
        let masteridhex: string = ClipBoard.getMasterIdHex();
        if (srvcode === '' || masteridhex === '') {
            message.error(Configure.Gl(1838, '服务器ID或角色唯一ID为空'))
            return;
        }
    }
}