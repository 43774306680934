import { StoreUtil } from './store'

export default class ClipBoard {

    static setSrvCode(value:string): void {
        StoreUtil.putStore("srvCode", value);
    }

    static getSrvCode(): string {
        return StoreUtil.getStore("srvCode");
    }

    static setMasterIdHex(value:string): void {
        StoreUtil.putStore("masterIdHex", value);
    }

    static getMasterIdHex(): string {
        return StoreUtil.getStore("masterIdHex");
    }

    static setMasterName(value:string): void {
        StoreUtil.putStore("masterName", value);
    }

    static getMasterName(): string {
        return StoreUtil.getStore("masterName");
    }

    static setOpenId(value:string): void {
        StoreUtil.putStore("openId", value);
    }

    static getOpenId(value:string): void {
        return StoreUtil.getStore("openId");
    }

    static setAccountUid(value:string): void {
        StoreUtil.putStore("accountUid", value);
    }

    static getAccountUid(): string {
        return StoreUtil.getStore("accountUid");
    }

    static setPlatformCode(value:string): void {
        StoreUtil.putStore("platformCode", value);
    }

    static getPlatformCode(): string {
        return StoreUtil.getStore("platformCode");
    }

    static setRoleName(value:string): void{
        StoreUtil.putStore("roleName",value);
    }   

    static getRoleName(){
        return StoreUtil.getStore("roleName");
    }

    static setRoleId(value:string): void{
        StoreUtil.putStore("roleId",value);
    }   

    static getRoleId(){
        return StoreUtil.getStore("roleId");
    }

    static setOpenIdRaw(value:string): void{
        StoreUtil.putStore("openIdRaw",value);
    }   

    static getOpenIdRaw(){
        return StoreUtil.getStore("openIdRaw");
    }
    
}