import React, { RefObject } from 'react';
import { Button, Space, Modal, Divider, Input } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/Table';

import { RoutePath } from '../../../route/path';
import { srvCrossApi } from '../../../api/console/srv.cross';
import { srvCrossCmdApi } from '../../../api/console/srv.cross.cmd';
import SrvCrossEdit from './SrvCrossEdit';
import SrvCrossFilter from './SrvCrossFilter';
import SrvCrossCmd from './SrvCrossCmd';
import { compareCode } from '../../../util/toolbox';
import { TableRowSelection } from 'antd/lib/table/interface';
import { IPagingState, StdItemPageLimit } from '../../../part/StdItemPageLimit';
import Configure from '../../../api/Configure';
import { cloudDeivceApi } from '../../../api/console/cloud.device';

interface IState extends IPagingState {
    selectItems: any;
    failsMsg: any;
}

export default class SrvCrossIndex extends StdItemPageLimit<IState> {

    private editor: RefObject<SrvCrossEdit> = React.createRef();

    private cmd: RefObject<SrvCrossCmd> = React.createRef();

    state = {
        ...this.getDefaultState(),
        selectItems: [],
        failsMsg: undefined,
    };

    getCols(): ColumnsType<any> {
        return [
            { title: Configure.Gl(1055,'ID'), dataIndex: 'id', width: 80, fixed: 'left', sorter: (a, b) => a.id - b.id, },
            { title: Configure.Gl(1056,"编码"), dataIndex: 'code', width: 120, fixed: 'left', sorter: (a, b) => compareCode(a.code, b.code) },
            { title: Configure.Gl(1424,"分类"), dataIndex: 'classify', width: 120, fixed: 'left', sorter: (a, b) => compareCode(a.classify, b.classify) },
            {
                title: Configure.Gl(1057,'操作'),
                width: 360,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.startItem(record)}>启动</Button>
                        <Button danger onClick={() => this.stopItem(record)}>停止</Button>
                        <Button onClick={() => this.doCT(record)}>CT</Button>
                        <Button onClick={() => this.doDeviceStatus(record, 'ps-java')}>设备ps-java</Button>
                    </Space>);
                },
            },
            { title: Configure.Gl(1425,'服务的URL'), dataIndex: 'url', width: 260, },
            { title: Configure.Gl(1426,'web端口'), dataIndex: 'port', width: 120, },
            { title: Configure.Gl(1069,"设备编码"), dataIndex: 'deviceCode', width: 120, },
            { title: Configure.Gl(1427,"数据库名"), dataIndex: 'dbName', width: 120, },
            { title: Configure.Gl(1428,'mongo编码'), dataIndex: 'mongoCode', width: 120, },
            { title: Configure.Gl(1429,'mq编码'), dataIndex: 'mqCode', width: 120, },
            { title: Configure.Gl(1430,'Excel模式'), dataIndex: 'excelMode', width: 120, },
            { title: Configure.Gl(1431,'JVM参数'), dataIndex: 'jvmArgs', width: 200, },
            {
                title: Configure.Gl(1057,'操作'),
                width: 360,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117,"编辑")}</Button>
                        <Button onClick={() => this.editor.current?.showCreate(record)}>克隆</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133,'删除')}</Button>
                        <Button onClick={() => this.resetMember(record)}>成员重置</Button>
                    </Space>);
                },
            },
            { title: Configure.Gl(1065,"备注"), dataIndex: 'note', width: 220 },
        ];
    }

    doCT(item: any) {
        srvCrossApi.ct(item.code).then(d => this.doAlert('CT', d));
    }

    doDeviceStatus(item: any, cmd: string) {
        cloudDeivceApi.status(item.deviceCode, cmd).then(d => {
            let str = d as string;
            str = str.replace(/[\r\n]/g,"<br/>");
            console.log("str=", str);
            this.doAlert('status'+cmd, str);
        });
    }

    getFilterUI() {
        return (<>
        <Space>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389,'新增')}</Button>
            <SrvCrossFilter search={(f:any) => this.onFilterChange(f)}></SrvCrossFilter>            
        </Space>
        <Divider/>
        <div style={{width:'100%'}}>
            <Button type="default" onClick={() => this.fails()}>{Configure.Gl(1433,'刷新')}</Button>
            <Input.TextArea bordered={false} autoSize  value={this.state.failsMsg}/>
        </div>
        </>
        );
    }

    getEditorUI() {
        return (<SrvCrossEdit ref={this.editor} reload={() => this.reload()} ></SrvCrossEdit>);
    }

    getTableRowSelection():TableRowSelection<any> {
        return {
            type: 'checkbox',
            onChange: (keys, rows) =>this.onSelectItemChanged(keys, rows),
        };
    }

    getBottomUI() {
        return (<SrvCrossCmd ref={this.cmd}></SrvCrossCmd>);
    }

    getPath() { return RoutePath.SRV_CROSS_INDEX; }

    async reload() {
        const query = this.getQueryParam();
        let data: any = await this.reloadForData(query);
        this.setState({ itemList: data.records, itemTotal: data.total});
        await this.fails();
    }

    async fails(){
        let fails = await srvCrossApi.fails() as any[];
        console.log("请求到的故障列表：", fails);
        let failsMsg = Configure.Gl(1432,"故障统计：");
        fails.forEach(v => {
            failsMsg += v.crossCode + " | ";
        })
        this.setState({failsMsg: failsMsg});
    }

    async reloadForData(query: any) {
        let data = await srvCrossApi.list(query) as any;
        console.log(Configure.Gl(1390,"请求到的数据是："), data);
        this.addKeyToData(data.records);
        this.onSelectItemChanged([], []);
        return data;
    }

    onSelectItemChanged(selectedRowKeys: React.Key[], selectedRows: any[]) {
        console.log("选项变更：", selectedRows);
        this.setState({selectItems: selectedRows});
        this.cmd.current?.handleItemSelected(selectedRows);
    }

    startItem(item: any) {
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content:  Configure.Gl(1434,'你确定要 启动 ') + item.code + ' ('+item.note+')？',
            onOk() {
                srvCrossCmdApi.start(item.code);
            }
        });
    }

    stopItem(item: any) {
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1435,'你确定要 停止 ') + item.code + ' ('+item.note+')？',
            onOk() {
                srvCrossCmdApi.stop(item.code);
            }
        });
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1391,'你确定要删除') + item.code + ' ('+item.note+')？',
            onOk() {
                srvCrossApi.delete(item.id).then(d => that.reload());
            }
        });
    }

    resetMember(item: any) {
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1437,'你确定要对 ') + item.code + ' ('+item.note+') '+Configure.Gl(1438,'执行重置成员操作？'),
            onOk() {
                srvCrossCmdApi.resetMember(item.code);
            }
        });
    }

}