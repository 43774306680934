import { metaApi } from '../../util/http';
import Configure from '../Configure';

class MetaSrvApi {

    gmc_enum() {
        return metaApi.get({
            path: 'gmc/enum',
            param: {},
            title: Configure.Gl(1678,'GMC所需要的各种枚举'),
            quiet: true
        });
    }
    announce_refresh() {
        return metaApi.get({
            path: 'announce/refresh?', 
            title: Configure.Gl(1677,'强制刷新游戏公告(但不排除console缓存)'),
            quiet: false
        });
    }
}

export const metaSrvApi: MetaSrvApi = new MetaSrvApi();