import React from 'react';
import { Button, Input, Row, Col, Select } from 'antd';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { SrvGameEntity } from '../../../def/srv/SrvGameEntity';
import { srvGroupApi } from '../../../api/console/srv.group';
import Configure from '../../../api/Configure';

export interface IProps {
    search: Function
}

export default class SrvGameFilter extends React.Component<IProps, any> {

    state = {
        code: undefined as string | undefined,
        name: undefined as string | undefined,
        status: undefined as number | undefined,
        deviceCode: undefined as string | undefined,
        groupId: undefined as number | undefined,
        eaten: undefined as string | undefined,
        groupOpt: [] as any[],
    };

    private filter = {
        code: undefined as string | undefined,
        name: undefined as string | undefined,
        status: undefined as number | undefined,
        deviceCode: undefined as string | undefined,
        groupId: undefined as number | undefined,
        eaten: undefined as string | undefined,
        clear() {
            this.code = undefined;
            this.name = undefined;
            this.status = undefined;
            this.deviceCode = undefined;
            this.groupId = undefined;
            this.eaten = undefined;
        }
    }

    setCode(v: string) {
        this.filter.code = v;
        this.setState({ code: v });
    }

    setName(v: string) {
        this.filter.name = v;
        this.setState({ name: v });
    }

    setStatus(v: number) {
        this.filter.status = v;
        this.setState({ status: v });
    }

    setDeviceCode(v: string) {
        this.filter.deviceCode = v;
        this.setState({ deviceCode: v });
    }

    setGroupId(v: number) {
        this.filter.groupId = v;
        this.setState({ groupId: v });
    }

    setEaten(v: string) {
        this.filter.eaten = v;
        this.setState({ eaten: v });
    }

    constructor(props: IProps, context: any) {
        super(props, context);
        srvGroupApi.optionList().then(d => this.setState({ groupOpt: d }))
    }

    render() {
        const statusOptUi: any[] = [];
        SrvGameEntity.getStatusOpt().forEach(opt => {
            statusOptUi.push(<Select.Option value={opt.value} key={opt.value}>{opt.label}</Select.Option>);
        })
        const groupOptUi: any[] = [];
        const { groupOpt } = this.state;
        groupOpt.forEach(opt => {
            groupOptUi.push(<Select.Option value={opt.value} key={opt.value}>{opt.label}</Select.Option>);
        })
        return (<>
            <Row gutter={8}>
                <Col span={3}>
                    <Input placeholder={Configure.Gl(1079,'游戏服编码')} value={this.state.code} onChange={e => this.setCode(e.target.value)} />
                </Col>
                <Col span={3}>
                    <Input placeholder={Configure.Gl(1472,'游戏服名字')} value={this.state.name} onChange={e => this.setName(e.target.value)} />
                </Col>
                <Col span={3}>
                    <Select placeholder={Configure.Gl(1496,'服务器状态')} value={this.state.status} onChange={v => this.setStatus(v)}>{statusOptUi}</Select>
                </Col>
                <Col span={3}>
                    <Input placeholder={Configure.Gl(1069,"设备编码")} value={this.state.deviceCode} onChange={e => this.setDeviceCode(e.target.value)} />
                </Col>
                <Col span={3}>
                    <Select placeholder={Configure.Gl(1083,"服务器组")} value={this.state.groupId} onChange={v => this.setGroupId(v)}>{groupOptUi}</Select>
                </Col>
                <Col span={3}>
                <Select placeholder={Configure.Gl(1082,"被合服吞并")} value={this.state.eaten} onChange={v => this.setEaten(v)}>
                    <Select.Option key="1" value="true">{Configure.Gl(1497,"吞并")}</Select.Option>
                    <Select.Option key="2" value="false">{Configure.Gl(1498,"独立")}</Select.Option>
                </Select>
                </Col>
                <Col span={5}>
                    <Button icon={<SearchOutlined />} onClick={this.doSearch}>{Configure.Gl(1152,'查找')}</Button>
                    <Button type="dashed" icon={<CloseCircleOutlined />} onClick={this.doClear}>{Configure.Gl(1153,'清空')}</Button>
                </Col>
            </Row>
        </>
        );
    }

    doSearch = () => {
        this.props.search(this.filter);
    }

    doClear = () => {
        this.setState({ code: null, name: null, status: null, deviceCode: null, groupId: null });
        this.filter.clear();
        this.props.search();
    }
}