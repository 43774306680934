import React from 'react';
import { Form, Input, InputNumber, Button, Select, Switch, DatePicker } from 'antd';
import { StdItemEdit, IProps, IState } from '../../../part/StdItemEdit';
import { srvGameApi } from '../../../api/console/srv.game';
import { SrvGameEntity } from '../../../def/srv/SrvGameEntity';
import { srvGroupApi } from '../../../api/console/srv.group';
import moment from 'moment';
import Configure from '../../../api/Configure';
import SrvEnum from '../../../def/SrvEnum';
import { selectOptionFilter } from '../../../util/ui';

interface ThisState extends IState {
    groupOpt: any[],
}

export default class SrvGameEdit extends StdItemEdit<IProps, ThisState> {

    state = {
        ...super.buildInitState(),
        groupOpt: [] as any[],
    }

    constructor(props: IProps, context: any) {
        super(props, context);
        srvGroupApi.optionList().then(d => this.setState({ groupOpt: d }))
    }

    getDefaultItem = (): any => {
        return {
            openTime: moment(),
            platformCode: 'yezixi',
            excelMode: 'release',
            status: SrvGameEntity.STATUS_CLOSE,
            payScale: 1,
            wsScheme: 'wss',
            jvmArgs: '-Xms128m -Xmx4096m',
        };
    }

    renderForm() {
        const { createMode, item } = this.state;
        console.log("拿到的item=", item);
        const statusOptUi: any[] = [];
        SrvGameEntity.getStatusOpt().forEach(opt => {
            statusOptUi.push(<Select.Option value={opt.value} key={opt.value}>{opt.label}</Select.Option>);
        })
        const groupOptUi: any[] = [];
        const { groupOpt } = this.state;
        groupOpt.forEach(opt => {
            groupOptUi.push(<Select.Option value={opt.value} key={opt.value}>{opt.label}</Select.Option>);
        })
        return (
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                name="basic"
                initialValues={item}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}
            >
                <Form.Item label={Configure.Gl(1055, "ID")} name="id">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={Configure.Gl(1056, "编码")} name="code">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1471, "序号")} name="index">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1472, '游戏服名字')} name="name">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1088, '状态')} name="status">
                    <Select>{statusOptUi}</Select>
                </Form.Item>
                <Form.Item label={Configure.Gl(1473, "推荐")} name="recommend" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1474, "服务器组ID")} name="groupId">
                    <Select>{groupOptUi}</Select>
                </Form.Item>
                <Form.Item label={Configure.Gl(1475, "分区归类")} name="folder">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1476, "跨服编号")} name="crossCode">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1478, "开服时间")} name="openTime">
                    <DatePicker showTime />
                </Form.Item>
                <Form.Item label={Configure.Gl(1479, "合服时间")} name="mergeTime">
                    <DatePicker showTime />
                </Form.Item>
                <Form.Item label={Configure.Gl(1082, "被合服吞并")} name="eaten" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1122, '平台编码')} name="platformCode">
                    <Select>
                        <Select.Option value="yezixi">{Configure.Gl(1247, '叶子戏')}</Select.Option>
                        <Select.Option value="develop">{Configure.Gl(1246, '研发平台')}</Select.Option>
                        <Select.Option value="show">{Configure.Gl(1480, '演示平台')}</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label={Configure.Gl(1481, "支付倍数")} name="payScale">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1430, "excel模式")} name="excelMode">
                    <Select>
                        <Select.Option value="release">{Configure.Gl(1439, '首发版(release)')}</Select.Option>
                        <Select.Option value="debug">{Configure.Gl(1440, '调试版(debug)')}</Select.Option>
                        <Select.Option value="test">{Configure.Gl(1441, '测试版(test)')}</Select.Option>
                        <Select.Option value="wx">{Configure.Gl(1442, '微信小程序版(wx)')}</Select.Option>
                        <Select.Option value="gat">{Configure.Gl(1443, '港澳台版(gat)')}</Select.Option>
                        <Select.Option value="vi">{Configure.Gl(1444, '越南版(vi)')}</Select.Option>
                        <Select.Option value="韩国">{Configure.Gl(1445, '韩国')}</Select.Option>
                        <Select.Option value="日本">{Configure.Gl(1904, '日本')}</Select.Option>
                        <Select.Option value="东南亚">{Configure.Gl(1905, '东南亚')}</Select.Option>
                        <Select.Option value="欧美">{Configure.Gl(Configure.GL_UNDEFINED,'欧美')}</Select.Option>
                        <Select.Option value="test1">{Configure.Gl(1446, '内部测试1(test1)')}</Select.Option>
                        <Select.Option value="test2">{Configure.Gl(1447, '内部测试2(test2)')}</Select.Option>
                        <Select.Option value="test3">{Configure.Gl(1448, '内部测试3(test3)')}</Select.Option>
                        <Select.Option value="test4">{Configure.Gl(1449, '内部测试4(test4)')}</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label={Configure.Gl(1428, "mongo编码")} name="mongoCode">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1477, "Mysql编码")} name="mysqlCode">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1482, "关联设备编码")} name="deviceCode">
                    <Input />
                </Form.Item>
                <Form.Item label="ws scheme" name="wsScheme">
                    <Input />
                </Form.Item>
                <Form.Item label="ws port" name="wsPort">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1483, "JVM启动参数")} name="jvmArgs">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1484, "最大玩家数量")} name="playerMax">
                    <InputNumber min={0} max={99999} />
                </Form.Item>
                <Form.Item label={Configure.Gl(1790, "游戏版本")} name="gameVer">
                    <Select showSearch filterOption={selectOptionFilter} options={SrvEnum.instance.gameVer}></Select>
                </Form.Item>
                <Form.Item label={Configure.Gl(1791, "vip统计对象")} name="vipStatTar">
                    <Select showSearch filterOption={selectOptionFilter} options={SrvEnum.instance.vipStatTar}></Select>
                </Form.Item>
                <Form.Item label={Configure.Gl(1065, "备注")} name="note">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit"> {createMode ? Configure.Gl(1387, "保存新增") : Configure.Gl(1388, "保存编辑")} </Button>
                </Form.Item>
            </Form>
        );
    }


    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        //if(true) return;
        const { createMode } = this.state;
        if (createMode) {
            srvGameApi.create(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            });
        } else {
            srvGameApi.update(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            })
        }
    }

}