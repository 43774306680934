import React, {RefObject } from 'react';
import { Button, Modal, Space} from 'antd';
import { RoutePath } from '../../../route/path';
import { ColumnsType } from 'antd/lib/table/Table';
import { formatDate } from '../../../util/toolbox';
import { RouteComponentProps } from 'react-router';
import { StdItemPageLimit } from '../../../part/StdItemPageLimit';
import GmAnnounceEdit from './GmAnnounceEdit';
import { gmAnnounceApi } from '../../../api/console/gm.announce';
import { TableRowSelection } from 'antd/lib/table/interface';
import { metaSrvApi } from '../../../api/meta/all';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Configure from '../../../api/Configure';

export default class GmAnnounceIndex extends StdItemPageLimit<any> {
    private editor: RefObject<GmAnnounceEdit>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
    }

    state = {
        queryPagination: {
            page: 1,
            pageSize: 10
        },
        itemTotal: undefined,
        itemList: [],
        selectItems: [] as any[],
    }

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1118,'id'),
                dataIndex: 'id',
                width: 60,
            },
            {
                title: Configure.Gl(1121,'服务器编码'),
                dataIndex: 'srvCode',
                width: 120,
            },
            {
                title: Configure.Gl(1122,'平台编码'),
                dataIndex: 'platformCode',
                width: 140,
            },
            {
                title: Configure.Gl(1099,'渠道编码'),
                dataIndex: 'channelCode',
                width: 140,
            },
            {
                title: Configure.Gl(1123,'GameID'),
                dataIndex: 'gameId',
                width: 140,
            },
            {
                title: Configure.Gl(1119,'开始时间'),
                width: 200,
                dataIndex: 'beginTime',
                render: (v, r) => formatDate(v)
            },
            {
                title: Configure.Gl(1119,'开始时间UTC'),
                width: 210,
                dataIndex: 'beginTime',
            },
            {
                title: Configure.Gl(1120,'结束时间'),
                width: 200,
                dataIndex: 'endTime',
                render: (v, r) => formatDate(v)
            },
            {
                title: Configure.Gl(1120,'结束时间UTC'),
                width: 210,
                dataIndex: 'endTime',
            },
            {
                title: Configure.Gl(1124,'标题'),
                width: 200,
                dataIndex: 'title',
            },
            {
                title: Configure.Gl(1125,'内容'),
                width: 2200,
                dataIndex: 'content',
            },
            {
                title: Configure.Gl(1126,'标题2'),
                width: 200,
                dataIndex: 'title2',
            },
            {
                title: Configure.Gl(1128,'内容2'),
                width: 2200,
                dataIndex: 'content2',
            },
            {
                title: Configure.Gl(1129,'标题3'),
                width: 200,
                dataIndex: 'title3',
            },
            {
                title: Configure.Gl(1130,'内容3'),
                width: 2200,
                dataIndex: 'content3',
            },
        ];
    }

    getFilterUI() {
        return (<Space>
            <Button type="primary" onClick={() => this.create()}>{Configure.Gl(1139,"新增公告")}</Button>
            <Button type="default" onClick={() => this.modify()}>{Configure.Gl(1140,'修改公告')}</Button>
            <Button danger type="primary" onClick={() => this.delete()}>{Configure.Gl(1141,'删除公告')}</Button>
            <Button type="default" onClick={() => this.reload()}>{Configure.Gl(1142,'刷新数据')}</Button>
            <Button type="default" onClick={() => this.refresh()}>{Configure.Gl(1143,'强制推送至meta')}</Button>
        </Space>
        );
    }

    create(){
        const {selectItems} = this.state;
        if (selectItems.length > 0) {
            alert(Configure.Gl(1144,"请取消选择再新增公告"));
            return;
        }
        this.editor.current?.show(true);
    }

    modify(){
        const {selectItems} = this.state;
        if (selectItems.length === 0) {
            alert(Configure.Gl(1131,"请先选择，再修改！"));
            return;
        }
        this.editor.current?.show(false)
    }

    async reload() {
        let data = await gmAnnounceApi.list() as any[];
        console.log("data=",data);
        this.addKeyToData(data);
        this.setState({ itemList: data, itemTotal: data.length ,selectItems: []});
        this.editor.current?.handleItemSelected([]);
    }

    getEditorUI() {
        return (<GmAnnounceEdit ref={this.editor} reload={() => this.reload()}></GmAnnounceEdit>);
    }

    getPath(){
        return RoutePath.GM_ANNOUNCE_INDEX;
    }

    getTableRowSelection():TableRowSelection<any> {
        const {selectItems} = this.state;
        return {
            type: 'checkbox',
            onChange: (keys, rows) =>this.onSelectItemChanged(keys, rows),
            hideSelectAll: true,
            selectedRowKeys: selectItems.map(x => {return x.key}),
        };
    }

    onSelectItemChanged(selectedRowKeys: React.Key[], selectedRows: any[]) {
        if(selectedRows.length > 1){
            const rows = [selectedRows[selectedRows.length-1]];
            console.log("选项变更：", rows);
            this.setState({selectItems: rows});
            this.editor.current?.handleItemSelected(rows);
        }else{
            console.log("选项变更：", selectedRows);
            this.setState({selectItems: selectedRows});
            this.editor.current?.handleItemSelected(selectedRows);
        }
    }

    delete() {
        const{selectItems} = this.state;
        if (selectItems.length === 0) {
            alert(Configure.Gl(1131,"请先选择，再修改！"));
            return;
        }
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1145,'确实要删除吗?'),
            onOk() {
                gmAnnounceApi.delete(selectItems[0].id).then(() => {
                    that.reload();
                });
            }
        });
    }

    refresh() {
        metaSrvApi.announce_refresh();
    }
}