import { consoleApi } from '../../util/http';
import Configure from '../Configure';

class RebateApi {

    list(filter: any) {
        return consoleApi.get({
            path: 'gm/rebate/list', 
            title: Configure.Gl(1692,'获取邮件列表'),
            param : filter,
            quiet: true
        });
    }

    create(item: any) {
        return consoleApi.post({
            path: 'gm/rebate/create', 
            title: Configure.Gl(1693,'新建邮件'),
            param: item,
            quiet: true
        });
    }

    complete(item: any){
        return consoleApi.post({
            path: 'gm/rebate/complete', 
            title: Configure.Gl(1694,'审批邮件'),
            param: item
        });
    }

    money_list(item: any){
        return consoleApi.get({
            path: 'gm/rebate/money/list', 
            title: Configure.Gl(1703,'获取充值金额列表'),
            param: item,
            quiet: true
        });
    }
}

export const rebateApi: RebateApi = new RebateApi();