import React from 'react';
import { ColumnsType } from 'antd/lib/table/Table';

import { RoutePath } from '../../../route/path';
import { sysTaskApi } from '../../../api/console/sys.task';
import SysTaskFilter from './SysTaskFilter';
import { IPagingState, StdItemPageLimit } from '../../../part/StdItemPageLimit';
import DateTool from '../../../util/DateTool';
import Configure from '../../../api/Configure';
import { Button } from 'antd';

interface IState extends IPagingState {
    xxx: any;
}

export default class SysTaskIndex extends StdItemPageLimit<IState> {

    state = {
        ...this.getDefaultState(),
        xxx: ""
    };

    getCols(): ColumnsType<any> {
        return [
            { title: Configure.Gl(1055, 'ID'), dataIndex: 'id', width: 80, fixed: 'left' },
            { title: Configure.Gl(1212, '类型'), dataIndex: 'type', width: 200 },
            { title: Configure.Gl(1640, '详情'), dataIndex: 'detail', width: 160, },
            { title: Configure.Gl(1636, "标签1"), dataIndex: 'tag1', width: 80, },
            { title: Configure.Gl(1637, "标签2"), dataIndex: 'tag2', width: 80, },
            { title: Configure.Gl(1638, "标签3"), dataIndex: 'tag3', width: 80, },
            { title: Configure.Gl(1088, '状态'), dataIndex: 'status', width: 120, },
            { title: Configure.Gl(1641, '任务创建时间'), dataIndex: 'createTime', width: 200, render: DateTool.beautifyIgnMsTimezone },
            { title: Configure.Gl(1642, '任务结束时间'), dataIndex: 'endTime', width: 200, render: DateTool.beautifyIgnMsTimezone },
            { title: Configure.Gl(1643, '错误码'), dataIndex: 'errorCode', width: 80, },
            { title: Configure.Gl(1644, '错误信息'), dataIndex: 'errorMsg', width: 480, },
            { title: Configure.Gl(1645, '操作人'), dataIndex: 'username', width: 120, },
        ];
    }

    getFilterUI() {
        return (<>
            <SysTaskFilter search={(f: any) => this.onFilterChange(f)}></SysTaskFilter>
            <Button onClick={this.doClear}>{Configure.Gl(1561, '清理过期任务')}</Button>
        </>);
    }

    getEditorUI() {
        return null;
    }

    getPath() { return RoutePath.SYS_TASK_INDEX; }


    async reloadForData(query: any) {
        let data = await sysTaskApi.list(query) as any;
        return data;
    }

    doClear = async () => {
        await sysTaskApi.clear();
        this.reload();
    }
}