import { SelectOpt } from "./CommonDef";

export function getMysqlTimezoneOpt(): SelectOpt[] {
    // https://www.zeitverschiebung.net/cn/city/1796236
    return [
        {label: "中国上海", value: "Asia/Shanghai"},
        {label: "中国香港", value: "Asia/Hong_Kong"},
        {label: "中国澳门", value: "Asia/Macau"},
        {label: "中国台湾", value: "Asia/Taipei"},
        {label: "越南胡志明", value: "Asia/Ho_Chi_Minh"},
        {label: "美国纽约", value: "America/New_York"},
        {label: "美国洛杉矶", value: "America/Los_Angeles"},
    ];
}