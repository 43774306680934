import React, { RefObject } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Table, Space, Divider, Modal } from 'antd';
import { SearchOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';

import { HomeLayout } from '../../layout/HomeLayout';
import { RoutePath } from '../../route/path';
import { cloudMqApi } from '../../api/console/cloud.mq';
import CloudMqEdit from './CloudMqEdit';
import Configure from '../../api/Configure';
import { IPagingState, StdItemPageLimit } from '../../part/StdItemPageLimit';

export default class CloudMqIndex extends StdItemPageLimit<IPagingState> {

    private editor: RefObject<CloudMqEdit> = React.createRef();

    state = {
        ...this.getDefaultState(),
    };

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1056,'编码'),
                dataIndex: 'code',
                width: 150,
                fixed: 'left',
                sorter: (a, b) => a.code - b.code,
            },
            { title: Configure.Gl(1065,'备注'), dataIndex: 'note', width: 150 },
            { title: Configure.Gl(Configure.GL_UNDEFINED,'关联服务器编码'), dataIndex: 'serverCode', width: 180 },
            { title: Configure.Gl(1075,'username'), dataIndex: 'username', width: 150 },
            { title: Configure.Gl(1076,'password'), dataIndex: 'password', width: 150 },
            {
                title: Configure.Gl(1057,'操作'),
                width: 200,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117,"编辑")}</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133,'删除')}</Button>
                    </Space>);
                },
            },
        ];
    }

    getFilterUI() {
        return (<Space>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389,'新增')}</Button>
        </Space>);
    }

    getEditorUI() {
        return (<CloudMqEdit ref={this.editor} reload={() => this.reload()}></CloudMqEdit>);
    }

    getPath() { return RoutePath.CLOUD_MQ_INDEX; }

    async reloadForData(query: any) {
        let data:any = await cloudMqApi.list(query);
        console.log("请求到的数据是：", data);
        this.addKeyToData(data.records);
        return data;
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1067,'你确定要删除 ') +' code = ' + item.code + ' ( ' + item.note + ' ) ？',
            onOk() {
                cloudMqApi.delete(item.code).then(d => that.reload());
            }
        });
    }

}