import {consoleApi} from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

class SrvCrossPlanApi extends ApiBase {

    create(item:any) {
        return consoleApi.post({
            path: 'srv/cross/plan/create',
            param: item,
            title: Configure.Gl(1116,"新建")
        });
    }

    update(item:any) {
        return consoleApi.post({
            path: 'srv/cross/plan/update',
            param: item,
            title: Configure.Gl(1132,'修改')
        });
    }

    delete(id:number) {
        return consoleApi.get({
            path: 'srv/cross/plan/delete?id='+id,
            title: Configure.Gl(1133,'删除'),
            quiet: true
        });
    }

    list2(filter: any) {
        return consoleApi.get({
            path: 'srv/cross/plan/list', 
            title: Configure.Gl(1704,'列表'),
            param: filter,
            quiet: true
        });
    }

    list(filter?:any) {
        return new Promise((resolve, reject) => {
            consoleApi.get({
                path: 'srv/cross/plan/list',
                title:Configure.Gl(1704,'列表'),
                quiet: true,
                param: filter
            }).then((data:any) => {
                (data.records as any[]).forEach(item => {
                    item.changeTime = this.parseZoneTime(item.changeTime);
                });
                resolve(data);
            });
        });
    }

}

export const srvCrossPlanApi: SrvCrossPlanApi = new SrvCrossPlanApi();