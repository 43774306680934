import React from 'react';
import { Input } from 'antd';
import { AbsFilter, StdItemFilter } from '../../../part/StdItemFilter';
import Configure from '../../../api/Configure';

class Filter extends AbsFilter {
    id?: number = undefined;
    name?: string = undefined;
    valDoubleMax?: number = undefined;
}

export default class DemoEntityFilter extends StdItemFilter {

    state = {
        id: undefined as number | undefined,
        name: undefined as string | undefined,
        valDoubleMax: undefined as number | undefined,
    };

    filter:Filter = new Filter();

    getFilter() { return this.filter; }

    async componentDidMount() {
        //const opt = await xxxApi.optXxx();
        //this.setState({optXxx: opt});
    }

    render() {
        return (
            <span>
                <Input placeholder={Configure.Gl(1055,"ID")} value={this.state.id} onChange={e => this.setValue('id', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1499,"名称")} value={this.state.name} onChange={e => this.setValue('name', e.target.value)} style={{width: 180}}/>
                <Input placeholder="Double最大值" value={this.state.valDoubleMax} onChange={e => this.setValue('valDoubleMax', e.target.value)} style={{width: 180}}/>
                { this.renderCmd() }
            </span>
        );
    }

}