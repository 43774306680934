import { consoleApi } from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

class GameRtApi extends ApiBase {

    list(filter: any) {
        return consoleApi.get({
            path: 'state/game/list',
            title: Configure.Gl(1670,"游戏实时状况查询"),
            param: filter,
            quiet: true
        });
    }

}

export const gameRtApi: GameRtApi = new GameRtApi();