import React, { RefObject } from 'react';
import { Button, Space, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/Table';

import { RoutePath } from '../../../route/path';
import { srvMergeApi } from '../../../api/console/srv.merge';
import SrvMergeEdit from './SrvMergeEdit';
import SrvMergeFilter from './SrvMergeFilter';
import SrvMergeGameMongoProgress from './SrvMergeGameMongoProgress';
import { IPagingState, StdItemPageLimit } from '../../../part/StdItemPageLimit';
import DateTool from '../../../util/DateTool';
import Configure from '../../../api/Configure';

interface IState extends IPagingState {
    xxx: any;
}

export default class SrvMergeIndex extends StdItemPageLimit<IState> {

    private editor: RefObject<SrvMergeEdit> = React.createRef();

    private gameMongoProgress: RefObject<SrvMergeGameMongoProgress> = React.createRef();

    state = {
        ...this.getDefaultState(),
        xxx: {}
    };

    getCols(): ColumnsType<any> {
        return [
            { title: Configure.Gl(1055,'ID'), dataIndex: 'id', width: 80, fixed: 'left' },
            { title: Configure.Gl(1512,'原始游戏服'), dataIndex: 'srvCodeOrigin', width: 150, fixed: 'left', render:(v,r)=> v + " " + r.srvNameFrom },
            { title: Configure.Gl(1513,'原始服务器组'), dataIndex: 'groupIdFrom', width: 150, fixed: 'left', render:(v,r)=> v + "-" + r.groupNameFrom },
            { title: Configure.Gl(1514,'迁移至游戏服'), dataIndex: 'srvCodeNow', width: 150, fixed: 'left', render:(v,r)=> v + " " + r.srvNameTo },
            { title: Configure.Gl(1515,'迁移至服务器组'), dataIndex: 'groupIdTo', width: 150, fixed: 'left', render:(v,r)=> v + "-" + r.groupNameTo },
            {
                title: Configure.Gl(1057,'操作'),
                width: 580,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117,"编辑")}</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133,'删除')}</Button>
                        <Button onClick={() => this.migrateAccountMaster(record)}>{Configure.Gl(1516,'账号角色迁移')}</Button>
                        <Button disabled onClick={() => this.migrateGameMongo(record)}>{Configure.Gl(1517,'游戏服mongo迁移')}</Button>
                        <Button type="primary" onClick={() => this.gameMongoProgress.current?.show(record)}>{Configure.Gl(1518,'mongo迁移进度')}</Button>
                    </Space>);
                },
            },
            { title: Configure.Gl(1507,"账号主角区服迁移的执行次数"), dataIndex: 'migrateAmTimes',  width: 130 },
            { title: Configure.Gl(1508,"账号主角区服迁移的最近一次执行时间"), dataIndex: 'migrateAmLast',  width: 200, render: DateTool.beautifyLocal },
            { title: Configure.Gl(1509,"游戏服mongo迁移的执行次数"), dataIndex: 'migrateGameMongoTimes',  width: 130 },
            { title: Configure.Gl(1510,"游戏服mongo迁移的最近一次执行时间"), dataIndex: 'migrateGameMongoLast',  width: 200, render: DateTool.beautifyLocal },
            { title: Configure.Gl(1511,"游戏服mongo迁移的最近一次日志"), dataIndex: 'migrateGameMongoLog',  width: 600 },
            { title: Configure.Gl(1358,'启用'), dataIndex: 'enabled', width: 120, render: (v) => v ? '√':'×' },
            { title: Configure.Gl(1065,"备注"), dataIndex: 'note', width: 120 },
        ];
    }

    getFilterUI() {
        return (<Space>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389,'新增')}</Button>
            <SrvMergeFilter search={(f:any) => this.onFilterChange(f)}></SrvMergeFilter>
        </Space>);
    }

    getEditorUI() {
        return (<SrvMergeEdit ref={this.editor} reload={() => this.reload()} ></SrvMergeEdit>);
    }

    getBottomUI() {
        return (<div>
            <SrvMergeGameMongoProgress ref={this.gameMongoProgress}></SrvMergeGameMongoProgress>
            <span>{Configure.Gl(1519,'为了防止误点，先临时禁用【游戏服mongo迁移】功能')}</span>
        </div>);
    }

    getPath() { return RoutePath.SRV_MERGE_INDEX; }

    async reloadForData(query: any) {
        let data = await srvMergeApi.list(query) as any;
        console.log(Configure.Gl(1390,"请求到的数据是："), data);
        this.addKeyToData(data.records);
        return data;
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1391,'你确定要删除') + item.srvCodeOrigin + ' =>'+item.srvCodeNow+'？',
            onOk() {
                srvMergeApi.delete(item.id).then(d => that.reload());
            }
        });
    }

    migrateAccountMaster(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1522,'你确定要更新account_master表，将 srv_code=') + item.srvCodeOrigin +Configure.Gl(1523,' 的角色迁移为 ') +item.srvCodeNow+Configure.Gl(1521,' ？该操作不可逆'),
            onOk() {
                srvMergeApi.migrateAccountMaster(item.srvCodeOrigin, item.srvCodeNow).then((d:any) => {
                    that.reload();
                    that.doAlert(Configure.Gl(1520,"操作结果"), "migrateNum="+d.migrateNum+", updateNum="+d.updateNum);
                });
            }
        });
    }

    migrateGameMongo(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1525,'你确定要导入游戏服mongo数据，将 ') + item.srvCodeOrigin + Configure.Gl(1524,' 的数据导入 ')+item.srvCodeNow+Configure.Gl(1521,' ？该操作不可逆'),
            onOk() {
                srvMergeApi.migrateGameMongo(item.srvCodeOrigin, item.srvCodeNow).then((d:any) => {
                    that.reload();
                    that.doAlert(Configure.Gl(1520,"操作结果"), "xxx="+d.xxx+", yyy="+d.yyy);
                });
            }
        });
    }
}