import React, { RefObject } from 'react';
import { Tabs, Select, Button, Tag } from 'antd';
import { AppleOutlined, AndroidOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { SrvGameEntity } from '../../../def/srv/SrvGameEntity';
import { srvGameApi } from '../../../api/console/srv.game';
import { srvGameCmdApi } from '../../../api/console/srv.game.cmd';
import Configure from '../../../api/Configure';
 
const { TabPane } = Tabs;

export default class SrvGameCmd extends React.Component {

    private batchStartStop: RefObject<BatchStartStop> = React.createRef();;

    render() {
        
        return (
            <Tabs type="card">
                <TabPane tab={<span><AndroidOutlined />{Configure.Gl(1405,'批量开关服')}</span>} key="1">
                    <BatchStartStop ref={this.batchStartStop}></BatchStartStop>
                </TabPane>
                <TabPane tab={<span><AppleOutlined />{Configure.Gl(1418,'批量改状态')}</span>} key="2">
                    <BatchChangeStatus></BatchChangeStatus>
                </TabPane>
            </Tabs>
        );
    }

    handleSrvSelected(srvSelected:any[]) {
        this.batchStartStop.current?.setState({srvSelected});
    }

}


class BatchStartStop extends React.Component {

    state = {
        srvSelected: []
    }

    render() {
        const { srvSelected } = this.state;
        let selectedUi: any[] = [];
        srvSelected.forEach((srv:any) => {
            selectedUi.push(<Tag color="green" key={srv.id}>{srv.code +" "+ srv.name}</Tag>);
        })
        return (<>
            <Button icon={<DoubleRightOutlined />} onClick={()=>this.doBatchStart()}>{Configure.Gl(1408,'启动下列所选')}</Button>
            <Button icon={<DoubleRightOutlined />} onClick={()=>this.doBatchStop()}>{Configure.Gl(1409,'停止下列所选')}</Button>
            <hr/>
            {selectedUi}
        </>);
    }

    doBatchStart() {
        if(window.confirm(Configure.Gl(1410,"确实要 【启动】如下服务器吗, 操作不可撤销？"))) {
            if(window.confirm(Configure.Gl(1411,"慎重了吗？"))) {
                const { srvSelected } = this.state;
                let srvCodeList: string[] = [];
                srvSelected.forEach((srv:any) => {
                    srvCodeList.push(srv.code);
                });
                srvGameCmdApi.startBatch(srvCodeList).then(d => {
                    alert(Configure.Gl(1412,"控制台后台程序已接收到命令，当前正在运行中...请手动检查执行状况！确保该操作有效且安全，请勿重复操作！") + d);
                });
            }
        }
    }

    doBatchStop() {
        if(window.confirm(Configure.Gl(1413,"确实要 【停止】如下服务器吗, 操作不可撤销？"))) {
            if(window.confirm(Configure.Gl(1411,"慎重了吗？"))) {
                const { srvSelected } = this.state;
                let srvCodeList: string[] = [];
                srvSelected.forEach((srv:any) => {
                    srvCodeList.push(srv.code);
                });
                srvGameCmdApi.stopBatch(srvCodeList).then(d => {
                    alert(Configure.Gl(1412,"控制台后台程序已接收到命令，当前正在运行中...请手动检查执行状况！确保该操作有效且安全，请勿重复操作！") + d);
                });
            }
        }
    }
}


class BatchChangeStatus extends React.Component {

    state = {
        statusChangeOld: undefined,
        statusChangeNew: undefined
    }

    render() {
        const statusOptUi:any[] = [];
        SrvGameEntity.getStatusOpt().forEach(opt => {
            statusOptUi.push(<Select.Option value={opt.value} key={opt.value}>{opt.label}</Select.Option>);
        })
        return (
            <>
            <Select placeholder={Configure.Gl(1414,"旧状态")} value={this.state.statusChangeOld} onChange={v=>this.setState({statusChangeOld: v})}>{ statusOptUi }</Select>
            <Button icon={<DoubleRightOutlined />} onClick={()=>this.doChange()}>{Configure.Gl(1417,"改变为")}</Button>
            <Select placeholder={Configure.Gl(1416,"新状态")} value={this.state.statusChangeNew} onChange={v=>this.setState({statusChangeNew: v})}>{ statusOptUi }</Select>
            </>
        );
    }

    doChange() {
        console.log("从状态 " + this.state.statusChangeOld + " 变成 " + this.state.statusChangeNew);
        srvGameApi.statusChangeAll(this.state.statusChangeOld, this.state.statusChangeNew);
    }

}