import React from 'react';
import { IState, StdItemEdit, IProps } from "../../../part/StdItemEdit";
import { Form, Input, Button, Divider, DatePicker } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { gmAnnounceApi } from '../../../api/console/gm.announce';
import Configure from '../../../api/Configure';
import moment from 'moment';

interface ThisState extends IState {
    initData: any,
    editForCreate: boolean
}

export default class GmAnnounceEdit extends StdItemEdit<IProps, ThisState> {

    state = {
        ...super.buildInitState(),
        initData: {} as any,
        editForCreate: true,
        TimeBegin: undefined as moment.Moment | undefined,
        TimeEnd: undefined as moment.Moment | undefined,
    }

    render() {
        const { visible, createMode } = this.state;
        return (
            <Modal title={createMode ? Configure.Gl(1116, "新建") : Configure.Gl(1117, "编辑")} visible={visible} width='90%'
                onOk={this.handleModalOk} onCancel={this.handleModalCancel} footer={null} destroyOnClose>
                {this.renderForm()}
            </Modal>
        );
    }

    renderForm() {
        const { initData } = this.state;
        console.log("initData=", initData);
        initData.beginTime = this.parseZone(initData.beginTime);
        initData.endTime = this.parseZone(initData.endTime);
        return (
            <span>
                <Form
                    layout="vertical"
                    name="basic"
                    onFinish={this.onFormFinish}
                    onFinishFailed={this.onFormFinishFailed}
                    initialValues={initData}
                >
                    <Form.Item>
                        <Form.Item label={Configure.Gl(1118, "id")} name="id" style={{ float: 'left', width: '33%' }}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label={Configure.Gl(1119, "开始时间")} name="beginTime" style={{ float: 'left', margin: '0 5px', width: '15%' }}>
                            <DatePicker placeholder={Configure.Gl(1578, "开始时间")} name="beginTime" showTime />
                        </Form.Item>
                        <Form.Item label={Configure.Gl(1120, "结束时间")} name="endTime" style={{ float: 'left', margin: '0 5px', width: '15%' }}>
                            <DatePicker placeholder={Configure.Gl(1579, "结束时间")} value={this.state.TimeEnd} showTime />
                        </Form.Item>
                    </Form.Item>
                    <Divider />
                    <Form.Item>
                        <Form.Item label={Configure.Gl(1121, "服务器编码")} name="srvCode" style={{ float: 'left', width: '24%' }}>
                            <Input maxLength={10} />
                        </Form.Item>
                        <Form.Item label={Configure.Gl(1122, "平台编码")} name="platformCode" style={{ float: 'left', margin: '0 5px', width: '24%' }}>
                            <Input maxLength={32} />
                        </Form.Item>
                        <Form.Item label={Configure.Gl(1099, "渠道编码")} name="channelCode" style={{ float: 'left', margin: '0 5px', width: '24%' }}>
                            <Input maxLength={64} />
                        </Form.Item>
                        <Form.Item label={Configure.Gl(1123, "GameID")} name="gameId" style={{ float: 'left', margin: '0 5px', width: '24%' }}>
                            <Input maxLength={64} />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1124, "标题")} name="title">
                        <Input maxLength={100} />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1125, "内容")} name="content">
                        <Input.TextArea placeholder={Configure.Gl(1127, "请输入内容")} autoSize maxLength={2800} bordered={false} />
                    </Form.Item>
                    <Divider />
                    <Form.Item label={Configure.Gl(1126, "标题2")} name="title2">
                        <Input maxLength={100} />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1128, "内容2")} name="content2">
                        <Input.TextArea placeholder={Configure.Gl(1127, "请输入内容")} autoSize maxLength={2800} bordered={false} />
                    </Form.Item>
                    <Divider />
                    <Form.Item label={Configure.Gl(1129, "标题3")} name="title3">
                        <Input maxLength={100} />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1130, "内容3")} name="content3">
                        <Input.TextArea placeholder={Configure.Gl(1127, "请输入内容")} autoSize maxLength={2800} bordered={false} />
                    </Form.Item>
                    <Divider />
                    <Form.Item>
                        <Button type="primary" htmlType="submit">{Configure.Gl(1228, '确定')}</Button>
                        <Button type="default" onClick={this.handleModalCancel}>{Configure.Gl(1229, '取消')}</Button>
                    </Form.Item>
                </Form>
            </span>
        )
    }

    show(isCreate: boolean) {
        this.setState({ editForCreate: isCreate });
        this.showCreate();
    }

    handleModalCancel = () => {
        this.setState({ visible: false });
    }

    onFormFinish = (values: any) => {
        const { editForCreate, initData } = this.state;
        if (editForCreate) {
            console.log("新增 values=", values);
            //values.beginTime = values.beginTime ? formatDate(values.beginTime, "yyyy-MM-dd hh:mm:ss") : undefined;
            //values.endTime = values.endTime ? formatDate(values.endTime, "yyyy-MM-dd hh:mm:ss") : undefined;
            gmAnnounceApi.create(values).then(() => {
                this.handleModalCancel();
                this.props.reload();
            });
        } else {
            console.log("编辑 values=", values);
            if (!initData || initData.id === 0) {
                alert(Configure.Gl(1131, "请先选择，再修改！"));
                return;
            }
            //values.beginTime = values.beginTime ? formatDate(values.beginTime, "yyyy-MM-dd hh:mm:ss") : undefined;
            //values.endTime = values.endTime ? formatDate(values.endTime, "yyyy-MM-dd hh:mm:ss") : undefined;
            gmAnnounceApi.update(values).then(() => {
                this.handleModalCancel();
                this.props.reload();
            });
        }
    }

    onFormFinishFailed = (errorInfo: any) => {
        console.error('onFormFinishFailed:', errorInfo);
    }

    handleItemSelected(item: any[]) {
        this.setState({ initData: item.length > 0 ? item[0] : {} });
    }
}