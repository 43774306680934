import { payApi } from '../../util/http';
import Configure from '../Configure';

class PaySrvApi {

    statGsLife(query: any) {
        return payApi.post({
            path: 'stat/gs/life',
            param: query,
            title: Configure.Gl(1679,'各服务器生命周期统计')
        });
    }

    /** 清理1个月前的无效订单 */
    recordClear() {
        return payApi.get({
            path: 'record/clear',
            title: Configure.Gl(1784,'清理1个月前的无效订单')
        });
    }
}

export const paySrvApi: PaySrvApi = new PaySrvApi();