import React from 'react';
import { Button, Input, Row, Col } from 'antd';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Configure from '../../api/Configure';

export interface IProps {
    search: Function
    empty: Function
}

export default class MasterQueryFilter extends React.Component<IProps, any> {

    state = {
        srvCode: undefined as string | undefined,
        masterName: undefined as string | undefined,
        masterIdHex: undefined as string | undefined,
        openId: undefined as string | undefined,
        openIdRaw: undefined as string | undefined,
        roleName: undefined as string | undefined,
        roleId: undefined as string | undefined,
    };

    private filter = {
        srvCode: undefined as string | undefined,
        masterName: undefined as string | undefined,
        masterIdHex: undefined as string | undefined,
        openId: undefined as string | undefined,
        openIdRaw: undefined as string | undefined,
        roleName: undefined as string | undefined,
        roleId: undefined as string | undefined,
        clear() {
            this.srvCode = undefined;
            this.masterName = undefined;
            this.masterIdHex = undefined;
            this.openId = undefined;
            this.openIdRaw = undefined;
            this.roleName = undefined;
            this.roleId = undefined;
        }
    }

    setSrvCode(v: string) {
        this.filter.srvCode = v;
        this.setState({ srvCode: v });
    }

    setMasterName(v: string) {
        this.filter.masterName = v;
        this.setState({ masterName: v });
    }

    setMasterIdHex(v: string) {
        this.filter.masterIdHex = v;
        this.setState({ masterIdHex: v });
    }

    setOpenIdHex(v: string) {
        this.filter.openId = v;
        this.setState({ openId: v });
    }

    setOrgOpenIdHex(v: string) {
        this.filter.openIdRaw = v;
        this.setState({ openIdRaw: v });
    }

    setRoleNameHex(v: string) {
        this.filter.roleName = v;
        this.setState({ roleName: v });
    }

    setRoleIdHex(v: string) {
        this.filter.roleId = v;
        this.setState({ roleId: v });
    }

    render() {
        return (<>
            <Row gutter={8}>
                <Col span={3}>
                    <Input placeholder={Configure.Gl(1079, '游戏服编码')} value={this.state.srvCode} onChange={e => this.setSrvCode(e.target.value)} />
                </Col>
                <Col span={3}>
                    <Input placeholder={Configure.Gl(1151, "主角名字")} value={this.state.roleName} onChange={e => this.setRoleNameHex(e.target.value)} />
                </Col>
                <Col span={3}>
                    <Input placeholder={Configure.Gl(1101, '主角唯一ID')} value={this.state.roleId} onChange={e => this.setRoleIdHex(e.target.value)} />
                </Col>
                <Col span={3}>
                    <Input placeholder={Configure.Gl(1337, 'OpenID')} value={this.state.openId} onChange={e => this.setOpenIdHex(e.target.value)} />
                </Col>
                <Col span={3}>
                    <Input placeholder={Configure.Gl(1870, '原始OpenID')} value={this.state.openIdRaw} onChange={e => this.setOrgOpenIdHex(e.target.value)} />
                </Col>
                <Col span={3}>
                    <Button icon={<SearchOutlined />} onClick={this.doSearch}>{Configure.Gl(1152, '查找')}</Button>
                </Col>
                <Col span={3}>
                    <Button icon={<CloseCircleOutlined />} onClick={this.doClear}>{Configure.Gl(1153, '清空')}</Button>
                </Col>
            </Row>
        </>
        );
    }

    doSearch = () => {
        let f: any = { ...this.filter };
        f.clear = null;
        this.props.search(f);
    }

    doClear = () => {
        this.setState({ srvCode: null, masterName: null, masterIdHex: null });
        this.filter.clear();
        let f: any = { ...this.filter };
        this.props.empty(f);
    }
}