import React from 'react';
import { Input, DatePicker, Button } from 'antd';
import { AbsFilter, StdItemFilter } from '../../../part/StdItemFilter';
import Configure from '../../../api/Configure';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';

class Filter extends AbsFilter {
    srvCode?: string = undefined;
    TimeBegin?: moment.Moment = undefined;
    TimeEnd?: moment.Moment = undefined;
}


export default class FunParticipateFilter extends StdItemFilter {

    state = {
        srvCode: undefined as string | undefined,
        TimeBegin: undefined as moment.Moment | undefined,
        TimeEnd: undefined as moment.Moment | undefined,
    };

    
    filter: Filter = new Filter();

    getFilter() { 
        return this.filter;
    }

    render() {
        return (
            <span>
                <Input placeholder={Configure.Gl(1234,'服务器')} value={this.state.srvCode} onChange={e => this.setValue('srvCode', e.target.value)} style={{width: 180}}/>
                <DatePicker placeholder={Configure.Gl(1578,"数据开始时间")} value={this.state.TimeBegin} showTime onChange={e => this.setValue('TimeBegin', e)} />
                <DatePicker placeholder={Configure.Gl(1579,"数据结束时间")} value={this.state.TimeEnd} showTime onChange={e => this.setValue('TimeEnd', e)} />
                <span>
                <Button icon={<SearchOutlined />} onClick={this.doSearch}>{Configure.Gl(1152,'查找')}</Button>
                <Button type="dashed" icon={<CloseCircleOutlined />} onClick={this.doClear}>{Configure.Gl(1153,'清空')}</Button>
                 </span>
            </span>
        );
    }

}