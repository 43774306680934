import { statApi } from '../../util/http';
import Configure from '../Configure';

class StatSrvApi {

    pay_rank(filter: any) {
        return statApi.get({
            path: 'stat/payRank',
            title: Configure.Gl(1574,"充值排行"),
            quiet: true,
            param: filter
        });
    }

    pay_list(f: any) {
        let q = 'page='+f.page+"&pageSize="+f.pageSize;
        q += f.srvCode ? "&srvCode="+f.srvCode : "";
        q += f.itemTid ? "&itemTid="+f.itemTid : "";
        q += f.orderType ? "&orderType="+f.orderType : "";
        q += f.step ? "&step="+f.step : "";
        q += f.masterName ? "&masterName="+f.masterName : "";
        q += f.masterIdHex ? "&masterIdHex="+f.masterIdHex : "";
        q += f.platformCode ? "&platformCode="+f.platformCode : "";
        q += f.channelCode ? "&channelCode="+f.channelCode : "";
        q += f.platformOpenId ? "&platformOpenId="+f.platformOpenId : "";
        q += f.platformOrderId ? "&platformOrderId="+f.platformOrderId : "";
        q += f.accountUid ? "&accountUid="+f.accountUid : "";
        q += f.payTimeBegin ? "&payTimeBegin="+f.payTimeBegin : "";
        q += f.payTimeEnd ? "&payTimeEnd="+f.payTimeEnd : "";
        q += f.groupId ? "&groupId="+f.groupId : "";
        q += f.rawOrderId?"&rawOrderId="+f.rawOrderId:"";
        return statApi.get({
            path: 'pay/list?'+q, 
            title: Configure.Gl(1558,'统计:支付查询'),
            quiet: true
        });
    }

    participate_sum(filter: any) {
        if(!filter){
            return;
        }
        if(filter.srvCode===undefined||filter.TimeBegin===undefined||filter.TimeEnd===undefined){
            return undefined;
        }
        return statApi.get({
            path: 'game/participate/sum?srvCode='+filter.srvCode+'&beginTime='+filter.TimeBegin+"&endTime="+filter.TimeEnd, 
            title: '统计:功能参与',
            quiet: true
        });
    }

    item_io_list(filter: any) {
        let query = "";
        query += 'page='+filter.page;
        query += '&pageSize='+filter.pageSize;
        query += '&srvCode='+filter.srvCode;
        query += '&beginTime='+filter.beginTime;
        query += '&endTime='+filter.endTime;
        if(filter.itemName) { query += "&itemName="+filter.itemName; }
        if(filter.itemTid) { query += "&itemTid="+filter.itemTid; }
        if(filter.masterIdHex) { query += "&masterIdHex="+filter.masterIdHex; }
        if(filter.masterName) { query += "&masterName="+filter.masterName; }
        if(filter.operation && filter.operation > 0) { query += "&operation="+filter.operation; }
        if(filter.operationSub && filter.operationSub > 0) { query += "&operationSub="+filter.operationSub; }
        return statApi.get({
            path: 'item/io/list?'+query, 
            title: Configure.Gl(1039,"产出消耗查询"),
            quiet: true
        });
    }

    preserve(filter: any,day:number) {
        let query ='';
        query += 'dimension='+filter.dimension+"&regDate="+filter.beginTime+"&day="+day;
        if(filter.srvCode!=='' && filter.srvCode!==undefined){
            query += '&srvCode='+filter.srvCode;
        }
        if(filter.srvGroupId!==0){
            query += '&srvGroupId='+filter.srvGroupId;
        }
        if(filter.gameId!==0){
            query += '&gameId='+filter.gameId;
        }
        if(filter.channelCode!==''){
            query += '&channelCode='+filter.channelCode;
        }
        
        return statApi.get({
            path: 'retention/by/srv?'+query, 
            title: '统计:新版留存',
            quiet: true
        });
    }

    NewPreserve(filter: any,day:number) {
        let query ='';
        query += 'dimension='+filter.dimension+"&regDate="+filter.beginTime+"&day="+day;
        if(filter.srvCode!=='' && filter.srvCode!==undefined){
            query += '&srvCode='+filter.srvCode;
        }
        if(filter.srvGroupId!==0){
            query += '&srvGroupId='+filter.srvGroupId;
        }
        if(filter.gameId!==0){
            query += '&gameId='+filter.gameId;
        }
        if(filter.channelCode!==''){
            query += '&channelCode='+filter.channelCode;
        }
        //http://localhost:7788/login/retention/list?dimension=role&regDate=2021-04-01&day=1
        return statApi.get({
            path: 'login/retention/list?'+query, 
            title: '统计:新版留存',
            quiet: true
        });
    }
}

export const statSrvApi: StatSrvApi = new StatSrvApi();