import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { List } from 'antd';

import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import StatLoginFlowFilter from './StatLoginFlowFilter';
import { statLoginApi } from '../../../api/stat/login';
import Configure from '../../../api/Configure';

export default class StatLoginFlowIndex extends React.Component<RouteComponentProps> {

    state = {
        filter: {},
        data: {} as any,
    }

    render() {
        const { data } = this.state;
        return (
            <HomeLayout path={RoutePath.STAT_LOGIN_FLOW_INDEX}>
                <StatLoginFlowFilter search={(f: any) => this.onFilterChange(f)}></StatLoginFlowFilter>
                <List>
                    <List.Item>{Configure.Gl(1591, '完成 叶子戏SDK登录 的 账户数 =')} {data.sdkLogin}</List.Item>
                    <List.Item>{Configure.Gl(1592, '完成 请求meta服facade/client/start接口 de 账户数 = ')}{data.metaStart}</List.Item>
                    <List.Item>{Configure.Gl(1593, '完成 请求login服account/login接口 de 账户数 =')} {data.loginAccount}</List.Item>
                    <List.Item>{Configure.Gl(1594, '完成 请求login服srv/game/list接口 de 账户数 = ')}{data.loginGameList}</List.Item>
                    <List.Item>{Configure.Gl(1595, '完成 向login服请求目标游戏服连接信息 de 账户数 =')} {data.loginConnectTarget}</List.Item>
                    <List.Item>{Configure.Gl(1596, '完成 客户端下载并解析模板表 de 账户数 = ')}{data.clientTemplate}</List.Item>
                    <List.Item>{Configure.Gl(1597, '完成 游戏服websocket连接 de 账户数 =')} {data.gameWs}</List.Item>
                    <List.Item>{Configure.Gl(1598, '完成 进入游戏主界面 de 账户数 = ')}{data.gameEnter}</List.Item>
                </List>
            </HomeLayout>
        );
    }

    onFilterChange(filter: any) {
        this.setState({ filter }, () => {
            this.reload();
        });
    }

    async reload() {
        const { filter } = this.state;
        let data = await statLoginApi.loginFlowSummary(filter);
        this.setState({ data });
    }
}