import React, { Key } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Divider, Input, Table } from 'antd';
import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import Configure from '../../../api/Configure';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';

export default class UserLifeCycleValueIndex extends React.Component<RouteComponentProps> {

    state = {
        itemTotal: undefined,
        spinLoad: false,
        srvCode: "",
        queryPagination: {
            page: 1,
            pageSize: 100
        },
        itemList: [],
        queryFilter: {},
    }


    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1121, '服务器编码'),
                dataIndex: 'srvCode',
                width: 100,
            },
            {
                title: Configure.Gl(1478, '开服时间'),
                dataIndex: 'masterIdHex',
                width: 200,
            },
            {
                title: Configure.Gl(1937, '新服新增用户'),
                dataIndex: 'masterName',
                width: 120,
            },
            {
                title: Configure.Gl(1187, '第1日用户充值金额'),
                dataIndex: 'masterLevel',
                width: 120,
            },
            {
                title: Configure.Gl(1188, '第1日LTV'),
                dataIndex: 'masterVip',
                width: 65,
            },
        ];
    }

    render() {
        const columns: ColumnsType<any> = this.getCols();
        const { itemTotal, queryPagination } = this.state;
        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            defaultPageSize: queryPagination.pageSize,
            total: itemTotal
        };
        return (
            <HomeLayout path={RoutePath.STAT_USER_LTV_INDEX}>
                <span>
                    <Input placeholder={Configure.Gl(1234, '服务器')} value={this.state.srvCode} onChange={e => this.selectSvr(e.target.value)} style={{ width: 180 }} />
                    <span>
                        {/* <Button icon={<SearchOutlined />} onClick={this.doSearch}>{Configure.Gl(1152, '查找')}</Button>
                        <Button type="dashed" icon={<CloseCircleOutlined />} onClick={this.doClear}>{Configure.Gl(1153, '清空')}</Button> */}
                    </span>
                    <Divider />
                    <Table dataSource={this.state.itemList} columns={columns}
                        pagination={pagination} onChange={(p, f, s, e) => { this.onPageChange(p, f, s, e) }} />
                </span>
            </HomeLayout>
        );
    }


    onPageChange(pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        this.setState({
            queryPagination: {
                page: pagination.current,
                pageSize: pagination.pageSize
            }
        }, () => {
            this.reload();
        });
    }

    onFilterChange(filter: any) {
        this.setState({ filter }, () => {
            this.reload();
        });

    }

    selectSvr(e: any) {

    }

    async reload() {
        //const { filter } = this.state;
        let data = "";//await statSrvApi.participate_sum(this.state.filter) as any[];
        console.log(Configure.Gl(1390, "请求到的数据是："), data);
        if (data === undefined) {
            return;
        }
        this.setState({ rank: data });
    }

}