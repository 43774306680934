import React, { RefObject } from 'react';
import { Button, Space, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/Table';
import { IPagingState, StdItemPageLimit } from '../../part/StdItemPageLimit';
import { RoutePath } from '../../route/path';
import { staffApi } from '../../api/console/staff';
import StaffEdit from './StaffEdit';
import StaffFilter from './StaffFilter';
import { staffRoleApi } from '../../api/console/staff.role';
import Configure from '../../api/Configure';

interface IState extends IPagingState {
    sth: string;
}

export default class StaffIndex extends StdItemPageLimit<IState> {

    private editor: RefObject<StaffEdit> = React.createRef();

    state = {
        ...this.getDefaultState(),
        sth: Configure.Gl(1001,"员工")
    };

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1055,"ID"),
                dataIndex: 'id',
                width: 80,
                fixed: 'left',
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: Configure.Gl(1102,"角色名"),
                dataIndex: 'username',
                width: 120,
            },
            {
                title: Configure.Gl(1534,"昵称"),
                dataIndex: 'nickname',
                width: 120,
            },
            {
                title: Configure.Gl(1309,"密码"),
                dataIndex: 'password',
                width: 120,
            },
            {
                title: Configure.Gl(1535,"管理员"),
                dataIndex: 'admin',
                width: 120,
                render: (value) => value ? "√" : ""
            },
            {
                title: Configure.Gl(1000,"角色"),
                dataIndex: 'roleName',
                width: 120,
            },
            {
                title: Configure.Gl(1536,"所属部门"),
                dataIndex: 'dept',
                width: 120,
            },
            {
                title: Configure.Gl(1537,"所属公司"),
                dataIndex: 'company',
                width: 120,
            },
            {
                title: Configure.Gl(1057,'操作'),
                width: 200,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117,"编辑")}</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133,'删除')}</Button>
                    </Space>);
                },
            },
            {
                title: Configure.Gl(1065,"备注"),
                width: 300,
                dataIndex: 'note',
            },
        ];
    }

    getFilterUI() {
        return (<Space>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389,'新增')}{this.state.sth}</Button>
            <StaffFilter search={(f: any) => this.onFilterChange(f)}></StaffFilter>
        </Space>);
    }

    getEditorUI() {
        return (<StaffEdit ref={this.editor} reload={() => this.reload()} ></StaffEdit>);
    }

    getPath() { return RoutePath.STAFF_INDEX; }


    async reloadForData(query: any) {
        let data = await staffApi.list(query) as any;
        console.log(Configure.Gl(1390,"请求到的数据是："), data);
        this.addKeyToData(data.records);
        const roleList = await staffRoleApi.list() as any[];
        data.records.forEach((value: any) => {
            let roleName = "" + value.roleId;
            //console.log("this.roleOpt=", this.roleOpt);
            for (let opt of roleList) {
                if (opt.id === value.roleId) {
                    roleName = opt.name;
                    break;
                }
            }
            value.roleName = roleName;
        })
        return data;
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1391,'你确定要删除') + item.id + ' (' + item.name + ')？',
            onOk() {
                staffApi.delete(item.id).then(d => that.reload());
            }
        });
    }

}