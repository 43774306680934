import React from 'react';
import { Button, Select } from 'antd';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { SelectOpt } from '../def/CommonDef';
import moment from 'moment';
import Configure from '../api/Configure';

export interface IProps {
    search: Function
}

export abstract class AbsFilter {

    clear() {
        let obj: any = this;
        for (let k of Object.keys(this)) {
            obj[k] = undefined;
        }
    }
}

export abstract class StdItemFilter extends React.Component<IProps, any> {

    protected abstract getFilter(): any;

    protected setValue(key: string, value: any) {
        this.getFilter()[key] = value;
        let s: any = {};
        s[key] = value;
        this.setState(s);
    }

    protected optUI(optList: SelectOpt[]) {
        let ui:any[] = [];
        optList.forEach((opt: SelectOpt) => ui.push(<Select.Option key={opt.value} value={opt.value}>{opt.label}</Select.Option>));
        return ui;
    }

    protected getWebParam() {
        let f:any = {...this.getFilter()};
        for(let k in f){
            // console.log("属性=", k, ", 值=", f[k]);
            if(moment.isMoment(f[k])) {
                let m: moment.Moment = f[k];
                f[k] = m.format();
            }
         }
        return f;
    }

    protected doSearch = () => {
        this.props.search(this.getWebParam());
    }

    protected doClear = () => {
        let s: any = {};
        for (let k of Object.keys(this.getFilter())) {
            // let v = this.getFilter()[k];
            // console.log("k=", k, ", v=", v);
            s[k] = null;
        }
        this.setState(s);
        this.getFilter().clear();
        this.props.search();
    }

    protected renderCmd() {
        return (
            <span>
                <Button icon={<SearchOutlined />} onClick={this.doSearch}>{Configure.Gl(1152,'查找')}</Button>
                <Button type="dashed" icon={<CloseCircleOutlined />} onClick={this.doClear}>{Configure.Gl(1153,'清空')}</Button>
            </span>
        );
    }
}