import React from 'react';
import { Input } from 'antd';
import { AbsFilter, StdItemFilter } from '../../part/StdItemFilter';
import Configure from '../../api/Configure';

class Filter extends AbsFilter {
    username?: string = undefined;
    code?: string = undefined;
    deviceCode?: string = undefined;
    note?: string = undefined;
}

export default class StaffFilter extends StdItemFilter {

    state = {
        username: undefined as string | undefined,
        nickname: undefined as string | undefined,
        dept: undefined as string | undefined,
        company: undefined as string | undefined,
    };

    filter:Filter = new Filter();

    getFilter() { return this.filter; }

    async componentDidMount() {
        //const opt = await srvCrossApi.optClassify();
        //this.setState({optClassify: opt});
    }

    render() {
        return (
            <span>
                <Input placeholder={Configure.Gl(1308,"用户名")} value={this.state.username} onChange={e => this.setValue('username', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1534,"昵称")} value={this.state.nickname} onChange={e => this.setValue('nickname', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1536,"所属部门")} value={this.state.dept} onChange={e => this.setValue('dept', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1537,"所属公司")} value={this.state.company} onChange={e => this.setValue('company', e.target.value)} style={{width: 180}}/>
                { this.renderCmd() }
            </span>
        );
    }

}