import React from 'react';
import { Button, Select } from 'antd';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { staffRoleApi } from '../../api/console/staff.role';
import Configure from '../../api/Configure';

export interface IProps {
    search: Function
}

export default class StaffRoleLimitFilter extends React.Component<IProps, any> {

    constructor(props: IProps, context: any) {
        super(props, context);
        staffRoleApi.optionList().then(d => this.setState({ roleOpt: d }));
        staffRoleApi.optionGameIdList().then(d => this.setState({ groupOpt: d }));
    }

    state = {
        roleId: undefined as number | undefined,
        gameId: undefined as number | undefined,
        roleOpt: [] as any[],
        groupOpt: [] as any[],
    };

    private filter = {
        roleId: undefined as number | undefined,
        gameId: undefined as number | undefined,
        clear() {
            this.roleId = undefined;
            this.gameId = undefined;
        }
    }

    setRoleId(v: number) {
        this.filter.roleId = v;
        this.setState({ roleId: v });
    }

    getRoleName(corrid: number): string {
        let name = "";
        this.state.roleOpt.forEach((element: { id: number; gameId: string; name: string; }) => {
            if (corrid === element.id) {
                console.log(Configure.Gl(1390, "获得服务器"), corrid + "=" + element.id + "name:" + element.name);
                return element.name;
            }
        });
        return name;
    }

    setGameId(v: number) {
        this.filter.gameId = v;
        this.setState({ gameId: v });
    }

    render() {
        const roleOptUi: any[] = [];
        const { roleOpt } = this.state;
        roleOpt.forEach(opt => {
            roleOptUi.push(<Select.Option value={opt.value} key={opt.value}>{opt.label}</Select.Option>);
        });
        const groupOptUi: any[] = [];
        const { groupOpt } = this.state;
        groupOpt.forEach(opt => {
            groupOptUi.push(<Select.Option value={opt.value} key={opt.value}>{opt.label}</Select.Option>);
        });
        return (
            <span>
                <Select placeholder={Configure.Gl(1000, "角色")} value={this.state.roleId} onChange={v => this.setRoleId(v)} style={{ minWidth: 160 }}>
                    {roleOptUi}
                </Select>
                <Select placeholder={Configure.Gl(1123, "gameId")} value={this.state.gameId} onChange={v => this.setGameId(v)} style={{ minWidth: 160 }}>
                    {groupOptUi}
                </Select>
                <Button icon={<SearchOutlined />} onClick={this.doSearch}>{Configure.Gl(1152, '查找')}</Button>
                <Button type="dashed" icon={<CloseCircleOutlined />} onClick={this.doClear}>{Configure.Gl(1153, '清空')}</Button>
            </span>
        );
    }

    doSearch = () => {
        this.props.search(this.filter);
    }

    doClear = () => {
        this.setState({ roleId: null, gameId: null });
        this.filter.clear();
        this.props.search();
    }
}