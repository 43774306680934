export class RoutePath {

    public static HOME_INDEX: string = "/home/index";
    public static LOGIN_INDEX: string = "/login/index";
    public static LAB_CC_TEST_INDEX: string = "/lab/cc/test/index";
    public static LAB_DEMO_ENTITY_INDEX: string = "/lab/demo/entity/index";
    public static EMERGENCY_INDEX: string = "/emergency/index";
    public static OTHER_CHLEAN_MEMORY_INDEX: string ="/other/cleanmemory/index";
    public static URL_MAP_INDEX: string = "/url/map/index";

    public static PLATFORM_APP_INDEX: string = "/platform/app/index";

    public static CLOUD_DEVICE_INDEX: string = "/cloud/device/index";
    public static CLOUD_MQ_INDEX: string = "/cloud/mq/index";
    public static CLOUD_MYSQL_INDEX: string = "/cloud/mysql/index";
    public static CLOUD_MONGO_INDEX: string = "/cloud/mongo/index";

    public static STAFF_INDEX: string = "/staff/index";
    public static STAFF_ROLE_INDEX: string = "/staff/role/index";
    public static STAFF_ROLE_PERMISSION_INDEX: string = "/staff/role/permission/index";
    public static STAFF_ROLE_SRV_GROUP_INDEX: string = "/staff/role/srv/group/index";
    public static STAFF_GAMEID_LIMIT_INDEX: string = "/staff/role/gameidlimit/index";
    
    public static GLOBAL_CONF_INDEX: string = "/global/conf/index";
    public static SRV_GAME_INDEX: string = "/srv/game/index";
    public static SRV_GAME_ACCESS_INDEX: string = "/srv/game/access/index";
    public static SRV_GAME_EXT_INDEX: string = "/srv/game/ext/index";
    public static SRV_STATUS_PLAN_INDEX: string = "/srv/status/plan/index";
    public static SRV_GROUP_INDEX: string = "/srv/group/index";
    public static SRV_GROUP_CONF_INDEX: string = "/srv/group/conf/index";
    public static SRV_CROSS_INDEX: string = "/srv/cross/index";
    public static SRV_CROSS_PLAN_INDEX: string = "/srv/cross/plan/index";
    public static SRV_MERGE_INDEX: string = "/srv/merge/index";

    public static GAME_RT_INDEX: string = "/game/rt/index";
    public static SUPERVISE_REPORT_INDEX: string = "/supervise/report/index";
    public static NEW_DAILY_BRIEFING_INDEX: string = "/briefing/newdaily/index";

    public static GM_FREEZE_INDEX: string = "/gm/freeze/index";
    public static GM_CHAT_FORBID_INDEX: string = "/gm/chat/forbid/index";
    public static GIFT_CODE_PLATFORM_INDEX: string = "/gift/code/platform/index";
    public static GIFT_CODE_BUILD_INDEX: string = "/gift/code/build/index";
    public static GIFT_CODE_SEND_INDEX: string = "/gift/code/send/index";
    public static GM_PAY_MANUAL_INDEX: string = "/gm/pay/manual/index";
    public static GM_PAY_APPROVE_INDEX: string = "/gm/pay/approve/index";
    public static GM_MAIL_EDIT_INDEX: string = "/gm/mail/edit/index";
    public static GM_MAIL_APPROVE_INDEX: string = "/gm/mail/approve/index";
    public static GM_ANNOUNCE_INDEX: string = "/gm/announce/index";
    public static REBATE_APPROVE_INDEX: string = "/gm/rebate/approve/index";
    public static REBATE_EDIT_INDEX: string = "/gm/rebate/edit/index";
    public static GM_MAIL_SPE_APPROVE_INDEX: string = "/gm/mail/spe/approve/index";
    public static GM_ORDER_QUERY_INDEX: string = "/gm/order/query/index";
    public static GM_ITEM_QUERY_INDEX: string = "/gm/item/query/index";
    public static GM_VIP_CUSTOMER_INDEX: string ="/gm/vip/boundary/index";
    public static GM_HERO_SEACH_INDEX:string ="/gm/hero/seach/index";

    public static ROLE_BAG_INDEX: string = "/role/bag/index";
    public static ROLE_WALLET_INDEX: string = "/role/wallet/index";

    public static STAT_COIN_MALL_INDEX:string ="/stat/pay/coinmall/index";
    public static STAT_PART_CONF_INDEX: string = "/stat/part/conf/index";
    public static STAT_PAY_RANK_INDEX: string = "/stat/pay/rank/index";
    public static STAT_ROLE_KEY_NUM_INDEX: string = "/stat/role/key/num/index";
    public static STAT_LOGIN_FLOW_INDEX: string = "/stat/login/flow/index";
    public static STAT_HERO_LINEUP_INDEX: string = "/stat/hero/lineup/index";

    public static STAT_USER_LTV_INDEX:string ="/stat/user/ltv/index";

    public static STAT_FUNC_PART_INDEX:string ="/stat/transfer/lineup/index";
    public static STAT_NEW_PRESERVER_INDEX:string ="/stat/preserver/newlineup/index";

    public static PAY_STAT_GS_LIFE_INDEX: string = "/pay/stat/gs/life/index";

    public static SYS_TASK_INDEX: string = "/sys/task/index";
};

