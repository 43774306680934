import axios from 'axios';
import { Modal } from 'antd';
import { GmcEnv } from './env';
import { StoreUtil } from './store';
import Configure from '../api/Configure';

let alertSuccess = (title: string, content: string): void => {
    //alert("" + title + "\r" + content);
    Modal.success({ title, content, maskClosable: true });
}

let alertFail = (title: string, content: string): void => {
    //alert("" + title + "\r" + content);
    Modal.error({ title, content, maskClosable: true });
}

export let res = (baseUrl: string, obj: any) => {
    return new Promise((resolve, reject) => {
        axios.get(baseUrl + obj.path, {
        }).then(response => {
            if (response.data.errorcode === 0) {
                console.log("请求" + obj.path + "成功", response.data);

                let t = Configure.Gl(1757,'请求') + obj.path + Configure.Gl(1758,'成功');
                if (obj.title) {
                    t = obj.title + Configure.Gl(1758,' 成功');
                }
                if (obj.quiet) {
                    //
                } else {
                    alertSuccess(t, '' + response.data.errormsg);
                }
                resolve(response.data.data);
            } else {
                console.error("请求" + obj.path + "失败", response.data);

                let t = Configure.Gl(1757,'请求') + obj.path + Configure.Gl(1759,'失败');
                if (obj.title) {
                    t = obj.title + Configure.Gl(1759,' 失败');
                }
                alertFail(t, '' + response.data.errormsg);

                reject(response.data);
            }
        });
    });
}

export interface HttpCmd {
    path: string;
    param?: any;
    title: string;
    quiet?: boolean;
    showData?: boolean;
    resultHandler?: Function;
}

export let http = {
    get: <R>(baseUrl: string, obj: HttpCmd) => {
        return new Promise<R>((resolve, reject) => {
            //console.log('store.getToken()=', store.getToken());
            axios.get(baseUrl + obj.path, {
                params: obj.param,
                headers: {
                    'content-type': 'application/json',
                    'Login-Token': StoreUtil.getToken()
                }
            }).then(response => {
                if (response.data.errorcode === 0) {
                    console.log("请求" + obj.path + "成功", response.data);

                    let t = Configure.Gl(1757,'请求') + obj.path + Configure.Gl(1758,'成功');
                    if (obj.title) {
                        t = obj.title + Configure.Gl(1758,' 成功');
                    }
                    if (obj.quiet) {
                        //
                    } else {
                        alertSuccess(t, '' + response.data.errormsg + (obj.showData?'! '+response.data.data:''));
                    }

                    if(obj.resultHandler) {
                        obj.resultHandler(response.data.data);
                    }

                    resolve(response.data.data);
                } else {
                    console.error("请求" + obj.path + "失败", response.data);

                    let t = Configure.Gl(1757,'请求') + obj.path + Configure.Gl(1759,'失败');
                    if (obj.title) {
                        t = obj.title + Configure.Gl(1759,' 失败');
                    }
                    alertFail(t, '' + response.data.errormsg);

                    //reject(response.data);
                }
            }).catch(e => {
                alertFail(Configure.Gl(1760,"故障"), "" + e);
            });
        });
    },

    post: <R>(baseUrl: string, obj: HttpCmd) => {
        return new Promise<R>((resolve, reject) => {
            axios.post(baseUrl + obj.path, obj.param, {
                headers: {
                    'content-type': 'application/json',
                    'Login-Token': StoreUtil.getToken()
                }
            }).then(response => {
                if (response.data.errorcode === 0) {
                    console.log("请求" + obj.path + "成功", response.data);

                    let t = Configure.Gl(1757,'请求') + obj.path + Configure.Gl(1758,'成功');
                    if (obj.title) {
                        t = obj.title + Configure.Gl(1758,' 成功');
                    }
                    if (obj.quiet) {
                        //
                    } else {
                        alertSuccess(t, '' + response.data.errormsg + (obj.showData?'! '+response.data.data:''));
                    }

                    if(obj.resultHandler) {
                        obj.resultHandler(response.data.data);
                    }

                    resolve(response.data.data);
                } else {
                    console.error("请求" + obj.path + "失败", response.data);

                    let t = Configure.Gl(1757,'请求') + obj.path + Configure.Gl(1759,'失败');
                    if (obj.title) {
                        t = obj.title + Configure.Gl(1759,' 失败');
                    }
                    alertFail(t, '' + response.data.errormsg);

                    //reject(response.data);
                }
            }).catch(e => {
                alertFail(Configure.Gl(1760,"故障"), "" + e);
            });
        });
    }
}

export let consoleApi = {
    get: <R>(obj: HttpCmd) => {
        return http.get<R>(GmcEnv.getInstance().consoleUrl, obj);
    },

    post: <R>(obj: HttpCmd) => {
        return http.post<R>(GmcEnv.getInstance().consoleUrl, obj);
    }
}

export let metaApi = {
    get: <R>(obj: HttpCmd) => {
        return http.get<R>(GmcEnv.getInstance().metaUrl, obj);
    },

    post: <R>(obj: HttpCmd) => {
        return http.post<R>(GmcEnv.getInstance().metaUrl, obj);
    }
}

export let statApi = {
    get: (obj: any) => {
        return http.get(GmcEnv.getInstance().statUrl, obj);
    },

    post: (obj: any) => {
        return http.post(GmcEnv.getInstance().statUrl, obj);
    }
}

export let payApi = {
    get: (obj: any) => {
        return http.get(GmcEnv.getInstance().payUrl, obj);
    },

    post: (obj: any) => {
        return http.post(GmcEnv.getInstance().payUrl, obj);
    }
}

export let worldApi = {
    get: (obj: any) => {
        return http.get(GmcEnv.getInstance().worldUrl, obj);
    },

    post: (obj: any) => {
        return http.post(GmcEnv.getInstance().worldUrl, obj);
    }
}

export let loginApi = {
    get: <R>(obj: any) => {
        return http.get<R>(GmcEnv.getInstance().loginUrl, obj);
    },

    post: <R>(obj: any) => {
        return http.post<R>(GmcEnv.getInstance().loginUrl, obj);
    }
}