import { consoleApi } from '../../util/http';
import Configure from '../Configure';

export class SrvGroupApi {

    create(item: any) {
        return consoleApi.post({
            path: 'srv/group/create',
            param: item,
            title: Configure.Gl(1116,"新建")
        });
    }

    update(item: any) {
        return consoleApi.post({
            path: 'srv/group/update',
            param: item,
            title: Configure.Gl(1132,'修改')
        });
    }

    delete(id: number) {
        return consoleApi.get({
            path: 'srv/group/delete?id=' + id,
            title: Configure.Gl(1133,'删除'),
            quiet: true
        });
    }

    list() {
        return consoleApi.get({
            path: 'srv/group/list',
            title: Configure.Gl(1704,'列表'),
            quiet: true
        });
    }

    async optionList() {
        let data = await this.list() as any[];
        let opts: any[] = [];
        data.forEach(item => {
            opts.push({
                label: "(" + item.id + ")" + item.name,
                value: item.id
            });
        });
        return opts;
    }
}

export const srvGroupApi: SrvGroupApi = new SrvGroupApi();