import { consoleApi } from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

class CloudMysqlApi extends ApiBase {
    
    create(item: any) {
        return consoleApi.post({
            path: 'cloud/mysql/v2/create',
            param: item,
            title: Configure.Gl(1664,'新建Mysql')
        });
    }

    update(item: any) {
        return consoleApi.post({
            path: 'cloud/mysql/v2/update',
            param: item,
            title: Configure.Gl(1665,'修改Mysql')
        });
    }

    delete(code: string) {
        return consoleApi.get({
            path: 'cloud/mysql/v2/delete?code='+code,
            title: Configure.Gl(1666,"删除Mysql"),
            quiet: true
        });
    }

    list(query: any) {
        return consoleApi.get({
            path: 'cloud/mysql/v2/list',
            title: Configure.Gl(1667,"列出Mysql"),
            param: query,
            quiet: true
        });
    }

}

export const cloudMysqlApi: CloudMysqlApi = new CloudMysqlApi();