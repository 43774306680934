import { consoleApi } from '../../util/http';
import Configure from '../Configure';

class StaffApi {

    list(filter?:any) {
        return consoleApi.get({
            path: 'staff/list',
            title: Configure.Gl(1744,'拉取所有员工'),
            param: filter,
            quiet: true
        });
    }

    create(item: any) {
        return consoleApi.post({
            path: 'staff/create',
            param: item,
            title: Configure.Gl(1745,'新建员工')
        });
    }

    update(item: any) {
        return consoleApi.post({
            path: 'staff/update',
            param: item,
            title: Configure.Gl(1746,'修改员工信息')
        });
    }

    delete(id: number) {
        return consoleApi.get({
            path: 'staff/delete?id=' + id,
            title: Configure.Gl(1133,'删除'),
            quiet: false
        });
    }

    async optionList() {
        let data: any = await this.list();
        let opts: any[] = [];
        data.forEach((element: any) => {
            opts.push({
                text: "(" + element.id + ") " + element.nickname,
                value: element.id
            });
        });
        return opts;
    }

    login(username: string, password: string) {
        return consoleApi.post({
            path: 'staff/login',
            param: { username, password },
            title: Configure.Gl(1747,'内部用户登录')
        });
    }

    register(username: string, password: string, nickname: string, secret: string) {
        return consoleApi.post({
            path: 'staff/register',
            param: { staff: { username, password, nickname }, secret },
            title: Configure.Gl(1748,'内部用户注册')
        });
    }

    updateAuth(authTypeList: any, secret: string) {
        return consoleApi.post({
            path: 'staff/auth/update?secret=' + secret,
            param: authTypeList,
            title: Configure.Gl(1749,'内部用户权限应用')
        });
    };

    listAuth() {
        return consoleApi.get({
            path: 'staff/auth/list',
            title: Configure.Gl(1750,'获取我的权限'),
            quiet: true
        });
    }

    session() {
        return consoleApi.get({
            path: 'staff/session',
            title: Configure.Gl(1751,'获取session'),
            quiet: true
        });
    }
}

export const staffApi: StaffApi = new StaffApi();