import { SelectOpt } from '../../def/CommonDef';
import { consoleApi } from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

class PlatformAppApi extends ApiBase {

    create(item: any) {
        return consoleApi.post({
            path: 'app/create',
            param: item,
            title: Configure.Gl(1708,'新建APP')
        });
    }

    update(item: any) {
        return consoleApi.post({
            path: 'app/update',
            param: item,
            title: Configure.Gl(1709,'修改APP')
        });
    }

    delete(id: number) {
        return consoleApi.get({
            path: 'app/delete?id='+id,
            title: Configure.Gl(1710,"删除APP"),
            quiet: true
        });
    }

    list() {
        return consoleApi.get({
            path: 'app/list',
            title: Configure.Gl(1711,"列出APP"),
            quiet: true
        });
    }

    optGameId() {
        return new Promise((resolve, reject) => {
            consoleApi.get({
                path: 'app/list',
                title: Configure.Gl(1712,'获取App列表'),
                quiet: true
            }).then(data => {
                let opts: SelectOpt[] = [];
                let arr = data as any[];
                arr.forEach(ele => {
                    let opt: SelectOpt = {
                        label: "(" + ele.gameId + ")" + ele.name,
                        value: ele.gameId
                    }
                    opts.push(opt);
                });
                resolve(opts);
            }).catch(e => reject(e));
        });
    }

}

export const platformAppApi: PlatformAppApi = new PlatformAppApi();