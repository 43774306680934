import React from 'react';

import { RoutePath } from '../../../route/path';
import GameRtFilter from './GameRtFilter';
import { gameRtApi } from '../../../api/console/game.rt';
import { IPagingState, StdItemPageLimit } from '../../../part/StdItemPageLimit';
import { ColumnsType } from 'antd/lib/table';
import { Space } from 'antd';
import DateTool from '../../../util/DateTool';
import Configure from '../../../api/Configure';

interface IState extends IPagingState {
    sth: string;
}

export default class GameRtIndex extends StdItemPageLimit<IState> {

    state = {
        ...this.getDefaultState(),
        sth: ''
    };

    getCols(): ColumnsType<any> {
        return [
            { title: Configure.Gl(1079,"游戏服编码"), dataIndex: 'srvCode', fixed: 'left', width: 120 },
            { title: Configure.Gl(1080,"游戏服名"), dataIndex: 'srvName',fixed: 'left' , width: 120 },
            { title: Configure.Gl(1081,"游戏服序号"), dataIndex: 'srvIndex', width: 120 },
            { title: Configure.Gl(1082,"被合服吞并"), dataIndex: 'srvEaten', width: 120, render: v => v ? Configure.Gl(1087,"已被合"):"" },
            { title: Configure.Gl(1478,'开服时间'), dataIndex: 'srvOpenTime', width: 200, render: this.formatDateTime },
            { title: Configure.Gl(1083,"服务器组"), dataIndex: 'groupName', width: 120 },
            { title: Configure.Gl(1085,'更新时间'), dataIndex: 'updateTime', width: 200, render: DateTool.beautifyIgnMs },
            { title: Configure.Gl(1086,'延迟'), dataIndex: 'delaySeconds', width: 120, render: v => {
                    if(v > 15 && v <= 20) return <b style={{color: "#ee9911"}}>{v}</b>
                    if(v > 20) return <b style={{color: "#ee1111"}}>{v}</b>
                    return v;
                }
            },
            { title: Configure.Gl(1088,'状态'), dataIndex: 'status', width: 120 },
            { title: Configure.Gl(1089,'在线人数'), dataIndex: 'online', width: 120, sorter: {multiple: 1, compare: (a,b)=>0}},
            { title: Configure.Gl(1090,'角色数'), dataIndex: 'roleNum', width: 120, sorter: {multiple: 2, compare: (a,b)=>0} },
            { title: Configure.Gl(1091,'最高等级'), dataIndex: 'levelMax', width: 120, sorter: {multiple: 3, compare: (a,b)=>0} },
            { title: Configure.Gl(1092,'VIP最高级'), dataIndex: 'vipMax', width: 120, sorter: {multiple: 4, compare: (a,b)=>0} },
            { title: Configure.Gl(1093,'最高战力'), dataIndex: 'powerMax', width: 180, sorter: {multiple: 5, compare: (a,b)=>0}, },
            { title: Configure.Gl(1094,'战力前50均值'), dataIndex: 'powerAvg50', width: 180 },
            { title: Configure.Gl(1095,'世界等级'), dataIndex: 'worldLevel', width: 120, sorter: {multiple: 6, compare: (a,b)=>0}, },
            { title: Configure.Gl(1096,'版本信息'), dataIndex: 'version', width: 1800 },
        ];
    }
    
    getFilterUI() {
        return (<Space>
            <GameRtFilter search={(f: any) => this.onFilterChange(f)}></GameRtFilter>
        </Space>);
    }

    getEditorUI() {
        return null;
    }

    getPath() { return RoutePath.GAME_RT_INDEX; }

    async reloadForData(query: any) {
        const { sorter } = this.state;
        console.log("查看排序参数:", sorter);
        this.writeSorter("online", query, "onlineOrder");
        this.writeSorter("roleNum", query, "roleNumOrder");
        this.writeSorter("levelMax", query, "levelMaxOrder");
        this.writeSorter("vipMax", query, "vipMaxOrder");
        this.writeSorter("powerMax", query, "powerMaxOrder");
        this.writeSorter("worldLevel", query, "worldLevelOrder");
        let data = await gameRtApi.list(query) as any;
        return data;
    }
}