import React, { RefObject } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Table, Space, Divider, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';

import { StdItemPage } from '../../part/StdItemPage';
import { HomeLayout } from '../../layout/HomeLayout';
import { RoutePath } from '../../route/path';
import StaffRoleSrvGroupEdit from './StaffRoleSrvGroupEdit';
import StaffRoleSrvGroupFilter from './StaffRoleSrvGroupFilter';
import { staffRoleApi } from '../../api/console/staff.role';
import Configure from '../../api/Configure';

export default class StaffRoleSrvGroupIndex extends StdItemPage<any> {

    private editor: RefObject<StaffRoleSrvGroupEdit>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
    }

    state = {
        showEdit: false,
        itemList: [],
        query: {}
    };

    render() {
        const columns: ColumnsType<any> = [
            {
                title: Configure.Gl(1000, "角色"),
                dataIndex: 'roleName',
                fixed: 'left',
                width: 160,
                render: (value: any, record: any) => {
                    return <Button type="link" onClick={() => this.editor.current?.showUpdate(record)}>{"(" + record.roleId + ")" + value}</Button>
                }
            },
            {
                title: Configure.Gl(1083, '服务器组'),
                dataIndex: 'srvGroupName',
                width: 150,
                render: (value: any, record: any) => "(" + record.srvGroupId + ")" + value
            },
            {
                title: Configure.Gl(1057, '操作'),
                width: 200,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117, "编辑")}</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133, '删除')}</Button>
                    </Space>);
                },
            }
        ];

        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
        };

        return (
            <HomeLayout path={RoutePath.STAFF_ROLE_SRV_GROUP_INDEX}>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389, '新增')}</Button>
                    <StaffRoleSrvGroupFilter search={(f: any) => this.onFilterChange(f)}></StaffRoleSrvGroupFilter>
                </Space>
                <Divider />
                <Table dataSource={this.state.itemList} columns={columns} pagination={pagination} scroll={{ x: 1500, y: 670 }} />
                <StaffRoleSrvGroupEdit ref={this.editor} reload={() => this.reload()} ></StaffRoleSrvGroupEdit>
            </HomeLayout>
        );
    }

    componentDidMount() {
        console.log("StaffRoleSrvGroupIndex::componentDidMount");
        this.reload();
    }

    onFilterChange(filter: any) {
        console.log("沙雕 filter=", filter);
        this.setState({ query: filter }, () => {
            this.reload();
        });
    }

    async reload() {
        const { query } = this.state;
        let data = await staffRoleApi.listSrvGroup(query) as any[];
        console.log(Configure.Gl(1390, "请求到的数据是："), data);
        this.addKeyToData(data);
        this.setState({ itemList: data });
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066, '警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1391, '你确定要删除') + item.roleId + ' (' + item.roleName + ') ' + item.srvGroupId + '(' + item.srvGroupName + ')？',
            onOk() {
                staffRoleApi.deletePermission(item.id).then(d => that.reload());
            }
        });
    }

}