import { consoleApi } from '../../util/http';
import Configure from '../Configure';

export class SrvCrossCmdApi {

    start(crossCode: string) {
        return consoleApi.get({
            path: 'srv/cross/cmd/start?crossCode=' + crossCode,
            title: Configure.Gl(1419,"启动跨服"),
            quiet: false
        });
    }

    stop(crossCode: string) {
        return consoleApi.get({
            path: 'srv/cross/cmd/stop?crossCode=' + crossCode,
            title: Configure.Gl(1420,"关闭跨服"),
            quiet: false
        });
    }

    startBatch(crossCodeList: string[]) {
        return consoleApi.post({
            path: 'srv/cross/cmd/start/batch',
            param: crossCodeList,
            title: Configure.Gl(1421,"启动所选跨服"),
            quiet: false
        });
    }

    stopBatch(crossCodeList: string[]) {
        return consoleApi.post({
            path: 'srv/cross/cmd/stop/batch',
            param: crossCodeList,
            title: Configure.Gl(1422,"关闭所选跨服"),
            quiet: false
        });
    }

    checkAll() {
        return consoleApi.post({
            path: 'srv/cross/cmd/check/all',
            param: {},
            title: Configure.Gl(1406,'配置核验与纠正'),
            quiet: false,
            showData: true
        });
    }

    resetMember(crossCode: string) {
        return consoleApi.get({
            path: 'srv/cross/cmd/member/reset?crossCode=' + crossCode,
            title: Configure.Gl(1423,"重置跨服成员"),
            quiet: false
        });
    }
}

export const srvCrossCmdApi: SrvCrossCmdApi = new SrvCrossCmdApi();