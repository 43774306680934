import React, { RefObject } from 'react';
import { Button, Table, Form, Divider, Input, DatePicker, Spin, Switch, message } from 'antd';
import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import { StdItemPage } from '../../../part/StdItemPage';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';
import { formatDate } from '../../../util/toolbox';
import { Key, TableCurrentDataSource } from 'antd/lib/table/interface';
import { SorterResult } from 'antd/es/table/interface';
import { FormInstance } from 'antd/lib/form';
import { statSrvApi } from '../../../api/stat/all';
import SrvGameSelectEdit from '../../srv/SrvGameSelectEdit';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import Configure from '../../../api/Configure';
import ExcelEdit from '../../stat/preserve/ExcelEdit';


export default class GmItemQueryIndex extends StdItemPage<any> {
    private editor: RefObject<SrvGameSelectEdit>;
    private excel: RefObject<ExcelEdit>;
    private passtime: number = 0;
    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
        this.excel = React.createRef();
    }

    state = {
        itemTotal: undefined,
        spinLoad: false,
        queryPagination: {
            page: 1,
            pageSize: 100
        },
        itemList: [],
        queryFilter: {},
    }

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1121, '服务器编码'),
                dataIndex: 'srvCode',
                width: 100,
            },
            {
                title: Configure.Gl(1101, '主角唯一ID'),
                dataIndex: 'masterIdHex',
                width: 200,
            },
            {
                title: Configure.Gl(1151, '主角名'),
                dataIndex: 'masterName',
                width: 120,
            },
            {
                title: Configure.Gl(1187, '主角等级'),
                dataIndex: 'masterLevel',
                width: 65,
            },
            {
                title: Configure.Gl(1188, '主角VIP'),
                dataIndex: 'masterVip',
                width: 65,
            },
            {
                title: Configure.Gl(1189, '物品类型'),
                dataIndex: 'itemType',
                width: 70,
            },
            {
                title: Configure.Gl(1190, '模板ID'),
                dataIndex: 'itemTid',
                width: 75,
            },
            {
                title: Configure.Gl(1191, '物品名'),
                dataIndex: 'itemName',
                width: 115,
            },
            {
                title: Configure.Gl(1192, '数量变更'),
                dataIndex: 'itemNum',
                width: 100,
            },
            {
                title: Configure.Gl(1193, '变更后数量'),
                dataIndex: 'itemNumEnd',
                width: 100,
            },
            {
                title: Configure.Gl(1194, '操作ID'),
                dataIndex: 'operation',
                width: 80,
            },
            {
                title: Configure.Gl(1195, '操作描述'),
                dataIndex: 'operationDesc',
                width: 120,
            },
            {
                title: Configure.Gl(1196, '操作子ID'),
                dataIndex: 'operationSub',
                width: 100,
            },
            {
                title: Configure.Gl(1197, '时间'),
                width: 100,
                dataIndex: 'time',
                render: (v, r) => formatDate(r.time),
            },
        ];
    }

    formRef = React.createRef<FormInstance>();
    render() {
        const { itemTotal, queryPagination } = this.state;
        const columns: ColumnsType<any> = this.getCols();
        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            defaultPageSize: queryPagination.pageSize,
            total: itemTotal
        };
        return (
            <HomeLayout path={RoutePath.GM_ITEM_QUERY_INDEX}>
                <Spin spinning={this.state.spinLoad} tip={Configure.Gl(1774, '获取服务器数据中...')}>
                    <Form
                        ref={this.formRef}
                        layout="vertical"
                        name="basic"
                        onFinish={this.onFormFinish}
                        onFinishFailed={this.onFormFinishFailed}
                        initialValues={{
                            beginTime: moment().startOf('day'),
                            endTime: moment(),
                        }}
                    >
                        <Form.Item>
                            <Form.Item style={{ float: 'left' }}>
                                <SrvGameSelectEdit ref={this.editor} selectType={"radio"} handleOk={(v: any[]) => this.handleOk(v)} ></SrvGameSelectEdit>
                            </Form.Item>
                            <Form.Item name="srvCode" style={{ float: 'left', width: '12%' }}>
                                <Input placeholder={Configure.Gl(1198, "请填写或选择游戏服务器")} />
                            </Form.Item>
                            <Form.Item name="beginTime" style={{ float: 'left' }}>
                                <DatePicker showTime />
                            </Form.Item>
                            <Form.Item name="endTime" style={{ float: 'left' }}>
                                <DatePicker showTime />
                            </Form.Item>
                            <Form.Item style={{ float: 'left' }}>
                                <Button type="primary" htmlType="submit">{Configure.Gl(1152, '查找')}</Button>
                            </Form.Item>
                            <Form.Item style={{ float: 'left' }}>
                                <ExcelEdit ref={this.excel} onExcelprot={(v: any[]) => this.handleExeclOk}></ExcelEdit>
                            </Form.Item>
                            <Form.Item>
                                <Switch className="switch" checkedChildren={Configure.Gl(1929, "开启查询全部条目")} unCheckedChildren={Configure.Gl(1930, "关闭查询全部条目")} onChange={e => this.onSwitch(e)} />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name="itemName" style={{ float: 'left', width: '10%' }}>
                                <Input placeholder={Configure.Gl(1191, "物品名")} />
                            </Form.Item>
                            <Form.Item name="itemTid" style={{ float: 'left', width: '10%' }}>
                                <Input placeholder={Configure.Gl(1190, "模板ID")} />
                            </Form.Item>
                            <Form.Item name="masterIdHex" style={{ float: 'left', width: '20%' }}>
                                <Input placeholder={Configure.Gl(1101, "主角唯一ID")} />
                            </Form.Item>
                            <Form.Item name="masterName" style={{ float: 'left', width: '10%' }}>
                                <Input placeholder={Configure.Gl(1151, "主角名")} />
                            </Form.Item>
                            <Form.Item name="operation" style={{ float: 'left', width: '10%' }}>
                                <Input placeholder={Configure.Gl(1194, "操作ID")} />
                            </Form.Item>
                            <Form.Item name="operationSub" style={{ float: 'left', width: '10%' }}>
                                <Input placeholder={Configure.Gl(1196, "操作子ID")} />
                            </Form.Item>
                        </Form.Item>
                    </Form>
                    <Divider />
                    <Table dataSource={this.state.itemList} columns={columns}
                        pagination={pagination} scroll={{ x: this.getColsWidth(columns), y: 512 }} onChange={(p, f, s, e) => { this.onPageChange(p, f, s, e) }} />
                </Spin>
            </HomeLayout >
        );
    }

    handleOk(v: any[]) {
        if (v.length > 0) {
            this.formRef.current?.setFieldsValue({ srvCode: v[0].code });
        }
    }

    async onSwitch(v: boolean) {
        console.error(v);
        if (v) {
            if (this.state.itemList.length <= 0) {
                message.error(Configure.Gl(1931, "请先查询一次后再进行全选操作!"));
                return;
            } else {
                let count: number = Math.ceil(this.state.itemTotal as unknown as number / this.state.queryPagination.pageSize);
                let itemList: any[] = [];
                let time: number = count * this.passtime;
                let strTime: string = Configure.Gl(1933, "秒");
                if (time > 60 && time < 3600) {
                    strTime = Configure.Gl(1935, "分");
                    time = Math.ceil(time / 60);
                } else if (time >= 3600) {
                    strTime = Configure.Gl(1936, "小时");
                    time = Math.ceil(time / 3600);
                }
                message.success(Configure.Gl(1932, "当前预估需要:") + time + strTime + Configure.Gl(1934, ",请耐心等待!"));
                this.setState({ spinLoad: true });
                for (let i = 1; i <= count; i++) {
                    this.state.queryPagination.page = i;
                    const { queryPagination, queryFilter } = this.state;
                    const query = { ...queryPagination, ...queryFilter };
                    let data = await statSrvApi.item_io_list(query) as any;
                    itemList = itemList.concat(data.records);
                }
                this.excel.current?.setOdataSource(itemList, this.getCols());
                this.setState({ spinLoad: false });
            }
        } else {
            if (this.state.itemList.length > 0) {
                this.setState({ itemList: [] })
                this.excel.current?.clearItem();
            }
        }
    }

    onFormFinish = (values: any) => {
        if (!values.srvCode) {
            alert(Configure.Gl(1199, "请先选择游戏服务器"));
            return;
        }
        let filter = values;
        filter.beginTime = values.beginTime ? formatDate(values.beginTime as moment.Moment) : undefined;
        filter.endTime = values.endTime ? formatDate(values.endTime as moment.Moment) : undefined;
        this.setState({ queryFilter: filter }, () => this.reload());
    }

    onFormFinishFailed = (errorInfo: any) => {
        console.error('onFormFinishFailed:', errorInfo);
    }

    onPageChange(pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        this.setState({
            queryPagination: {
                page: pagination.current,
                pageSize: pagination.pageSize
            }
        }, () => {
            this.reload();
        });
    }

    async handleExeclOk() {
        console.log('这边做合并所有页面操作!');
    }

    componentDidMount() {
        // this.reload();
    }

    async reload() {
        this.setState({ spinLoad: true });
        const { queryPagination, queryFilter } = this.state;
        const query = { ...queryPagination, ...queryFilter };
        console.log("请求参数为：", query);
        let dt: Date = new Date();
        let startTime: number = dt.getTime();
        let data = await statSrvApi.item_io_list(query) as any;
        console.log(Configure.Gl(1390, "请求到的数据是："), data);
        this.addKeyToData(data.records);
        this.setState({ itemList: data.records, itemTotal: data.total, sumPayAmount: data.sumPayAmount });

        if (data.records.length > 0) {
            data.records.forEach((element: { time: string; }) => {
                let day = formatDate(element.time);
                element.time = day;
            });
        }
        let dt1: Date = new Date();
        let endTime: number = dt1.getTime();
        this.passtime = Math.ceil((endTime - startTime) / 1000);
        console.log('当前每页执行时间:' + this.passtime);
        this.excel.current?.setOdataSource(data.records, this.getCols());
        this.setState({ spinLoad: false });
    }
}