import React, { RefObject } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Table, Space, Divider, Modal } from 'antd';
import { SearchOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';

import { HomeLayout } from '../../layout/HomeLayout';
import { RoutePath } from '../../route/path';
import { platformAppApi } from '../../api/console/platform.app';
import PlatformAppEdit from './PlatformAppEdit';
import Configure from '../../api/Configure';

export default class PlatformAppIndex extends React.Component<RouteComponentProps> {

    private editor: RefObject<PlatformAppEdit>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
    }

    state = {
        showEdit: false,
        itemList: [],
    };

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1055,'ID'),
                dataIndex: 'id',
                width: 80,
                fixed: 'left',
                sorter: (a, b) => a.id - b.id,
            },
            { title: Configure.Gl(1384,"应用名称"), dataIndex: 'name', width: 180 },
            { title: Configure.Gl(1122,'平台编码'), dataIndex: 'platformCode', width: 100 },
            { title: 'GameId', dataIndex: 'gameId', width: 100 },
            { title: 'GameKey', dataIndex: 'gameKey', width: 200 },
            { title: 'SecretKey', dataIndex: 'secretKey', width: 200 },
            { title: Configure.Gl(1385,"关联编码"), dataIndex: 'associatedCode', width: 100 },
            { title: Configure.Gl(1065,"备注"), dataIndex: 'note', width: 200 },
            { title: Configure.Gl(1386,'客户端路径'), dataIndex: 'xxxx', width: 300 },
            {
                title: Configure.Gl(1057,'操作'),
                width: 200,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117,"编辑")}</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133,'删除')}</Button>
                    </Space>);
                },
            },
        ];
    }

    render() {
        const columns = this.getCols();

        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
        };

        return (
            <HomeLayout path={RoutePath.PLATFORM_APP_INDEX}>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389,'新增')}</Button>
                    <Button type="primary" icon={<SearchOutlined />} onClick={() => this.reload()}>{Configure.Gl(1152,'查找')}</Button>
                </Space>
                <Divider />
                <Table dataSource={this.state.itemList} columns={columns} pagination={pagination} scroll={{ x: 1500, y: 512 }} />
                <PlatformAppEdit ref={this.editor} reload={() => this.reload()} ></PlatformAppEdit>
            </HomeLayout>
        );
    }

    componentDidMount() {
        console.log("PlatformAppIndex::componentDidMount");
        this.reload();
    }
    
    async reload() {
        let data = await platformAppApi.list() as any[];
        console.log(Configure.Gl(1390,"请求到的数据是："), data);
        data.forEach(element => { element.key = Math.random() });
        this.setState({ itemList: data });
        
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1067,'你确定要删除 id = ') + item.id + ' name = ' + item.name + '？',
            onOk() {
                platformAppApi.delete(item.id).then(d => that.reload());
            }
        });
    }

}