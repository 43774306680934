export class UiOpt {
    label: string = '';
    value: any;
}

export function selectOptionFilter(input: any, option: any) {
    if(!option?.label) {
        return true;
    }
    return option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
}