import { statApi } from "../../util/http";
import Configure from "../Configure";

class HeroSeachApi {
    heroSeach(f: any) {
        console.log(f);
        return statApi.get({
            path: 'hero/record/sum?srvCode='+f.filters.svrCode,
            title: Configure.Gl(1806,"英雄查询"),
            quiet: true,
        });
    }
}
export const heroSeachApi: HeroSeachApi = new HeroSeachApi();