import React from 'react';
import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';
import { RoutePath } from '../route/path';
import Configure from '../api/Configure';
import { StoreUtil } from '../util/store';

export default class NotFound extends React.Component {

  state = {
    labelList: ['点击', '旁边', '按钮']
  }

  render() {
    let labelDom = [];
    for (let i = 0; i < this.state.labelList.length; i++) {
      labelDom.push(
        <Button type="link" key={i}> {this.state.labelList[i]} </Button>
      )
    }
    return (<div>
      <Result
        status="warning"
        title={Configure.Gl(404, "404 Not Found! 恭喜你到达荒芜之地.")}
        extra={
          <div>
            <Button type="primary">
              <Link to={RoutePath.HOME_INDEX}>{Configure.Gl(1138, '回到主页')}</Link>
            </Button>
            <Button onClick={() => this.changeLanguage()}>切换语言</Button>
            {labelDom}
          </div>
        }
      />
    </div>);
  }

  changeLanguage() {
    if (Configure.LocalWord === "en_US") {
      Configure.LocalWord = "zh_CN";
    } else {
      Configure.LocalWord = "en_US";
    }
    StoreUtil.putStore("LocalWord", Configure.LocalWord);
    this.forceUpdate();
  }

}