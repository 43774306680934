import React, { RefObject } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Table, Space, Divider, Modal } from 'antd';
import { SearchOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';

import { HomeLayout } from '../../layout/HomeLayout';
import { RoutePath } from '../../route/path';
import { srvGroupApi } from '../../api/console/srv.group';
import SrvGroupEdit from './SrvGroupEdit';
import Configure from '../../api/Configure';

export default class SrvGroupIndex extends React.Component<RouteComponentProps> {

    private editor: RefObject<SrvGroupEdit>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
    }

    state = {
        showEdit: false,
        itemList: [],
    };

    render() {
        const columns: ColumnsType<any> = [
            {
                title:Configure.Gl(1055,'ID'),
                dataIndex: 'id',
                width: 80,
                fixed: 'left',
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: Configure.Gl(1499,'名称'),
                dataIndex: 'name',
                width: 260,
            },
            {
                title: Configure.Gl(1951,'游戏服组编码'),
                dataIndex: 'code',
                width: 80,
            },
            {
                title: Configure.Gl(1057,'操作'),
                width: 200,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117,"编辑")}</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133,'删除')}</Button>
                    </Space>);
                },
            },
            {
                title: Configure.Gl(1065,"备注"),
                width: 300,
                dataIndex: 'note',
            },
        ];

        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
        };

        return (
            <HomeLayout path={RoutePath.SRV_GROUP_INDEX}>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389,'新增')}</Button>
                    <Button type="primary" icon={<SearchOutlined />} onClick={() => this.reload()}>{Configure.Gl(1152,'查找')}</Button>
                </Space>
                <Divider />
                <Table dataSource={this.state.itemList} columns={columns} pagination={pagination} scroll={{ x: 1500, y: 512 }} />
                <SrvGroupEdit ref={this.editor} reload={() => this.reload()} ></SrvGroupEdit>
            </HomeLayout>
        );
    }

    componentDidMount() {
        console.log("SrvCrossIndex::componentDidMount");
        this.reload();
    }

    async reload() {
        let data = await srvGroupApi.list() as any[];
        console.log(Configure.Gl(1390,"请求到的数据是："), data);
        data.forEach(element => { element.key = Math.random() });
        this.setState({ itemList: data });
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1391,'你确定要删除') + item.code + ' ('+item.note+')？',
            onOk() {
                srvGroupApi.delete(item.id).then(d => that.reload());
            }
        });
    }

}