import React from 'react';
import { Form, Input, Button, Checkbox, Layout, Select } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { Store, ValidateErrorEntity } from 'rc-field-form/lib/interface';
import logo from '../../logo.svg';
import { GmcEnv } from '../../util/env';
import { staffApi } from '../../api/console/staff';
import { StoreUtil } from '../../util/store';
import { RoutePath } from '../../route/path';
import Configure from '../../api/Configure';

const { Header, Footer, Content } = Layout;

interface FormValues extends Store {
    username: string;
    password: string;
}

export default class LoginIndex extends React.Component<RouteComponentProps> {

    constructor(props: RouteComponentProps) {
        super(props);
        GmcEnv.getInstance().echo();
    }

    render() {
        const contentStyle: React.CSSProperties = {
            padding: "10%"
        };

        return (
            <Layout>
                <Header><img src={logo} className="App-logo" alt="logo" /></Header>
                <Content>
                    <div style={contentStyle}>
                        {this.renderForm()}

                    </div>

                </Content>
                <Footer>版本 0.0.1 alpha</Footer>
            </Layout>
        );
    }

    onFormFinish = async (values: Store) => {
        console.log('form submit success:', values);
        let p = values as FormValues;
        let token: any = await staffApi.login(p.username, p.password);
        console.log("staffApi返回", token);
        StoreUtil.setToken(token);
        this.props.history.push(RoutePath.HOME_INDEX, null);
    }

    onFormFinishFailed = (errorInfo: ValidateErrorEntity) => {
        console.log('form submit failed:', errorInfo);
    }

    handleChange = (value: any) => {
        Configure.LocalWord = value.value;
        StoreUtil.putStore("LocalWord", Configure.LocalWord);
        this.forceUpdate();
    }

    renderForm() {
        const layout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };
        return (
            <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}
            >
                <Form.Item></Form.Item>
                <Form.Item
                    label={Configure.Gl(1308, "用户名")}
                    name="username"
                    rules={[{ required: true, message: Configure.Gl(1310, '请输入用户名!') }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={Configure.Gl(1309, "密码")}
                    name="password"
                    rules={[{ required: true, message: Configure.Gl(1311, '请输入密码!') }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                    <Checkbox>{Configure.Gl(1312, "记住我")}</Checkbox>
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">{Configure.Gl(1313, "登录")}</Button>
                </Form.Item>
                <Select placeholder="Please Select Language:"
                    labelInValue
                    style={{ width: 200 }}
                    onChange={this.handleChange}
                >
                    <Select.Option value='zh_CN'>中文简体</Select.Option>
                    <Select.Option value='en_US'>English</Select.Option>
                    <Select.Option value='ja_JP'>日本語</Select.Option>
                    <Select.Option value='ko_KR'>한국어</Select.Option>
                    <Select.Option value='th_TH'>ภาษาไทย</Select.Option>
                    <Select.Option value='vi_VN'>ViệtName</Select.Option>
                    <Select.Option value='zh_HK'>中文繁體</Select.Option>
                </Select>
            </Form>
        );
    }
}