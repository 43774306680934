import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Table } from 'antd';

import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import StatHeroLineupFilter from './StatHeroLineupFilter';
import { statResApi } from '../../../api/stat/res';
import Configure from '../../../api/Configure';

export default class StatLoginFlowIndex extends React.Component<RouteComponentProps> {

    state = {
        filter: {},
        rank: [],
    }
    
    render() {
        const { rank } = this.state;
        const columns = [
            { title: Configure.Gl(1580,'排名'), dataIndex: 'index', sorter: (a: any, b: any) => a.index - b.index,  },
            { title: Configure.Gl(1581,'原型ID'), dataIndex: 'pid' },
            { title: Configure.Gl(1582,'英雄名'), dataIndex: 'name' },
            { title: Configure.Gl(1583,'使用数'), dataIndex: 'num', sorter: (a: any, b: any) => a.num - b.num, },
            { title: Configure.Gl(1584,'平均星级'), dataIndex: 'starAvg', sorter: (a: any, b: any) => a.starAvg - b.starAvg, },
            { title: Configure.Gl(1585,'最小星级'), dataIndex: 'starMin', sorter: (a: any, b: any) => a.starMin - b.starMin, },
            { title: Configure.Gl(1586,'最大星级'), dataIndex: 'starMax', sorter: (a: any, b: any) => a.starMax - b.starMax, },
            { title: Configure.Gl(1587,'平均等级'), dataIndex: 'lvAvg', sorter: (a: any, b: any) => a.lvAvg - b.lvAvg, },
            { title: Configure.Gl(1588,'最小等级'), dataIndex: 'lvMin', sorter: (a: any, b: any) => a.lvMin - b.lvMin, },
            { title: Configure.Gl(1589,'最大等级'), dataIndex: 'lvMax', sorter: (a: any, b: any) => a.lvMax - b.lvMax, },
        ]
        return (
            <HomeLayout path={RoutePath.STAT_HERO_LINEUP_INDEX}>
                <StatHeroLineupFilter search={(f: any) => this.onFilterChange(f)}></StatHeroLineupFilter>
                <hr/>
                <Table columns={columns} dataSource={rank} />
            </HomeLayout>
        );
    }

    onFilterChange(filter: any) {
        this.setState({filter}, ()=>{
            this.reload();
        }); 
    }

    async reload() {
        const { filter } = this.state;
        let data: any = await statResApi.heroLineupSummary(filter);
        for(let i = 0; i < data.recordList.length; i++) {
            data.recordList[i].index = 1+i;
        }
        this.setState({rank: data.recordList});
    }
}