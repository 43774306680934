import React from 'react';
import { Button, Modal, List } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { formatDate } from '../../util/toolbox';
import { mixApi } from '../../api/mix';
import './ServerInfoIndex.css';
import Configure from '../../api/Configure';

export default class ServerInfoIndex extends React.Component {

    state = {
        show: false,
        metaInfo: {} as any,
        loginInfo: {} as any,
        consoleInfo: {} as any,
        statInfo: {} as any,
        worldInfo: {} as any,
        payInfo: {} as any,
    }

    fixInfo(info: any) {
        if(!info.apiCallCount) {
            info.apiCallCount = {};
        }
    }

    render() {
        const { show, metaInfo, loginInfo, consoleInfo, statInfo, worldInfo, payInfo } = this.state;
        this.fixInfo(metaInfo);
        this.fixInfo(loginInfo);
        this.fixInfo(consoleInfo);
        this.fixInfo(statInfo);
        this.fixInfo(worldInfo);
        this.fixInfo(payInfo);
        const listGrid = {
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 6,
            xxl: 3,
        };

        const metaApiCalls:string[] = [];
        metaApiCalls.push("facade/client/start"+Configure.Gl(1314,'接口')+"：" + metaInfo.apiCallCount.clientStart + Configure.Gl(1315,"次"));
        metaApiCalls.push("facade/client/version/get"+Configure.Gl(1314,'接口')+"：" + metaInfo.apiCallCount.clientVersionGet + Configure.Gl(1315,"次"));
        metaApiCalls.push("facade/client/version/set"+Configure.Gl(1314,'接口')+"：" + metaInfo.apiCallCount.clientVersionSet + Configure.Gl(1315,"次"));
        metaApiCalls.push("announce/one"+Configure.Gl(1314,'接口')+"：" + metaInfo.apiCallCount.announceOne + Configure.Gl(1315,"次"));

        const loginApiCalls:string[] = [];
        loginApiCalls.push(Configure.Gl(1316,"账号登录：" )+ loginInfo.apiCallCount.accountLogin + Configure.Gl(1315,"次"));
        loginApiCalls.push(Configure.Gl(1317,"账号注册：" )+ loginInfo.apiCallCount.accountRegister + Configure.Gl(1315,"次"));
        loginApiCalls.push(Configure.Gl(1318,"账号主角列出：" )+ loginInfo.apiCallCount.accountMasterList + Configure.Gl(1315,"次"));
        loginApiCalls.push(Configure.Gl(1319,"账号主角更新：" )+ loginInfo.apiCallCount.accountMasterUpdate + Configure.Gl(1315,"次"));
        loginApiCalls.push(Configure.Gl(1320,"游戏服激活：" )+ loginInfo.apiCallCount.gameSrvActive + Configure.Gl(1315,"次"));

        const worldApiCalls:string[] = [];
        worldApiCalls.push(Configure.Gl(1321,"礼包码构建：" )+ worldInfo.apiCallCount.giftCodeBuild + Configure.Gl(1315,"次"));
        worldApiCalls.push(Configure.Gl(1322,"礼包码使用：" )+ worldInfo.apiCallCount.giftCodeUse + Configure.Gl(1315,"次"));
        worldApiCalls.push(Configure.Gl(1323,"玩家测试记录查找：" )+ worldInfo.apiCallCount.betaRecordList + Configure.Gl(1315,"次"));
        worldApiCalls.push(Configure.Gl(1324,"玩家测试记录插入更新：" )+ worldInfo.apiCallCount.betaRecordUpsert + Configure.Gl(1315,"次"));
        worldApiCalls.push(Configure.Gl(1325,"邀请：构造邀请记录：" )+ worldInfo.apiCallCount.inviteBuildRecord + Configure.Gl(1315,"次"));
        worldApiCalls.push(Configure.Gl(1326,"邀请：收到每日分享事件：" )+ worldInfo.apiCallCount.inviteRecvDailyEvent + Configure.Gl(1315,"次"));
        worldApiCalls.push(Configure.Gl(1327,"邀请：收到等级变更事件：" )+ worldInfo.apiCallCount.inviteRecvLevelEvent + Configure.Gl(1315,"次"));
        worldApiCalls.push(Configure.Gl(1328,"邀请：收到玩家首充事件：" )+ worldInfo.apiCallCount.inviteRecvFirstPayEvent + Configure.Gl(1315,"次"));

        const payApiCalls:string[] = [];
        payApiCalls.push(Configure.Gl(1329,"支付注册：" )+ payInfo.apiCallCount.payReg + Configure.Gl(1315,"次"));
        payApiCalls.push(Configure.Gl(1031,"手工支付")+"：" + payInfo.apiCallCount.payManual + Configure.Gl(1315,"次"));
        payApiCalls.push(Configure.Gl(1331,"叶子戏模拟支付：" )+ payInfo.apiCallCount.yzxPayMock + Configure.Gl(1315,"次"));
        payApiCalls.push(Configure.Gl(1330,"叶子戏回调：" )+ payInfo.apiCallCount.yzxPayCallback + Configure.Gl(1315,"次"));

        return (<span>
            <Button type="primary" icon={<SearchOutlined />} onClick={() => this.doShow()}>
            {Configure.Gl(1332,'公共服务状态')}
            </Button>
            <Modal title={Configure.Gl(1332,"公共服务状态")} visible={show} width="90%"
                onOk={() => this.handleModalOk()} onCancel={() => this.handleModalCancel()} footer={null} destroyOnClose>
                <div className="server-info-title"><span role="img" aria-hidden>👔</span>&nbsp;<b>Meta Server</b></div>
                <div>{Configure.Gl(1333,'服务器当前时间：')}{formatDate(metaInfo.time)}</div>
                <div>{Configure.Gl(1334,'服务器启动时间：')}{formatDate(metaInfo.startTime)}</div>
                <List 
                    grid={listGrid}
                    size="small"
                    header={<div>{Configure.Gl(1335,'库版本')}</div>}
                    bordered
                    dataSource={metaInfo.libVersion}
                    renderItem={(item:string) => <List.Item>{item}</List.Item>}
                    />
                <br/>
                <List 
                    grid={listGrid}
                    size="small"
                    header={<div>{Configure.Gl(1336,'本次服务器启动之后的API调用统计')}</div>}
                    bordered
                    dataSource={metaApiCalls}
                    renderItem={(item:string) => <List.Item>{item}</List.Item>}
                    />
                <br/>
                <div className="server-info-title"><span role="img" aria-hidden>🙊</span>&nbsp;<b>Login Server</b></div>
                <div>{Configure.Gl(1333,'服务器当前时间：')}{formatDate(loginInfo.time)}</div>
                <div>{Configure.Gl(1334,'服务器启动时间：')}{formatDate(loginInfo.startTime)}</div>
                <List 
                    grid={listGrid}
                    size="small"
                    header={<div>{Configure.Gl(1335,'库版本')}</div>}
                    bordered
                    dataSource={loginInfo.libVersion}
                    renderItem={(item:string) => <List.Item>{item}</List.Item>}
                    />
                <br/>
                <List 
                    grid={listGrid}
                    size="small"
                    header={<div>{Configure.Gl(1336,'本次服务器启动之后的API调用统计')}</div>}
                    bordered
                    dataSource={loginApiCalls}
                    renderItem={(item:string) => <List.Item>{item}</List.Item>}
                    />
                <br/>
                <div className="server-info-title"><span role="img" aria-hidden>🌺</span>&nbsp;<b>Console Server</b></div>
                <div>{Configure.Gl(1333,'服务器当前时间：')}{formatDate(consoleInfo.time)}</div>
                <div>{Configure.Gl(1334,'服务器启动时间：')}{formatDate(consoleInfo.startTime)}</div>
                <List 
                    grid={listGrid}
                    size="small"
                    header={<div>{Configure.Gl(1335,'库版本')}</div>}
                    bordered
                    dataSource={consoleInfo.libVersion}
                    renderItem={(item:string) => <List.Item>{item}</List.Item>}
                    />
                <br/>
                <div className="server-info-title"><span role="img" aria-hidden>🍌</span>&nbsp;<b>Stat Server</b></div>
                <div>{Configure.Gl(1333,'服务器当前时间：')}{formatDate(statInfo.time)}</div>
                <div>{Configure.Gl(1334,'服务器启动时间：')}{formatDate(statInfo.startTime)}</div>
                <List 
                    grid={listGrid}
                    size="small"
                    header={<div>{Configure.Gl(1335,'库版本')}</div>}
                    bordered
                    dataSource={statInfo.libVersion}
                    renderItem={(item:string) => <List.Item>{item}</List.Item>}
                    />
                <br/>
                <div className="server-info-title"><span role="img" aria-hidden>🌀</span>&nbsp;<b>World Server</b></div>
                <div>{Configure.Gl(1333,'服务器当前时间：')}{formatDate(worldInfo.time)}</div>
                <div>{Configure.Gl(1334,'服务器启动时间：')}{formatDate(worldInfo.startTime)}</div>
                <List 
                    grid={listGrid}
                    size="small"
                    header={<div>{Configure.Gl(1335,'库版本')}</div>}
                    bordered
                    dataSource={worldInfo.libVersion}
                    renderItem={(item:string) => <List.Item>{item}</List.Item>}
                    />
                <br/>
                <List 
                    grid={listGrid}
                    size="small"
                    header={<div>{Configure.Gl(1336,'本次服务器启动之后的API调用统计')}</div>}
                    bordered
                    dataSource={worldApiCalls}
                    renderItem={(item:string) => <List.Item>{item}</List.Item>}
                    />
                <br/>
                <div className="server-info-title"><span role="img" aria-hidden>💰</span>&nbsp;<b>Pay Server</b></div>
                <div>{Configure.Gl(1333,'服务器当前时间：')}{formatDate(payInfo.time)}</div>
                <div>{Configure.Gl(1334,'服务器启动时间：')}{formatDate(payInfo.startTime)}</div>
                <List 
                    grid={listGrid}
                    size="small"
                    header={<div>{Configure.Gl(1335,'库版本')}</div>}
                    bordered
                    dataSource={payInfo.libVersion}
                    renderItem={(item:string) => <List.Item>{item}</List.Item>}
                    />
                <br/>
                <List 
                    grid={listGrid}
                    size="small"
                    header={<div>{Configure.Gl(1336,'本次服务器启动之后的API调用统计')}</div>}
                    bordered
                    dataSource={payApiCalls}
                    renderItem={(item:string) => <List.Item>{item}</List.Item>}
                    />
                <br/>
            </Modal>
        </span>
        );
    }

    componentDidMount() {
        console.log("ServerInfoIndex::componentDidMount()");
    }

    doShow = async () => {
        try {
            this.setState({ show: true });
            let metaInfo = await mixApi.meta_self_info();
            console.log("metaInfo=", metaInfo);
            this.setState({metaInfo});
        } catch (e) {
            alert(e);
        }

        try {
            this.setState({ show: true });
            let loginInfo = await mixApi.login_self_info();
            console.log("loginInfo=", loginInfo);
            this.setState({loginInfo});
        } catch (e) {
            alert(e);
        }

        try {
            let consoleInfo = await mixApi.console_self_info();
            console.log("consoleInfo=", consoleInfo);
            this.setState({consoleInfo});
        } catch (e) {
            alert(e);
        }

        try {
            let statInfo = await mixApi.stat_self_info();
            console.log("statInfo=", statInfo);
            this.setState({statInfo});
        } catch (e) {
            alert(e);
        }

        try {
            let worldInfo = await mixApi.world_self_info();
            console.log("worldInfo=", worldInfo);
            this.setState({worldInfo});
        } catch (e) {
            alert(e);
        }

        try {
            let payInfo = await mixApi.pay_self_info();
            console.log("payInfo=", payInfo);
            this.setState({payInfo});
        } catch (e) {
            alert(e);
        }
    }

    handleModalOk() {

    }

    handleModalCancel() {
        this.setState({ show: false });
    }

}