import moment from 'moment';
import 'moment/locale/zh-cn';

export class ApiBase {

    parseZoneTime = (value:any):moment.Moment|null => {
        if(!value) { return null; }
        let m = moment.parseZone(value);
        m.locale('zh-cn');
        //console.log("此时此刻", m);
        return m.local();
    }

}