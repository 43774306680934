import { Input, Space } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import Configure from "../../../api/Configure";
import { statSrvApi } from "../../../api/stat/all";
import { StdItemPageLimit } from "../../../part/StdItemPageLimit";
import { RoutePath } from "../../../route/path";
import StatPayRankFilter from "./StatPayRankFilter";

export default class StatPayRankIndex extends StdItemPageLimit<any>{
    formRef = React.createRef<FormInstance>();
    state = {
        ...this.getDefaultState(),
        sumPayAmount: 0,
    };

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1121, '服务器编码'),
                dataIndex: 'srvCode',
                width: 80,
            },
            {
                title: Configure.Gl(1101, '主角唯一ID'),
                dataIndex: 'masterIdHex',
                width: 80,
            },
            {
                title: Configure.Gl(1151, '主角名'),
                dataIndex: 'masterName',
                width: 80,
            },
            {
                title: Configure.Gl(1630, '充值总金额'),
                dataIndex: 'total',
                width: 80,
            },
            {
                title: Configure.Gl(1631, '充值次数'),
                dataIndex: 'payCount',
                width: 80,
            }, {
                title: Configure.Gl(1632, '账号唯一ID'),
                dataIndex: 'accountUid',
                width: 80,
            },
            {
                title: Configure.Gl(1122, '平台编码'),
                dataIndex: 'platformCode',
                width: 80,
            },
            {
                title: Configure.Gl(1099, '渠道编码'),
                dataIndex: 'channelCode',
                width: 80,
            },
        ]
    }
    getFilterUI() {
        return (<Space>
            <span>
                <StatPayRankFilter search={(f: any) => this.onFilterChange(f)}></StatPayRankFilter>
                <Input size="large" style={{ width: 120 }} value={Configure.Gl(1258, "付款金额总计: ") + this.state.sumPayAmount.toFixed(2) + Configure.Gl(1259, "元")} readOnly bordered={false} />
            </span>
        </Space>);
    }
    getEditorUI() {
        return "";
    }

    getPath() {
        return RoutePath.STAT_PAY_RANK_INDEX;
    }

    async reloadPayAmount(query: any) {
        let filter = {} as any;
        filter.page = 1;
        filter.pageSize = 1;
        filter.payTimeBegin = query.beginTime ? new Date(query.beginTime).getTime() : new Date(new Date().toLocaleDateString()).getTime();
        filter.payTimeEnd = query.endTime ? new Date(query.endTime).getTime() : new Date().getTime();
        let data = await statSrvApi.pay_list(filter) as any;
        this.setState({ sumPayAmount: data.sumPayAmount });
    }

    async reloadForData(query: any) {
        // this.reloadPayAmount(query);
        if (!query.srvCode) {
            if (!query.beginTime && !query.endTime) {
                return { "records": [], "total": 0 };
            }
            alert(Configure.Gl(1633, "服务器编码不能为空"));
            return { "records": [], "total": 0 };
        }
        console.log("单服充值排行，query=", query);
        let data = await statSrvApi.pay_rank(query) as any;
        if (!data.records || data.records.length === 0) {
            alert(Configure.Gl(1634, "查不到数据噢"));
            return { "records": [], "total": 0 };
        }
        console.log("单服充值排行结果 data=", data);
        this.addKeyToData(data.records);
        return data;
    }
}