import React, { RefObject } from 'react';
import { Button, Space, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/Table';

import { RoutePath } from '../../route/path';
import { cloudMongoApi } from '../../api/console/cloud.mongo';
import CloudMongoEdit from './CloudMongoEdit';
import Configure from '../../api/Configure';
import { IPagingState, StdItemPageLimit } from '../../part/StdItemPageLimit';

export default class CloudMongoIndex extends StdItemPageLimit<IPagingState> {

    private editor: RefObject<CloudMongoEdit> = React.createRef();

    state = {
        ...this.getDefaultState(),
    };

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1056, '编码'),
                dataIndex: 'code',
                width: 150,
                fixed: 'left',
                sorter: (a, b) => a.code - b.code,
            },
            { title: Configure.Gl(1065, '备注'), dataIndex: 'note', width: 150 },
            { title: Configure.Gl(Configure.GL_UNDEFINED, '云服务器编码'), dataIndex: 'serverCode', width: 150 },
            { title: Configure.Gl(1074, '端口'), dataIndex: 'port', width: 90 },
            { title: Configure.Gl(1075, 'username'), dataIndex: 'username', width: 160 },
            { title: Configure.Gl(1076, 'password'), dataIndex: 'password', width: 200 },
            { title: Configure.Gl(1077, 'source'), dataIndex: 'source', width: 100 },
            { title: Configure.Gl(Configure.GL_UNDEFINED, 'database'), dataIndex: 'database', width: 100 },
            {
                title: Configure.Gl(1057, '操作'),
                width: 200,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117, "编辑")}</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133, '删除')}</Button>
                    </Space>);
                },
            },
            {
                title: Configure.Gl(1451, '模式'), dataIndex: 'mode', width: 150, render: (value: any) => {
                    return (value===0||value===1) ? ["单实例", "副本集"][parseInt(value)] : ""+value;
                },
            },
            { title: 'url', dataIndex: 'url', width: 420 },
            { title: 'urlPublic', dataIndex: 'urlPublic', width: 420 },
        ];
    }

    getFilterUI() {
        return (<Space>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389,'新增')}</Button>
        </Space>);
    }

    getEditorUI() {
        return (<CloudMongoEdit ref={this.editor} reload={() => this.reload()}></CloudMongoEdit>);
    }

    getPath() { return RoutePath.CLOUD_MONGO_INDEX; }

    async reloadForData(query: any) {
        let data:any = await cloudMongoApi.list(query);
        console.log("请求到的数据是：", data);
        this.addKeyToData(data.records);
        return data;
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066, '警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1067, '你确定要删除 ') + ' code = ' + item.code + ' ( ' + item.note + ' ) ？',
            onOk() {
                cloudMongoApi.delete(item.code).then(d => that.reload());
            }
        });
    }

}




