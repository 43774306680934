import { consoleApi } from '../../util/http';
import Configure from '../Configure';

export class SrvGameCmdApi {

    start(srvCode: string) {
        return consoleApi.get({
            path: 'srv/game/cmd/start?srvCode=' + srvCode,
            title: Configure.Gl(1723,"启动服务器"),
            quiet: false
        });
    }

    stop(srvCode: string) {
        return consoleApi.get({
            path: 'srv/game/cmd/stop?srvCode=' + srvCode,
            title: Configure.Gl(1724,"关闭服务器"),
            quiet: false
        });
    }

    startBatch(srvCodeList: string[]) {
        return consoleApi.post({
            path: 'srv/game/cmd/start/batch',
            param: srvCodeList,
            title: Configure.Gl(1725,"启动所选服务器"),
            quiet: false
        });
    }

    stopBatch(srvCodeList: string[]) {
        return consoleApi.post({
            path: 'srv/game/cmd/stop/batch',
            param: srvCodeList,
            title: Configure.Gl(1726,"关闭所选服务器"),
            quiet: false
        });
    }

    crossReload(srvCode: string) {
        return consoleApi.get({
            path: 'srv/game/cmd/cross/reload?srvCode=' + srvCode,
            title: Configure.Gl(1727,"重载跨服"),
            quiet: false
        });
    }

    mergeSettle(srvCode: string) {
        return consoleApi.get({
            path: 'srv/game/cmd/merge/settle?srvCode=' + srvCode,
            title: Configure.Gl(1728,"合服结算"),
            quiet: false
        });
    }

    emergencyExe(cmd: any) {
        return consoleApi.get({
            path: 'srv/game/cmd/emergency/exe',
            param: cmd,
            title: Configure.Gl(1729,"紧急操作执行"),
            quiet: false
        });
    }
}

export const srvGameCmdApi: SrvGameCmdApi = new SrvGameCmdApi();