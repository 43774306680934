import { statApi } from '../../util/http';
import { formatDate, getNowDate } from '../../util/toolbox';
import { ApiBase } from '../api';
import Configure from '../Configure';

class BriefingDailyReportApi extends ApiBase {

    // list(filter: any) {
    //     if(filter.date!==undefined){
    //         filter.date = formatDate(filter.date, "yyyy-MM-dd");
    //         if(filter.date==="--"){
    //             filter.date = getNowDate(false);
    //         }
    //     }
    //     return statApi.get({
    //         path: 'briefing/daily/list',
    //         title: Configure.Gl(1805,"每日简报"),
    //         param: filter,
    //         quiet: true
    //     });
    // }

    newlist(filter: any) {
        if(filter.date!==undefined){
            filter.date = formatDate(filter.date, "yyyy-MM-dd");
            if(filter.date==="--"){
                filter.date = getNowDate(false);
            }
        }else{
            return "";
        }
        return statApi.get({
            path: 'summary/daily/query',
            title: Configure.Gl(1805,"每日简报"),
            param: filter,
            quiet: true
        });
    }

    newOverview(filter: any) {
        if(filter.date!==undefined){
            filter.date = formatDate(filter.date, "yyyy-MM-dd");
            if(filter.date==="--"){
                filter.date = getNowDate(false);
            }
        }else{
            return "";
        }
        return statApi.get({
            path: 'summary/daily/total',
            title: Configure.Gl(Configure.GL_UNDEFINED,"每日简报总览"),
            param: filter,
            quiet: true
        });
    }

   clear(Filter: any){
    Filter.date = formatDate(Filter.date, "yyyy-MM-dd");
    return statApi.get({
        path: 'briefing/daily/clear',
        title: Configure.Gl(1902,"每日简报清理"),
        param: Filter,
        quiet: true
    });
   }

   update(Filter: any){
    Filter.date = formatDate(Filter.date, "yyyy-MM-dd");
    return statApi.get({
        path: 'briefing/daily/update',
        title: Configure.Gl(1902,"每日简报清理"),
        param: Filter,
        quiet: true
    });
   }

}

export const briefingdailyReportApi: BriefingDailyReportApi = new BriefingDailyReportApi();