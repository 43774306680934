import { statApi} from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

interface AccountMasterFilter {
    srvCode?: string;
    masterName?: string;
    masterIdHex?: string;
}

class NewGmMasterApi extends ApiBase {

    search(query: any) {
        return  statApi.get({
                path: 'role/record/list',
                param: query,
                title: Configure.Gl(1864,"新版GM主角搜索"),
                quiet: true
            });
    }

}

export const newGmMasterApi: NewGmMasterApi = new NewGmMasterApi();