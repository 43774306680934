import React, { RefObject } from 'react';
import { Button, DatePicker, Input, message, Modal } from 'antd';
import Configure from '../../../api/Configure';
import { briefingdailyReportApi } from '../../../api/stat/daily.report';
import { RouteComponentProps } from 'react-router';

export default class DailyBriefingClear extends React.Component<any> {

    private passwordID: RefObject<Input>;
    state = {
        srvCode: undefined as string | undefined,
        gameId: undefined as string | undefined,
        gameGroup: undefined as string | undefined,
        groupBySrv: false,
        isModalVisible: false,
        queryFilter: {
            date: undefined as moment.Moment | undefined,
        },
    };

    constructor(props: RouteComponentProps) {
        super(props);
        this.passwordID = React.createRef();
    }

    render() {
        const { isModalVisible } = this.state;
        const handleOk = () => {
            this.setState({ isModalVisible: false });
        };
        const handleCancel = () => {
            this.setState({ isModalVisible: false });
        };
        return (

            <span>
                <Input.Password ref={this.passwordID} placeholder="input password" />
                <Button type="primary" onClick={this.showModal}>{Configure.Gl(1898, "验证")}</Button>

                <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <p><DatePicker placeholder={Configure.Gl(1895, "清理日期")} onChange={e => this.setDate(e)} style={{ width: 120 }} showToday /></p>
                    <p><Button type="primary" onClick={this.onClear}>{Configure.Gl(1899, "清理")}</Button></p>
                    <p><Button type="primary" onClick={this.onUpdata}>{Configure.Gl(1900, "更新")}</Button></p>
                </Modal>
            </span>
        );
    }

    onClear = async () => {
        let data = await briefingdailyReportApi.clear(this.state.queryFilter) as any;
        console.log(Configure.Gl(1895, "清理数据:" + data));
    }

    onUpdata = async () => {
        let data = await briefingdailyReportApi.update(this.state.queryFilter) as any;
        console.log(Configure.Gl(1903, "更新数据:" + data));
    }

    showModal = () => {
        if (this.passwordID.current?.state.value === 'ydwl666') {
            this.setState({ isModalVisible: true });
        } else {
            message.error(Configure.Gl(1901, '密码错误!'));
        }

    }


    doSearch = () => {
        this.props.search(this.state);
    }

    setDate(data: any) {
        // this.state.queryFilter.date = data;
        this.setState({ queryFilter: data });
    }

}