import { consoleApi } from '../../util/http';
import Configure from '../Configure';

export class SysProgressApi {

    query(code: string) {
        return consoleApi.get({
            path: 'sys/progress/query',
            param: { code },
            title: Configure.Gl(1697,'查询操作进度'),
            quiet: true
        });
    }
}

export const sysProgressApi: SysProgressApi = new SysProgressApi();