import { consoleApi } from '../../util/http';
import Configure from '../Configure';

class MailApi {

    list(filter: any) {
        return consoleApi.get({
            path: 'gm/mail/x/list', 
            title: Configure.Gl(1692,'获取邮件列表'),
            param : filter,
            quiet: true
        });
    }

    create(item: any) {
        return consoleApi.post({
            path: 'gm/mail/x/create', 
            title: Configure.Gl(1693,'新建邮件'),
            param: item,
            quiet: true
        });
    }

    complete(item: any){
        return consoleApi.post({
            path: 'gm/mail/x/complete', 
            title: Configure.Gl(1694,'审批邮件'),
            param: item
        });
    }

    no_reward_complete(item: any){
        return consoleApi.post({
            path: 'gm/mail/noReward/complete', 
            title: Configure.Gl(1695,'无附件邮件审批'),
            param: item
        });
    }
}

export const mailApi: MailApi = new MailApi();