import { consoleApi } from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

interface BagQueryCmd {
    srvCode: string;
    roleId: string;
    bagType: number;
}

interface BagReduceCmd {
    /** 游戏服编码 */
    srvCode: string;
    /** 角色唯一ID */
    roleId: string;
    /** 指令模式 */
    cmdMode: string;
    /** 背包类型 */
    bagType: number;
    /** 扣减数量 */
    num: number;
    /** 物品唯一ID */
    itemUid?: string;
    /** 物品模板ID */
    itemTid?: number;
    /** 备注 */
    note?: string;
}

/** 指令模式：通过唯一ID扣除 */
export const BAG_REDUCE_CMD_MODE_UID:string  = "uid";
/** 指令模式：通过模板ID扣除 */
export const BAG_REDUCE_CMD_MODE_TID:string  = "tid"; 

class RoleApi extends ApiBase {

    bagQuery(query: BagQueryCmd) {
        return consoleApi.get({
            path: 'role/bag/query',
            param: query,
            title: Configure.Gl(1780, "角色背包查询"),
            quiet: true
        })
    }

    bagReduce(query: BagReduceCmd) {
        return consoleApi.get({
            path: 'role/bag/reduce',
            param: query,
            title: Configure.Gl(1781, "角色背包扣减"),
            quiet: false
        })
    }
}

export const roleApi: RoleApi = new RoleApi();