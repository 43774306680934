import React, { RefObject } from 'react';
import { IState, StdItemEdit, IProps } from "../../../part/StdItemEdit";
import { Form, Input, Button, Divider, Select, DatePicker } from 'antd';
import SrvSelectEdit from '../../srv/SrvSelectEdit';
import { giftcodeApi } from '../../../api/world/giftcode';
import MasterQueryMulti from '../../../part/master/MasterQueryMulti';
import Modal from 'antd/lib/modal/Modal';
import { formatMoment } from '../../../util/toolbox';
import { mailApi } from '../../../api/console/gm.mail';
import moment from 'moment';
import Configure from '../../../api/Configure';

interface ThisState extends IState {
    srvShow: any,
    reward: any,
    masterShow: any,
    queryFilter: any,
    masterSelected: any[],
    srvGameSelected: any[],
    initData: any,
    readonly: boolean,
    scheduleShow: boolean,
}

export default class GmMailEdit extends StdItemEdit<IProps, ThisState> {
    srvEditor: RefObject<SrvSelectEdit> = React.createRef();
    masterEditor: RefObject<MasterQueryMulti> = React.createRef();

    state = {
        ...super.buildInitState(),
        reward: null,
        queryFilter: {},
        srvShow: false,
        masterShow: false,
        masterSelected: [] as any[],
        srvGameSelected: [] as any[],
        initData: {} as any,
        readonly: false,
        scheduleShow: false,
    }

    onSelectedSrvs(r: any[]) {
        let tmpData = { gmMailEntity: this.state.initData.gmMailEntity, gmMailTars: r };
        this.setState({ srvGameSelected: r, masterSelected: [], initData: tmpData });
    }

    onSelectedMasters(r: any[]) {
        let tmpData = { gmMailEntity: this.state.initData.gmMailEntity, gmMailTars: r };
        this.setState({ masterSelected: r, srvGameSelected: [], initData: tmpData });
    }

    render() {
        const { visible, createMode } = this.state;
        return (
            <Modal title={createMode ? Configure.Gl(1116, "新建") : Configure.Gl(1117, "编辑")} visible={visible} width='90%'
                onOk={this.handleModalOk} onCancel={this.handleModalCancel} footer={null} destroyOnClose>
                {this.renderForm()}
            </Modal>
        );
    }

    renderForm() {
        const { initData } = this.state;
        const tableInit = initData ? initData.gmMailEntity : undefined;
        const tarsSrvInit = tableInit && tableInit.type === 1 ? initData.gmMailTars : undefined;
        const tarsMasterInit = tableInit && tableInit.type === 2 ? initData.gmMailTars : undefined;
        return (
            <span>
                <div style={{ display: this.state.srvShow ? "block" : "none" }}>
                    <SrvSelectEdit readonly={this.state.readonly} initData={tarsSrvInit} ref={this.srvEditor}
                        onSelect={(r: any[]) => this.onSelectedSrvs(r)}>
                    </SrvSelectEdit>
                </div>
                <div style={{ display: this.state.masterShow ? "block" : "none" }}>
                    <MasterQueryMulti readonly={this.state.readonly} initData={tarsMasterInit} ref={this.masterEditor} onSelect={(r: any[]) => this.onSelectedMasters(r)}></MasterQueryMulti>
                </div>
                <Divider />
                <Form
                    layout="vertical"
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 20 }}
                    name="basic"
                    onFinish={this.onFormFinish}
                    onFinishFailed={this.onFormFinishFailed}
                    initialValues={tableInit}
                >
                    <Form.Item label={Configure.Gl(1911, "邮件类型")} name="mailType" style={{ width: '40%' }}>
                        <Select placeholder={Configure.Gl(1911, "邮件类型")} defaultValue={1} disabled={this.state.readonly} onSelect={(v) => this.selectMailType(v)}>
                            <Select.Option value={1}>{Configure.Gl(1909, "普通邮件")}</Select.Option>
                            <Select.Option value={2}>{Configure.Gl(1910, "定时邮件")}</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1212, "类型")} name="type" style={{ width: '40%' }}>
                        <Select placeholder={Configure.Gl(1212, "类型")} disabled={this.state.readonly} onSelect={(v) => this.selectType(v)}>
                            <Select.Option value={1}>{Configure.Gl(1214, "发送给指定游戏服")}</Select.Option>
                            <Select.Option value={2}>{Configure.Gl(1215, "发送给指定角色")}</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1213, "操作原因")} name="operation" style={{ width: '40%' }}>
                        <Select placeholder={Configure.Gl(1213, "操作原因")} disabled={this.state.readonly}>
                            <Select.Option value={11}>{Configure.Gl(1216, "GM补偿")}</Select.Option>
                            <Select.Option value={12}>{Configure.Gl(1217, "GM赠送")}</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1124, "标题")} name="title" style={{ width: '40%' }}>
                        <Input maxLength={100} disabled={this.state.readonly} />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1200, "奖励")} style={{ width: '40%' }}>
                        <Form.Item name="reward" style={{ float: 'left', width: '60%' }} >
                            <Input onChange={v => this.setState({ reward: v.target.value })} disabled={this.state.readonly} />
                        </Form.Item>
                        <Button type="primary" style={{ float: 'left', margin: '0 8px', width: '25%' }} onClick={() => this.translate()}>翻译</Button>
                    </Form.Item>
                    <div style={{ display: this.state.scheduleShow ? "block" : "none" }}>
                        <Form.Item name="scheduleTime" label={Configure.Gl(1908, "定时时间")} style={{ width: '40%' }}>
                            <DatePicker showTime disabled={this.state.readonly}></DatePicker>
                        </Form.Item>
                    </div>
                    <Form.Item name="invalidTime" label={Configure.Gl(1218, "邮件有效期(非必选)")} style={{ width: '40%' }}>
                        <DatePicker showTime disabled={this.state.readonly}></DatePicker>
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1125, "内容")} name="content">
                        <Input.TextArea placeholder={Configure.Gl(1127, "请输入内容")} autoSize maxLength={2800} bordered={false} disabled={this.state.readonly} />
                    </Form.Item>
                    <Divider />
                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={this.state.readonly}>{Configure.Gl(1228, '确定')}</Button>
                        <Button type="default" onClick={this.handleModalCancel} disabled={this.state.readonly}>{Configure.Gl(1229, '取消')}</Button>
                    </Form.Item>
                </Form>
            </span>
        )
    }

    handleModalCancel = () => {
        this.setState({ initData: {}, visible: false, srvShow: false, masterShow: false, reward: null, masterSelected: [], srvGameSelected: [] });
    }

    selectMailType(v: any) {
        this.setState({ scheduleShow: v === 2 });
    }

    selectType(v: any) {
        if (v === 2) {
            this.srvEditor.current?.clearSrvs();
        } else if (v === 1) {
            this.masterEditor.current?.clearSelect();
        }
        this.setState({ srvShow: v === 1, masterShow: v === 2 });
    }

    async translate() {
        if (this.state.reward === null || this.state.reward === '') {
            alert(Configure.Gl(1160, "礼包内容不可为空"));
            return;
        }
        console.log("state=", this.state);
        if ((this.state.masterSelected === undefined && this.state.srvGameSelected === undefined)
            || ((this.state.masterSelected && this.state.masterSelected.length === 0)
                && (this.state.srvGameSelected && this.state.srvGameSelected.length === 0))) {
            alert(Configure.Gl(1219, "请选择邮件类型并添加目标后再进行翻译"));
            return;
        }
        let reqObj = {
            "type": "MixRes",
            "code": this.state.reward,
            "srvCode": this.state.masterSelected && this.state.masterSelected.length > 0 ? this.state.masterSelected[0].srvCode : this.state.srvGameSelected[0].code
        };
        let data = await giftcodeApi.translate(reqObj) as any;
        alert(data);
    }

    onFormFinish = (values: any) => {
        if (values.title === null || values.title === undefined || values.title.length === 0) {
            alert(Configure.Gl(1220, "邮件标题不能为空"));
            return;
        } else if (values.title.length > 100) {
            alert(Configure.Gl(1221, "邮件标题长度不能大于100"));
            return;
        }
        if (values.content === null || values.content === undefined || values.content.length === 0) {
            alert(Configure.Gl(1222, "邮件内容不能为空"));
            return;
        } else if (values.content.length > 2800) {
            alert(Configure.Gl(1223, "邮件内容长度不能大于2800"));
            return;
        }
        if (values.operation !== 11 && values.operation !== 12) {
            alert(Configure.Gl(1224, "请选择操作原因"));
            return;
        }
        if (values.mailType === 2 && !values.scheduleTime) {
            alert(Configure.Gl(1906, "请选择定时时间"));
            return;
        }
        if (values.mailType === 2 && values.type === 2) {
            alert(Configure.Gl(1907, "定时邮件不支持发给角色"));
            return;
        }
        let req = {} as any;
        req.gmMailEntity = values;
        if (this.state.initData && this.state.initData.gmMailEntity && this.state.initData.gmMailEntity.id) {
            req.gmMailEntity.id = this.state.initData.gmMailEntity.id;
        }
        req.gmMailTars = [];
        req.reward = this.state.reward;
        let scheduleTime = values.scheduleTime;
        req.gmMailEntity.scheduleTime = values.mailType === 1 ? null : (values.scheduleTime ? formatMoment(values.scheduleTime as moment.Moment) : undefined);
        req.gmMailEntity.invalidTime = values.invalidTime ?
            formatMoment(values.invalidTime as moment.Moment) :
            values.mailType === 2 ?
                formatMoment((scheduleTime as moment.Moment).add(3, "d")) :
                undefined;
        if (values.type === 2) {
            if (this.state.masterSelected.length <= 0) {
                alert(Configure.Gl(1225, "请添加游戏角色"));
                return;
            }
            this.state.masterSelected.forEach(element => {
                req.gmMailTars.push({
                    type: 2,
                    srvCode: element.srvCode,
                    masterIdHex: element.masterIdHex,
                    name: element.masterName,
                    masterName: element.masterName,
                    roleId: element.masterIdHex,
                    roleName: element.masterName,
                    platformCode: element.platformCode
                });
            });
        } else if (values.type === 1) {
            if (this.state.srvGameSelected.length <= 0) {
                alert(Configure.Gl(1226, "请添加游戏服务器"));
                return;
            }
            this.state.srvGameSelected.forEach(element => {
                req.gmMailTars.push({
                    type: 1,
                    srvCode: element.code,
                    name: element.name,
                    roleId: element.masterIdHex,
                    roleName: element.masterName,
                    platformCode: element.platformCode
                });
            });
        } else {
            alert(Configure.Gl(1227, "请选择正确的类型"));
            return;
        }
        console.log("mail create req=", req)
        mailApi.create(req).then(() => {
            this.handleModalCancel();
            this.props.reload();
        });
    }

    onFormFinishFailed = (errorInfo: any) => {
        console.error('onFormFinishFailed:', errorInfo);
    }
}