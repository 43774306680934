import React, { RefObject } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Table, Space, Divider, Modal } from 'antd';
import { SearchOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';

import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import { statPartConfApi } from '../../../api/console/stat.part.conf';
import StatPartConfEdit from './StatPartConfEdit';
import { formatMoment } from '../../../util/toolbox';
import Configure from '../../../api/Configure';

export default class StatPartConfIndex extends React.Component<RouteComponentProps> {

    private editor: RefObject<StatPartConfEdit>;

    private cmd: RefObject<StatPartConfEdit>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
        this.cmd = React.createRef();
    }

    state = {
        showEdit: false,
        itemList: [],
    };

    render() {
        const columns: ColumnsType<any> = [
            {
                title: Configure.Gl(1055,'ID'),
                dataIndex: 'id',
                width: 80,
                fixed: 'left',
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: Configure.Gl(1599,"配置名"),
                dataIndex: 'name',
                width: 120,
                fixed: 'left',
            },
            {
                title: Configure.Gl(1119,'开始时间'),
                dataIndex: 'beginTime',
                width: 160,
                render: (v) => formatMoment(v)
            },
            {
                title: Configure.Gl(1120,'结束时间'),
                dataIndex: 'endTime',
                width: 160,
                render: (v) => formatMoment(v)
            },
            {
                title: Configure.Gl(1057,'操作'),
                width: 160,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117,"编辑")}</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133,'删除')}</Button>
                    </Space>);
                },
            },
            { title: Configure.Gl(1600,"mysql主机地址"), dataIndex: 'mysqlHost', width: 150 },
            { title: Configure.Gl(1601,"mysql端口"), dataIndex: 'mysqlPort', width: 120 },
            { title: Configure.Gl(1602,"mysql数据库名"), dataIndex: 'mysqlDatabase', width: 140 },
            { title: Configure.Gl(1603,"mysql用户名"), dataIndex: 'mysqlUser', width: 120 },
            { title: Configure.Gl(1604,"mysql密码"), dataIndex: 'mysqlPassword', width: 120 },
            { title: Configure.Gl(1605,"mysql时区"), dataIndex: 'mysqlTimezone', width: 160 },
            { title: Configure.Gl(1065,"备注"), dataIndex: 'note',  width: 120 },
        ];

        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
        };

        return (
            <HomeLayout path={RoutePath.STAT_PART_CONF_INDEX}>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389,'新增')}</Button>
                    <Button type="primary" icon={<SearchOutlined />} onClick={() => this.reload()}>{Configure.Gl(1152,'查找')}</Button>
                </Space>
                <Divider />
                <Table dataSource={this.state.itemList} columns={columns}
                    pagination={pagination} scroll={{ x: 1000, y: 670 }} />
                <StatPartConfEdit ref={this.editor} reload={() => this.reload()} ></StatPartConfEdit>
            </HomeLayout>
        );
    }

    componentDidMount() {
        console.log("StatPartConfIndex::componentDidMount");
        this.reload();
    }

    async reload() {
        let data = await statPartConfApi.list() as any[];
        console.log(Configure.Gl(1390,"请求到的数据是："), data);
        data.forEach(element => { element.key = Math.random() });
        this.setState({ itemList: data });
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1391,'你确定要删除') + item.name + ' (' + item.note + ')？',
            onOk() {
                statPartConfApi.delete(item.id).then(d => that.reload());
            }
        });
    }

}