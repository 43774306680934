import { consoleApi } from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

class GmChatApi extends ApiBase {
    
    createForbid(item: any) {
        return consoleApi.post({
            path: 'gm/chat/forbid/create',
            param: item,
            title: Configure.Gl(1681,'GM禁言执行')
        });
    }

    listForbid(query: any) {
        return consoleApi.get({
            path: 'gm/chat/forbid/list',
            param: query,
            title: Configure.Gl(1682,"GM禁言列表"),
            quiet: true
        });
    }

}

export const gmChatApi: GmChatApi = new GmChatApi();