import { consoleApi } from '../../util/http';
import Configure from '../Configure';

export class SrvGroupCmdApi {

    start(groupCode: string) {
        return consoleApi.get({
            path: 'srv/group/cmd/start?groupCode=' + groupCode,
            title: Configure.Gl(1952,"启动游戏服组"),
            quiet: false
        });
    }

    stop(groupCode: string) {
        return consoleApi.get({
            path: 'srv/group/cmd/stop?groupCode=' + groupCode,
            title: Configure.Gl(1953,"关闭游戏服组"),
            quiet: false
        });
    }

    startBatch(groupCodeList: string[]) {
        return consoleApi.post({
            path: 'srv/group/cmd/start/batch',
            param: groupCodeList,
            title: Configure.Gl(1954,"启动所选游戏服组"),
            quiet: false
        });
    }

    stopBatch(groupCodeList: string[]) {
        return consoleApi.post({
            path: 'srv/group/cmd/stop/batch',
            param: groupCodeList,
            title: Configure.Gl(1955,"关闭所选游戏服组"),
            quiet: false
        });
    }

    checkAll() {
        return consoleApi.post({
            path: 'srv/group/cmd/check/all',
            param: {},
            title: Configure.Gl(1406,'配置核验与纠正'),
            quiet: false,
            showData: true
        });
    }

    ct(groupCode: string) {
        return consoleApi.get({
            path: 'srv/group/cmd/ct?groupCode=' + groupCode,
            title: Configure.Gl(1715,"服务器CT"),
            quiet: true
        });
    }
}

export const srvGroupCmdApi: SrvGroupCmdApi = new SrvGroupCmdApi();