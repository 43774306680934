import React from 'react';
import { Button, Divider, Form, Input, Checkbox, Select , DatePicker} from 'antd';
import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import { FormInstance } from 'antd/lib/form';
import { StdItemPage } from '../../../part/StdItemPage';
import { giftcodeApi } from '../../../api/world/giftcode';
import TextArea from 'antd/lib/input/TextArea';

import { srvGameApi } from '../../../api/console/srv.game';
import Configure from '../../../api/Configure';

export default class GiftcodeBuildIndex extends StdItemPage<any> {
    formRef = React.createRef<FormInstance>();

    state = {
        reward: undefined as string | undefined,
        result: [] as any[],
        queryFilter: {"page":1,"pageSize":1},
    }

    render() {
        return (
            <HomeLayout path={RoutePath.GIFT_CODE_BUILD_INDEX}>
                <Form
                    ref={this.formRef}
                    layout="inline"
                    name="basic"
                    onFinish={this.onFormFinish}
                    onFinishFailed={this.onFormFinishFailed}
                    initialValues={{
                        'limitPlatform':'',
                        'buildMode':'0',
                    }}
                >
                    <Divider>
                        <Form.Item label={Configure.Gl(1161,"礼包名称")} name="name">
                            <Input />
                        </Form.Item>
                        <Form.Item label={Configure.Gl(1162,"礼包描述")} name="description">
                            <Input />
                        </Form.Item>
                        <Form.Item label={Configure.Gl(1163,"礼包奖励内容")}>
                            <Form.Item name="reward" noStyle><Input value={this.state.reward} onChange={e => this.setState({"reward" : e.target.value})}/></Form.Item>
                            <Button style={{ margin: '0 8px' }} type="primary" onClick={() => this.translate()}>翻译</Button>
                        </Form.Item>
                        <Form.Item label={Configure.Gl(1164,"礼包种类")} name="kind">
                            <Input />
                        </Form.Item>
                    </Divider>
                    <Divider>
                        <Form.Item label={Configure.Gl(1165,"平台限定")} name="limitPlatform">
                            <Input />
                        </Form.Item>
                        <Form.Item label={Configure.Gl(1166,"GameId限定")} name="limitGameId">
                            <Input />
                        </Form.Item>
                        <Form.Item label={Configure.Gl(1167,"渠道限定")} name="limitChannel">
                            <Input />
                        </Form.Item>
                        <Form.Item label={Configure.Gl(1168,"vip限定")} name="limitVip">
                            <Input />
                        </Form.Item>
                        <Form.Item name="kindExclusive" valuePropName="checked">
                            <Checkbox>{Configure.Gl(1172,"同种类礼包互斥")}</Checkbox>
                        </Form.Item>
                        <Form.Item name="limitOnce" valuePropName="checked">
                            <Checkbox>{Configure.Gl(1173,"只可使用一次")}</Checkbox>
                        </Form.Item>
                    </Divider>
                    <Divider>
                        <Form.Item label={Configure.Gl(1169,"编码模式")} name="buildMode">
                            <Select placeholder={Configure.Gl(1169,"编码模式")}>
                                <Select.Option value='0'>{Configure.Gl(1170,'自动编码')}</Select.Option>
                                <Select.Option value='1'>{Configure.Gl(1171,'自定义编码')}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label={Configure.Gl(1174,"自定义码")} name="customCode" >
                            <Input />
                        </Form.Item>
                        <Form.Item label={Configure.Gl(1175,"礼包码实例数量")} name="instanceNum">
                            <Input />
                        </Form.Item>
                        <Form.Item label={Configure.Gl(1176,"到期时间")} name="expiryTime">
                            <DatePicker showTime />
                        </Form.Item>
                    </Divider>
                    <Divider>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">{Configure.Gl(1177,'生成')}</Button>
                        </Form.Item>
                    </Divider>
                    <TextArea value={this.state.result} allowClear={false}/>
                </Form>
            </HomeLayout>
        );
    }

    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        giftcodeApi.build(values).then(d => {
            let result = d as any[];
            let res = '';
            result.forEach(r => res = res + r + "\n");
            this.setState({result: res});
        });
    }

    onFormFinishFailed = (errorInfo: any) => {
        console.error('onFormFinishFailed:', errorInfo);
    }

    componentDidMount() {
        console.log("GiftcodeBuildIndex::componentDidMount");
        this.reload();
    }

    async reload() {
    }

    async translate(){
        // eslint-disable-next-line
        if(this.state.reward == null || this.state.reward == ''){
            alert(Configure.Gl(1160,"礼包内容不可为空"));
            return;
        }
        const { queryFilter } = this.state; 
        const query = { ...queryFilter };
        
        let list = await srvGameApi.list(query) as any[];
        if(list.length === 0){
            return;
        }
        let reqObj = {
            "type":"MixRes",
            "code":this.state.reward,
            "srvCode": list[0].code,
          };
        let data = await giftcodeApi.translate(reqObj) as any;
        alert(data);
    }
}