import React from 'react';
import { Button, Table, Space} from 'antd';
import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import { FormInstance } from 'antd/lib/form';
import { StdItemPage } from '../../../part/StdItemPage';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';
import { payManualApi } from '../../../api/console/gm.pay';
import { formatDate } from '../../../util/toolbox';
import { Key, TableCurrentDataSource } from 'antd/lib/table/interface';
import { SorterResult } from 'antd/es/table/interface';
import Configure from '../../../api/Configure';

export default class GmPayApproveIndex extends StdItemPage<any> {
    formRef = React.createRef<FormInstance>();

    state = {
        itemTotal: undefined,
        queryPagination: {
            page: 1,
            pageSize: 10
        },
        itemList: [],
        queryFilter: {viewAll:true}
    }

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1231,'订单类型'),
                dataIndex: 'orderType',
                width: 80,
                render: (value: any) => value === 2 ? Configure.Gl(1260,"玩家补单") : Configure.Gl(1261,"内部订单（给托号）")
            },
            {
                title: Configure.Gl(1262,'审核状态'),
                dataIndex: 'status',
                width: 80,
                render: (value: any) => value === 1 ? Configure.Gl(1201,"未审批") : value === 2 ? Configure.Gl(1202,"同意"): Configure.Gl(1203,"拒绝")
            },
            {
                title: Configure.Gl(1263,'审核操作'),
                dataIndex: 'operate',
                width: 150,
                render: (value: any, record: any, index: number) => {
                    return record.status === 1 ? 
                     (<Space>
                        <Button type="primary" onClick={() => this.doAccept(record)}>{Configure.Gl(1202,"同意")}</Button>
                        <Button danger onClick={() => this.doRefuse(record)}>{Configure.Gl(1203,"拒绝")}</Button>
                    </Space>) : "";
                },
            },
            {
                title: Configure.Gl(1237,'支付项模板ID'),
                dataIndex: 'itemTid',
                width: 80,
            },
            {
                title: Configure.Gl(1239,'支付项名称'),
                dataIndex: 'itemName',
                width: 120,
            },
            {
                title: Configure.Gl(1238,'支付项购买数量'),
                dataIndex: 'itemCount',
                width: 60,
            },
            {
                title: Configure.Gl(1240,'付款总金额(元)'),
                dataIndex: 'payAmount',
                width: 60,
            },
            {
                title: Configure.Gl(1233,  '平台订单号'),
                dataIndex: 'platformOrderId',
                width: 100,
            },
            {
                title: Configure.Gl(1234,'服务器'),
                dataIndex: 'srvCode',
                width: 100,
            },
            {
                title: Configure.Gl(1264,'主角姓名'),
                dataIndex: 'masterName',
                width: 120,
            },
            {
                title: Configure.Gl(1101,'主角唯一ID'),
                dataIndex: 'masterIdHex',
                width: 170,
            },
            {
                title: Configure.Gl(1235,'账号uid'),
                dataIndex: 'accountUid',
                width: 170,
            },
            {
                title: Configure.Gl(1122,'平台编码'),
                dataIndex: 'platformCode',
                width: 80,
            },
            {
                title: Configure.Gl(1099,'渠道编码'),
                dataIndex: 'channelCode',
                width: 80,
            },
            {
                title: Configure.Gl(1241,'平台openId'),
                width: 170,
                dataIndex: 'platformOpenId',
            },
            {
                title: Configure.Gl(1205,'提交人用户名'),
                width: 80,
                dataIndex: 'submitUsername',
            },
            {
                title: Configure.Gl(1206,'提交人昵称'),
                width: 120,
                dataIndex: 'submitNickname',
            },
            {
                title: Configure.Gl(1207,'提交时间'),
                width: 120,
                dataIndex: 'submitTime',
                render: (v, r) => formatDate(v)
            },
            {
                title:Configure.Gl(1208,'审核人用户名'),
                width: 80,
                dataIndex: 'approveUsername',
            },
            {
                title: Configure.Gl(1209,'审核人昵称'),
                width: 120,
                dataIndex: 'approveNickname',
            },
            {
                title: Configure.Gl(1210,'审核时间'),
                width: 120,
                dataIndex: 'approveTime',
                render: (v, r) => formatDate(v)
            }
        ];
    }

    render() {
        const { itemTotal, queryPagination } = this.state;
        const columns: ColumnsType<any> = this.getCols();
        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            defaultPageSize: queryPagination.pageSize,
            total: itemTotal
        };
        return (
            <HomeLayout path={RoutePath.GM_PAY_APPROVE_INDEX}>
                <Table dataSource={this.state.itemList} columns={columns}
                pagination={pagination} scroll={{ x: this.getColsWidth(columns), y: 512 }}  onChange={(p, f, s, e) => { this.onPageChange(p, f, s, e) }}/>
            </HomeLayout>
        );
    }

    onPageChange(pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        this.setState({queryPagination: {
            page: pagination.current,
            pageSize: pagination.pageSize
        }}, ()=>{
            this.reload();
        });
    }

    componentDidMount() {
        this.reload();
    }

    async reload() {
        const { queryPagination, queryFilter } = this.state; 
        const query = { ...queryPagination, ...queryFilter };
        console.log("请求参数为：",query);
        let data = await payManualApi.list(query) as any;
        console.log(Configure.Gl(1390,"请求到的数据是："), data);
        this.addKeyToData(data.records);
        this.setState({ itemList: data.records, itemTotal: data.total });
    }

    doAccept(item : any) {
        payManualApi.approve({status: 2,id: item.id}).then(() => {
          this.reload();
        });
    }

    doRefuse(item : any) {
        payManualApi.approve({status: 3, id: item.id}).then(() => {
          this.reload();
        });
    }
}