import { metaApi, loginApi, consoleApi, statApi, worldApi, payApi } from '../util/http';

class MixApi {

    meta_self_info() {
        return metaApi.get({
            path: 'self/info',
            param: {},
            title: 'Meta Server Info',
            quiet: true,
        });
    }

    login_self_info() {
        return loginApi.get({
            path: 'self/info',
            param: {},
            title: 'Login Server Info',
            quiet: true,
        });
    }

    console_self_info() {
        return consoleApi.get({
            path: 'self/info',
            param: {},
            title: 'Console Server Info',
            quiet: true,
        });
    }

    stat_self_info() {
        return statApi.get({
            path: 'self/info',
            param: {},
            title: 'Stat Server Info',
            quiet: true,
        });
    }

    world_self_info() {
        return worldApi.get({
            path: 'self/info',
            param: {},
            title: 'World Server Info',
            quiet: true,
        });
    }

    pay_self_info() {
        return payApi.get({
            path: 'self/info',
            param: {},
            title: 'Pay Server Info',
            quiet: true,
        });
    }
}

export const mixApi: MixApi = new MixApi();