import React, { RefObject } from 'react';
import { Button, Divider, Form, Input, InputNumber, message, Select, Spin, Table } from 'antd';
import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import { roleApi, BAG_REDUCE_CMD_MODE_UID } from '../../../api/console/role';
import Configure from '../../../api/Configure';
import SrvGameSelectEdit from '../../srv/SrvGameSelectEdit';
import { RouteComponentProps } from 'react-router';
import { FormInstance } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { StdItemPage } from '../../../part/StdItemPage';
import ClipBoard from '../../../util/clipboard';
import { SearchOutlined } from '@ant-design/icons';

export default class RoleBagIndex extends StdItemPage<any> {

    private editor: RefObject<SrvGameSelectEdit>;
    private onlyID: RefObject<Input>;
    private serverID: RefObject<Input>;
    state = {
        demo: "bag",
        srvCode: "",
        roleId: "",
        num: 0,
        itemTid: 0,
        bagType: 0,
        spinLoad: false,
        itemTotal: undefined,
        itemList: [],
        note: ""
    }

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
        this.onlyID = React.createRef();
        this.serverID = React.createRef();
    }

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1841, "物品唯一ID"),
                width: 200,
                dataIndex: 'id',
            },
            {
                title: Configure.Gl(1840, "名字"),
                dataIndex: 'name',
                width: 100,
            },
            {
                title: "Tid",
                dataIndex: 'tid',
                width: 100,
            },
            {
                title: Configure.Gl(1829, "数量"),
                dataIndex: 'num',
                width: 100,
            },
            {
                title: Configure.Gl(1830, '删除操作'),
                dataIndex: 'operation',
                render: (_, record) =>
                    <>
                        <InputNumber min={0} max={record.num} defaultValue={0} onChange={this.setDefValue} onBlur={this.onBlur} />
                        <Button onClick={() => this.doReduce(record)}>删除</Button>
                    </>,
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
            },
            {
                title: Configure.Gl(1842, "描述"),
                dataIndex: 'detail',
                width: 100,
            },
        ];
    }

    //设置默value值
    setDefValue = (e: any) => {
        if (e > 0) {
            this.state.num = e;
        } else {
            message.error(Configure.Gl(1828, "数值不能未负数!"));
        }
    }

    onBlur = (e: any) => {
        if (e.target.defaultValue > 0) {
            this.state.num = e.target.defaultValue;
        }
    }

    formRef = React.createRef<FormInstance>();
    render() {
        const { note, itemTotal } = this.state;
        const columns: ColumnsType<any> = this.getCols();
        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            defaultPageSize: 100,
            total: itemTotal
        };
        return (<HomeLayout path={RoutePath.ROLE_BAG_INDEX}>
            <Spin spinning={this.state.spinLoad} tip={Configure.Gl(1774, '获取服务器数据中...')}>
                <Form
                    ref={this.formRef}
                    style={{ float: 'right' }}
                    layout="vertical"
                    name="basic"
                    onFinish={this.onFormFinish}
                    onFinishFailed={this.onFormFinishFailed}
                >
                    <Button type="primary" shape="round" icon={<SearchOutlined />} onClick={this.doQuery}>查询bag</Button>
                </Form>

                <Form.Item style={{ float: 'left' }}>
                    <SrvGameSelectEdit ref={this.editor} selectType={"radio"} handleOk={(v: any[]) => this.handleOk(v)} ></SrvGameSelectEdit>
                </Form.Item>
                <Form.Item name="srvCode" style={{ float: 'left', width: '12%' }}>
                    <Input ref={this.serverID} placeholder={Configure.Gl(1198, "选择游戏服务器(srvCode)")} />
                </Form.Item>
                <Form.Item name="roleId" style={{ float: 'left', width: '20%' }}>
                    <Input ref={this.onlyID} placeholder={Configure.Gl(1832, '角色roleID')} />
                </Form.Item>
                <Select placeholder={Configure.Gl(1845, "选择查找类型")}
                    labelInValue
                    style={{ width: 200 }}
                    onChange={this.handleChange}
                >
                    <Select.Option value='1'>英雄</Select.Option>
                    <Select.Option value='2'>装备</Select.Option>
                    <Select.Option value='3'>道具</Select.Option>
                    <Select.Option value='4'>碎片</Select.Option>
                    <Select.Option value='5'>玺印</Select.Option>
                    <Select.Option value='6'>兽魂</Select.Option>
                    <Select.Option value='7'>仙器</Select.Option>
                    <Select.Option value='8'>仙器碎片</Select.Option>
                    <Select.Option value='13'>藏品</Select.Option>
                </Select>
                <Button onClick={this.doShearPlate}>{Configure.Gl(1833, '读取剪切板')}</Button>
                <Divider />
                <TextArea
                    value={note}
                    onChange={e => this.onControlled(e.target.value)}
                    placeholder={Configure.Gl(1825, '描述信息...')}
                    showCount maxLength={200}
                />
                <Divider />

                <Table dataSource={this.state.itemList} columns={columns}
                    pagination={pagination} scroll={{ x: this.getColsWidth(columns), y: 512 }} />
            </Spin>
        </HomeLayout>);
    }

    onFormFinish = (values: any) => {
        // if(!values.srvCode){
        //     alert(Configure.Gl(1199,"请先选择游戏服务器"));
        //     return;
        // }
        this.state.srvCode = values.srvCode;
        //this.setState({queryFilter:filter}, () => this.reload());
    }

    onFormFinishFailed = (errorInfo: any) => {
        console.error('onFormFinishFailed:', errorInfo);
    }

    async reload() {
        this.setState({ spinLoad: true })

        if (this.state.srvCode === '') {
            message.error(Configure.Gl(1834, '服务器不能为空'));
            return;
        }
        if (this.state.roleId === '') {
            message.error(Configure.Gl(1835, 'roleId不能为空'));
            return;
        }
        if (this.state.bagType < 1) {
            message.error(Configure.Gl(1846, "请选择背包类型"));
            return;
        }

        let _srv: string = this.state.srvCode;
        let _roleId: string = this.state.roleId;
        let _bagtype: number = this.state.bagType;
        let data = await roleApi.bagQuery({
            srvCode: _srv,
            roleId: _roleId,
            bagType: _bagtype,
        });
        console.log(Configure.Gl(1390, "请求到的数据是："), data);
        // this.addKeyToData(DataList);
        // this.setState({ itemList: DataList});
        this.setState({ spinLoad: false });
    }


    onControlled = (value: any) => {
        this.setState({ note: value });
    };

    handleChange = (SelectOption: any) => {
        this.state.bagType = SelectOption.value;
    }

    componentDidMount() {
        console.log("RoleBagIndex::componentDidMount()");
    }

    handleOk(v: any[]) {
        if (v.length > 0) {
            this.formRef.current?.setFieldsValue({ srvCode: v[0].code });
            this.serverID.current?.setValue(v[0].code);
        }
    }



    doQuery = async () => {

        let _srv: string = this.serverID.current?.state.value;
        let _roleId: string = this.onlyID.current?.state.value;
        let _bagtype: number = this.state.bagType;

        if (_srv === '') {
            message.error(Configure.Gl(1834, '服务器不能为空'));
            return;
        }
        if (_roleId === '') {
            message.error(Configure.Gl(1835, 'roleId不能为空'));
            return;
        }
        if (_bagtype < 1) {
            message.error(Configure.Gl(1846, "请选择背包类型"));
            return;
        }
        this.setState({ spinLoad: true });
        this.setState({ itemList: [] });
        let data = await roleApi.bagQuery({
            srvCode: _srv,
            roleId: _roleId,
            bagType: _bagtype,
        }) as any;
        //let rolearr:{} = data.role;
        this.setState({ itemList: data.list });
        console.log("角色背包查询结果：", data);
        this.setState({ spinLoad: false });
    }

    doShearPlate = () => {
        let srvcode: string = ClipBoard.getSrvCode();
        let masteridhex: string = ClipBoard.getMasterIdHex();
        if (srvcode === '' || masteridhex === '') {
            message.error(Configure.Gl(1838, '服务器ID或角色唯一ID为空'))
            return;
        }
        this.onlyID.current?.setValue(masteridhex);
        this.serverID.current?.setValue(srvcode);
    }

    //删除
    doReduce = async (record: any) => {

        this.setState({ spinLoad: true });
        if (this.state.note === "") {
            this.state.note = '删除物品';
        }

        let _srv: string = this.serverID.current?.state.value;
        let _roleId: string = this.onlyID.current?.state.value;
        let _bagtype: number = this.state.bagType;
        let _deleteNum: number = this.state.num;
        let _itemUid: string = record.id;
        let data = await roleApi.bagReduce({
            cmdMode: BAG_REDUCE_CMD_MODE_UID,
            srvCode: _srv,
            roleId: _roleId,
            bagType: _bagtype,
            num: _deleteNum,
            itemUid: _itemUid,
            note: this.state.note
        }) as any;

        if (data.logs !== "" || data.logs !== undefined) {
            this.doQuery();
        }

    }

}

