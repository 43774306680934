import { consoleApi } from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

class ConfApi extends ApiBase {

    setGlobal(data: any) {
        return consoleApi.post({
            path: 'conf/global/set',
            param: data,
            title: Configure.Gl(1668,'设置全局配置')
        });
    }

    getGlobal() {
        return new Promise((resolve, reject) => {
            consoleApi.get({
                path: 'conf/global/get',
                title: Configure.Gl(1669,'获取全局配置'),
                quiet: true
            }).then(data => {
                //item.dateTime = this.parseZoneTime(item.dateTime);
                resolve(data);
            });
        });
    }
}

export const confApi: ConfApi = new ConfApi();