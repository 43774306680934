import React from 'react';

import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import { GoContext } from './CCTestData';
import {CCTestComp} from './CCTestComp';
import { Button } from 'antd';

export default class CCTestIndex extends React.Component {

    state = {
        info: {color:'green', num: 0, changeValue: (c: string, n: number) => {this.doChangeValue(c, n)}}
    };

    render() {
        const { info } = this.state;
        return (
            <HomeLayout path={RoutePath.LAB_CC_TEST_INDEX}>
                <Button style={{ background: info.color }} onClick={()=>{
                    this.doChangeValue('yellow', 111);
                }}>当前num是{info.num}改变,点我会变成黄色111</Button>
                <Button onClick={()=>{
                    console.log("info=", info);
                    console.log("this.state.info=", this.state.info);
                }}>查看状态值</Button>

                <GoContext.Provider value={info}>
                        <CCTestComp>主页面提供的内容</CCTestComp>
                </GoContext.Provider>
            </HomeLayout>
        );
    }

    doChangeValue = (c: string, n: number) => {
        this.setState({info: {color: c, num:n, changeValue: this.doChangeValue}});
    }

    componentDidMount() {
        console.log("CCTestIndex::componentDidMount");
    }

}