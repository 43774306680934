import React from 'react';
import { Input, Select } from 'antd';
import { srvCrossApi } from '../../../api/console/srv.cross';
import { AbsFilter, StdItemFilter } from '../../../part/StdItemFilter';
import Configure from '../../../api/Configure';

class Filter extends AbsFilter {
    classify?: string = undefined;
    code?: string = undefined;
    deviceCode?: string = undefined;
    note?: string = undefined;
}

export default class SrvCrossFilter extends StdItemFilter {

    state = {
        classify: undefined as string | undefined,
        code: undefined as string | undefined,
        deviceCode: undefined as string | undefined,
        note: undefined as string | undefined,
        optClassify: []
    };

    filter:Filter = new Filter();

    getFilter() { return this.filter; }

    async componentDidMount() {
        const opt = await srvCrossApi.optClassify();
        this.setState({optClassify: opt});
    }

    render() {
        const { optClassify } = this.state;
        let optClassifyUI:any[] = this.optUI(optClassify);
        return (
            <span>
                <Select placeholder={Configure.Gl(1424,"分类")} value={this.state.classify} onChange={v => this.setValue('classify', v)} style={{minWidth: 180}}>
                    {optClassifyUI}
                </Select>
                <Input placeholder={Configure.Gl(1392,"跨服编码")} value={this.state.code} onChange={e => this.setValue('code', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1069,"设备编码")} value={this.state.deviceCode} onChange={e => this.setValue('deviceCode', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1065,"备注")} value={this.state.note} onChange={e => this.setValue('note', e.target.value)} style={{width: 180}}/>
                { this.renderCmd() }
            </span>
        );
    }

}