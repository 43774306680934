import { Button } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { Key, RefObject } from 'react';
import Configure from '../../api/Configure';
import MasterQuery from './MasterQuery';

export interface IProps {
    onSelect: Function,
    initData: any[],
    readonly: boolean,
}

export default class MasterQueryMulti extends React.Component<IProps> {
    editor: RefObject<MasterQuery> = React.createRef();

    state = {
        show: false,
        selectItems: [] as any[],
        itemTotal: undefined,
        queryPagination: {
            page: 1,
            pageSize: 10
        },
        queryFilter: {}
    }

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1265, '唯一ID'),
                dataIndex: 'masterIdHex',
                width: 240,
            },
            {
                title: Configure.Gl(1151, '主角名'),
                dataIndex: 'masterName',
                width: 80,
            },
            {
                title: Configure.Gl(1187, '主角等级'),
                dataIndex: 'masterLevel',
                width: 80,
            },
            {
                title: Configure.Gl(1014, '游戏服'),
                dataIndex: 'srvCode',
                width: 120,
            },
            {
                title: Configure.Gl(1122, '平台编码'),
                dataIndex: 'platformCode',
                width: 60,
            },
            {
                title: Configure.Gl(1099, '渠道编码'),
                dataIndex: 'channelCode',
                width: 60,
            },
            {
                title: Configure.Gl(1266, 'IMEI'),
                dataIndex: 'IMEI',
                width: 100,
            },
            {
                title: Configure.Gl(1267, 'IP'),
                dataIndex: 'ip',
                width: 180,
            },
        ];
    }

    render() {
        const { itemTotal, queryPagination, selectItems } = this.state;
        const columns = this.getCols();
        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            defaultPageSize: queryPagination.pageSize,
            total: itemTotal
        };
        const items = this.props.initData ? this.convert(this.props.initData) : selectItems;
        this.addKeyToData(items);
        return (<span>
            <div style={{ display: this.props.readonly ? "none" : "block" }}>
                <MasterQuery ref={this.editor} onSelect={(r: any) => this.handleSelect(r)} />
                <Button type="default" onClick={() => this.clearSelect()}>{Configure.Gl(1653, '清除主角')}</Button>
            </div>
            <br />
            <Table dataSource={items} columns={columns}
                pagination={pagination} scroll={{ x: 1200, y: 420 }} onChange={(p, f, s, e) => { this.onPageChange(p, f, s, e) }} />
        </span>
        );
    }

    convert(data: any[]) {
        data.forEach(element => {
            if (!element.masterName) {
                element.masterName = element.name;
            }
        });
        return data;
    }

    clearSelect() {
        this.setState({ selectItems: [] }, () => {
            this.props.onSelect(this.state.selectItems);
        });
    }

    addKeyToData(data: any[]) {
        data.forEach(element => {
            if (element.key === undefined || element.key === null) {
                element.key = Math.random();
            }
        });
    }

    handleSelect(r: any) {
        let items = this.props.initData ? this.convert(this.props.initData) : this.state.selectItems;
        let test = [] as any[];
        if (items.length > 0) {
            items.forEach(v => {
                test.push(v);
            })
        }
        test.push(r);
        this.addKeyToData(test);
        this.setState({ selectItems: test }, () => {
            this.props.onSelect(this.state.selectItems);
        });
        this.editor.current?.setState({ show: false });
    }

    onFilterChange(filter: any) {
        this.setState({ queryFilter: filter }, () => {
            this.reload();
        });
    }

    onPageChange(pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        this.setState({
            queryPagination: {
                page: pagination.current,
                pageSize: pagination.pageSize
            }
        }, () => {
            this.reload();
        });
    }

    async reload() {

    }

    handleModalOk() {

    }

    handleModalCancel() {
        this.setState({ show: false });
    }

}