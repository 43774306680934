import React from 'react';
import { Input, Select } from 'antd';
import { platformAppApi } from '../../../../api/console/platform.app';
import { srvGroupApi } from '../../../../api/console/srv.group';
import { AbsFilter, StdItemFilter } from '../../../../part/StdItemFilter';
import Configure from '../../../../api/Configure';

class Filter extends AbsFilter {
    srvCode?: string = undefined;
    platformCode?: string = undefined;
    channelCode?: string = undefined;
    gameId?: string = undefined;
    ext?: string = undefined;
    groupId?: number = undefined;
}

export default class SrvGameAccessFilter extends StdItemFilter {

    state = {
        srvCode: undefined as string | undefined,
        platformCode: undefined as string | undefined,
        channelCode: undefined as string | undefined,
        gameId: undefined as string | undefined,
        ext: undefined as string | undefined,
        groupId: undefined as number | undefined,
        optGameId: [],
        optGroup: []
    };

    filter:Filter = new Filter();

    getFilter() { return this.filter; }

    async componentDidMount() {
        const optGameId = await platformAppApi.optGameId();
        this.setState({optGameId: optGameId});
        srvGroupApi.optionList().then(d => this.setState({ optGroup: d }))
    }

    render() {
        const { optGameId, optGroup } = this.state;
        const optGameIdUI: any[] = this.optUI(optGameId);
        const optGroupUI: any[] = this.optUI(optGroup);
        return (
            <span>
                <Input placeholder={ Configure.Gl(1079,'游戏服编码')} value={this.state.srvCode} onChange={e => this.setValue('srvCode', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1122,'平台编码')} value={this.state.platformCode} onChange={e => this.setValue('platformCode', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1099,'渠道编码')} value={this.state.channelCode} onChange={e => this.setValue('channelCode', e.target.value)} style={{width: 180}}/>
                <Select placeholder="GameId" value={this.state.gameId} onChange={v => this.setValue('gameId', v)} style={{minWidth: 180}}>
                    {optGameIdUI}
                </Select>
                <Input placeholder={Configure.Gl(1459,"扩展编码")} value={this.state.ext} onChange={e => this.setValue('ext', e.target.value)} style={{width: 180}}/>
                <Select placeholder={Configure.Gl(1460,"游戏服务器组")} value={this.state.groupId} onChange={v => this.setValue('groupId', v)} style={{minWidth: 180}}>
                    {optGroupUI}
                </Select>
                { this.renderCmd() }
            </span>
        );
    }

}