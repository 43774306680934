import React from 'react';
import { Button, Divider, Form, Card, Spin } from 'antd';
import { HomeLayout } from '../../layout/HomeLayout';
import { RoutePath } from '../../route/path';
import { FormInstance } from 'antd/lib/form';
import Configure from '../../api/Configure';
import { cleanApi } from '../../api/stat/clean';

export default class CleanMemoryIndex extends React.Component {

    formRef = React.createRef<FormInstance>();

    state = {

        spinLoad: false
    };

    render() {
        return (
            <HomeLayout path={RoutePath.OTHER_CHLEAN_MEMORY_INDEX}>
                <Spin spinning={this.state.spinLoad} tip={Configure.Gl(1822, '正在清理中....')}>
                    <Form>
                        <Divider />
                        <Card title={Configure.Gl(1889, "一键清理24小时前的所有监控汇报")} style={{ width: 400 }}>
                            <p>
                                <Button type="primary" htmlType="button" onClick={() => this.clear()}>{Configure.Gl(1338, "执行")}</Button>
                            </p>
                        </Card>
                        <Card title={Configure.Gl(1785, "英雄阵容清理")} style={{ width: 400 }}>
                            <p>
                                <Button type="primary" htmlType="button" onClick={() => this.heroLineupClear()}>{Configure.Gl(1338, "执行")}</Button>
                            </p>
                        </Card>
                        <Card title={Configure.Gl(1784, '清理1个月前的无效订单')} style={{ width: 400 }}>
                            <p>
                                <Button type="primary" htmlType="button" onClick={() => this.recordClear()}>{Configure.Gl(1338, "执行")}</Button>
                            </p>
                        </Card>
                    </Form>
                </Spin>
            </HomeLayout>
        );
    }

    clear = async () => {
        this.setState({ spinLoad: true });
        let errormsg = await cleanApi.clear() as any[];
        console.log(errormsg);

        setTimeout(() => {
            this.setState({ spinLoad: false });
        }, 3000);
    }

    heroLineupClear = async () => {
        this.setState({ spinLoad: true });
        let errormsg = await cleanApi.heroLineupClear() as any[];
        console.log(errormsg);
        setTimeout(() => {
            this.setState({ spinLoad: false });
        }, 3000);
    }

    recordClear = async () => {
        this.setState({ spinLoad: true });
        let errormsg = await cleanApi.recordClear() as any[];
        console.log(errormsg);
        setTimeout(() => {
            this.setState({ spinLoad: false });
        }, 3000);
    }

}