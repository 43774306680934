import jpJsonData from "../language/ja_JP.json";
import enJsonData from "../language/en_US.json";
import viJsonData from "../language/vi_VN.json";
import koJsonData from "../language/ko_KR.json";
import hkJsonData from "../language/zh_HK.json";
import twJsonData from "../language/zh_TW.json";
import thJsonData from "../language/th_TH.json";
import zhJsonData from "../language/zh_CN.json";
import heroData from "../language/hero.json";
import { StoreUtil } from "../util/store";
import participateJsonData from "../participate.json";
export default class Configure {
    private static instance: Configure;
    static getInstance() {
        if (!this.instance) {
            this.instance = new Configure();
        }
        return this.instance;
    }

    public static LocalWord:string = StoreUtil.getStore("LocalWord")!==null?StoreUtil.getStore("LocalWord"):"zh_CN";//zh_CN,zh_HK,en_US,ko_KR,vi_VN,
    public state:any;
    constructor() {
        this.state={};
    }
    
    /**
    * @keyword 配置的id
    * @orgString id获取不到的时候使用的值.
    * @例子：GlF(1024,"草榴社区")
    **/
    // public static Gl(keywordID:number,orgString:string):string {
    //     let language:string = (LanguageJsonData as {[key:number]:any})[keywordID][this.LocalWord];
    //     if(language!=="" ||language!==null){
    //         return language;
    //     }
    //     return orgString;
    // }

    public static GL_UNDEFINED = 999999;

    /**
    * @keyword 配置的id
    * @orgString 可变参数的函数体 解析{}.
    * @args 可变参数
    * @例子：GlF(10024,"公司员工：{}同志，年龄{}岁"，李云龙，18)
    **/
    public static Gl(keywordID: number, orgString: string, ...args: any[]) {
        try {
            if (args.length <= 0) {
                let language: string = this.changeInternat(keywordID);
                if(language!=="" || language!==undefined || language!==null){
                    return language;
                }
                return orgString;
            } else {
                let re = /\{\}/;
                let restr = orgString;
                for (var i = 0; i < args.length;i++){
                    restr = restr.replace(re, args[i]);
                    continue;
                }
                return restr;
            }
        } catch (e) {
            return orgString;
        }
    }

    private static changeInternat(keywordid:number): string {
        let language: string = "";
        switch (this.LocalWord) {
            case "zh_CN":
                language = (zhJsonData as { [key: number]: any })[keywordid][this.LocalWord];
                break;
            case "ja_JP":
                language = (jpJsonData as { [key: number]: any })[keywordid][this.LocalWord];
                break;
            case "en_US":
                language = (enJsonData as { [key: number]: any })[keywordid][this.LocalWord];
                break;
            case "ko_KR":
                language = (koJsonData as { [key: number]: any })[keywordid][this.LocalWord];
                break;
            case "vi_VN":
                language = (viJsonData as { [key: number]: any })[keywordid][this.LocalWord];
                break;
            case "zh_TW":
                language = (twJsonData as { [key: number]: any })[keywordid][this.LocalWord];
                break;
            case "zh_HK":
                language = (hkJsonData as { [key: number]: any })[keywordid][this.LocalWord];
                break;
            case "th_TH":
                language = (thJsonData as { [key: number]: any })[keywordid][this.LocalWord];
                break;
            default:
                language = (enJsonData as { [key: number]: any })[keywordid]["en_US"];
                break;

        }
        return language;
    }

    public static GlExcel(keywordID: number) {
        try {
                let language: string = (heroData as { [key: number]: any })[keywordID]['zh_CN'];
            if(language!=="" || language!==undefined || language!==null){
                return language;
            }
            return "";
        } catch (error) {
            console.log(Configure.Gl(Configure.GL_UNDEFINED,"新英雄未配置")+" heroTid:"+keywordID);
            return Configure.Gl(Configure.GL_UNDEFINED,"新英雄未配置");
        }
    }

    public static GlStarExcel(keywordID: number) {
        try {
                let language: string = (heroData as { [key: number]: any })[keywordID]['star'];
            if(language!=="" || language!==undefined || language!==null){
                return language;
            }
            return '0';
        } catch (error) {
            console.log(Configure.Gl(Configure.GL_UNDEFINED,"新英雄未配置")+" heroTid:"+keywordID);
            return '0';
        }
    }

    public static getParticipate(keywordid:number):string{
        try {
            return (participateJsonData as { [key: number]: any })[keywordid]["name"];
        } catch (error) {
            return "数据库还未添加此ID："+keywordid.toString();
        }
    }

}