import React from 'react';
import { TablePaginationConfig } from "antd/lib/table";
import { ColumnsType, Key, TableCurrentDataSource, TableRowSelection } from 'antd/lib/table/interface';
import { SorterResult } from 'antd/es/table/interface';
import { Pagination } from "../def/CommonDef";
import { StdItemPage } from "./StdItemPage";
import { HomeLayout } from "../layout/HomeLayout";
import { Table, Divider } from 'antd';

export interface IPagingState {
    showEdit: boolean;
    itemList: any[];
    itemTotal: number;
    queryPagination: Pagination;
    queryFilter: any;
    sorter: SorterResult<any> | SorterResult<any>[] | undefined;
}

export abstract class StdItemPageLimit<IS extends IPagingState> extends StdItemPage<IS> {

    getDefaultState(): IPagingState {
        return {
            showEdit: false,
            itemList: [],
            itemTotal: 0,
            queryPagination: {
                page: 1,
                pageSize: 10
            },
            queryFilter: {},
            sorter: undefined as SorterResult<any> | SorterResult<any>[] | undefined,
        };
    }

    abstract getCols(): ColumnsType<any>;

    abstract getFilterUI(): any;

    abstract getEditorUI(): any;

    abstract getPath(): string;

    /** 获取底部UI */
    getBottomUI() {
        return (<div></div>);
    }

    getTableRowSelection(): TableRowSelection<any> | undefined {
        return undefined;
    }

    async reloadForData(query: any) {
        return {};
    }

    getQueryParam() {
        const { queryPagination, queryFilter } = this.state;
        return { ...queryPagination, ...queryFilter };
    }

    async reload() {
        const query = this.getQueryParam();
        let data: any = await this.reloadForData(query);
        this.addKeyToData(data.records);
        this.setState({ itemList: data.records, itemTotal: data.total });
    }

    render() {
        const { itemTotal, queryPagination } = this.state;

        const columns: ColumnsType<any> = this.getCols();

        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共 ${total} 条`,
            current: queryPagination.page,
            defaultPageSize: queryPagination.pageSize,
            total: itemTotal
        };
        const colWidth = this.getColsWidth(columns);
        return (
            <HomeLayout path={this.getPath()}>
                {this.getFilterUI()}
                <Divider />
                <Table dataSource={this.state.itemList} columns={columns} pagination={pagination}
                    rowSelection={this.getTableRowSelection()}
                    scroll={{ x: colWidth, y: 670 }}
                    onChange={(p, f, s, e) => { this.onPageChange(p, f, s, e) }} />
                {this.getEditorUI()}
                {this.getBottomUI()}
            </HomeLayout>
        );
    }

    componentDidMount() {
        console.log("StdItemPageLimit::componentDidMount");
        this.reload();
    }

    onFilterChange(filter: any) {
        console.log("沙雕 filter=", filter);
        const { queryPagination } = this.state;
        const p = { page: 1, pageSize: queryPagination.pageSize };
        this.setState({ queryFilter: filter, queryPagination: p }, () => {
            this.reload();
        });
    }

    onPageChange(pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        // console.log("StdItemPageLimit onPageChange pagination=", pagination, ", filters=", filters, ", sorter=", sorter, ", extra=", extra);
        this.setState({
            queryPagination: {
                page: pagination.current,
                pageSize: pagination.pageSize
            }, 
            sorter: sorter
        }, () => {
            this.reload();
        });
    }

    /** 写入sorter */
    writeSorter(field: string, param: any, paramOrderField: string) {
        const { sorter } = this.state;
        if(!sorter) { return; }
        if(Array.isArray(sorter)) {
            console.log("是数组");
            let ss = sorter as SorterResult<any>[];
            for(let k in ss) {
                let s = ss[k];
                if(field === s.field) {
                    if('ascend' === s.order) {
                        param[paramOrderField] = 'ASC';
                    } else if('descend' === s.order) {
                        param[paramOrderField] = 'DESC';
                    } else {
                        param[paramOrderField] = undefined;
                    }
                }
            }
        } else {
            console.log("不是数组");
            let s = sorter as SorterResult<any>;
            if(field === s.field) {
                if('ascend' === s.order) {
                    param[paramOrderField] = 'ASC';
                } else if('descend' === s.order) {
                    param[paramOrderField] = 'DESC';
                } else {
                    param[paramOrderField] = undefined;
                }
            }
        }
    }

}