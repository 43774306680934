import { consoleApi } from '../../util/http';
import Configure from '../Configure';

class SysTaskApi {

    list(filter?:any) {
        return consoleApi.get({
            path: 'sys/task/list',
            title: Configure.Gl(1696,'系统任务列表'),
            param: filter,
            quiet: true
        });
    }

    clear() {
        return consoleApi.get({
            path: 'sys/task/clear',
            title: Configure.Gl(1783,'系统任务清理'),
            quiet: true
        });
    }

}

export const sysTaskApi: SysTaskApi = new SysTaskApi();