import React, { Key, RefObject } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, DatePicker, Divider, Input, Spin, Table, Tabs, Tag } from 'antd';
import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import { statSrvApi } from '../../../api/stat/all';
import Configure from '../../../api/Configure';
import SrvGameSelectEdit from '../../srv/SrvGameSelectEdit';
import { StdItemPage } from '../../../part/StdItemPage';
import { ColumnsType } from 'antd/lib/table';
import { formatDate } from '../../../util/toolbox';
import Form, { FormInstance } from 'antd/lib/form';
import { SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/es/table/interface';
import moment from 'moment';
import { AppleOutlined, AndroidOutlined } from '@ant-design/icons';
import XLSX from 'xlsx'
import FileSaver from 'file-saver';

export default class NewPreserverIndex extends StdItemPage<any> {

    private editor: RefObject<SrvGameSelectEdit>;

    private dataArray: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 14, 30];

    private DateTabArray: PreserverData[] = [];
    input: any;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
    }

    state = {
        itemTotal: undefined,
        queryPagination: {
            page: 1,
            pageSize: 10
        },
        itemList: [],
        queryFilter: {},
        spinLoad: false,
        spinLoadExcel: false,
        srvCode: '',
        dimension: 'ip',
        srvGroupId: 0,
        gameId: 0,
        channelCode: '',
        beginTime: '',
        day: 0,
        tags: [Configure.Gl(1576, '过往查询:')],
        inputVisible: false,
        inputValue: ''
    }


    getCols(): ColumnsType<any> {
        return [
            {
                title: "游戏服",
                width: 200,
                dataIndex: 'GameSvrName',
            },
            {
                title: "当日导入",
                dataIndex: 'query_time',
                width: 100,
            },
            {
                title: "当日-留存",
                dataIndex: 'Day_pre_0',
                width: 100,
            },
            {
                title: "次日-留存",
                dataIndex: 'Day_pre_1',
                width: 100,
            },
            {
                title: "2日-留存",
                dataIndex: 'Day_pre_2',
                width: 100,
            },
            {
                title: "3日-留存",
                dataIndex: 'Day_pre_3',
                width: 100,
            },
            {
                title: "4日-留存",
                dataIndex: 'Day_pre_4',
                width: 100,
            },
            {
                title: "5日-留存",
                dataIndex: 'Day_pre_5',
                width: 100,
            },
            {
                title: "6日-留存",
                dataIndex: 'Day_pre_6',
                width: 100,
            },
            {
                title: "7日-留存",
                dataIndex: 'Day_pre_7',
                width: 100,
            },
            {
                title: "14日-留存",
                dataIndex: 'Day_pre_14',
                width: 100,
            },
            {
                title: "30日-留存",
                dataIndex: 'Day_pre_30',
                width: 100,
            },
        ];
    }

    formRef = React.createRef<FormInstance>();
    render() {
        const { itemTotal, queryPagination } = this.state;
        const columns: ColumnsType<any> = this.getCols();
        const { TabPane } = Tabs;
        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            defaultPageSize: queryPagination.pageSize,
            total: itemTotal
        };
        const { tags, inputVisible, inputValue } = this.state;
        const tagChild = tags.map(this.forMap);
        return (
            <HomeLayout path={RoutePath.STAT_NEW_PRESERVER_INDEX}>
                <Spin spinning={this.state.spinLoadExcel} tip={Configure.Gl(1894, '导出数据中...')}>
                    <Form
                        ref={this.formRef}
                        layout="vertical"
                        name="basic"
                        onFinish={this.onFormFinish}
                        onFinishFailed={this.onFormFinishFailed}
                        initialValues={{
                            beginTime: moment().startOf('day'),
                        }}
                    >
                        <Form.Item>
                            <Form.Item style={{ float: 'left' }}>
                                <SrvGameSelectEdit ref={this.editor} selectType={"radio"} handleOk={(v: any[]) => this.handleOk(v)} ></SrvGameSelectEdit>
                            </Form.Item>
                            <Form.Item name="srvCode" style={{ float: 'left', width: '12%' }}>
                                <Input placeholder={Configure.Gl(1198, "选择游戏服务器(srvCode)")} />
                            </Form.Item>
                            <Form.Item name="srvGroupId" style={{ float: 'left', width: '20%' }}>
                                <Input placeholder={Configure.Gl(1775, "请填写服务器组ID(srvGroupId)")} />
                            </Form.Item>
                            <Form.Item name="gameId" style={{ float: 'left', width: '15%' }}>
                                <Input placeholder={Configure.Gl(1776, "请填写游戏ID(gameId)")} />
                            </Form.Item>
                            <Form.Item name="channelCode" style={{ float: 'left', width: '15%' }}>
                                <Input placeholder={Configure.Gl(1777, "请填写channelCode")} />
                            </Form.Item>
                            <Form.Item name="beginTime" style={{ float: 'left' }}>
                                <DatePicker showToday />
                            </Form.Item>
                            <Form.Item style={{ float: 'left' }}>
                                <Button type="primary" htmlType="submit">{Configure.Gl(1152, '查找')}</Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" onClick={this.onExcelprot}>{Configure.Gl(1577, "导出EXCEL")}</Button>
                            </Form.Item>

                        </Form.Item>
                    </Form>

                    <Divider />
                    <div style={{ marginBottom: 16 }}>{tagChild}</div>
                    {inputVisible && (
                        <Input
                            ref={this.saveInputRef}
                            type="text"
                            size="small"
                            style={{ width: 100 }}
                            value={inputValue}
                        />
                    )}

                    <Divider />
                    <Tabs type="card" onChange={this.changeTabPaneCall}>
                        <TabPane tab={<span><AndroidOutlined />{Configure.Gl(1770, '按Ip')}</span>} key="ip">
                        </TabPane>
                        <TabPane tab={<span><AppleOutlined />{Configure.Gl(1771, '按设备')}</span>} key="device">
                        </TabPane>
                        <TabPane tab={<span><AppleOutlined />{Configure.Gl(1772, '按账户')}</span>} key="account">
                        </TabPane>
                        <TabPane tab={<span><AppleOutlined />{Configure.Gl(1773, '按角色')}</span>} key="role">
                        </TabPane>
                    </Tabs>
                    <Spin spinning={this.state.spinLoad} tip={Configure.Gl(1774, '获取服务器数据中...')}>
                        <Table dataSource={this.state.itemList} columns={columns}
                            pagination={pagination} scroll={{ x: this.getColsWidth(columns), y: 512 }} onChange={(p, f, s, e) => { this.onPageChange(p, f, s, e) }} />
                    </Spin>
                </Spin>
            </HomeLayout>
        );
    }

    saveInputRef = (input: any) => {
        this.input = input;
    }

    forMap = (tag: {} | string | undefined, index: number) => {
        const tagElem = (
            <Tag
                closable={index !== 0}
                onClose={e => {
                    e.preventDefault();
                    this.handleClose(tag);
                }}
                onClick={e => {
                    this.pastqueries(tag);
                }
                }
            >
                {tag}
            </Tag>
        );
        return (
            <span key={tag?.toString()} style={{ display: 'inline-block' }}>
                {tagElem}
            </span>
        );
    }

    handleClose = (removedTag: any) => {
        const tags = this.state.tags.filter((tag) => tag !== removedTag);
        console.log(tags);
        this.setState({ tags });
    }

    handleInputConfirm = (date: string) => {
        let inputValue = date;
        let { tags } = this.state;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }
        console.log(tags);
        this.setState({
            tags,
            inputVisible: false,
            inputValue: ""
        });
    }

    pastqueries = (CheckdateTag: any) => {
        let nowDate: PreserverData = this.queryData(CheckdateTag);
        this.state.beginTime = CheckdateTag;
        this.setState({ beginTime: CheckdateTag });
        if (nowDate.getDimension(this.state.dimension) !== 0) {
            console.log("获得到Datalist:");
            let itemlist = nowDate.getDataList(this.state.dimension);
            this.addKeyToData(itemlist);
            this.setState({ itemList: itemlist });
            this.setState({ spinLoad: false });
        } else {
            this.setState({ itemList: [] });
        }
    }

    onFilterChange(filter: any) {
        this.setState({ filter }, () => {
            this.reload();
        });

    }


    onExcelprot = () => {
        console.log("导出execl");
        //let data = this.queryData(this.state.beginTime);
        let data = this.doArrayToJson(this.state.beginTime);
        const workbook = XLSX.utils.book_new();//创建一个新的工作簿对象

        let excelName: string = data.DatePD;

        for (var attr in data) {
            let row: any = {};
            row.array = data[attr];
            if (row.array.length > 0 && attr !== 'DatePD') {
                let ws = XLSX.utils.json_to_sheet(row.array);//将json对象数组转化成工作表
                XLSX.utils.book_append_sheet(workbook, ws, attr + "Sheet");//把sheet添加到workbook里，第三个参数是sheet名
            }
        }

        // let ws = XLSX.utils.json_to_sheet(this.resData);//将json对象数组转化成工作表
        // XLSX.utils.book_append_sheet(workbook, ws, "sheet1");//把sheet添加到workbook里，第三个参数是sheet名
        //const wopts = { type:'array'};//写入的样式bookType:输出的文件类型，type：输出的数据类型，bookSST: 是否生成Shared String Table，官方解释是，如果开启生成速度会下降
        const wbout = XLSX.write(workbook, { type: 'array' });// 浏览器端和node共有的API,实际上node可以直接使用xlsx.writeFile来写入文件,但是浏览器没有该API
        FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), { excelName } + '留存数据.xlsx');//保存文件
    }

    doArrayToJson(times: string): any {
        for (let i = 0; i < this.DateTabArray.length; i++) {
            if (this.DateTabArray[i].DatePD === times) {
                return this.DateTabArray[i];
            }
        }
        return undefined;
    }

    handleOk(v: any[]) {
        if (v.length > 0) {
            this.formRef.current?.setFieldsValue({ srvCode: v[0].code });
        }
    }

    changeTabPaneCall = (v: any) => {
        this.setState({ dimension: v });
        if (this.state.beginTime === "" || this.state.beginTime === undefined) return;
        let nowDate: PreserverData = this.queryData(this.state.beginTime);
        if (nowDate.getDimension(v) !== 0) {
            console.log("获得到Datalist:");
            let DataList: any[] = nowDate.getDataList(v);
            this.addKeyToData(DataList);
            this.setState({ itemList: DataList });
        } else {
            this.setState({ itemList: [] });
        }
    }


    onFormFinish = (values: any) => {
        // if(!values.srvCode){
        //     alert(Configure.Gl(1199,"请先选择游戏服务器"));
        //     return;
        // }
        this.state.srvCode = values.srvCode;
        let filter = values;
        filter.beginTime = values.beginTime ? formatDate(values.beginTime, "yyyy-MM-dd") : undefined;
        filter.dimension = this.state.dimension;
        filter.day = this.state.day;
        filter.srvGroupId = this.state.srvGroupId;
        filter.gameId = this.state.gameId;
        filter.srvCode = this.state.srvCode;
        filter.channelCode = this.state.channelCode;
        this.state.beginTime = filter.beginTime;
        this.setState({ queryFilter: filter }, () => this.reload());
    }

    onFormFinishFailed = (errorInfo: any) => {
        console.error('onFormFinishFailed:', errorInfo);
    }

    onPageChange(pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        this.setState({
            queryPagination: {
                page: pagination.current,
                pageSize: pagination.pageSize
            }
        }, () => {
            this.reload();
        });
    }

    async reload() {
        const { queryPagination, queryFilter } = this.state;
        const query = { ...queryPagination, ...queryFilter };
        console.log("请求参数为：", query);
        this.setState({ spinLoad: true })

        // for (var i = 0; i < 6; i++) {
        //     (function (t, data) {   // 注意这里是形参
        //         setTimeout(function () {
        //             console.log(`这是第 ${t} 次，这是其他参数：${data}`);
        //         }, 1000 * t);	// 还是每秒执行一次，不是累加的
        //     })(i, '其他参数')   // 注意这里是实参，这里把要用的参数传进去
        // }
        let nowDate: PreserverData = this.queryData(this.state.beginTime);

        if (nowDate.getDimension(this.state.dimension) !== 0) {
            console.log("获得到Datalist:");
            let itemlist = nowDate.getDataList(this.state.dimension);
            this.addKeyToData(itemlist);
            this.setState({ itemList: itemlist });
            this.setState({ spinLoad: false });
            return;
        }

        let DataList: any[] = [];
        let data = await statSrvApi.NewPreserve(this.state.queryFilter, 0) as any;

        for (let j = 0; j < data.length; j++) {
            let AllData: any = {
                srvCode: data[j].srvCode,
                GameSvrName: data[j].srvCode + '-' + data[j].srvName,
                query_time: data[j].regNum,
                Day_pre_0: data[j].loginNum + '-' + ((data[j].loginNum / data[j].regNum) * 100).toFixed(1) + '%', Day_pre_1: '', Day_pre_2: '', Day_pre_3: '', Day_pre_4: '', Day_pre_5: '', Day_pre_6: '', Day_pre_7: '', Day_pre_14: '', Day_pre_30: '',
            };
            DataList.push(AllData);
        }

        let num: number = 1;
        while (num < this.dataArray.length) {
            if (!this.needDelay(500)) continue;
            this.setState({ day: this.dataArray[num] });
            let data = await statSrvApi.NewPreserve(this.state.queryFilter, this.dataArray[num]) as any;
            for (let i = 0; i < data.length; i++) {
                switch (this.state.day) {
                    case 0:
                        DataList[i].Day_pre_0 = data[i].loginNum + '-' + ((data[i].loginNum / data[i].regNum) * 100).toFixed(1) + '%';
                        break;
                    case 1:
                        DataList[i].Day_pre_1 = data[i].loginNum + '-' + ((data[i].loginNum / data[i].regNum) * 100).toFixed(1) + '%';
                        break;
                    case 2:
                        DataList[i].Day_pre_2 = data[i].loginNum + '-' + ((data[i].loginNum / data[i].regNum) * 100).toFixed(1) + '%';
                        break;
                    case 3:
                        DataList[i].Day_pre_3 = data[i].loginNum + '-' + ((data[i].loginNum / data[i].regNum) * 100).toFixed(1) + '%';
                        break;
                    case 4:
                        DataList[i].Day_pre_4 = data[i].loginNum + '-' + ((data[i].loginNum / data[i].regNum) * 100).toFixed(1) + '%';
                        break;
                    case 5:
                        DataList[i].Day_pre_5 = data[i].loginNum + '-' + ((data[i].loginNum / data[i].regNum) * 100).toFixed(1) + '%';
                        break;
                    case 6:
                        DataList[i].Day_pre_6 = data[i].loginNum + '-' + ((data[i].loginNum / data[i].regNum) * 100).toFixed(1) + '%';
                        break;
                    case 7:
                        DataList[i].Day_pre_7 = data[i].loginNum + '-' + ((data[i].loginNum / data[i].regNum) * 100).toFixed(1) + '%';
                        break;
                    case 14:
                        DataList[i].Day_pre_14 = data[i].loginNum + '-' + ((data[i].loginNum / data[i].regNum) * 100).toFixed(1) + '%';
                        break;
                    case 30:
                        DataList[i].Day_pre_30 = data[i].loginNum + '-' + ((data[i].loginNum / data[i].regNum) * 100).toFixed(1) + '%';
                        break;
                }
                DataList[i].key = i;
            }

            console.log(Configure.Gl(1390, "请求到的数据是：") + "第" + this.dataArray[num] + "天", data);
            num++;
        }

        // let data = await statSrvApi.preserve(this.state.queryFilter) as any;
        // console.log(Configure.Gl(1390,"请求到的数据是："), data);

        // for(let i = 0; i < data.length; i++) {
        //    // data[i].name = Configure.getParticipate(data[i].funcId);
        // }
        nowDate.cloneData(this.state.dimension, this.state.beginTime, DataList);
        this.addKeyToData(DataList);
        this.setState({ itemList: DataList });
        this.setState({ spinLoad: false });
    }

    changearr(DataList: any[], data: any): any {
        DataList.forEach(element => {
            if (element.srvCode === data.srvCode) {
                return element;
            }
        });
        return undefined;
    }

    private preCTime: number = 0;    //ms
    public needDelay(delayTime: number = 0): boolean {
        let nowTime: number = Date.now();
        if (nowTime - this.preCTime < delayTime) {
            return true;
        }
        this.preCTime = nowTime;
        return false;
    }

    queryData(times: string): PreserverData {
        for (let i = 0; i < this.DateTabArray.length; i++) {
            if (this.DateTabArray[i].DatePD === times) {
                return this.DateTabArray[i];
            }
        }
        let data: PreserverData = new PreserverData();
        this.DateTabArray.push(data);
        this.handleInputConfirm(times)
        return data;
    }

}

export class PreserverData {
    public DatePD: string = '';//日期
    public AccountDataList: any[] = [];
    public IpDataList: any[] = [];
    public DeviceDataList: any[] = [];
    public roleDataList: any[] = [];

    public cloneData(dimension: string, _time: string, DataList: any[]) {
        this.DatePD = _time;
        switch (dimension) {
            case 'device':
                this.copylist(this.DeviceDataList, DataList);
                break;
            case 'ip':
                this.copylist(this.IpDataList, DataList);
                break;
            case 'role':
                this.copylist(this.roleDataList, DataList);
                break;
            case 'account':
                this.copylist(this.AccountDataList, DataList);
                break;
        }
    }

    private copylist(target: any[], orgData: any[]) {
        for (let j = 0; j < orgData.length; j++) {
            let AllData: any = {
                srvCode: orgData[j].srvCode,
                GameSvrName: orgData[j].GameSvrName,
                query_time: orgData[j].query_time,
                Day_pre_0: orgData[j].Day_pre_0,
                Day_pre_1: orgData[j].Day_pre_1,
                Day_pre_2: orgData[j].Day_pre_2,
                Day_pre_3: orgData[j].Day_pre_3,
                Day_pre_4: orgData[j].Day_pre_4,
                Day_pre_5: orgData[j].Day_pre_5,
                Day_pre_6: orgData[j].Day_pre_6,
                Day_pre_7: orgData[j].Day_pre_7,
                Day_pre_14: orgData[j].Day_pre_14,
                Day_pre_30: orgData[j].Day_pre_30,
            };
            target.push(AllData);
        }
    }

    public getDataList(dimension: string): any[] {
        switch (dimension) {
            case 'device':
                return this.DeviceDataList;
            case 'ip':
                return this.IpDataList;
            case 'role':
                return this.roleDataList;
            case 'account':
                return this.AccountDataList;
            default:
                return this.IpDataList;
        }
    }

    public getDimension(dimension: string): number {
        let length: number = 0;
        switch (dimension) {
            case 'device':
                length = this.DeviceDataList.length;
                break;
            case 'ip':
                length = this.IpDataList.length;
                break;
            case 'role':
                length = this.roleDataList.length;
                break;
            case 'account':
                length = this.AccountDataList.length;
                break;
        }
        return length;
    }

}