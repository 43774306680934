import React from 'react';
import { Form, Input, Button, Switch } from 'antd';
import { StdItemEdit, IProps, IState } from '../../../../part/StdItemEdit';
import { srvGameExtApi } from '../../../../api/console/srv.game.ext';
import Configure from '../../../../api/Configure';

export default class SrvGameExtEdit extends StdItemEdit<IProps, IState> {

    state = {
        ...super.buildInitState(),
    }

    renderForm() {
        const { createMode, item } = this.state;
        console.log("拿到的item=", item);
        return (
            <Form
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="basic"
                initialValues={item}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}
            >
                <Form.Item label={Configure.Gl(1055, "ID")} name="id">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={Configure.Gl(1079, '游戏服编码')} name="code">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1761, '删档返代币启用')} name="payRefundEnabled" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1762, '专10礼包码特性')} name="z10GiftCode" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1763, '微信屏蔽词接入')} name="wxForbiddenWord" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1792, '菜菜版做任务抽奖红包')} name="raffleTask" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1867, "龙图gameid")} name="longtuGameId">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1065, "备注")} name="note">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit"> {createMode ? Configure.Gl(1387, "保存新增") : Configure.Gl(1388, "保存编辑")} </Button>
                </Form.Item>
            </Form>
        );
    }


    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        //if(true) return;
        const { createMode } = this.state;
        if (createMode) {
            srvGameExtApi.create(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            });
        } else {
            srvGameExtApi.update(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            })
        }
    }

}