import React, { RefObject } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Space, Divider, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/Table';

import { RoutePath } from '../../../../route/path';
import { IPagingState, StdItemPageLimit } from '../../../../part/StdItemPageLimit';
import { srvGameExtApi } from '../../../../api/console/srv.game.ext';
import SrvGameExtEdit from './SrvGameExtEdit';
import SrvGameExtFilter from './SrvGameExtFilter';
import Configure from '../../../../api/Configure';

interface IState extends IPagingState {
    xxx: string;
}

export default class SrvGameExtIndex extends StdItemPageLimit<IState> {

    private editor: RefObject<SrvGameExtEdit>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
    }

    state = {
        ...this.getDefaultState(),
        xxx: 'hey'
    };

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1055, 'ID'),
                dataIndex: 'id',
                width: 80,
                fixed: 'left',
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: Configure.Gl(1079, '游戏服编码'),
                dataIndex: 'code',
                width: 150,
            },
            {
                title: Configure.Gl(1761, '启用删档返代币'),
                width: 150,
                dataIndex: 'payRefundEnabled',
                render: (v) => v ? '√' : '×'
            },
            {
                title: Configure.Gl(1762, '专10礼包码特性'),
                width: 150,
                dataIndex: 'z10GiftCode',
                render: (v) => v ? '√' : '×'
            },
            {
                title: Configure.Gl(1763, '微信屏蔽词接入'),
                width: 150,
                dataIndex: 'wxForbiddenWord',
                render: (v) => v ? '√' : '×'
            },
            {
                title: Configure.Gl(1792, '菜菜版做任务抽奖红包'),
                width: 150,
                dataIndex: 'raffleTask',
                render: (v) => v ? '√' : '×'
            },
            {
                title: Configure.Gl(1867, "龙图gameid"),
                width: 100,
                dataIndex: 'longtuGameId',
            },
            {
                title: Configure.Gl(1065, "备注"),
                width: 200,
                dataIndex: 'note',
            },
            {
                title: Configure.Gl(1057, '操作'),
                width: 200,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117, "编辑")}</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133, '删除')}</Button>
                    </Space>);
                },
            },
        ];
    }

    getFilterUI() {
        return (<>
            <Space>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389, '新增')}</Button>
                <SrvGameExtFilter search={(f: any) => this.onFilterChange(f)}></SrvGameExtFilter>
            </Space>
            <Divider />
        </>
        );
    }

    getEditorUI() {
        return (<SrvGameExtEdit ref={this.editor} reload={() => this.reload()} ></SrvGameExtEdit>);
    }

    getPath() { return RoutePath.SRV_GAME_EXT_INDEX; }

    async reloadForData(query: any) {
        let data = await srvGameExtApi.list(query) as any[];
        console.log(Configure.Gl(1390, "请求到的数据是："), data);
        return data;
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066, '警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1391, '你确定要删除') + ' (' + item.id + ') ' + item.code + Configure.Gl(1436, ' 的扩展配置？'),
            onOk() {
                srvGameExtApi.delete(item.id).then(d => that.reload());
            }
        });
    }

}