import React from 'react';
import { Button, Table, Form, Divider, Input, Select, DatePicker, Spin, message } from 'antd';
import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import { StdItemPage } from '../../../part/StdItemPage';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';
import { formatDate } from '../../../util/toolbox';
import { Key, TableCurrentDataSource } from 'antd/lib/table/interface';
import { SorterResult } from 'antd/es/table/interface';
import { excelApi } from '../../../api/excel/excel';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import { statSrvApi } from '../../../api/stat/all';
import { srvGroupApi } from '../../../api/console/srv.group';
import Configure from '../../../api/Configure';
import XLSX from 'xlsx'
import FileSaver from 'file-saver';


export default class GmOrderQueryIndex extends StdItemPage<any> {

    state = {
        itemTotal: undefined,
        queryPagination: {
            page: 1,
            pageSize: 100
        },
        itemList: [],
        payItemOpts: [] as any[],
        groupOpts: [] as any[],
        queryFilter: {
            payTimeBegin: new Date(new Date().toLocaleDateString()).getTime(),
            payTimeEnd: new Date().getTime(),
        },
        spinLoad: false,
        sumPayAmount: 0,
        payItemId: 0,
        maxItem: 0,
    }

    private SearchAgain: boolean = true;
    private passtime: number = 0;

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1295, 'uid'),
                dataIndex: 'uid',
                width: 300,
            },
            {
                title: Configure.Gl(1231, '订单类型'),
                dataIndex: 'orderType',
                width: 80,
            },
            {
                title: Configure.Gl(1232, '支付时间'),
                dataIndex: 'payTime',
                width: 170,
                render: (v, r) => formatDate(r.payTime),
            },
            {
                title: Configure.Gl(1233, '平台订单号'),
                dataIndex: 'platformOrderId',
                width: 300,
            },
            {
                title: Configure.Gl(1234, '服务器'),
                dataIndex: 'srvCode',
                width: 100,
            },
            {
                title: Configure.Gl(1264, '主角姓名'),
                dataIndex: 'masterName',
                width: 120,
            },
            {
                title: Configure.Gl(1101, '主角唯一ID'),
                dataIndex: 'masterIdHex',
                width: 260,
            },
            {
                title: Configure.Gl(1235, '账号uid'),
                dataIndex: 'accountUid',
                width: 300,
            },
            {
                title: Configure.Gl(1236, '平台支付状态'),
                dataIndex: 'platformPayStatus',
                width: 120,
            },
            {
                title: Configure.Gl(1237, '支付项模板ID'),
                dataIndex: 'itemTid',
                width: 120,
            },
            {
                title: Configure.Gl(1238, '支付项购买数量'),
                dataIndex: 'itemCount',
                width: 120,
            },
            {
                title: Configure.Gl(1239, '支付项名称'),
                dataIndex: 'itemName',
                width: 120,
            },
            {
                title: Configure.Gl(1928, '支付项名称(中文)'),
                dataIndex: 'itemNameCn',
                width: 120,
            },
            {
                title: Configure.Gl(1240, '付款总金额(元)'),
                dataIndex: 'payAmount',
                width: 130,
            },
            {
                title: Configure.Gl(1122, '平台编码'),
                width: 100,
                dataIndex: 'platformCode',
            },
            {
                title: Configure.Gl(1099, '渠道编码'),
                width: 100,
                dataIndex: 'channelCode',
            },
            {
                title: Configure.Gl(1241, '平台openId'),
                width: 260,
                dataIndex: 'platformOpenId',
            },
            {
                title: Configure.Gl(1182, '创建时间'),
                width: 170,
                dataIndex: 'createTime',
                render: (v, r) => formatDate(r.createTime),
            },
            {
                title: Configure.Gl(1242, '步骤'),
                width: 80,
                dataIndex: 'step',
            },
            {
                title: Configure.Gl(1243, '重试次数'),
                width: 80,
                dataIndex: 'retryTimes',
            },
            {
                title: Configure.Gl(1927, "原始订单号"),
                width: 180,
                dataIndex: 'rawOrderId',
            },
            {
                title: Configure.Gl(1244, 'note'),
                width: 160,
                dataIndex: 'note',
            },
        ];
    }

    formRef = React.createRef<FormInstance>();
    render() {
        const { itemTotal, queryPagination, payItemOpts, groupOpts } = this.state;
        const columns: ColumnsType<any> = this.getCols();
        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            current: queryPagination.page,
            defaultPageSize: queryPagination.pageSize,
            total: itemTotal
        };
        const payItemOptUi: any[] = [];
        payItemOpts.forEach(opt => {
            payItemOptUi.push(<Select.Option value={opt.text} key={opt.tid} item={opt.tid} name={opt.name} rmb={opt.rmb}>{opt.text}</Select.Option>);
        })
        const groupOptUi: any[] = [];
        groupOpts.forEach(opt => {
            groupOptUi.push(<Select.Option value={opt.value} key={opt.value}>{opt.label}</Select.Option>);
        });
        return (
            <HomeLayout path={RoutePath.GM_ORDER_QUERY_INDEX}>
                <Spin spinning={this.state.spinLoad} tip={Configure.Gl(1894, '导出数据中...')}>
                    <Form
                        ref={this.formRef}
                        layout="vertical"
                        name="basic"
                        onFinish={this.onFormFinish}
                        onFinishFailed={this.onFormFinishFailed}
                        initialValues={{
                            payTimeBegin: moment().startOf('day'),
                            payTimeEnd: moment(),
                        }}
                    >
                        <Form.Item>
                            <Form.Item name="srvCode" style={{ float: 'left', width: '16%' }}>
                                <Input placeholder={Configure.Gl(1121, "服务器编码")} />
                            </Form.Item>
                            <Form.Item name="masterName" style={{ float: 'left', width: '16%' }}>
                                <Input placeholder={Configure.Gl(1264, "主角姓名")} />
                            </Form.Item>
                            <Form.Item name="masterIdHex" style={{ float: 'left', width: '32%' }}>
                                <Input placeholder={Configure.Gl(1101, "主角唯一ID")} />
                            </Form.Item>
                            <Form.Item name="platformCode" style={{ float: 'left', width: '16%' }}>
                                <Select placeholder={Configure.Gl(1245, "所属平台")}>
                                    <Select.Option value="develop">{Configure.Gl(1246, '研发平台')}</Select.Option>
                                    <Select.Option value="yezixi">{Configure.Gl(1247, '叶子戏')}</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="channelCode" style={{ float: 'left', width: '16%' }}>
                                <Input placeholder={Configure.Gl(1099, "渠道编码")} />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name="platformOpenId" style={{ float: 'left', width: '32%' }}>
                                <Input placeholder={Configure.Gl(1241, "平台openid")} />
                            </Form.Item>
                            <Form.Item name="platformOrderId" style={{ float: 'left', width: '32%' }}>
                                <Input placeholder={Configure.Gl(1233, "平台订单号")} />
                            </Form.Item>
                            <Form.Item name="accountUid" style={{ float: 'left', width: '32%' }}>
                                <Input placeholder={Configure.Gl(1235, "账号uid")} />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name="payTimeBegin" style={{ float: 'left', width: '12%' }}>
                                <DatePicker showTime placeholder={Configure.Gl(1119, "开始时间")} />
                            </Form.Item>
                            <Form.Item name="payTimeEnd" style={{ float: 'left', width: '20%' }}>
                                <DatePicker showTime placeholder={Configure.Gl(1120, "结束时间")} />
                            </Form.Item>
                            <Form.Item name="groupId" style={{ float: 'left', width: '32%' }}>
                                <Select placeholder={Configure.Gl(1083, "服务器组")}>
                                    {groupOptUi}
                                </Select>
                            </Form.Item>
                            <Form.Item name="rawOrderId" style={{ float: 'left', width: '32%' }} >
                                <Input placeholder={Configure.Gl(1927, "原始订单号")} />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name="payOpt" style={{ float: 'left', width: '48%' }}>
                                <Select showSearch placeholder={Configure.Gl(1248, "支付项")} onChange={(e, option) => this.handleChange(e, option)} >
                                    {payItemOptUi}
                                </Select>
                            </Form.Item>
                            <Form.Item name="orderType" style={{ float: 'left', width: '16%' }}>
                                <Select placeholder={Configure.Gl(1231, "订单类型")} >
                                    <Select.Option value="1">{Configure.Gl(1249, '正常订单')}</Select.Option>
                                    <Select.Option value="2">{Configure.Gl(1250, '补单')}</Select.Option>
                                    <Select.Option value="3">{Configure.Gl(1251, '内部订单')}</Select.Option>
                                    <Select.Option value="4">{Configure.Gl(1893, '游戏外订单')}</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="step" style={{ float: 'left', width: '16%' }}>
                                <Select placeholder={Configure.Gl(1253, "支付阶段")}>
                                    <Select.Option value="1">{Configure.Gl(1254, "发起支付")}</Select.Option>
                                    <Select.Option value="2">{Configure.Gl(1255, "回调处理中")}</Select.Option>
                                    <Select.Option value="3">{Configure.Gl(1256, "支付完成")}</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item style={{ float: 'left' }}>
                                <Button type="primary" htmlType="submit">{Configure.Gl(1257, "搜索")}</Button>
                            </Form.Item>
                            <Form.Item style={{ float: 'left' }}>
                                <Button type="default" onClick={() => { this.clear() }}>{Configure.Gl(1153, '清空')}</Button>
                            </Form.Item>
                        </Form.Item>
                    </Form>
                    <Divider />
                    <Input size="large" value={Configure.Gl(1258, "付款金额总计: ") + this.state.sumPayAmount.toFixed(2) + Configure.Gl(1259, "元")} readOnly bordered={false} />
                    <Table dataSource={this.state.itemList} columns={columns}
                        pagination={pagination} scroll={{ x: this.getColsWidth(columns), y: 512 }} onChange={(p, f, s, e) => { this.onPageChange(p, f, s, e) }} />
                    <Divider />
                    <Form.Item style={{ float: 'right' }}>
                        <Button type="primary" onClick={this.onExcelprot}>{Configure.Gl(1577, "导出EXCEL")}</Button>
                    </Form.Item>
                </Spin>
            </HomeLayout>
        );
    }

    clear() {
        this.formRef.current?.setFieldsValue(
            {
                payTimeBegin: moment().startOf('day'),
                payTimeEnd: moment(),
                srvCode: undefined,
                masterName: undefined,
                masterIdHex: undefined,
                platformCode: undefined,
                channelCode: undefined,
                platformOpenId: undefined,
                platformOrderId: undefined,
                accountUid: undefined,
                groupId: undefined,
                payOpt: undefined,
                orderType: undefined,
                step: undefined,
                rawOrderId: undefined,
            });
        this.setState({ payItemId: 0 });
    }

    onFormFinish = (values: any) => {
        let filter = values;
        filter.payTimeBegin = values.payTimeBegin ? (values.payTimeBegin as moment.Moment).valueOf() : undefined;
        filter.payTimeEnd = values.payTimeEnd ? (values.payTimeEnd as moment.Moment).valueOf() : undefined;
        const { payItemId } = this.state;
        filter.itemTid = payItemId === 0 ? undefined : payItemId;
        this.SearchAgain = true;
        this.setState({ queryFilter: filter }, () => this.reload());
    }

    onFormFinishFailed = (errorInfo: any) => {
        console.error('onFormFinishFailed:', errorInfo);
    }

    onExcelprot = async () => {
        console.log("导出execl");
        if (this.state.itemList.length <= 0) {
            console.error('当前未查询!');
            return;
        }

        let count1: number = Math.ceil(this.state.itemTotal as unknown as number / this.state.queryPagination.pageSize);
        let itemList: any[] = [];
        let time: number = count1 * this.passtime;
        let strTime: string = Configure.Gl(1933, "秒");
        if (time > 60 && time < 3600) {
            strTime = Configure.Gl(1935, "分");
            time = Math.ceil(time / 60);
        } else if (time >= 3600) {
            strTime = Configure.Gl(1936, "小时");
            time = Math.ceil(time / 3600);
        }
        message.success(Configure.Gl(1932, "当前预估需要:") + time + strTime + Configure.Gl(1934, ",请耐心等待!"), 8);

        this.setState({ spinLoad: true });

        let count: number = Math.ceil(this.state.maxItem / this.state.queryPagination.pageSize);
        for (let i = 1; i <= count; i++) {
            this.state.queryPagination.page = i;
            const { queryPagination, queryFilter } = this.state;
            const query = { ...queryPagination, ...queryFilter };
            let data = await statSrvApi.pay_list(query) as any;
            itemList = itemList.concat(data.records);
        }


        this.setState({ spinLoad: false });
        let data: any = this.doArrayToJson(itemList);
        const workbook = XLSX.utils.book_new();//创建一个新的工作簿对象

        let excelName: string = "订单查询";

        let ws = XLSX.utils.json_to_sheet(data);//将json对象数组转化成工作表
        XLSX.utils.book_append_sheet(workbook, ws, "sheet1");//把sheet添加到workbook里，第三个参数是sheet名
        //const wopts = {type:'array'};//写入的样式bookType:输出的文件类型，type：输出的数据类型，bookSST: 是否生成Shared String Table，官方解释是，如果开启生成速度会下降
        const wbout = XLSX.write(workbook, { type: 'array' });// 浏览器端和node共有的API,实际上node可以直接使用xlsx.writeFile来写入文件,但是浏览器没有该API
        FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), excelName + '.xlsx');//保存文件
    }

    doArrayToJson(itemList: any): any {
        //     const columns: ColumnsType<any> = this.getCols();
        //   itemList.forEach((element: {"": any; }) => {
        //     console.error();
        //   });
        return itemList;
    }

    // async inputAllitem() {
    //     let itemList: [] = [];
    //     let count: number = Math.ceil(this.state.maxItem / this.state.queryPagination.pageSize);
    //     for (let i = 1; i < count; i++) {
    //         this.state.queryPagination.page = i;
    //         const { queryPagination, queryFilter } = this.state;
    //         const query = { ...queryPagination, ...queryFilter };
    //         let data = await statSrvApi.pay_list(query) as any;
    //         itemList = data.concat(data.records);
    //     }
    // }

    handleChange(e: any, option: any) {
        this.setState({ payItemId: option.item });
        this.formRef.current?.setFieldsValue({ rawOrderId: undefined })
    }

    onPageChange(pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        this.setState({
            queryPagination: {
                page: pagination.current,
                pageSize: pagination.pageSize
            }
        }, () => {
            this.reload();
        });
    }

    componentDidMount() {
        this.reload();
    }

    async reload() {
        if (this.SearchAgain) {
            this.state.queryPagination.page = 1;
        }



        const { queryPagination, queryFilter } = this.state;
        const query = { ...queryPagination, ...queryFilter };
        console.log("请求参数为：", query);

        message.success('当前查询时间范围:' + formatDate(query.payTimeBegin) + "-" + formatDate(query.payTimeEnd), 5);

        let dt: Date = new Date();
        let startTime: number = dt.getTime();
        let data = await statSrvApi.pay_list(query) as any;
        console.log(Configure.Gl(1390, "请求到的数据是："), data);
        let sumPay: number = this.state.sumPayAmount;
        if (this.SearchAgain) {
            sumPay = data.sumPayAmount;
            this.SearchAgain = false;
        }

        let dt1: Date = new Date();
        let endTime: number = dt1.getTime();
        this.passtime = Math.ceil((endTime - startTime) / 1000);
        console.log('当前每页执行时间:' + this.passtime);

        this.setState({ itemlist: [] });
        this.addKeyToData(data.records);
        this.setState({ itemList: data.records, itemTotal: data.total, sumPayAmount: sumPay, maxItem: data.total });
        this.loadPayItems();
        this.loadGroupItems();

    }

    async loadGroupItems() {
        srvGroupApi.optionList().then(data => {
            this.setState({ groupOpts: data });
        });
    }

    async loadPayItems() {
        excelApi.pay_item().then(data => {
            (data as any[]).forEach(element => {
                element.text =
                    "" + element.id + " " + element.nameCn + " ¥" + element.rmb + " " + element.name + " $" + element.priceUSD;
            });
            this.setState({ payItemOpts: data });
        });
    }
}