import React from 'react';
import { Button, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Configure from '../../api/Configure';
import { GmcEnv } from '../../util/env';

export default class TimeInfoIndex extends React.Component {

    state = {
        show: false,
    }

    render() {
        const { show } = this.state;
        return (<span>
            <Button type="primary" icon={<SearchOutlined />} onClick={() => this.doShow()}>
            {Configure.Gl(Configure.GL_UNDEFINED,'时间地区信息')}
            </Button>
            <Modal title={Configure.Gl(Configure.GL_UNDEFINED,'时间地区信息')} visible={show} width="90%"
                onOk={() => this.handleModalOk()} onCancel={() => this.handleModalCancel()} footer={null} destroyOnClose>

                <div className="server-info-title"><span role="img" aria-hidden>👔</span>&nbsp;<b>Intl.DateTimeFormat().resolvedOptions().timeZone</b></div>
                <div>{Intl.DateTimeFormat().resolvedOptions().timeZone}</div>
                
                <br/>
                <div className="server-info-title"><span role="img" aria-hidden>🌺</span>&nbsp;<b>new Date().getTimezoneOffset()</b></div>
                <div>{new Date().getTimezoneOffset()}</div>
                
                <br/>
                <div className="server-info-title"><span role="img" aria-hidden>🍌</span>&nbsp;<b>'UTC+' + (0 - new Date().getTimezoneOffset() / 60)</b></div>
                <div>{'UTC+' + (0 - new Date().getTimezoneOffset() / 60)}</div>

                <br/>
                <div className="server-info-title"><span role="img" aria-hidden>🦸‍♀️</span>&nbsp;<b>GmcEnv.getInstance().timezone</b></div>
                <div>{GmcEnv.getInstance().timezone}</div>
                
                <br/>
                <div className="server-info-title"><span role="img" aria-hidden>🚅</span>&nbsp;<b>GmcEnv.getInstance().locale</b></div>
                <div>{GmcEnv.getInstance().locale}</div>

                <br/>
                <div className="server-info-title"><span role="img" aria-hidden>🥭</span>&nbsp;<b>Configure.LocalWord</b></div>
                <div>{Configure.LocalWord}</div>
                
            </Modal>
        </span>
        );
    }

    componentDidMount() {
        console.log("TimeInfoIndex::componentDidMount()");
    }

    doShow = async () => {
        this.setState({ show: true });
    }

    handleModalOk() {
    }

    handleModalCancel() {
        this.setState({ show: false });
    }

}