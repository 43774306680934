import { consoleApi } from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

interface WalletQueryCmd {
    srvCode: string;
    roleId: string;
}

interface WalletReduceCmd {
    /** 游戏服编码 */
    srvCode: string;
    /** 角色唯一ID */
    roleId: string;
    /** 指令模式 */
    cmdMode: string;
    /** 钱包类型 */
    numType: number;
    /** 扣减数量 */
    num: number;
    /** 备注 */
    note?: string;
}

/** 指令模式：通过唯一ID扣除 */
export const BAG_REDUCE_CMD_MODE_UID:string  = "uid";

class WalletApi extends ApiBase {

    walletQuery(query: WalletQueryCmd) {
        return consoleApi.get({
            path: 'role/wallet/query?',
            param: query,
            title: Configure.Gl(1780, "角色钱包查询"),
            quiet: true
        })
    }

    walletReduce(query: WalletReduceCmd) {
        return consoleApi.get({
            path: 'role/wallet/reduce?',
            param: query,
            title: Configure.Gl(1781, "角色钱包扣减"),
            quiet: false
        })
    }
}

export const walletApi: WalletApi = new WalletApi();