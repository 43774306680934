import React from 'react';
import { Form, Input, Button, DatePicker } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { StdItemEdit, IProps, IState } from '../../../part/StdItemEdit';
import ClipBoard from '../../../util/clipboard';
import { gmFreezeApi } from '../../../api/console/gm.freeze';
import Configure from '../../../api/Configure';

export default class GmFreezeEdit extends StdItemEdit<IProps, IState> {

    formRef = React.createRef<FormInstance>();

    state = {
        ...super.buildInitState(),
    }

    renderForm() {
        const { item } = this.state;
        console.log("拿到的item=", item);
        return (
            <Form 
                ref={this.formRef}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
                name="basic"
                initialValues={item}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}
            >
                <Form.Item label={Configure.Gl(1146,"所属服务器编码")} name="srvCode">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1098,"角色唯一ID")} name="masterIdHex">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1102,"角色名")} name="masterName">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1157,"冻结至")} name="until">
                    <DatePicker showTime />
                </Form.Item>
                <Form.Item label={Configure.Gl(1148,"原因")} name="reason">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit">{Configure.Gl(1158,'立即冻结')}</Button>
                    <Button onClick={()=>{
                        //
                        this.formRef.current?.setFieldsValue({
                            "srvCode": ClipBoard.getSrvCode(),
                            "masterIdHex": ClipBoard.getMasterIdHex(),
                            "masterName": ClipBoard.getMasterName()
                        });
                    }}>{Configure.Gl(1150,'剪切板填充')}</Button>
                </Form.Item>
            </Form>
        );
    }


    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        gmFreezeApi.create({
            master: {
                srvCode: values.srvCode,
                _idHex: values.masterIdHex,
                name: values.masterName,
            },
            until: values.until,
            reason: values.reason
        }).then(d => {
            this.setState({ visible: false });
            this.props.reload();
        });
    }

}