import { payApi, res } from '../../util/http';
import { GmcEnv } from '../../util/env';
import Configure from '../Configure';

class ExcelApi {
    
    pay_item_old() {
        return res(GmcEnv.getInstance().excelJsonUrl, {
            path: '充值项.json?' + Math.random(),
            title: Configure.Gl(1675,'充值项.json'),
            quiet: true
        });
    }

    pay_item() {
        return payApi.get({
            path: 'config/pay/item/list',
            title: Configure.Gl(1676,"支付项列表"),
            quiet: true
        })
    }

    pay_item_opt() {
        return new Promise((resolve, reject) => {
            this.pay_item().then(data => {
                let opts = [] as any;
                (data as any[]).forEach(element => {
                    const t = `${element.id} ${element.name} ${element.rmb}元 ${element.tradeCode}`;
                    opts.push({value: element.id, text: t});
                });
                resolve(opts);
            });
        });
    }
}

export const excelApi: ExcelApi = new ExcelApi();