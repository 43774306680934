import React from 'react';
import { Form, Input, Button, Select, Switch } from 'antd';
import { StdItemEdit, IProps, IState } from '../../../../part/StdItemEdit';
import { srvGameAccessApi } from '../../../../api/console/srv.game.access';
import Configure from '../../../../api/Configure';


export default class SrvGameAccessEdit extends StdItemEdit<IProps, IState> {

    state = {
        ...super.buildInitState(),
    }

    renderForm() {
        const { createMode, item } = this.state;
        console.log("拿到的item=", item);
        return (
            <Form 
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                name="basic"
                initialValues={item}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}
            >
                <Form.Item label={Configure.Gl(1055,"ID")} name="id">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={Configure.Gl(1079,'游戏服编码')} name="srvCode">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1451,"模式")} name="mode">
                    <Select>
                        <Select.Option value="1">{Configure.Gl(1452,'允许')}</Select.Option>
                        <Select.Option value="0">{Configure.Gl(1453,'禁止')}</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label={Configure.Gl(1454,"限定平台")} name="platformLimit" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1122,'平台编码')} name="platformCode">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1455,"限定渠道")} name="channelLimit" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1099,'渠道编码')} name="channelCode">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1456,"限定GameID")} name="gameIdLimit" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1123,'GameID')} name="gameId">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1457,"限定扩展")} name="extLimit" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1458,"扩展码")} name="ext">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit"> {createMode ? Configure.Gl(1387,"保存新增")  : Configure.Gl(1388,"保存编辑")} </Button>
                </Form.Item>
            </Form>
        );
    }


    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        //if(true) return;
        const { createMode } = this.state;
        if (createMode) {
            srvGameAccessApi.create(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            });
        } else {
            srvGameAccessApi.update(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            })
        }
    }

}