import React, { RefObject } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Table, Space, Divider, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/Table';

import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import { srvGameApi } from '../../../api/console/srv.game';
import { srvGameCmdApi } from '../../../api/console/srv.game.cmd';
import { cloudDeivceApi } from '../../../api/console/cloud.device';
import { StdItemPage } from '../../../part/StdItemPage';
import SrvGameEdit from './SrvGameEdit';
import SrvGameFilter from './SrvGameFilter';
import SrvGameCmd from './SrvGameCmd';
import { compareCode } from '../../../util/toolbox';
import { SrvGameEntity } from '../../../def/srv/SrvGameEntity';
import { TableRowSelection } from 'antd/lib/table/interface';
import Configure from '../../../api/Configure';

export default class SrvGameIndex extends StdItemPage<any> {

    private editor: RefObject<SrvGameEdit>;
    private cmd: RefObject<SrvGameCmd>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
        this.cmd = React.createRef();
    }

    state = {
        itemList: [],
        selectItems: [],
        queryFilter: {}
    };

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1055,'ID'),
                dataIndex: 'id',
                width: 80,
                fixed: 'left',
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: Configure.Gl(1056,"编码"),
                dataIndex: 'code',
                width: 100,
                fixed: 'left',
                sorter: (a, b) => compareCode(a.code, b.code)
            },
            {
                title: Configure.Gl(1472,'游戏服名字'),
                dataIndex: 'name',
                width: 120,
                fixed: 'left',
                render: (value: any, record: any) => <Button type="link" onClick={() => this.editor.current?.showUpdate(record)}>{value}</Button>
            },
            {
                title: Configure.Gl(1471,"序号"),
                dataIndex: 'index',
                width: 80,
            },
            {
                title: Configure.Gl(1088,'状态'),
                dataIndex: 'status',
                width: 120,
                render: (value: any) => SrvGameEntity.statusToString(value)
            },
            {
                title: Configure.Gl(1473,"推荐"),
                dataIndex: 'recommend',
                width: 60,
                render: (value: any) => value ? '√' : ''
            },
            {
                title:  Configure.Gl(1485,'服务器组名'),
                dataIndex: 'groupName',
                width: 130,
            },
            {
                title: Configure.Gl(1475,"分区归类"),
                dataIndex: 'folder',
                width: 100,
            },
            {
                title: Configure.Gl(1476,"跨服编号"),
                dataIndex: 'crossCode',
                width: 120,
            },
            {
                title: '配置操作',
                width: 240,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>编辑</Button>
                        <Button onClick={() => this.editor.current?.showCreate(record)}>克隆</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133,'删除')}</Button>
                    </Space>);
                },
            },
            {
                title: '命令操作',
                width: 700,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.doStart(record)}>启动</Button>
                        <Button danger onClick={() => this.doStop(record)}>停止</Button>
                        <Button onClick={() => this.doJvmInfo(record)}>JVM状态</Button>
                        <Button onClick={() => this.doGC(record)}>GC</Button>
                        <Button onClick={() => this.doCT(record)}>CT</Button>
                        <Button onClick={() => this.doDeviceStatus(record, 'ps-java')}>设备ps-java</Button>
                        <Button onClick={() => this.doMergeSettle(record)}>合服结算</Button>
                        <Button onClick={() => this.doCrossReload(record)}>重载跨服组</Button>
                    </Space>);
                },
            },
            {
                title: Configure.Gl(1478,"开服时间"),
                dataIndex: 'openTime',
                width: 170,
                render: this.formatDateTime
            },
            {
                title: Configure.Gl(1479,"合服时间"),
                dataIndex: 'mergeTime',
                width: 170,
                render: this.formatDateTime
            },
            {
                title: Configure.Gl(1082,"被合服吞并"),
                dataIndex: 'eaten',
                width: 120,
                render: (value: any) => value ? Configure.Gl(1495,'被吞并了') : ''
            },
            {
                title: Configure.Gl(1122,'平台编码'),
                width: 100,
                dataIndex: 'platformCode',
            },
            {
                title: Configure.Gl(1481,"支付倍数"),
                width: 60,
                dataIndex: 'payScale',
            },
            {
                title: Configure.Gl(1430,"excel模式"),
                width: 120,
                dataIndex: 'excelMode',
            },
            {
                title: Configure.Gl(1428,'Mongo编码'),
                width: 120,
                dataIndex: 'mongoCode',
            },
            {
                title: Configure.Gl(1477,"Mysql编码"),
                width: 120,
                dataIndex: 'mysqlCode',
            },
            {
                title: Configure.Gl(1482,"关联设备编码"),
                width: 120,
                dataIndex: 'deviceCode',
            },
            {
                title: 'ws scheme',
                width: 100,
                dataIndex: 'wsScheme',
            },
            {
                title: 'ws port',
                width: 80,
                dataIndex: 'wsPort',
            },
            {
                title: Configure.Gl(1483,"JVM启动参数"),
                width: 200,
                dataIndex: 'jvmArgs',
            },
            {
                title: Configure.Gl(1484,"最大玩家数量"),
                width: 100,
                dataIndex: 'playerMax',
            },
            {
                title: Configure.Gl(1790,"游戏版本"),
                width: 100,
                dataIndex: 'gameVer',
            },
            {
                title: Configure.Gl(1791,"vip统计对象"),
                width: 120,
                dataIndex: 'vipStatTar',
            },
            {
                title: Configure.Gl(1065,"备注"),
                width: 360,
                dataIndex: 'note',
            },
        ];
    }

    render() {
        const columns: ColumnsType<any> = this.getCols();
        const rowSelection: TableRowSelection<any> = {
            type: 'checkbox',
            onChange: (keys, rows) =>this.onSelectItemChanged(keys, rows),
        };
        return (
            <HomeLayout path={RoutePath.SRV_GAME_INDEX}>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389,'新增')}</Button>
                    <SrvGameFilter search={(f:any) => this.onFilterChange(f)}></SrvGameFilter>
                </Space>
                <Divider />
                <Table dataSource={this.state.itemList} columns={columns} rowSelection={rowSelection} 
                pagination={this.getPaginationConfig()} scroll={{ x: this.getColsWidth(columns), y: 512 }} />
                <SrvGameEdit ref={this.editor} reload={() => this.reload()} ></SrvGameEdit>
                <SrvGameCmd ref={this.cmd}></SrvGameCmd>
            </HomeLayout>
        );
    }

    componentDidMount() {
        this.reload();
    }

    onFilterChange(filter: any) {
        this.setState({queryFilter: filter}, ()=>{
            this.reload();
        }); 
    }

    async reload() {
        const { queryFilter } = this.state; 
        const query = { ...queryFilter };
        
        let data = await srvGameApi.list(query) as any[];
        this.addKeyToData(data);
        this.setState({ itemList: data });
    }

    onSelectItemChanged(selectedRowKeys: React.Key[], selectedRows: any[]) {
        //console.log("啊啊啊啊啊啊 selectedRowKeys=", selectedRowKeys, " selectedRows=", selectedRows);
        this.setState({selectItems: selectedRows});
        this.cmd.current?.handleSrvSelected(selectedRows);
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1391,'你确定要删除') + item.code + ' (' + item.name + ')?',
            onOk() {
                srvGameApi.delete(item.id).then(d => that.reload());
            }
        });
    }

    doStart(item: any) {
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1486,'你确定要启动 ') + item.code + ' (' + item.name + ') '+ Configure.Gl(1489,'吗? ')+Configure.Gl(1487,'启动之前请先关闭服务器，否则会产生数据异常！'),
            onOk() { srvGameCmdApi.start(item.code) }
        });
    }

    doStop(item: any) {
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1488,'你确定要停止 ') + item.code + ' (' + item.name + ')'+Configure.Gl(1489,'吗? ') ,
            onOk() { srvGameCmdApi.stop(item.code) }
        });
    }

    doJvmInfo(item: any) {
        srvGameApi.jvmInfo(item.code).then(d => this.doAlert(Configure.Gl(1490,'JVM 信息'), d));
    }

    doGC(item: any) {
        srvGameApi.gc(item.code);
    }

    doCT(item: any) {
        srvGameApi.ct(item.code).then(d => this.doAlert('CT', d));
    }

    doDeviceStatus(item: any, cmd: string) {
        cloudDeivceApi.status(item.deviceCode, cmd).then(d => {
            let str = d as string;
            str = str.replace(/[\r\n]/g,"<br/>");
            //str = str.replace(/[\r\n]/g,"<br/>");
            //str = str.replaceAll('/r', '<br/>');
            //str = str.replaceAll('/n', '<br/>');
            console.log("str=", str);
            this.doAlert('status'+cmd, str);
        });
    }

    doMergeSettle(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1437,'你确定要对 ')  + item.code + ' (' + item.name + ') '+Configure.Gl(1491,"进行合服结算?"),
            onOk() {
                srvGameCmdApi.mergeSettle(item.code).then(d => that.doAlert(Configure.Gl(1492,"结算汇报"), d));;
            }
        });
    }

    doCrossReload(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1493,'你确定要重载 ') + item.code + ' (' + item.name + ') '+Configure.Gl(1494,'の跨服组?'),
            onOk() {
                srvGameCmdApi.crossReload(item.code).then(d => that.reload());
            }
        });
    }

}