import React, { RefObject } from 'react';
import { Button, Divider, Form, Input, InputNumber, message, Spin } from 'antd';
import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import SrvGameSelectEdit from '../../srv/SrvGameSelectEdit';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import ClipBoard from '../../../util/clipboard';
import { RouteComponentProps } from 'react-router';
import { BAG_REDUCE_CMD_MODE_UID, walletApi } from '../../../api/console/wallet';
import { FormInstance } from 'antd/lib/form';
import { SearchOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import Configure from '../../../api/Configure';
import { StdItemPage } from '../../../part/StdItemPage';

export default class RoleWalletIndex extends StdItemPage<any> {

    private editor: RefObject<SrvGameSelectEdit>;
    private onlyID: RefObject<Input>;
    private serverID: RefObject<Input>;
    state = {
        demo: "wallet",
        srvCode: "",
        roleId: "",
        num: 0,
        spinLoad: false,
        itemTotal: undefined,
        itemList: [],
        note: ""
    }

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
        this.onlyID = React.createRef();
        this.serverID = React.createRef();
    }

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1837, "钱包类型"),
                width: 200,
                dataIndex: 'note',
            },
            {
                title: "Type",
                width: 40,
                dataIndex: 'numType',
            },
            {
                title: Configure.Gl(1829, "数量"),
                width: 200,
                dataIndex: 'num',
            },
            {
                title: Configure.Gl(1830, '删除操作'),
                dataIndex: 'operation',
                render: (value, record, index) =>
                    <>
                        <InputNumber min={0} max={record.num} defaultValue={0} onChange={this.setDefValue} onBlur={this.onBlur} />
                        <Button onClick={(v: any) => this.doReduce(record, value, index)}>删除</Button>
                    </>,
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
            },
        ];
    }

    //设置默value值
    setDefValue = (e: any) => {
        if (e > 0) {
            this.state.num = e;
        } else {
            message.error(Configure.Gl(1828, "数值不能未负数!"));
        }
    }

    onBlur = (e: any) => {
        this.state.num = 0;
        if (e.target.defaultValue > 0) {
            this.state.num = e.target.defaultValue;
        }
    }

    formRef = React.createRef<FormInstance>();
    render() {
        const { note, itemTotal } = this.state;
        const columns: ColumnsType<any> = this.getCols();
        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            defaultPageSize: 100,
            total: itemTotal
        };
        return (<HomeLayout path={RoutePath.ROLE_WALLET_INDEX}>
            <Spin spinning={this.state.spinLoad} tip={Configure.Gl(1774, '获取服务器数据中...')}>
                <Form
                    ref={this.formRef}
                    style={{ float: 'right' }}
                    layout="vertical"
                    name="basic"
                >
                    <Button type="primary" shape="round" icon={<SearchOutlined />} onClick={this.doQuery}>{Configure.Gl(1831, '查询wallet')}</Button>
                </Form>

                <Form.Item style={{ float: 'left' }}>
                    <SrvGameSelectEdit ref={this.editor} selectType={"radio"} handleOk={(v: any[]) => this.handleOk(v)} ></SrvGameSelectEdit>
                </Form.Item>
                <Form.Item name="srvCode" style={{ float: 'left', width: '12%' }}>
                    <Input ref={this.serverID} placeholder={Configure.Gl(1198, "选择游戏服务器(srvCode)")} />
                </Form.Item>
                <Form.Item name="roleId" style={{ float: 'left', width: '20%' }}>
                    <Input ref={this.onlyID} placeholder={Configure.Gl(1832, '角色roleID')} />
                </Form.Item>
                <Button onClick={this.doShearPlate}>{Configure.Gl(1833, '读取剪切板')}</Button>
                <Divider />
                <TextArea
                    value={note}
                    onChange={e => this.onControlled(e.target.value)}
                    placeholder={Configure.Gl(1825, '描述信息...')}
                    showCount maxLength={200}
                />
                <Divider />

                <Table dataSource={this.state.itemList} columns={columns}
                    pagination={pagination} scroll={{ x: this.getColsWidth(columns), y: 512 }} />
            </Spin>

        </HomeLayout>);
    }


    onControlled = (value: any) => {
        this.setState({ note: value });
    };

    handleOk(v: any[]) {
        if (v.length > 0) {
            this.formRef.current?.setFieldsValue({ srvCode: v[0].code });
            this.serverID.current?.setValue(v[0].code);
        }
    }

    doQuery = async () => {

        let _srv: string = this.serverID.current?.state.value;
        let _roleId: string = this.onlyID.current?.state.value;

        if (_srv === '' || _srv === undefined) {
            message.error(Configure.Gl(1834, '服务器不能为空'));
            return;
        }
        if (_roleId === '' || _roleId === undefined) {
            message.error(Configure.Gl(1835, 'roleId不能为空'));
            return;
        }
        this.setState({ spinLoad: true });
        this.setState({ itemList: [] });
        let data = await walletApi.walletQuery({
            srvCode: _srv,
            roleId: _roleId,
        }) as any;
        //let rolearr:{} = data.role;
        let overlist: [] = this.doRestructured(data);
        this.setState({ itemList: overlist });
        console.log("角色钱包查询结果：", data);
        this.setState({ spinLoad: false });
    }

    doRestructured(data: any): any {
        var datalist: any = [];
        for (var attr in data) {
            let row: any = this.setTypetoName(attr);
            row.num = data[attr];
            if (row.note !== '') {
                datalist.push(row);
            }
        }
        return datalist;
    }

    setTypetoName(name: string): any {
        var row = {
            numType: 0,
            num: 0,
            note: ''
        };
        switch (name) {
            case 'm0':
                row.note = Configure.Gl(1611, "金币");
                row.numType = 0;
                break;
            case 'm1':
                row.note = Configure.Gl(1612, '元宝');
                row.numType = 1;
                break;
            case 'friendship':
                row.note = Configure.Gl(1613, '友情点');
                row.numType = 5;
                break;
            case 'heroAsh':
                row.note = Configure.Gl(1614, '魂精');
                row.numType = 6;
                break;
            case 'crystal':
                row.note = Configure.Gl(1615, '先祖结晶');
                row.numType = 7;
                break;
            case 'leaf':
                row.note = Configure.Gl(1616, '槐荫叶');
                row.numType = 8;
                break;
            case 'p0':
                row.note = Configure.Gl(1617, '乾坤币');
                row.numType = 10;
                break;
            case 'p1':
                row.note = Configure.Gl(1836, '演武场声望');
                row.numType = 11;
                break;
            case 'p2':
                row.note = Configure.Gl(1619, '仙盟贡献');
                row.numType = 12;
                break;
            case 'p3':
                row.note = Configure.Gl(1620, '寻宝积分');
                row.numType = 13;
                break;
            case 'p4':
                row.note = Configure.Gl(1621, '荣誉');
                row.numType = 14;
                break;
            case 'ladderScore':
                row.note = Configure.Gl(1622, '跨服天梯赛积分');
                row.numType = 18;
                break;
            case 'crossPvpFeats':
                row.note = Configure.Gl(1623, '跨服竞技场功勋');
                row.numType = 19;
                break;
            case 'crossGradeScore':
                row.note = Configure.Gl(1624, '跨服段位赛积分');
                row.numType = 20;
                break;
            case 'heroExp':
                row.note = Configure.Gl(1110, "英雄经验");
                row.numType = 4;
                break;
            case 'vipPoint':
                row.note = Configure.Gl(1626, "vip积分");
                row.numType = 16;
                break;
        }
        return row;
    }

    doShearPlate = () => {
        let srvcode: string = ClipBoard.getSrvCode();
        let masteridhex: string = ClipBoard.getMasterIdHex();
        if (srvcode === '' || masteridhex === '') {
            message.error(Configure.Gl(1838, '服务器ID或角色唯一ID为空'))
            return;
        }
        this.onlyID.current?.setValue(masteridhex);
        this.serverID.current?.setValue(srvcode);
    }

    //删除
    doReduce = async (record: any, value: any, index: number) => {

        this.setState({ spinLoad: true });
        if (this.state.note === "") {
            this.state.note = '删除物品';
        }

        if (this.state.num <= 0) {
            message.error(Configure.Gl(1839, '删除数量必须大于0'));
            this.setState({ spinLoad: false });
            return;
        }

        let _srv: string = this.serverID.current?.state.value;
        let _roleId: string = this.onlyID.current?.state.value;
        let _deleteNum: number = this.state.num;
        let type: number = record.numType;
        let data = await walletApi.walletReduce({
            cmdMode: BAG_REDUCE_CMD_MODE_UID,
            srvCode: _srv,
            roleId: _roleId,
            numType: type,
            num: _deleteNum
        }) as any;

        if (data.logs !== "" || data.logs !== undefined) {
            this.doQuery();
        }

    }

    componentDidMount() {
        console.log("RoleWalletIndex::componentDidMount()");
    }

}
