import React from 'react';
import { Button, Input, Space } from 'antd';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Configure from '../../../api/Configure';

export interface IProps {
    search: Function
}

export default class GmFreezeFilter extends React.Component<IProps, any> {

    state = {
        masterIdHex: undefined as string | undefined,
        srvCode: undefined as string | undefined,
        masterName: undefined as string | undefined,
    };

    private filter = {
        masterIdHex: undefined as string | undefined,
        srvCode: undefined as string | undefined,
        masterName: undefined as string | undefined,
        clear() {
            this.masterIdHex = undefined;
            this.srvCode = undefined;
            this.masterName = undefined;
        }
    }

    setMasterIdHex(v: string) {
        this.filter.masterIdHex = v;
        this.setState({ masterIdHex: v });
    }

    setSrvCode(v: string) {
        this.filter.srvCode = v;
        this.setState({ srvCode: v });
    }

    setMasterName(v: string) {
        this.filter.masterName = v;
        this.setState({ masterName: v });
    }

    render() {
        return (
            <Space>
                <Input placeholder={Configure.Gl(1101,"主角唯一ID")} value={this.state.masterIdHex} onChange={e => this.setMasterIdHex(e.target.value)} style={{ width: 168 }} />
                <Input placeholder={Configure.Gl(1079,"游戏服编码")} value={this.state.srvCode} onChange={e => this.setSrvCode(e.target.value)} style={{ width: 120 }} />
                <Input placeholder={Configure.Gl(1151,"主角名字")} value={this.state.masterName} onChange={e => this.setMasterName(e.target.value)} style={{ width: 130 }} />
                <Button icon={<SearchOutlined />} onClick={this.doSearch}>{Configure.Gl(1152,'查找')}</Button>
                <Button type="dashed" icon={<CloseCircleOutlined />} onClick={this.doClear}>{Configure.Gl(1153,'清空')}</Button>
            </Space>
        );
    }

    doSearch = () => {
        this.props.search({...this.filter, clear: null});
    }

    doClear = () => {
        this.setState({ masterIdHex: null, srvCode: null, masterName: null });
        this.filter.clear();
        this.props.search();
    }
}