import React, { RefObject } from 'react';
import { Tabs, Button, Tag } from 'antd';
import { AppleOutlined, AndroidOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { srvGroupCmdApi } from '../../api/console/srv.group.cmd';
import Configure from '../../api/Configure';
 
const { TabPane } = Tabs;

export default class SrvGroupConfCmd extends React.Component {

    private batchStartStop: RefObject<BatchStartStop> = React.createRef();

    render() {
        
        return (
            <Tabs type="card">
                <TabPane tab={<span><AndroidOutlined />{Configure.Gl(1405,'批量开关服')}</span>} key="1">
                    <BatchStartStop ref={this.batchStartStop}></BatchStartStop>
                </TabPane>
                <TabPane tab={<span><AppleOutlined />{Configure.Gl(1406,'配置核验与纠正')}</span>} key="2">
                    <DataCheckAll></DataCheckAll>
                </TabPane>
            </Tabs>
        );
    }

    handleItemSelected(itemSelected:any[]) {
        this.batchStartStop.current?.setState({itemSelected});
    }

}


class BatchStartStop extends React.Component {

    state = {
        itemSelected: []
    }

    render() {
        const { itemSelected } = this.state;
        let selectedUi: any[] = [];
        itemSelected.forEach((item:any) => {
            selectedUi.push(<Tag color="green" key={item.id}>{"("+item.code+") "+ item.note}</Tag>);
        })
        return (<>
            <Button icon={<DoubleRightOutlined />} onClick={()=>this.doBatchStart()}>{Configure.Gl(1408,'启动下列所选')}</Button>
            <Button icon={<DoubleRightOutlined />} onClick={()=>this.doBatchStop()}>{Configure.Gl(1409,'停止下列所选')}</Button>
            <hr/>
            {selectedUi}
        </>);
    }

    doBatchStart() {
        if(window.confirm(Configure.Gl(1410,"确实要 【启动】如下游戏服组程序吗, 操作不可撤销？"))) {
            if(window.confirm(Configure.Gl(1411,"慎重了吗？"))) {
                const { itemSelected } = this.state;
                let groupCodeList: string[] = [];
                itemSelected.forEach((srv:any) => {
                    groupCodeList.push(srv.code);
                });
                srvGroupCmdApi.startBatch(groupCodeList).then(d => {
                    alert(Configure.Gl(1412,"控制台后台程序已接收到命令，当前正在运行中...请手动检查执行状况！确保该操作有效且安全，请勿重复操作！" )+ d);
                });
            }
        }
    }

    doBatchStop() {
        if(window.confirm(Configure.Gl(1413,"确实要 【停止】如下游戏服组程序吗, 操作不可撤销？"))) {
            if(window.confirm(Configure.Gl(1411,"慎重了吗？"))) {
                const { itemSelected } = this.state;
                let groupCodeList: string[] = [];
                itemSelected.forEach((srv:any) => {
                    groupCodeList.push(srv.code);
                });
                srvGroupCmdApi.stopBatch(groupCodeList).then(d => {
                    alert(Configure.Gl(1412,"控制台后台程序已接收到命令，当前正在运行中...请手动检查执行状况！确保该操作有效且安全，请勿重复操作！") + d);
                });
            }
        }
    }
}

class DataCheckAll extends React.Component {

    render() {
        return (<>
            <Button icon={<DoubleRightOutlined />} onClick={()=>this.doCheckAll()}>{Configure.Gl(1415,'执行该操作（不可逆）')}</Button>
        </>);
    }

    doCheckAll() {
        if(window.confirm(Configure.Gl(1407,"确实要执行 配置核验与纠正, 操作不可撤销？"))) {
            srvGroupCmdApi.checkAll();
        }
    }
}
