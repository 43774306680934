import React from 'react';
import { Form, Input, Button, Select } from 'antd';
import { StdItemEdit, IProps, IState } from '../../part/StdItemEdit';
import { srvGroupConfApi } from '../../api/console/srv.group.conf';
import Configure from '../../api/Configure';


export default class SrvGroupConfEdit extends StdItemEdit<IProps, IState> {

    state = {
        ...super.buildInitState(),
    }

    renderForm() {
        const { createMode, item } = this.state;
        console.log("拿到的item=", item);
        return (
            <Form 
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                name="basic"
                initialValues={item}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}
            >
                <Form.Item label={Configure.Gl(1055,"ID")} name="id">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={Configure.Gl(1056,"编码")} name="code">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1425,"服务的URL")} name="url">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1426,"web端口")} name="port">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1069,"设备编码")} name="deviceCode">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1427,"数据库名")} name="dbName">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1428,"mongo编码")} name="mongoCode">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1429,"mq编码")} name="mqCode">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1430,"excel模式")} name="excelMode">
                    <Select>
                        <Select.Option value="release">{Configure.Gl(1439,'首发版(release)')}</Select.Option>
                        <Select.Option value="debug">{Configure.Gl(1440,'调试版(debug)')}</Select.Option>
                        <Select.Option value="test">{Configure.Gl(1441,'测试版(test)')}</Select.Option>
                        <Select.Option value="wx">{Configure.Gl(1442,'微信小程序版(wx)')}</Select.Option>
                        <Select.Option value="gat">{Configure.Gl(1443,'港澳台版(gat)')}</Select.Option>
                        <Select.Option value="vi">{Configure.Gl(1444,'越南版(vi)')}</Select.Option>
                        <Select.Option value="韩国">{Configure.Gl(1445,'韩国')}</Select.Option>
                        <Select.Option value="日本">{Configure.Gl(1904, '日本')}</Select.Option>
                        <Select.Option value="东南亚">{Configure.Gl(1905, '东南亚')}</Select.Option>
                        <Select.Option value="欧美">{Configure.Gl(Configure.GL_UNDEFINED,'欧美')}</Select.Option>
                        <Select.Option value="test1">{Configure.Gl(1446,'内部测试1(test1)')}</Select.Option>
                        <Select.Option value="test2">{Configure.Gl(1447,'内部测试2(test2)')}</Select.Option>
                        <Select.Option value="test3">{Configure.Gl(1448,'内部测试3(test3)')}</Select.Option>
                        <Select.Option value="test4">{Configure.Gl(1449,'内部测试4(test4)')}</Select.Option>
                        <Select.Option value="">{Configure.Gl(1450,'空')}</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label={Configure.Gl(1431,'JVM参数')} name="jvmArgs">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1065,"备注")} name="note">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit"> {createMode ? Configure.Gl(1387,"保存新增")  : Configure.Gl(1388,"保存编辑")} </Button>
                </Form.Item>
            </Form>
        );
    }


    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        //if(true) return;
        const { createMode } = this.state;
        if (createMode) {
            srvGroupConfApi.create(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            });
        } else {
            srvGroupConfApi.update(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            })
        }
    }

}