import { consoleApi } from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

class LabApi extends ApiBase {

    createDemo(item: any) {
        return consoleApi.post({
            path: 'lab/demo/create',
            param: item,
            title: Configure.Gl(1116,"新建")
        });
    }

    updateDemo(item: any) {
        return consoleApi.post({
            path: 'lab/demo/update',
            param: item,
            title: Configure.Gl(1132,'修改')
        });
    }

    deleteDemo(id: any) {
        return consoleApi.get({
            path: 'lab/demo/delete?id=' + id,
            title: Configure.Gl(1133,'删除'),
            quiet: true
        });
    }

    listDemo(filter?: any) {
        return consoleApi.get({
            path: 'lab/demo/list',
            title: Configure.Gl(1704,'列表'),
            param: filter,
            quiet: true
        })
    }
}

export const labApi: LabApi = new LabApi();