import React, { RefObject } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Table, Space, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';

import { StdItemPage } from '../../../part/StdItemPage';
import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import { gmFreezeApi } from '../../../api/console/gm.freeze';
import GmFreezeEdit from './GmFreezeEdit';
import GmFreezeFilter from './GmFreezeFilter';
import { formatDate } from '../../../util/toolbox';
import { Key, TableCurrentDataSource } from 'antd/lib/table/interface';
import { SorterResult } from 'antd/es/table/interface';
import Configure from '../../../api/Configure';

export default class GmFreezeIndex extends StdItemPage<any> {

    private editor: RefObject<GmFreezeEdit>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
    }

    state = {
        showEdit: false,
        itemList: [],
        itemTotal: undefined,
        queryPagination: {
            page: 1,
            pageSize: 10
        },
        queryFilter: {}
    };

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1079,'游戏服编码'),
                dataIndex: 'master.srvCode',
                width: 120,
                fixed: 'left',
                render: (v, r) => r.master.srvCode
            },
            {
                title: Configure.Gl(1151,'主角名'),
                dataIndex: 'master.name',
                width: 120,
                fixed: 'left',
                render: (v, r) => r.master.name
            },
            {
                title: Configure.Gl(1101,'主角唯一ID'),
                dataIndex: 'master._idHex',
                width: 240,
                render: (v, r) => r.master._idHex
            },
            {
                title: Configure.Gl(1159,'主角mid'),
                dataIndex: 'master.mid',
                width: 120,
                render: (v, r) => r.master.mid
            },
            {
                title: Configure.Gl(1157,'冻结至'),
                dataIndex: 'until',
                width: 170,
                render: (v, r) => formatDate(v)
            },
            {
                title: Configure.Gl(1148,'原因'),
                dataIndex: 'reason',
                width: 200,
            },
            {
                title: Configure.Gl(1154,'最新执行人'),
                dataIndex: 'el.username',
                width: 180,
                render: (v,r) => r.el.nickname + '(' + r.el.username + ')'
            },
            {
                title: Configure.Gl(1155,'执行时间'),
                dataIndex: 'el.time',
                width: 170,
                render: (v, r) => formatDate(r.el.time)
            },
            {
                title: Configure.Gl(1156,'执行结果'),
                dataIndex: 'el.errormsg',
                width: 280,
                render: (v, r) => r.el.errormsg
            }
        ];
    }

    render() {
        const { itemTotal, queryPagination } = this.state;

        const columns: ColumnsType<any> = this.getCols();

        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            defaultPageSize: queryPagination.pageSize,
            total: itemTotal
        };

        return (
            <HomeLayout path={RoutePath.GM_FREEZE_INDEX}>
                <Space>
                    <Button danger icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1753,'新增冻结')}</Button>
                    <GmFreezeFilter search={(f:any) => this.onFilterChange(f)}></GmFreezeFilter>
                </Space>
                <Divider />
                <Table dataSource={this.state.itemList} columns={columns} pagination={pagination}
                    scroll={{ x: this.getColsWidth(columns), y: 650 }} onChange={(p, f, s, e) => { this.onPageChange(p, f, s, e) }} />
                <GmFreezeEdit ref={this.editor} reload={() => this.reload()} ></GmFreezeEdit>
            </HomeLayout>
        );
    }

    componentDidMount() {
        console.log("GmFreezeIndex::componentDidMount");
        this.reload();
    }

    onFilterChange(filter: any) {
        console.log("沙雕 filter=", filter);
        this.setState({queryFilter: filter}, ()=>{
            this.reload();
        }); 
    }

    onPageChange(pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        console.log("沙雕 onPageChange pagination=", pagination, ", filters=", filters, ", sorter=", sorter, ", extra=", extra);
        this.setState({queryPagination: {
            page: pagination.current,
            pageSize: pagination.pageSize
        }}, ()=>{
            this.reload();
        });
    }

    async reload() {
        const { queryPagination, queryFilter } = this.state; 
        const query = { ...queryPagination, ...queryFilter };
        let data = await gmFreezeApi.list(query) as any;
        console.log(Configure.Gl(1390,"请求到的数据是："), data);
        this.addKeyToData(data.records);
        this.setState({ itemList: data.records, itemTotal: data.total });
    }

}