import { statApi } from '../../util/http';
import Configure from '../Configure';

class StatResApi {

    roleKeyNumList(filter: any) {
        return statApi.get({
            path: 'res/role/key/num/list',
            title: Configure.Gl(1680,"基于角色的关键数量统计"),
            quiet: true,
            param: filter
        });
    }

    heroLineupSummary(filter: any) {
        return statApi.get({
            path: 'res/hero/lineup/summary',
            title: Configure.Gl(1045,"英雄阵容统计"),
            quiet: true,
            param: filter
        });
    }

    /** 一键清理24小时前的英雄阵容数据 */
    heroLineupClear() {
        return statApi.get({
            path: 'res/hero/lineup/clear',
            title: Configure.Gl(1785,"英雄阵容清理"),
            quiet: false,
        });
    }

}

export const statResApi: StatResApi = new StatResApi();