import { ApiBase } from '../api';
import {worldApi} from '../../util/http';
import {consoleApi} from '../../util/http';
import Configure from '../Configure';

class GiftcodeApi extends ApiBase{
    listSource(filter: any){
        return worldApi.get({
            path: 'gift/code/source/list',
            title: Configure.Gl(1685,'礼包码列表'),
            param: filter,
            quiet: true
        });
    }

    list() {
        return worldApi.get({
            path: 'gift/code/channel/list',
            title: Configure.Gl(1686,'礼包码平台列表'),
            quiet: true
        });
    }

    add(item: any){
        return worldApi.post({
            path: 'gift/code/channel/add',
            param: item,
            title: Configure.Gl(1687,'添加礼包码平台')
        });
    }

    delete(item: any){
        return worldApi.post({
            path: 'gift/code/channel/delete',
            param: item,
            title: Configure.Gl(1688,'删除礼包码平台')
        });
    }

    update(item: any){
        return worldApi.post({
            path: 'gift/code/channel/update',
            param: item,
            title: Configure.Gl(1689,'更新礼包码平台')
        });
    }

    build(item: any){
        return worldApi.post({
            path: 'gift/code/build',
            param: item,
            title: Configure.Gl(1690,'生成礼包码')
        });
    }

    translate(item: any) {
        return consoleApi.get({
            path: 'srv/game/translate',
            title: Configure.Gl(1691,"翻译奖励"),
            quiet: true,
            param: item
        });
    }
}

export const giftcodeApi: GiftcodeApi = new GiftcodeApi();