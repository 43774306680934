import { consoleApi } from '../../util/http';
import Configure from '../Configure';
import { platformAppApi } from './platform.app';

class StaffRoleApi {

    create(item: any) {
        return consoleApi.post({
            path: 'staff/role/create',
            param: item,
            title: Configure.Gl(1116,"新建")
        });
    }

    update(item: any) {
        return consoleApi.post({
            path: 'staff/role/update',
            param: item,
            title: Configure.Gl(1132,'修改')
        });
    }

    delete(id: number) {
        return consoleApi.get({
            path: 'staff/role/delete?id=' + id,
            title: Configure.Gl(1133,'删除'),
            quiet: true
        });
    }

    list() {
        return consoleApi.get({
            path: 'staff/role/list',
            title: Configure.Gl(1134,"列出"),
            quiet: true
        });
    }

    async optionList() {
        let data = await this.list() as any[];
        let opts:any[] = [{label: "无角色/全角色", value: -1}];
        data.forEach(element => {
            opts.push({
                label: "("+ element.id + ") " + element.name,
                value: element.id
            });
        });
        return opts;
    }

    // srv group

    createSrvGroup(item: any) {
        return consoleApi.post({
            path: 'staff/role/srv/group/create',
            param: item,
            title: Configure.Gl(1116,"新建")
        });
    }

    updateSrvGroup(item: any) {
        return consoleApi.post({
            path: 'staff/role/srv/group/update',
            param: item,
            title: Configure.Gl(1132,'修改')
        });
    }

    deleteSrvGroup(id: number) {
        return consoleApi.get({
            path: 'staff/role/srv/group/delete?id=' + id,
            title: Configure.Gl(1133,'删除'),
            quiet: true
        });
    }

    listSrvGroup(filter: any) {
        if(!filter) {
            filter = {};
        }
        if(filter.roleId < 0) {
            filter.roleId = null;
        }
        if(filter.srvGroupId < 0) {
            filter.srvGroupId = null;
        }
        return consoleApi.get({
            path: 'staff/role/srv/group/list',
            param: filter,
            title: Configure.Gl(1134,"列出"),
            quiet: true
        });
    }

    // permission

    createPermission(item: any) {
        return consoleApi.post({
            path: 'staff/role/permission/create',
            param: item,
            title: Configure.Gl(1116,"新建")
        });
    }

    updatePermission(item: any) {
        return consoleApi.post({
            path: 'staff/role/permission/update',
            param: item,
            title: Configure.Gl(1132,'修改')
        });
    }

    deletePermission(roleId: number) {
        return consoleApi.get({
            path: 'staff/role/permission/delete?roleId=' + roleId,
            title: Configure.Gl(1133,'删除'),
            quiet: true
        });
    }

    listPermission() {
        return consoleApi.get({
            path: 'staff/role/permission/list',
            title: Configure.Gl(1134,"列出"),
            quiet: true
        });
    }

    //game id
    createGameId(item: any) {
        return consoleApi.post({
            path: 'staff/role/game/id/create',
            param: item,
            title: Configure.Gl(1116,"新建")
        });
    }

    updateGameId(item: any) {
        return consoleApi.post({
            path: 'staff/role/game/id/update',
            param: item,
            title: Configure.Gl(1132,'修改')
        });
    }

    deleteGameId(id: number) {
        return consoleApi.get({
            path: 'staff/role/game/id/delete?id=' + id,
            title: Configure.Gl(1133,'删除'),
            quiet: true
        });
    }

    listGameId(filter: any) {
        if(!filter) {
            filter = {};
        }
        if(filter.roleId < 0) {
            filter.roleId = null;
        }
        if(filter.srvGroupId < 0) {
            filter.srvGroupId = null;
        }
        return consoleApi.get({
            path: 'staff/role/game/id/list',
            title: Configure.Gl(1134,"列出"),
            param: filter,
            quiet: true
        });
    }

    async optionGameIdList() {
       // let data = await this.listGameId() as any;
        let data = await platformAppApi.list() as any[];
        let opts:any[] = [{label: "GameID", value: -1}];
        data.forEach((element: { id: string; gameId: string; name: string; }) => {
            opts.push({
                label: "("+ element.name + ") -"+element.gameId,
                value: element.gameId
            });
        });
        return opts;
    }

}

export const staffRoleApi: StaffRoleApi = new StaffRoleApi();