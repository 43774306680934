import React from 'react';

import { RoutePath } from '../../../route/path';
import SuperviseReportFilter from './SuperviseReportFilter';
import { superviseReportApi } from '../../../api/console/supervise.report';
import { IPagingState, StdItemPageLimit } from '../../../part/StdItemPageLimit';
import { ColumnsType } from 'antd/lib/table';
import { Space } from 'antd';
import DateTool from '../../../util/DateTool';
import Configure from '../../../api/Configure';

interface IState extends IPagingState {
    sth: string;
}

export default class SuperviseReportIndex extends StdItemPageLimit<IState> {

    state = {
        ...this.getDefaultState(),
        sth: ''
    };

    getCols(): ColumnsType<any> {
        return [
            { title: Configure.Gl(1079,"游戏服编码"), dataIndex: 'srvCode', fixed: 'left', width: 120 },
            { title: Configure.Gl(1080,"游戏服名"), dataIndex: 'srvName',fixed: 'left' , width: 120 },
            { title: Configure.Gl(1099,'渠道编码'), dataIndex: 'channelCode', width: 120 },
            { title: Configure.Gl(1100,'角色创建时间'), dataIndex: 'roleCreateTime', width: 200, render: DateTool.beautifyIgnMs },
            { title: Configure.Gl(1097,'OPEN-ID'), dataIndex: 'openId', width: 380 },
            { title: Configure.Gl(1101,'主角唯一ID'), dataIndex: 'roleId', width: 240 },
            { title: Configure.Gl(1102,'角色名'), dataIndex: 'roleName', width: 120 },
            { title: Configure.Gl(1103,'角色等级'), dataIndex: 'roleLevel', width: 120 },
            { title: Configure.Gl(1104,'角色vip'), dataIndex: 'roleVip', width: 120 },
            { title: Configure.Gl(1105,'角色战力'), dataIndex: 'rolePower', width: 180 },
            { title: Configure.Gl(1106,'角色估值'), dataIndex: 'assessValue', width: 180, render: (v) => ''+Math.round(v) },
            { title: Configure.Gl(1107,'角色估值变化量'), dataIndex: 'assessValueDelta', width: 180, render: (v) => ''+Math.round(v) },
            { title: Configure.Gl(1108,'金币数量'), dataIndex: 'm0', width: 150 },
            { title: Configure.Gl(1109,'元宝数量'), dataIndex: 'm1', width: 140 },
            { title: Configure.Gl(1110,'英雄经验'), dataIndex: 'heroExp', width: 150 },
            { title: Configure.Gl(1111,'累计充值金额'), dataIndex: 'paySum', width: 180, render: (v) => ''+Math.round(v) },
            { title: Configure.Gl(1112,'累计充值金额变化量'), dataIndex: 'paySumDelta', width: 180, render: (v) => ''+Math.round(v) },
            { title: Configure.Gl(1113,'上报时间'), dataIndex: 'reportTime', width: 200, render: DateTool.beautifyIgnMs },
            { title: Configure.Gl(1114,'上报事件类型'), dataIndex: 'event', width: 120 },
            { title: Configure.Gl(1115,'上报详情'), dataIndex: 'detail', width: 280 },
        ];
    }
    
    getFilterUI() {
        return (<Space>
            <SuperviseReportFilter search={(f: any) => this.onFilterChange(f)}></SuperviseReportFilter>
        </Space>);
    }

    getEditorUI() {
        return null;
    }

    getPath() { return RoutePath.SUPERVISE_REPORT_INDEX; }

    async reloadForData(query: any) {
        let data = await superviseReportApi.list(query) as any;
        return data;
    }
}