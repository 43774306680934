import React from 'react';
import { Form, Input, Button, Modal, DatePicker } from 'antd';
import { cloudDeivceApi } from '../../api/console/cloud.device';
import { formatMoment } from '../../util/toolbox';
import Configure from  '../../api/Configure';

export default class CloudDeviceTimeChanger extends React.Component {

    state = {
        visible: false,
        cmd: {} as any
    }

    show = (item?: any) => {
        this.setState({
            visible: true,
            cmd: {
                code: item.code,
                name: item.name,
            }
        });
    }

    render() {
        const { visible } = this.state;
        return (
            <Modal title={Configure.Gl(1068,"修改服务器设备时间")} visible={visible} 
                onOk={this.handleModalOk} onCancel={this.handleModalCancel} footer={null} destroyOnClose>
                {this.renderForm()}
            </Modal>
        );
    }

    handleModalOk = () => {
        this.setState({ visible: false });
    }

    handleModalCancel = () => {
        this.setState({ visible: false });
    }

    renderForm() {
        const { cmd } = this.state;
        console.log("拿到的cmd=", cmd);
        return (
            <Form
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="basic"
                initialValues={cmd}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}
            >
                <Form.Item label={Configure.Gl(1069,"设备编码")} name="code">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={Configure.Gl(1070,"设备名")} name="name">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1071,"时间")} name="time">
                    <DatePicker showTime/>
                </Form.Item>
                <Form.Item label={Configure.Gl(1072,"操作备注")} name="note">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit">{Configure.Gl(1135,'修改时间')}</Button>
                </Form.Item>
            </Form>
        );
    }


    onFormFinish = (values: any) => {
        let t = formatMoment(values.time as moment.Moment);
        console.log("修改服务器时间指令=", values.code, t);
        cloudDeivceApi.setTime(values.code, t);
        this.setState({ visible: false });
    }

    onFormFinishFailed = (errorInfo: any) => {
        console.error('onFormFinishFailed:', errorInfo);
    }

}