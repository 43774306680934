import React, { RefObject } from 'react';
import { Button, Space, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/Table';

import { RoutePath } from '../../route/path';
import { srvGroupConfApi } from '../../api/console/srv.group.conf';
import { srvGroupCmdApi } from '../../api/console/srv.group.cmd';
import SrvGroupConfEdit from './SrvGroupConfEdit';
import SrvGroupConfFilter from './SrvGroupConfFilter';
import SrvGroupConfCmd from './SrvGroupConfCmd';
import { compareCode } from '../../util/toolbox';
import { TableRowSelection } from 'antd/lib/table/interface';
import { IPagingState, StdItemPageLimit } from '../../part/StdItemPageLimit';
import Configure from '../../api/Configure';
import { cloudDeivceApi } from '../../api/console/cloud.device';

interface IState extends IPagingState {
    selectItems: any;
    failsMsg: any;
}

export default class SrvGroupConfIndex extends StdItemPageLimit<IState> {

    private editor: RefObject<SrvGroupConfEdit> = React.createRef();

    private cmd: RefObject<SrvGroupConfCmd> = React.createRef();

    state = {
        ...this.getDefaultState(),
        selectItems: [],
        failsMsg: undefined,
    };

    getCols(): ColumnsType<any> {
        return [
            { title: Configure.Gl(1055,'ID'), dataIndex: 'id', width: 80, fixed: 'left', sorter: (a, b) => a.id - b.id, },
            { title: Configure.Gl(1056,"编码"), dataIndex: 'code', width: 120, fixed: 'left', sorter: (a, b) => compareCode(a.code, b.code) },
            {
                title: Configure.Gl(1057,'操作'),
                width: 360,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.startItem(record)}>启动</Button>
                        <Button danger onClick={() => this.stopItem(record)}>停止</Button>
                        <Button onClick={() => this.doCT(record)}>CT</Button>
                        <Button onClick={() => this.doDeviceStatus(record, 'ps-java')}>设备ps-java</Button>
                    </Space>);
                },
            },
            { title: Configure.Gl(1425,'服务的URL'), dataIndex: 'url', width: 260, },
            { title: Configure.Gl(1426,'web端口'), dataIndex: 'port', width: 120, },
            { title: Configure.Gl(1069,"设备编码"), dataIndex: 'deviceCode', width: 120, },
            { title: Configure.Gl(1427,"数据库名"), dataIndex: 'dbName', width: 120, },
            { title: Configure.Gl(1428,'mongo编码'), dataIndex: 'mongoCode', width: 120, },
            { title: Configure.Gl(1429,'mq编码'), dataIndex: 'mqCode', width: 120, },
            { title: Configure.Gl(1430,'Excel模式'), dataIndex: 'excelMode', width: 120, },
            { title: Configure.Gl(1431,'JVM参数'), dataIndex: 'jvmArgs', width: 200, },
            {
                title: Configure.Gl(1057,'操作'),
                width: 260,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117,"编辑")}</Button>
                        <Button onClick={() => this.editor.current?.showCreate(record)}>克隆</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133,'删除')}</Button>
                    </Space>);
                },
            },
            { title: Configure.Gl(1065,"备注"), dataIndex: 'note', width: 220 },
        ];
    }

    doCT(item: any) {
        srvGroupCmdApi.ct(item.code).then(d => this.doAlert('CT', d));
    }

    doDeviceStatus(item: any, cmd: string) {
        cloudDeivceApi.status(item.deviceCode, cmd).then(d => {
            let str = d as string;
            str = str.replace(/[\r\n]/g,"<br/>");
            console.log("str=", str);
            this.doAlert('status'+cmd, str);
        });
    }

    getFilterUI() {
        return (<>
        <Space>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389,'新增')}</Button>
            <SrvGroupConfFilter search={(f:any) => this.onFilterChange(f)}></SrvGroupConfFilter>            
        </Space>
        </>
        );
    }

    getEditorUI() {
        return (<SrvGroupConfEdit ref={this.editor} reload={() => this.reload()} ></SrvGroupConfEdit>);
    }

    getTableRowSelection():TableRowSelection<any> {
        return {
            type: 'checkbox',
            onChange: (keys, rows) =>this.onSelectItemChanged(keys, rows),
        };
    }

    getBottomUI() {
        return (<SrvGroupConfCmd ref={this.cmd}></SrvGroupConfCmd>);
    }

    getPath() { return RoutePath.SRV_GROUP_CONF_INDEX; }

    async reload() {
        const query = this.getQueryParam();
        let data: any = await this.reloadForData(query);
        this.setState({ itemList: data.records, itemTotal: data.total});
    }

    async reloadForData(query: any) {
        let data = await srvGroupConfApi.list(query) as any;
        console.log(Configure.Gl(1390,"请求到的数据是："), data);
        this.addKeyToData(data.records);
        this.onSelectItemChanged([], []);
        return data;
    }

    onSelectItemChanged(selectedRowKeys: React.Key[], selectedRows: any[]) {
        console.log("选项变更：", selectedRows);
        this.setState({selectItems: selectedRows});
        this.cmd.current?.handleItemSelected(selectedRows);
    }

    startItem(item: any) {
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content:  Configure.Gl(1434,'你确定要 启动 ') + item.code + ' ('+item.note+')？',
            onOk() {
                srvGroupCmdApi.start(item.code);
            }
        });
    }

    stopItem(item: any) {
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1435,'你确定要 停止 ') + item.code + ' ('+item.note+')？',
            onOk() {
                srvGroupCmdApi.stop(item.code);
            }
        });
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1391,'你确定要删除') + item.code + ' ('+item.note+')？',
            onOk() {
                srvGroupConfApi.delete(item.id).then(d => that.reload());
            }
        });
    }

}