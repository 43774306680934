import React from 'react';
import { GoContext } from './CCTestData';

export class CCTestComp extends React.Component {
    render() {
        return (
            <GoContext.Consumer>
                {info => (
                    <div>
                        <button style={{ background: info.color }} onClick={() => {
                            console.log("内部按钮点击了 info = ", info);
                            info.changeValue('white', 222);
                        }}>
                            现在读取到的num={info.num} -- 点我的话，会变成白色222
                        {this.props.children}
                        </button>
                        <SubComp></SubComp>
                    </div>
                )}
            </GoContext.Consumer>
        );
    }
}

class SubComp extends React.Component {
    render() {
        return (
            <GoContext.Consumer>
                {info => (
                    <div style={{ background: info.color }}>
                    <h4 onClick={
                        ()=>{
                            info.changeValue('red', 333);
                        }
                    }>我是子组件num={info.num}，点击我会变红，数字变成333</h4>
                    </div>
                )}
            </GoContext.Consumer>
        );
    }
}