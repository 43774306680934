declare global {
    interface Document {
        env_local_mode: string,
        env_local_title: string,
        env_local: any,
    }
}


export class GmcEnv {

    static teamGJK: GmcEnv;
    static teamZX: GmcEnv;
    static local: GmcEnv;
    static dev: GmcEnv;
    static release: GmcEnv;
    static demo: GmcEnv;
    static iosExam: GmcEnv;
    static wxappExam: GmcEnv;
    static wxapp: GmcEnv;
    static gatDemo: GmcEnv;
    static gatExam: GmcEnv;
    static gat: GmcEnv;
    static kor:GmcEnv;

    static getInstance() {
        let env: GmcEnv = new GmcEnv({});
        console.log("环境配置 env_local_mode=", document.env_local_mode);
        console.log("环境配置 env_local=", document.env_local);
        try {
            switch(document.env_local_mode) {
                case 'teamGJK': env = GmcEnv.teamGJK; break;
                case 'teamZX': env = GmcEnv.teamZX; break;
                case 'local': env = GmcEnv.local; break;
                case 'dev': env = GmcEnv.dev; break;
                case 'release': env = GmcEnv.release; break;
                case 'demo': env = GmcEnv.demo; break;
                case 'iosExam': env = GmcEnv.iosExam; break;
                case 'wxappExam': env = GmcEnv.wxappExam; break;
                case 'wxapp': env = GmcEnv.wxapp; break;
                case 'gatDemo': env = GmcEnv.gatDemo; break;
                case 'gatExam': env = GmcEnv.gatExam; break;
                case 'gat': env = GmcEnv.gat; break;
                case 'kor': env = GmcEnv.kor; break;
                default: env = GmcEnv.local; break;
            }
        } catch(e) {
            console.error("获取环境配置异常", e);
        }
        if(document.env_local) {
            if(document.env_local.locale) { env.locale = document.env_local.locale; }
            if(document.env_local.timezone) { env.timezone = document.env_local.timezone; }
            if(document.env_local.consoleUrl) { env.consoleUrl = document.env_local.consoleUrl; }
            if(document.env_local.metaUrl) { env.metaUrl = document.env_local.metaUrl; }
            if(document.env_local.statUrl) { env.statUrl = document.env_local.statUrl; }
            if(document.env_local.payUrl) { env.payUrl = document.env_local.payUrl; }
            if(document.env_local.excelJsonUrl) { env.excelJsonUrl = document.env_local.excelJsonUrl; }
            if(document.env_local.worldUrl) { env.worldUrl = document.env_local.worldUrl; }
            if(document.env_local.loginUrl) { env.loginUrl = document.env_local.loginUrl; }
        }
        console.log("最终拿到的配置是:", env);
        return env;
    }

    version: string;
    locale: string;
    timezone: string;
    consoleUrl: string;
    metaUrl: string;
    statUrl: string;
    payUrl: string;
    excelJsonUrl: string;
    worldUrl: string;
    loginUrl: string;

    constructor(params: any) {
        this.version = params['version'];
        this.locale = params['locale'] ? params['locale'] : 'zh-cn';
        this.timezone = params['timezone'] ? params['timezone'] : 'Asia/Shanghai';
        this.consoleUrl = params['consoleUrl'];
        this.metaUrl = params['metaUrl'];
        this.statUrl = params['statUrl'];
        this.payUrl = params['payUrl'];
        this.excelJsonUrl = params['excelJsonUrl'];
        this.worldUrl = params['worldUrl'];
        this.loginUrl = params['loginUrl'];
    }

    echo() {
        console.log("环境配置：", this);
    }

}

console.log("处理env配置 开始");

// 个人开发环境
GmcEnv.teamGJK = new GmcEnv({
    consoleUrl: 'http://192.168.10.115:9999/',
    metaUrl: 'http://192.168.10.106:12345/',
    statUrl: 'http://192.168.10.106:7788/',
    payUrl: 'http://192.168.10.106:10010/',
    excelJsonUrl: 'http://192.168.10.106/srv/excel/',
    phpUrl: 'http://106.54.158.126/dso/',
    worldUrl: 'http://192.168.10.106:10086/',
    loginUrl: 'http://192.168.10.106:9000/',
});

// 个人开发环境
GmcEnv.teamZX = new GmcEnv({
    consoleUrl: 'http://192.168.10.106:9999/',
    metaUrl: 'http://192.168.10.106:12345/',
    statUrl: 'http://192.168.10.106:7788/',
    payUrl: 'http://192.168.10.106:10010/',
    excelJsonUrl: 'http://192.168.10.106/srv/excel/',
    phpUrl: 'http://106.54.158.126/dso/',
    worldUrl: 'http://192.168.10.106:10086/',
    loginUrl: 'http://192.168.10.106:9000/',
});

// 本地开发环境
GmcEnv.local = new GmcEnv({
    consoleUrl: 'http://127.0.0.1:9999/',
    metaUrl: 'http://192.168.10.106:12345/',
    statUrl: 'http://192.168.10.106:7788/',
    payUrl: 'http://192.168.10.115:10010/',
    excelJsonUrl: 'http://192.168.10.106/srv/excel/',
    phpUrl: 'http://106.54.158.126/dso/',
    worldUrl: 'http://192.168.10.106:10086/',
    loginUrl: 'http://192.168.10.106:9000/',
});

// 内网开发环境
GmcEnv.dev = new GmcEnv({
    consoleUrl: 'http://192.168.10.106:9999/',
    metaUrl: 'http://192.168.10.106:12345/',
    statUrl: 'http://192.168.10.106:7788/',
    payUrl: 'http://192.168.10.106:10010/',
    excelJsonUrl: 'http://192.168.10.106/srv/excel/',
    phpUrl: 'http://106.54.158.126/dso/',
    worldUrl: 'http://192.168.10.106:10086/',
    loginUrl: 'http://192.168.10.106:9000/',
});

// 首发环境
GmcEnv.release = new GmcEnv({
    consoleUrl: 'http://service.fs.yezixigame.com:9999/',
    metaUrl: 'https://meta-fs.yezixigame.com:12345/',// https://service.fs.yezixigame.com:12345/
    statUrl: 'https://statistic-fs.yezixigame.com:7788/',// https://service.fs.yezixigame.com:7788/  WAN:118.89.168.121 LAN:172.16.64.78
    payUrl: 'http://pay-fs.yezixigame.com:10010/',
    excelJsonUrl: 'http://gmc.fs.yezixigame.com/srv/excel/',
    phpUrl: 'http://106.54.158.126/dso/',
    worldUrl: 'http://world-fs.yezixigame.com:10086/',
    loginUrl: 'https://login-fs.yezixigame.com:9000/',
});

// 外网演示环境
GmcEnv.demo = new GmcEnv({
    consoleUrl: 'http://81.68.218.52:9999/',
    metaUrl: 'http://81.68.218.52:12345/',
    statUrl: 'http://81.68.218.52:7788/',
    payUrl: 'http://81.68.218.52:10010/',
    excelJsonUrl: 'http://gmc-test-fs.yezixigame.com/srv/excel/',
    phpUrl: 'http://106.54.158.126/dso/',
    worldUrl: 'http://81.68.218.52:10086/',
    loginUrl: 'http://81.68.218.52:9000/',
});

// IOS审核环境
GmcEnv.iosExam = new GmcEnv({
    consoleUrl: 'http://81.68.228.193:9999/',
    metaUrl: 'https://fs-iossh-meta.yezixigame.com:12345/',
    statUrl: 'http://81.68.228.193:7788/',
    payUrl: 'http://81.68.228.193:10010/',
    excelJsonUrl: 'http://gmc-iossh-fs.yezixigame.com/srv/excel/',
    phpUrl: 'http://106.54.158.126/dso/',
    worldUrl: 'http://81.68.228.193:10086/',
    loginUrl: 'https://fs-iossh-login.yezixigame.com:9000/',
});

// 微信小程序审核环境
GmcEnv.wxappExam = new GmcEnv({
    consoleUrl: 'http://81.69.203.8:9999/',
    metaUrl: 'https://fs-wxsh-meta.yezixigame.com:12345/',
    statUrl: 'https://fs-wxsh-statistic.yezixigame.com:7788/',
    payUrl: 'http://81.69.203.8:10010/',
    excelJsonUrl: 'https://gmc-wxsh-fs.yezixigame.com/srv/excel/',
    phpUrl: 'http://106.54.158.126/dso/',
    worldUrl: 'http://81.69.203.8:10086/',
    loginUrl: 'https://fs-wxsh-login.yezixigame.com:9000/',
});

// 微信小程序正式环境
GmcEnv.wxapp = new GmcEnv({
    consoleUrl: 'http://81.68.211.88:9999/',
    metaUrl: 'https://fs-wx-meta.yezixigame.com:12345/',
    statUrl: 'https://fs-wx-statistic.yezixigame.com:7788/',
    payUrl: 'http://81.68.211.88:10010/',
    excelJsonUrl: 'http://gmc-wx-fs.yezixigame.com/srv/excel/',
    phpUrl: 'http://106.54.158.126/dso/',
    worldUrl: 'http://81.68.211.88:10086/',
    loginUrl: 'https://fs-wx-login.yezixigame.com:9000/',
});


// 港澳台资安/体验环境
GmcEnv.gatDemo = new GmcEnv({
    consoleUrl: 'http://150.116.94.103:9999/',
    metaUrl: 'https://zgfstw-test.movergames.com:12345/',
    statUrl: 'https://zgfstw-test.movergames.com:7788/',
    payUrl: 'http://150.116.94.103:10010/',
    excelJsonUrl: 'http://gat-test-fs.yezixigame.com/srv/excel/',
    phpUrl: 'http://150.116.94.103/dso/',
    worldUrl: 'http://150.116.94.103:10086/',
    loginUrl: 'https://zgfstw-test.movergames.com:9000/',
});

// 港澳台IOS审核环境
GmcEnv.gatExam = new GmcEnv({
    consoleUrl: 'http://150.116.94.102:9999/',
    metaUrl: 'https://zgfstw-ios.movergames.com:12345/',
    statUrl: 'https://zgfstw-ios.movergames.com:7788/',
    payUrl: 'http://150.116.94.102:10010/',
    excelJsonUrl: 'http://gat-sh-fs.yezixigame.com/srv/excel/',
    phpUrl: 'http://150.116.94.102/dso/',
    worldUrl: 'http://150.116.94.102:10086/',
    loginUrl: 'https://zgfstw-ios.movergames.com:9000/',
});

// 港澳台正式（Android审核、Android正式、IOS正式）环境
GmcEnv.gat = new GmcEnv({
    consoleUrl: 'http://118.193.63.139:9999/',
    metaUrl: 'https://zgfstw-meta.movergames.com:12345/',
    statUrl: 'https://zgfstw-statistic.movergames.com:7788/',
    payUrl: 'http://118.193.63.139:10010/',
    excelJsonUrl: 'http://gat-fs.yezixigame.com/srv/excel/',
    phpUrl: 'http://150.116.94.102/dso/',
    worldUrl: 'http://118.193.63.139:10086/',
    loginUrl: 'https://zgfstw-login.movergames.com:9000/',
});

// 韩国版环境
GmcEnv.kor = new GmcEnv({
    consoleUrl: 'http://150.109.251.53:9999/',
    metaUrl: 'https://fzfskor-meta.longtugames.co.kr:12345/',
    statUrl: 'https://fzfskor-stat.longtugames.co.kr:7788/',
    payUrl: 'http://150.109.251.53:10010/',
    excelJsonUrl: 'http://150.109.251.53/srv/excel/',
    phpUrl: 'http://150.109.251.53/dso/',
    worldUrl: 'http://150.109.251.53:10086/',
    loginUrl: 'https://fzfskor-login.longtugames.co.kr:9000/',
});

console.log("处理env配置 完成");

// world-fs.yezixigame.com  WAN:118.89.173.241 LAN:172.16.64.24