import React, { RefObject } from 'react';
import { IState, StdItemEdit, IProps } from "../../../part/StdItemEdit";
import { Form, Input, Button, Divider, Select, DatePicker, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import MasterQuery from '../../../part/master/MasterQuery';
import { ColumnsType } from 'antd/lib/table';
import { FormInstance } from 'antd/lib/form';
import { rebateApi } from '../../../api/console/gm.rebate';
import Configure from '../../../api/Configure';

interface ThisState extends IState {
    year: any,
    month: any,
    day: any,
    rewardId: any,
    title: any,
    content: any,
    reason: any,
    masterSelected: any[],
    create: boolean,
    preview: boolean,
    previewContent: any,
    moneyOpts: any[],
    type: any,
    money: any,
}

export default class RebateEdit extends StdItemEdit<IProps, ThisState> {
    editor: RefObject<MasterQuery> = React.createRef();

    state = {
        ...super.buildInitState(),
        year: 0,
        month: 0,
        day: 0,
        rewardId: 0,
        title: undefined as any,
        content: undefined as any,
        reason: undefined as any,
        masterSelected: [] as any[],
        create: false,
        preview: false,
        previewContent: undefined as any,
        moneyOpts: [] as any[],
        type: 0,
        money: 0,
    }

    mailForm = undefined as any;

    dailyRewardDesc = Configure.Gl(1287, "单日累计充值{0}元礼包*1,");
    lifeRewardDesc = Configure.Gl(1289, "累计充值{0}元礼包*1,");
    dailyTitleDesc = Configure.Gl(1290, "{0}月{1}日单日充值返利");
    lifeTitleDesc = Configure.Gl(1288, "累计充值返利");
    dailyContentDesc = Configure.Gl(1291, "亲爱的玩家，感谢您对本游戏的支持与厚爱，由于您在{0}年{1}月{2}日")
        + Configure.Gl(1292, "单日充值总额达到{3}元，特意为您发放返利道具，敬请查收。祝您游戏愉快！");
    lifeContentDesc = Configure.Gl(1293, "亲爱的玩家，感谢您对本游戏的支持与厚爱，由于您充值总额达到{0}元，特意为您发放返利道具，敬请查收。祝您游戏愉快！");
    dailyReasonDesc = Configure.Gl(1294, "{0}月{1}日单日返利");
    lifeReasonDesc = Configure.Gl(1288, "累计充值返利");

    getColsMaster(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1265, '唯一ID'),
                dataIndex: 'roleId',
                width: 80,
            },
            {
                title: Configure.Gl(1151, '主角名'),
                dataIndex: 'roleName',
                width: 80,
            },
            {
                title: Configure.Gl(1187, '主角等级'),
                dataIndex: 'masterLevel',
                width: 80,
            },
            {
                title: Configure.Gl(1014, '游戏服'),
                dataIndex: 'srvCode',
                width: 120,
                render: (v, r) => '' + r.srvCode + '(' + r.srvName + ')'
            },
            {
                title: Configure.Gl(1122, '平台编码'),
                dataIndex: 'platformCode',
                width: 60,
            },
            {
                title: Configure.Gl(1099, '渠道编码'),
                dataIndex: 'channelCode',
                width: 60,
            },
            {
                title: Configure.Gl(1266, 'IMEI'),
                dataIndex: 'IMEI',
                width: 100,
            },
            {
                title: Configure.Gl(1267, 'IP'),
                dataIndex: 'ip',
                width: 180,
            },
        ];
    }

    render() {
        const { visible, createMode } = this.state;
        return (
            <Modal title={createMode ? Configure.Gl(1116, "新建") : Configure.Gl(1117, "编辑")} visible={visible} width='90%'
                onOk={this.handleModalOk} onCancel={this.handleModalCancel} footer={null} destroyOnClose>
                {this.renderForm()}
            </Modal>
        );
    }

    renderForm() {
        const { title, content, reason, masterSelected, create, preview, previewContent, moneyOpts } = this.state;
        const columnsMaster: ColumnsType<any> = this.getColsMaster();
        const moneyOptsUi: any[] = [];
        moneyOpts.forEach(opt => {
            moneyOptsUi.push(<Select.Option value={opt.id} money={opt.money} key={opt.key}>{opt.desc}</Select.Option>);
        })
        return (
            <span>
                <MasterQuery ref={this.editor} onSelect={(r: any) => this.handleSelect(r)}></MasterQuery>
                <Table dataSource={masterSelected} columns={columnsMaster} />
                <Divider />
                <Select placeholder={Configure.Gl(1281, "累充类型")} onSelect={(value) => {
                    this.setState({ type: value, moneyOpts: [], rewardId: 0, money: 0 });
                }}>
                    <Select.Option value={1} key={1}>{Configure.Gl(1285, "单日累充")}</Select.Option>
                    <Select.Option value={2} key={2}>{Configure.Gl(1286, "生涯累充")}</Select.Option>
                </Select>
                <DatePicker placeholder={Configure.Gl(1282, "请选择日期(生涯累充类型可不选)")} onChange={v => this.changeDate(v)}></DatePicker>
                <Select placeholder={Configure.Gl(1283, "充值金额")} onDropdownVisibleChange={(open) => this.handleOnClick(open)} onSelect={(v, option) => this.handleOnSelect(v, option)}>
                    {moneyOptsUi}
                </Select>
                <Button onClick={() => this.create()}>{Configure.Gl(1284, "生成邮件")}</Button>
                <Divider />
                <div style={{ display: create ? "block" : "none" }}>
                    <Form
                        ref={(inst) => { this.mailForm = inst }}
                        layout="vertical"
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 20 }}
                        name="basic"
                        onFinish={this.onFormFinish}
                        onFinishFailed={this.onFormFinishFailed}
                        initialValues={{ title: title, content: content, reason: reason }}
                    >
                        <Form.Item label={Configure.Gl(1124, '标题')} name="title" style={{ width: '40%' }}>
                            <Input disabled={true} bordered={false} />
                        </Form.Item>
                        <Form.Item label={Configure.Gl(1125, '内容')} name="content">
                            <Input.TextArea autoSize bordered={false} disabled={true} />
                        </Form.Item>
                        <Form.Item label={Configure.Gl(1296, "发送原因")} name="reason">
                            <Input disabled={true} bordered={false} />
                        </Form.Item>
                        <Divider />
                        <Form.Item>
                            <Button type="default" onClick={() => this.preview()}>{Configure.Gl(1297, '预览')}</Button>
                            <Button type="primary" htmlType="submit" disabled={!preview}>{Configure.Gl(1298, '发送')}</Button>
                            <Button danger type="default" onClick={() => this.reset()}>{Configure.Gl(1299, '重置')}</Button>
                        </Form.Item>
                    </Form>
                </div>
                <div style={{ display: preview ? "block" : "none" }}>
                    <Input.TextArea autoSize bordered={false} disabled={true} value={previewContent} />
                </div>
            </span>
        )
    }

    handleOnSelect(v: any, option: any) {
        this.setState({ rewardId: v, money: option.money });
    }

    handleOnClick(open: boolean) {
        if (!open) {
            return;
        }
        const { masterSelected, year, month, day, type } = this.state;
        if (!masterSelected || masterSelected.length === 0) {
            alert(Configure.Gl(1300, "请先选择要发送的对象"));
            this.setState({ moneyOpts: [], rewardId: 0, money: 0 });
            return;
        }
        if (type === 0) {
            alert(Configure.Gl(1301, "请先选择累充类型"));
            this.setState({ moneyOpts: [], rewardId: 0, money: 0 });
            return;
        }
        console.log("type=", type);
        if (type === 1 && year === 0 && month === 0 && day === 0) {
            alert(Configure.Gl(1302, "请先选择充值时间"));
            this.setState({ moneyOpts: [], rewardId: 0, money: 0 });
            return;
        }
        let req = {
            type: type,
            masterIdHex: masterSelected[0].roleId,
            srvCode: masterSelected[0].srvCode,
            queryDate: type === 1 ? year + "-" + ((month as any) >= 10 ? month : ("0" + month)) + "-" + day + " 00:00:00" : undefined,
        }
        console.log("req=", req);
        rebateApi.money_list(req).then((data: any) => {
            console.log("data=", data);
            if (data.length === 0) {
                alert(Configure.Gl(1303, "玩家未充值或已经发送过累充奖励"));
                this.setState({ moneyOpts: [], rewardId: 0, money: 0 });
                return;
            }
            this.addKeyToData(data);
            this.setState({ moneyOpts: data })
        });
    }

    addKeyToData(data: any[]) {
        data.forEach(element => { if (!element.key) element.key = Math.random() });
    }

    handleSelect(r: any) {
        this.setState({ masterSelected: [r] });
        this.editor.current?.setState({ show: false });
    }

    preview() {
        const { title, content, reason, rewardId, moneyOpts, masterSelected, type } = this.state;
        let master = Configure.Gl(1277, "发送玩家: ");
        masterSelected.forEach(v => master += v.masterIdHex + "  " + v.masterName + "  " + v.srvCode + "\n");
        let reward = Configure.Gl(1278, "发送奖励: ");
        moneyOpts.forEach(v => {
            if (rewardId === v.id) {
                reward += (type === 1 ? this.stringFormat([this.dailyRewardDesc, v.desc]) :
                    this.stringFormat([this.lifeRewardDesc, v.desc])) + ", "
            }
        });
        reward += "\n";
        let previewContent = `${master}${reward}邮件标题: ${title}\n邮件正文: ${content}\n发送原因: ${reason}`;
        this.setState({ preview: true, previewContent: previewContent });
    }

    changeDate(values: any) {
        if (!values) {
            this.setState({ year: 0, month: 0, day: 0 });
            return;
        }
        let m = values as moment.Moment;
        this.setState({ year: m.year(), month: m.month() + 1, day: m.date() });
    }

    create() {
        console.log("state=", this.state);
        const { month, day, year, masterSelected, moneyOpts, type, money, rewardId } = this.state;
        if (type === 1 && (!month || !day || !year)) {
            alert(Configure.Gl(1304, "请先选择充值日期"));
            return;
        }
        if (!masterSelected || masterSelected.length === 0) {
            alert(Configure.Gl(1305, "请选择玩家后再生成邮件"));
            return;
        }
        if (!moneyOpts || moneyOpts.length === 0 || rewardId === 0) {
            alert(Configure.Gl(1306, "请选择充值金额"));
            return;
        }
        let title = type === 1 ? this.stringFormat([this.dailyTitleDesc, month, day]) : this.lifeTitleDesc;
        let content = type === 1 ? this.stringFormat([this.dailyContentDesc, year, month, day, money]) :
            this.stringFormat([this.lifeContentDesc, money]);
        let reason = type === 1 ? this.stringFormat([this.dailyReasonDesc, month, day]) : this.lifeReasonDesc;
        let fi = this.mailForm as FormInstance;
        fi.setFieldsValue({ title: title, content: content, reason: reason });
        this.setState({ title: title, content: content, reason: reason, create: true, preview: false });
    }

    stringFormat(args: any[]) {
        if (args.length === 0)
            return null;
        var str = args[0];
        for (var i = 1; i < args.length; i++) {
            console.log("args=", args[i]);
            var re = new RegExp('\\{' + (i - 1) + '\\}', 'g');
            str = str.replace(re, args[i]);
        }
        return str;
    }

    reset() {
        this.setState({
            masterSelected: [],
            preview: false, create: false, rewardId: 0, money: 0, title: undefined, content: undefined, reason: undefined,
            previewContent: undefined, moneyOpts: []
        });
    }

    handleModalCancel = () => {
        this.setState({
            visible: false, masterSelected: [], year: 0, month: 0, day: 0,
            preview: false, create: false, rewardId: 0, money: 0, title: undefined, content: undefined, reason: undefined,
            previewContent: undefined, moneyOpts: []
        });
    }

    onFormFinish = (values: any) => {
        const { title, content, masterSelected, rewardId, year, month, day, moneyOpts, type } = this.state;
        if (title === null || title === undefined || title.length === 0) {
            alert(Configure.Gl(1220, "邮件标题不能为空"));
            return;
        } else if (title.length > 100) {
            alert(Configure.Gl(1221, "邮件标题长度不能大于100"));
            return;
        }
        if (content === null || content === undefined || content.length === 0) {
            alert(Configure.Gl(1222, "邮件内容不能为空"));
            return;
        } else if (content.length > 2800) {
            alert(Configure.Gl(1223, "邮件内容长度不能大于2800"));
            return;
        }
        if (masterSelected.length <= 0) {
            alert(Configure.Gl(1225, "请添加游戏角色"));
            return;
        }
        if (!rewardId || rewardId === 0) {
            alert(Configure.Gl(1307, "请选择累充奖励"));
            return;
        }
        let req = {} as any;
        let rewardDesc = "";
        moneyOpts.forEach(v => {
            if (rewardId === v.id) {
                rewardDesc += (type === 1 ? this.stringFormat([this.dailyRewardDesc, v.desc]) :
                    this.stringFormat([this.lifeRewardDesc, v.desc])) + ", "
            }
        });
        req.gmRebateEntity = {
            type: type,
            title: title,
            content: content,
            queryDate: type === 1 ? year + "-" + ((month as any) >= 10 ? month : ("0" + month)) + "-" + day + " 00:00:00" : undefined,
            rewardId: rewardId,
            rewardDesc: rewardDesc,
        };
        req.gmRebateTars = [];
        masterSelected.forEach(element => {
            req.gmRebateTars.push({
                srvCode: element.srvCode,
                masterIdHex: element.masterIdHex,
                name: element.masterName,
                platformCode: element.platformCode
            });
        });
        console.log("rebate create req=", req)
        rebateApi.create(req).then(() => {
            this.handleModalCancel();
            this.props.reload();
        });
    }

    onFormFinishFailed = (errorInfo: any) => {
        console.error('onFormFinishFailed:', errorInfo);
    }
}