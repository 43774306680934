export class StoreUtil {

    static LOGIN_TOKEN_HEADER = "Login-Token";
    static NEED_LOGIN_HEADER = "Need-Login";
    static NEED_LOGIN_YES = "true";
    static LOGIN_USERNAME = "login.username";
    static LOGIN_PASSWORD = "login.password";

    static getCookie(sName:string):string|null {
        let aCookie = document.cookie.split("; ");
        for (let i = 0; i < aCookie.length; i++) {
            let aCrumb = aCookie[i].split("=");
            if (sName === aCrumb[0])
                return decodeURIComponent(aCrumb[1]);
        }
        return null;
    }

    static putStore(key:string, value:any): void {
        if(value === null) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, value);
        }
    }

    static getStore(key:string):any {
        let val = localStorage.getItem(key);
        if(val !== null && val !== undefined) {
            return val;
        }
        return StoreUtil.getCookie(key);
    }

    static setToken(value:string|null) {
        StoreUtil.putStore(StoreUtil.LOGIN_TOKEN_HEADER, value);
    }

    static getToken():string {
        return StoreUtil.getStore(StoreUtil.LOGIN_TOKEN_HEADER);
    }

    static existToken():boolean {
        const token = StoreUtil.getToken();
        console.log("token=", token);
        if(token !== null && token !== '') {
            return true;
        }
        return false;
    }

    static setLoginUsername(value:string) {
        StoreUtil.putStore(StoreUtil.LOGIN_USERNAME, value);
    }

    static getLoginUsername():string {
        return StoreUtil.getStore(StoreUtil.LOGIN_USERNAME);
    }

    static setLoginPassword(value:string) {
        StoreUtil.putStore(StoreUtil.LOGIN_PASSWORD, value);
    }

    static getLoginPassword():string {
        return StoreUtil.getStore(StoreUtil.LOGIN_PASSWORD);
    }
}