import React, { RefObject } from 'react';
import { Button, Table, Space} from 'antd';
import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import { FormInstance } from 'antd/lib/form';
import { StdItemPage } from '../../../part/StdItemPage';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';
import { mailApi } from '../../../api/console/gm.mail';
import { formatDate } from '../../../util/toolbox';
import { Key, TableCurrentDataSource } from 'antd/lib/table/interface';
import { SorterResult } from 'antd/es/table/interface';
import GmMailEdit from './GmMailEdit';
import { RouteComponentProps } from 'react-router';
import Search from 'antd/lib/input/Search';
import moment from 'moment';
import Configure from '../../../api/Configure';

export default class GmMailApproveIndex extends StdItemPage<any> {
    formRef = React.createRef<FormInstance>();
    private editor: RefObject<GmMailEdit>;
    
    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
    }

    state = {
        itemTotal: undefined,
        queryPagination: {
            page: 1,
            pageSize: 10
        },
        itemList: [],
        queryFilter: {viewAll:true}
    }

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1118,'id'),
                dataIndex: ['gmMailEntity','id'],
                width: 80,
            },
            {
                title: Configure.Gl(1124,'标题'),
                dataIndex: ['gmMailEntity','title'],
                width: 240,
            },
            {
                title: Configure.Gl(1200,'奖励'),
                dataIndex: ['gmMailEntity','reward'],
                width: 150,
            },
            {
                title: Configure.Gl(1088,'状态'),
                dataIndex: ['gmMailEntity','status'],
                width: 80,
                render: (value: any) => value === 1 ? Configure.Gl(1201,"未审批") : value === 2 ? Configure.Gl(1202,"同意") : Configure.Gl(1203,"拒绝")
            },
            {
                title: Configure.Gl(1057,'操作'),
                dataIndex: ['gmMailEntity','status'],
                width: 300,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <div style={{display : value === 1? "block": "none"}}>
                            <Button type="primary" onClick={() => this.doAccept(record)}>{Configure.Gl(1202,'同意')}</Button>
                            <Button danger style={{margin:'0 8px'}} onClick={() => this.doRefuse(record)}>{Configure.Gl(1203,'拒绝')}</Button>
                        </div>
                        <Button type="default" onClick={() => {
                            if(record.gmMailEntity.invalidTime){
                                record.gmMailEntity.invalidTime = moment(record.gmMailEntity.invalidTime);
                            }
                            if(record.gmMailEntity.scheduleTime){
                                record.gmMailEntity.scheduleTime = moment(record.gmMailEntity.scheduleTime);
                            }
                            if(record.gmMailEntity.mailType === null || record.gmMailEntity.mailType === 0){
                                record.gmMailEntity.mailType = 1;
                            }
                            this.editor.current?.setState({
                                initData: record, 
                                readonly: record.gmMailEntity.status !== 1, 
                                masterShow: record.gmMailEntity.type === 2, 
                                srvShow: record.gmMailEntity.type === 1, 
                                reward: record.gmMailEntity.reward,
                                masterSelected: record.gmMailEntity.type === 2? record.gmMailTars: undefined,
                                srvGameSelected: record.gmMailEntity.type === 1? record.gmMailTars: undefined,
                                scheduleShow: record.gmMailEntity.mailType === 2, 
                            },()=>{this.editor.current?.showCreate();});
                        }}>{record.gmMailEntity.status === 1? Configure.Gl(1132,"修改") : Configure.Gl(1204,"查看详情") }</Button>
                    </Space>);
                },
            },
            {
                title: Configure.Gl(1205,'提交人用户名'),
                width: 80,
                dataIndex: ['gmMailEntity','submitUsername'],
            },
            {
                title: Configure.Gl(1206,'提交人昵称'),
                width: 120,
                dataIndex: ['gmMailEntity','submitNickname'],
            },
            {
                title: Configure.Gl(1207,'提交时间'),
                width: 120,
                dataIndex: ['gmMailEntity','submitTime'],
                render: (v, r) => formatDate(v)
            },
            {
                title: Configure.Gl(1208,'审核人用户名'),
                width: 80,
                dataIndex: ['gmMailEntity','approveUsername'],
            },
            {
                title: Configure.Gl(1209,'审核人昵称'),
                width: 120,
                dataIndex: ['gmMailEntity','approveNickname'],
            },
            {
                title: Configure.Gl(1210,'审核时间'),
                width: 120,
                dataIndex: ['gmMailEntity','approveTime'],
                render: (v, r) => formatDate(v)
            },
            {
                title: Configure.Gl(1125,'内容'),
                width: 1200,
                dataIndex: ['gmMailEntity','content'],
            }
        ];
    }

    render() {
        const { itemTotal, queryPagination } = this.state;
        const columns: ColumnsType<any> = this.getCols();
        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            defaultPageSize: queryPagination.pageSize,
            total: itemTotal
        };
        return (
            <HomeLayout path={RoutePath.GM_MAIL_APPROVE_INDEX}>
                <Search placeholder={Configure.Gl(1211,"输入要搜索的提交人用户名")} onSearch={v=>{this.onSearch(v)}} style={{ width: 250 }} />
                <Table dataSource={this.state.itemList} columns={columns}
                pagination={pagination} scroll={{ x: this.getColsWidth(columns), y: 512 }}  onChange={(p, f, s, e) => { this.onPageChange(p, f, s, e) }}/>
                <GmMailEdit ref={this.editor} reload={() => this.reload()}></GmMailEdit>
            </HomeLayout>
        );
    }

    onSearch(v: any){
        let filter = v && v.length > 0?{viewAll: true, userName: v}: {viewAll: true}; 
        this.setState({queryFilter: filter}, ()=> {
            this.reload();
        });
    }

    onPageChange(pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        this.setState({queryPagination: {
            page: pagination.current,
            pageSize: pagination.pageSize
        }}, ()=>{
            this.reload();
        });
    }

    componentDidMount() {
        this.reload();
    }

    async reload() {
        const { queryPagination, queryFilter } = this.state; 
        const query = { ...queryPagination, ...queryFilter };
        console.log("请求参数为：",query);
        let data = await mailApi.list(query) as any;
        console.log(Configure.Gl(1390,"请求到的数据是："), data);
        this.addKeyToData(data.records);
        this.setState({ itemList: data.records, itemTotal: data.total });
    }

    doAccept(item : any) {
        mailApi.complete({status: 2,id: item.gmMailEntity.id}).then(() => {
          this.reload();
        });
    }

    doRefuse(item : any) {
        mailApi.complete({status: 3, id: item.gmMailEntity.id}).then(() => {
          this.reload();
        });
    }
}