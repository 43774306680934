import React, { RefObject } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Table, Space, Divider, Modal, Alert } from 'antd';
import { SearchOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';

import { StdItemPage } from '../../part/StdItemPage';
import { HomeLayout } from '../../layout/HomeLayout';
import { RoutePath } from '../../route/path';
import StaffRolePermissionEdit from './StaffRolePermissionEdit';
import { staffRoleApi } from '../../api/console/staff.role';
import Configure from '../../api/Configure';

export default class StaffRolePermissionIndex extends StdItemPage<any> {

    private editor: RefObject<StaffRolePermissionEdit>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
    }

    state = {
        showEdit: false,
        itemList: [],
    };

    render() {
        const columns: ColumnsType<any> = [
            {
                title: Configure.Gl(1000, "角色"),
                dataIndex: 'roleName',
                fixed: 'left',
                width: 160,
                render: (value: any, record: any) => <Button type="link" onClick={() => this.editor.current?.showUpdate(record)}>{"(" + record.roleId + ")" + value}</Button>
            },
            { title: Configure.Gl(1535, "管理员"), dataIndex: 'admin', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1539, "基础权限"), dataIndex: 'p0', width: 100, render: (value) => value ? "√" : "" },
            {
                title: Configure.Gl(1538, "运维权限"),
                dataIndex: 'p1',
                width: 100,
                render: (value) => value ? "√" : ""
            },
            {
                title: Configure.Gl(1540, "角色冻结与禁言"),
                dataIndex: 'p2',
                width: 100,
                render: (value) => value ? "√" : ""
            },
            {
                title: Configure.Gl(1543, '礼包码'),
                dataIndex: 'p3',
                width: 100,
                render: (value) => value ? "√" : ""
            },
            {
                title: Configure.Gl(1546, '基础统计'),
                dataIndex: 'p4',
                width: 100,
                render: (value) => value ? "√" : ""
            },
            {
                title: Configure.Gl(1547, '手动支付'),
                dataIndex: 'p5',
                width: 100,
                render: (value) => value ? "√" : ""
            },
            { title: Configure.Gl(1562, '支付数据读取'), dataIndex: 'p6', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1563, '邮件读取'), dataIndex: 'p7', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1564, '邮件撰写'), dataIndex: 'p8', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1565, '邮件审批'), dataIndex: 'p9', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1566, '游戏通知'), dataIndex: 'p10', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1567, '全服公告'), dataIndex: 'p11', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1568, '高级统计权限'), dataIndex: 'p12', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1569, '充值返利编辑'), dataIndex: 'p13', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1570, '充值返利审核'), dataIndex: 'p14', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1571, '无附件邮件审核'), dataIndex: 'p15', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1572, '订单查询'), dataIndex: 'p16', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1039, '产出消耗查询'), dataIndex: 'p17', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1575, '单服充值排行'), dataIndex: 'p18', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1560, '应急通道'), dataIndex: 'p19', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1796, '留存分析'), dataIndex: 'p20', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1797, '功能参与分析'), dataIndex: 'p21', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1798, '游戏实时状态'), dataIndex: 'p22', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1799, '游戏监控汇报'), dataIndex: 'p23', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1800, '角色背包物品查询'), dataIndex: 'p24', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1801, '角色背包物品扣减'), dataIndex: 'p25', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1802, '角色钱包数值查询'), dataIndex: 'p26', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1803, '角色钱包数值扣减'), dataIndex: 'p27', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1804, 'vip客服功能'), dataIndex: 'p28', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1805, '每日简报'), dataIndex: 'p29', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1806, '英雄查询'), dataIndex: 'p30', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1807, 'LTV统计'), dataIndex: 'p31', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(1808, '元宝商城统计'), dataIndex: 'p32', width: 100, render: (value) => value ? "√" : "" },
            { title: Configure.Gl(Configure.GL_UNDEFINED, '角色关键数据'), dataIndex: 'p33', width: 100, render: (value) => value ? "√" : "" },
            {
                title: Configure.Gl(1057, '操作'),
                width: 200,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117, "编辑")}</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133, '删除')}</Button>
                    </Space>);
                },
            }
        ];

        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
        };

        return (
            <HomeLayout path={RoutePath.STAFF_ROLE_PERMISSION_INDEX}>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389, '新增')}</Button>
                    <Button type="primary" icon={<SearchOutlined />} onClick={() => this.reload()}>{Configure.Gl(1152, '查找')}</Button>
                </Space>
                <Divider />
                <Table dataSource={this.state.itemList} columns={columns} pagination={pagination} scroll={{ x: 1500, y: 512 }} />
                <StaffRolePermissionEdit ref={this.editor} reload={() => this.reload()} ></StaffRolePermissionEdit>
                {this.renderDescription()}
            </HomeLayout>
        );
    }

    renderDescription() {
        return (<div>
            <Alert message={Configure.Gl(1573, "高级统计权限 包含：单服充值排行、登录流程打点 等一些仅限运维和叶子戏核心人员可查看的功能。")} type="info" />
        </div>);
    }

    componentDidMount() {
        console.log("StaffRolePermissionIndex::componentDidMount");
        this.reload();
    }

    async reload() {
        let data = await staffRoleApi.listPermission() as any[];
        console.log(Configure.Gl(1390, "请求到的数据是："), data);
        this.addKeyToData(data);
        const roleList = await staffRoleApi.list() as any[];
        data.forEach(value => {
            let roleName = "" + value.roleId;
            //console.log("this.roleOpt=", this.roleOpt);
            for (let opt of roleList) {
                if (opt.id === value.roleId) {
                    roleName = opt.name;
                    break;
                }
            }
            value.roleName = roleName;
        })
        this.setState({ itemList: data });
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066, '警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1391, '你确定要删除') + item.roleId + ' (' + item.roleName + ')？',
            onOk() {
                staffRoleApi.deletePermission(item.roleId).then(d => that.reload());
            }
        });
    }

}