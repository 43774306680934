import { UiOpt } from '../../util/ui';

export class SrvGameEntity {

    /** 游戏服状态：不对外开放 */
    public static STATUS_CLOSE = 0;
    /** 游戏服状态：维护中 */
    public static STATUS_MAINTAIN = 1;
    /** 游戏服状态：正常运行 */
    public static STATUS_RUNNING = 2;

    public static statusToString(status: number) {
        switch(status) {
            case this.STATUS_CLOSE: return "已关闭";
            case this.STATUS_MAINTAIN: return "维护中";
            case this.STATUS_RUNNING: return "正常运行";
            default: return "未知状态" + status;
        }
    }

    public static getStatusOpt(): UiOpt[] {
        let vs = [SrvGameEntity.STATUS_CLOSE, SrvGameEntity.STATUS_MAINTAIN, SrvGameEntity.STATUS_RUNNING];
        let r: UiOpt[] = [];
        vs.forEach(v => {
            r.push({label: SrvGameEntity.statusToString(v), value: v});
        })
        return r;
    }

}

