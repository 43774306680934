import React from 'react';
import { RouteComponentProps, Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Tabs, Tag } from 'antd';
import { StoreUtil } from '../util/store';
import { getMenus } from '../route/index';
import './HomeLayout.css';
import { RoutePath } from '../route/path';
import { UserOutlined, WindowsOutlined } from '@ant-design/icons';
import { isMobile } from '../util/toolbox';
import { staffApi } from '../api/console/staff';
import Configure from '../api/Configure';


const { SubMenu } = Menu;
const { Content, Sider } = Layout;

interface IProps extends RouteComponentProps {
    path: string;
}


const { TabPane } = Tabs;
const panes = [
    { title: '主页', content: <Content></Content>, key: '/home/index' },
];

class HomeLayoutImpl extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
        if (!StoreUtil.existToken()) {
            console.log("没有登录令牌，需要登录一下！");
            //this.history.pushState(null, `/users/${user.id}`, query);
            this.props.history.push(RoutePath.LOGIN_INDEX, null);
        }
        staffApi.session().then((s: any) => {
            console.log("收到的权限数据是：", s);
            this.setState({ permission: s.permission })
        })
    }

    onChange = (activeKey1: any) => {
        this.props.history.push(activeKey1, null);
        this.setState({ panes, activeKey: activeKey1 });

    };


    addTabpanel = (titleName: string, actKey: string) => {
        const { panes } = this.state;
        const activeKey = actKey;
        let result: boolean = false;
        panes.forEach(element => {
            if (element.key === activeKey) {
                result = true;
            }
        });

        if (result) {
            this.onChange(activeKey);
        } else {
            let isOne: boolean = false;
            panes.forEach(element => {
                if (element.key === '-1') {
                    isOne = true;
                    element.title = titleName;
                    element.key = activeKey;
                    element.content = <Content></Content>;
                }
            });
            if (!isOne) {
                panes.push({ title: titleName, content: <Content></Content>, key: activeKey });
            }

            this.setState({ panes, activeKey: activeKey });
            this.onChange(activeKey);
        }

    };

    remove = (targetKey: any) => {
        const { panes } = this.state;
        let activeKey = "";
        panes.forEach((element, i) => {
            if (element.key === targetKey) {
                panes.splice(i, 1);

                return;
            }
        });
        activeKey = panes.length > 0 ? panes[panes.length - 1].key : "";
        this.setState({ panes, activeKey });
        if (activeKey !== "") this.onChange(activeKey);
    };


    tabClickHandler = (params: any) => {
        this.setState({ defaultActiveKey: params });
    }

    onEdit = (targetKey: any, action: any) => {
        this.remove(targetKey);
    };

    state = {
        collapsed: false,
        permission: {},
        activeKey: '/home/index',//panes[0].key,
        panes,
    }

    onCollapse = (collapsed: any) => {
        console.log(collapsed);
        this.setState({ collapsed });
    };

    componentDidMount() {
        this.setState({ activeKey: this.props.history.location.pathname });
    }

    componentDidUpdate() {
        console.log("");

    }

    render() {

        const { path } = this.props;
        const { permission } = this.state;
        console.log("render path=", path, "permission=", permission);
        let defaultOpenKey = "";
        const menuCfgs = getMenus(permission);
        console.log("menuCfgs=", menuCfgs);
        menuCfgs.forEach(menuGroup => {
            menuGroup.itemList.forEach(menuItem => {
                if (menuItem.path === path) {
                    defaultOpenKey = menuGroup.key;
                    if (this.state.panes.length === 1 && this.state.panes[0].key === '-1') {
                        this.state.panes[0].title = menuItem.name;
                        this.state.panes[0].key = path;
                        this.setState({ activeKey: path })
                    }
                }
            });
        })
        const minWidthPx = isMobile() ? '1024px' : '100px';
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Sider width={200} className="site-layout-background" collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
                    <div className="logo"><Tag color="blue" >GMC-gmq版-v57</Tag></div>
                    <Menu theme="dark"
                        mode="inline"
                        defaultSelectedKeys={[path]}
                        defaultOpenKeys={[defaultOpenKey]}
                        style={{ height: '100%', borderRight: 0 }}
                    >
                        <Menu.Item key={RoutePath.HOME_INDEX} icon={<WindowsOutlined />}>
                            <Link to={RoutePath.HOME_INDEX} onClick={() => this.addTabpanel(Configure.Gl(1136, '主页'), RoutePath.HOME_INDEX)}>{Configure.Gl(1136, '主页')}</Link>
                        </Menu.Item>
                        {
                            menuCfgs.map((group, index) => {
                                let groupVisible = false;
                                for (let k in group.itemList) {
                                    if (group.itemList[k].visible) {
                                        groupVisible = true;
                                    }
                                }
                                if (!groupVisible) {
                                    console.log("整个菜单不可见");
                                    return null;
                                }
                                //console.log("依然可以执行", menuCfgs);
                                return (
                                    <SubMenu key={group.key} icon={group.icon} title={group.title}>
                                        {group.itemList.map((item, subIndex) => {
                                            if (!item.visible) return null;
                                            return <Menu.Item key={item.path} icon={item.icon}>
                                                <Link to={item.path} onClick={() => this.addTabpanel(item.name, item.path)}>{item.name}</Link>
                                            </Menu.Item>;
                                        })}
                                    </SubMenu>
                                );
                            })
                        }
                        <Menu.Item key={RoutePath.LOGIN_INDEX} icon={<UserOutlined />}>
                            <Link to={RoutePath.LOGIN_INDEX}>{Configure.Gl(1137, '退出登录')}</Link>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout style={{ padding: '0 12px 12px', minWidth: minWidthPx }}>
                    <Tabs
                        hideAdd
                        onChange={this.onChange}
                        onEdit={this.onEdit}
                        activeKey={this.state.activeKey}
                        defaultActiveKey='1'
                        type="editable-card"

                    >
                        {

                            this.state.panes.map(pane => (
                                <TabPane tab={pane.title} key={pane.key} >
                                    {
                                        pane.content = <Content
                                            className="site-layout-background"
                                            style={{
                                                padding: 12,
                                                margin: 0,
                                                minWidth: isMobile() ? 1024 : 100,
                                                minHeight: 280,
                                            }}
                                        >
                                            {this.props.children}
                                        </Content>

                                    }
                                </TabPane>
                            ))
                        }
                    </Tabs>
                </Layout>
            </Layout >
        );
    }

}

const HomeLayout = withRouter(HomeLayoutImpl);

export { HomeLayout };