import React from 'react';
import { Form, Input, Button, Switch, Select, DatePicker } from 'antd';
import { StdItemEdit, IProps, IState } from '../../part/StdItemEdit';
import { srvStatusPlanApi } from '../../api/console/srv.status.plan';
import { SrvGameEntity } from '../../def/srv/SrvGameEntity';
import Configure from '../../api/Configure';

export default class SrvStatusPlanEdit extends StdItemEdit<IProps, IState> {

    state = {
        ...super.buildInitState(),
    }

    renderForm() {
        const statusOptUi: any[] = [];
        SrvGameEntity.getStatusOpt().forEach(opt => {
            statusOptUi.push(<Select.Option value={opt.value} key={opt.value}>{opt.label}</Select.Option>);
        })
        const { createMode, item } = this.state;
        console.log("拿到的item=", item);
        return (
            <Form 
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="basic"
                initialValues={item}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}
            >
                <Form.Item label="ID" name="id">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={ Configure.Gl(1079,'游戏服编码')} name="srvCode">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1088,'状态')} name="status">
                    <Select>{statusOptUi}</Select>
                </Form.Item>
                <Form.Item label={Configure.Gl(1463,"要取消推荐的游戏服编码")} name="cancelRecommendSrvCode">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1393,"切换时间")} name="changeTime">
                    <DatePicker showTime />
                </Form.Item>
                <Form.Item label={Configure.Gl(1464,"是否已执行过变更")} name="changed" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1358,'启用')} name="enabled" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit"> {createMode ? Configure.Gl(1387,"保存新增")  : Configure.Gl(1388,"保存编辑")} </Button>
                </Form.Item>
            </Form>
        );
    }


    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        //if(true) return;
        const { createMode } = this.state;
        if (createMode) {
            srvStatusPlanApi.create(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            });
        } else {
            srvStatusPlanApi.update(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            })
        }
    }

}