import React from 'react';
import { Button, Tag, Divider, Tabs, Form, Input } from 'antd';
import { TwitterOutlined } from '@ant-design/icons';

import { HomeLayout } from '../../layout/HomeLayout';
import { RoutePath } from '../../route/path';
import { srvGameCmdApi } from '../../api/console/srv.game.cmd';
import { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import Configure from '../../api/Configure';

export default class EmergencyIndex extends React.Component {

    formRef = React.createRef<FormInstance>();

    state = {
        data: {
            action: 'echo', srvCode: 'ds1', masterIdHex: '5ebe4ee9298d43158fb9e046', openId: 't1@develop', note: Configure.Gl(1756,'演示操作'),
            param0: 'log', param1: '<demo>嘿嘿<demo/>', param2: '1024', param3: '', param4: '',
        },
        result: '执行结果'
    };

    render() {
        const { data, result } = this.state;

        return (
            <HomeLayout path={RoutePath.EMERGENCY_INDEX}>
                <Form
                    ref={this.formRef}
                    layout="inline"
                    name="basic"
                    initialValues={data}
                    onFinish={this.onFormFinish}
                    onFinishFailed={this.onFormFinishFailed}
                >
                    <Divider >
                        <Tag icon={<TwitterOutlined />} color="#55acee">{Configure.Gl(1359,'应急请求 :')} </Tag>
                    </Divider>
                    <Form.Item label={Configure.Gl(1057,'操作')} name="action">
                        <Input />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1079,'游戏服编码')} name="srvCode">
                        <Input />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1101,'主角唯一ID')} name="masterIdHex">
                        <Input />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1337,'OpenID')} name="openId">
                        <Input />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1065,"备注")} name="note">
                        <Input />
                    </Form.Item>
                    <Divider />
                    <Form.Item label={Configure.Gl(1339,"参数0")} name="param0">
                        <Input />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1340,"参数1")} name="param1">
                        <Input />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1341,"参数2")} name="param2">
                        <Input />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1342,"参数3")} name="param3">
                        <Input />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1343,"参数4")} name="param4">
                        <Input />
                    </Form.Item>
                    <Divider />

                    <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                        <Button type="primary" htmlType="submit">{Configure.Gl(1338,"执行")}</Button>
                    </Form.Item>
                </Form>
                <Divider />
                {this.renderTemplate()}
                <Divider />
                <div dangerouslySetInnerHTML={{ __html: result }} />
            </HomeLayout>
        );
    }

    renderTemplate() {
        const { TabPane } = Tabs;
        return (<Tabs onChange={(key) => this.onTemplateChange(key)} type="card">
            <TabPane tab={Configure.Gl(1345,"演示")} key="echo">
                {Configure.Gl(1346,"通常情况下，行为、游戏服编码、主角唯一ID 是必填项，其他的都是可选项。")} 
            </TabPane>
            <TabPane tab={Configure.Gl(1347,"删档返代币")} key="pay-refund">
                {Configure.Gl(1348,"强制返还代币，不论之前是否已经返还，都会强制进行返还代币！慎重！")}
            </TabPane>
            <TabPane tab={Configure.Gl(1349,'封测奖励')} key="beta-reward">
                {Configure.Gl(1350,'尝试获取参与封测的各种奖励，会绕过GameSrv层面的判断，但不会绕过tcg库的记录，若要重复给奖励，请删除world_beta_record中的相关记录！慎重！')}
            </TabPane>
            <TabPane tab={Configure.Gl(1351,"删背包")} key="bag-remove">
                {Configure.Gl(1352,'参数0: 背包类型(英雄:1, 装备:2, 道具: 3, 碎片: 4, 玺印: 5, 兽魂: 6, 仙器: 7, 仙器碎片: 8)')} <br/>
                {Configure.Gl(1353,'参数1: 物品的模板ID')}<br/>
                {Configure.Gl(1354,'参数2: 需要删除的数量（正数）')}  <br/>
            </TabPane>
        </Tabs>);
    }

    onTemplateChange(key: string) {
        console.log("onTemplateChange", key);
        let data: Store = {
            action: key, srvCode: 'ds1', masterIdHex: '5ebe4ee9298d43158fb9e046', openId: '', note: '',
            param0: '', param1: '', param2: '', param3: '', param4: '',
        };
        switch (key) {
            case "echo":
                data.param0 = 'print'; data.param1 = '<h1>标题</h1>';
                break
            case "pay-refund":
                break;
            case "beta-reward":
                break;
            case "bag-remove":
                data.param0 = 3; data.param1 = 999999; data.param2 = 1;
                break;
        }
        this.formRef.current?.setFieldsValue(data);
    }

    componentDidMount() {
        console.log("EmergencyIndex::componentDidMount");
    }

    onFormFinish = (values: any) => {
        if (window.confirm(Configure.Gl(1344,"确实要执行该紧急操作？特殊情况下才能使用的哟！"))) {
            console.log('onFormFinish:', values);
            srvGameCmdApi.emergencyExe(values).then(d => {
                this.setState({result: d});
            });
        }
    }

    onFormFinishFailed = (errorInfo: any) => {
        console.error('onFormFinishFailed:', errorInfo);
    }


}