import { consoleApi } from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

class SuperviseReportApi extends ApiBase {

    list(filter: any) {
        return consoleApi.get({
            path: 'supervise/report/list',
            title: Configure.Gl(1698,"监控汇报列表"),
            param: filter,
            quiet: true
        });
    }

    /** 一键清理24小时前的所有监控汇报 */
    clear() {
        return consoleApi.get({
            path: 'supervise/report/clear',
            title: Configure.Gl(1782,"一键清理24小时前的所有监控汇报"),
            quiet: false
        });
    }

}

export const superviseReportApi: SuperviseReportApi = new SuperviseReportApi();