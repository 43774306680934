import React from 'react';
import { AbsFilter, StdItemFilter } from '../../../part/StdItemFilter';
import { Form, Input, DatePicker, Button} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { formatMoment } from '../../../util/toolbox';
import moment from 'moment';
import Configure from '../../../api/Configure';

class Filter extends AbsFilter {
    srvCode?: string = undefined;
    beginTime?: string = undefined;
    endTime?: string = undefined;
}

export default class StatPayRankFilter extends StdItemFilter {
    formRef = React.createRef<FormInstance>();
    state = {
        srvCode: undefined as string | undefined,
        beginTime: undefined as string | undefined,
        endTime: undefined as string | undefined,
    };

    filter:Filter = new Filter();

    getFilter() { return this.filter; }

    async componentDidMount() {
        //const opt = await srvCrossApi.optClassify();
        //this.setState({optClassify: opt});
    }

    render() {
        return (
            <span>
                <Form ref={this.formRef}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
                name="basic"
                initialValues={{
                    beginTime: moment(new Date(new Date(new Date().toLocaleDateString()).getTime()), "YYYY-MM-DD"),
                    endTime: moment(new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1), "YYYY-MM-DD"),
                }}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}>
                    <Form.Item label={Configure.Gl(1121,'服务器编码')} name="srvCode">
                        <Input placeholder={Configure.Gl(1121,'服务器编码')}/>
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1119,'开始时间')} name="beginTime">
                        <DatePicker  showTime />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1120,'结束时间')} name="endTime">
                        <DatePicker showTime />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">{Configure.Gl(1228,'确定')}</Button>
                    </Form.Item>
                </Form>
            </span>
        );
    }

    onFormFinish = (values: any) => {
        let query = values;
        this.setValue("beginTime",formatMoment(query.beginTime));
        this.setValue("endTime",formatMoment(query.endTime));
        this.setValue("srvCode",query.srvCode);
        this.doSearch();
    }

    addKeyToData(data: any[]) {
        data.forEach(element => { if(!element.key){element.key = Math.random() }});
    }

    onFormFinishFailed = (errorInfo: any) => {
        console.error('onFormFinishFailed:', errorInfo);
     }
}