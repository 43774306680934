import { statApi } from '../../util/http';
import Configure from '../Configure';

class StatLoginApi {

    loginFlowSummary(filter: any) {
        if(filter.createTimeBegin!==undefined){
            filter.createTimeBegin = Date.parse(filter.createTimeBegin);
        }
        if(filter.createTimeEnd!==undefined){
            filter.createTimeEnd = Date.parse(filter.createTimeEnd);
        }
        if(filter.updateTimeBegin!==undefined){
            filter.updateTimeBegin = Date.parse(filter.updateTimeBegin);
        }
        if(filter.updateTimeEnd!==undefined){
            filter.updateTimeEnd = Date.parse(filter.updateTimeEnd);
        }
        return statApi.get({
            path: 'login/flow/count',
            title: Configure.Gl(1044,"登录流程打点"),
            quiet: true,
            param: filter
        });
    }
}

export const statLoginApi: StatLoginApi = new StatLoginApi();