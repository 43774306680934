import {consoleApi} from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

class GmFreezeApi extends ApiBase {
    
    create(item: any) {
        return consoleApi.post({
            path: 'gm/freeze/create',
            param: item,
            title: Configure.Gl(1683,'GM冻结玩家账号操作')
        });
    }

    list(query: any) {
        return consoleApi.get({
            path: 'gm/freeze/list',
            param: query,
            title: Configure.Gl(1684,"GM冻结玩家账号列表"),
            quiet: true
        });
    }

}

export const gmFreezeApi: GmFreezeApi = new GmFreezeApi();