import { consoleApi } from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

class CloudDeivceApi extends ApiBase {
    
    create(item: any) {
        return consoleApi.post({
            path: 'cloud/device/create',
            param: item,
            title: Configure.Gl(1116,'新建')
        });
    }

    update(item: any) {
        return consoleApi.post({
            path: 'cloud/device/update',
            param: item,
            title: Configure.Gl(1132,'修改')
        });
    }

    delete(id: number) {
        return consoleApi.get({
            path: 'cloud/device/delete?id='+id,
            title:  Configure.Gl(1133,"删除"),
            quiet: true
        });
    }

    list() {
        return consoleApi.get({
            path: 'cloud/device/list',
            title:  Configure.Gl(1134,"列出"),
            quiet: true
        });
    }

    status(code: string, cmd: string) {
        return consoleApi.get({
            path: 'cloud/device/status',
            title: Configure.Gl(1654,"执行状态查看指令"),
            param: {code, cmd},
            quiet: true
        });
    }

    async optionList() {
        let data = await this.list() as any[];
        let opts: any[] = [];
        data.forEach(element => {
            opts.push({
                text: "("+ element.code + ")" + element.name,
                value: element.code
            });
        });
        return opts;
    }

    setTime(code: string, time: any) {
        return consoleApi.get({
            path: 'cloud/device/time/set',
            param: {code, time},
            title: Configure.Gl(1655,"调整时间"),
            quiet: false
        });
    }

}

export const cloudDeivceApi: CloudDeivceApi = new CloudDeivceApi();