import React from 'react';
import { Form, Input, Button, Switch, DatePicker } from 'antd';
import { StdItemEdit, IProps, IState } from '../../../part/StdItemEdit';
import { srvMergeApi } from '../../../api/console/srv.merge';
import Configure from '../../../api/Configure';


export default class SrvMergeEdit extends StdItemEdit<IProps, IState> {

    state = {
        ...super.buildInitState(),
    }

    renderForm() {
        const { createMode, item } = this.state;
        console.log("拿到的item=", item);
        item.migrateAmLast = this.parseZone(item.migrateAmLast);
        item.migrateGameMongoLast = this.parseZone(item.migrateGameMongoLast);
        return (
            <Form 
                labelCol={{ span: 14 }}
                wrapperCol={{ span: 20 }}
                name="basic"
                initialValues={item}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}
            >
                <Form.Item label={Configure.Gl(1055,"ID")} name="id">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={Configure.Gl(1505,"原始游戏服编码")} name="srvCodeOrigin">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1506,"当前游戏服编码")} name="srvCodeNow">
                    <Input/>
                </Form.Item>
                <Form.Item label={Configure.Gl(1507,"账号主角区服迁移的执行次数")} name="migrateAmTimes">
                    <Input disabled/>
                </Form.Item>
                <Form.Item label={Configure.Gl(1508,"账号主角区服迁移的最近一次执行时间")} name="migrateAmLast">
                    <DatePicker showTime disabled/>
                </Form.Item>
                <Form.Item label={Configure.Gl(1509,"游戏服mongo迁移的执行次数")} name="migrateGameMongoTimes">
                    <Input disabled/>
                </Form.Item>
                <Form.Item label={Configure.Gl(1510,"游戏服mongo迁移的最近一次执行时间")} name="migrateGameMongoLast">
                    <DatePicker showTime disabled/>
                </Form.Item>
                <Form.Item label={Configure.Gl(1511,"游戏服mongo迁移的最近一次日志")} name="migrateGameMongoLog">
                    <Input.TextArea disabled/>
                </Form.Item>
                <Form.Item label={Configure.Gl(1358,'启用')} name="enabled" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1065,"备注")} name="note">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit"> {createMode ? Configure.Gl(1387,"保存新增")  : Configure.Gl(1388,"保存编辑")} </Button>
                </Form.Item>
            </Form>
        );
    }

    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        //if(true) return;
        const { createMode } = this.state;
        if (createMode) {
            srvMergeApi.create(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            });
        } else {
            srvMergeApi.update(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            })
        }
    }

}