import { Button, message } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import React from 'react';
import XLSX from 'xlsx'
import FileSaver from 'file-saver';
import Configure from '../../../api/Configure';

export interface IProps {
    onExcelprot: Function
}

export interface SrvEntity {
    itemList: [],
}

export default class ExcelEdit extends React.Component<IProps> {
    state = {
        itemList: [] as any[],
        loading: false,
    }

    private filter = {
        masterIdHex: undefined as string | undefined,
        srvCode: undefined as string | undefined,
        masterName: undefined as string | undefined,
    }


    render() {
        return (<span>
            <Button type="primary" onClick={() => this.onExcelprot()}>{Configure.Gl(1577, "导出EXCEL")}</Button>
        </span>
        );
    }

    onExcelprot = () => {
        this.props.onExcelprot({ ...this.filter });
        console.log("导出execl");
        //let data = this.queryData(this.state.beginTime);
        if (this.state.itemList.length <= 0) {
            console.error(Configure.Gl(Configure.GL_UNDEFINED, 'Execl不存在!'));
            message.error("导出列表为空!");
            return;
        }
        let data = this.state.itemList;
        const workbook = XLSX.utils.book_new();//创建一个新的工作簿对象

        // let excelName: string = data.DatePD;

        // for (var attr in data) {
        //     let row: any = {};
        //     row.array = data[attr];
        //     if (row.array.length > 0) {
        //         let ws = XLSX.utils.json_to_sheet(row.array);//将json对象数组转化成工作表
        //         XLSX.utils.book_append_sheet(workbook, ws, attr + "Sheet");//把sheet添加到workbook里，第三个参数是sheet名
        //     }
        // }
        let ws = XLSX.utils.json_to_sheet(data);//将json对象数组转化成工作表
        XLSX.utils.book_append_sheet(workbook, ws, "sheet1");//把sheet添加到workbook里，第三个参数是sheet名
        //const wopts = { type:'array'};//写入的样式bookType:输出的文件类型，type：输出的数据类型，bookSST: 是否生成Shared String Table，官方解释是，如果开启生成速度会下降
        const wbout = XLSX.write(workbook, { type: 'array' });// 浏览器端和node共有的API,实际上node可以直接使用xlsx.writeFile来写入文件,但是浏览器没有该API
        FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), '导出EXECEL.xlsx');//保存文件
    }

    clearItem() {
        this.setState({ itemList: [] });
    }

    setOdataSource(list: any[], column: ColumnsType<any>) {

        // this.state.itemList.length = 0;
        this.setState({ itemList: [] });
        for (let i = 0; i < list.length; i++) {
            let AllData: any = {
            };
            for (var k in list[i]) {
                let keyname: string = this.iskeyValue(k, column);
                if (keyname !== "") {
                    let key = keyname;
                    let value = list[i][k];
                    AllData[key] = value;
                }
            }
            this.state.itemList.push(AllData);
        }
    }

    iskeyValue(key: any, column: ColumnsType<any>): any {

        for (let i = 0; i < column.length; i++) {
            let obj: any = {
                dataIndex: "",
                title: "",
            };
            obj = column[i];
            if (obj.dataIndex === key) {
                return obj.title;
            }
        }
        return "";
    }

}