import { consoleApi, payApi, statApi } from '../../util/http';
import Configure from '../Configure';

class CleanApi {

    clear() {
        return consoleApi.get({
            path: 'supervise/report/clear',
            title: Configure.Gl(1819,"一键清理24小时前的所有监控汇报"),
            quiet: false
        });
    }

    heroLineupClear() {
        return statApi.get({
            path: 'res/hero/lineup/clear',
            title: Configure.Gl(1820,"英雄阵容清理"),
            quiet: false,
        });
    }

    recordClear() {
        return payApi.get({
            path: 'record/clear',
            title: Configure.Gl(1821,'清理1个月前的无效订单')
        });
    }
}
export const cleanApi: CleanApi = new CleanApi();