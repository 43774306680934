import React, { RefObject } from 'react';
import { Button, Space, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/Table';

import { RoutePath } from '../../../../route/path';
import { srvGameAccessApi } from '../../../../api/console/srv.game.access';
import SrvGameAccessEdit from './SrvGameAccessEdit';
import SrvGameAccessFilter from './SrvGameAccessFilter';
import { compareCode } from '../../../../util/toolbox';
import { IPagingState, StdItemPageLimit } from '../../../../part/StdItemPageLimit';
import Configure from '../../../../api/Configure';

interface IState extends IPagingState {
    sth: string;
}

export default class SrvGameAccessIndex extends StdItemPageLimit<IState> {

    private editor: RefObject<SrvGameAccessEdit> = React.createRef();

    state = {
        ...this.getDefaultState(),
        sth: 'access'
    };

    getCols(): ColumnsType<any> {
        return [
            { title: Configure.Gl(1055,'ID'), dataIndex: 'id', width: 80, fixed: 'left', sorter: (a, b) => a.id - b.id, },
            { title: Configure.Gl(1465,'游戏服编号'), dataIndex: 'srvCode', width: 130, fixed: 'left', sorter: (a, b) => compareCode(a.code, b.code) },
            { title: Configure.Gl(1018,"游戏服组"), dataIndex: 'groupId', width: 120, render: (v, r) => "("+v+")"+r.groupName },
            { title: Configure.Gl(1451,"模式"), dataIndex: 'mode', width: 120, render: (v, r) => v === 1 ? Configure.Gl(1452,'允许') : Configure.Gl(1453,'禁止') },
            { title: Configure.Gl(1466,'是否平台限定'), dataIndex: 'platformLimit', width: 120, render: (v) => v ? "√" : "" },
            { title: Configure.Gl(1122,'平台编码'), dataIndex: 'platformCode', width: 120, },
            { title: Configure.Gl(1467,'是否渠道限定'), dataIndex: 'channelLimit', width: 120, render: (v) => v ? "√" : "" },
            { title: Configure.Gl(1468,'渠道限定编码'), dataIndex: 'channelCode', width: 120, },
            { title: Configure.Gl(1469,'是否GameID限定'), dataIndex: 'gameIdLimit', width: 120, render: (v) => v ? "√" : "" },
            { title: Configure.Gl(1123,'GameID'), dataIndex: 'gameId', width: 120, },
            { title: Configure.Gl(1470,'是否扩展限定'), dataIndex: 'extLimit', width: 120, render: (v) => v ? "√" : "" },
            { title: Configure.Gl(1458,"扩展码"), dataIndex: 'ext', width: 120, },
            {
                title: Configure.Gl(1057,'操作'),
                width: 260,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117,"编辑")}</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133,'删除')}</Button>
                    </Space>);
                },
            },
        ];
    }

    getFilterUI() {
        return (<Space>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389,'新增')}{this.state.sth}</Button>
            <SrvGameAccessFilter search={(f:any) => this.onFilterChange(f)}></SrvGameAccessFilter>
        </Space>);
    }

    getEditorUI() {
        return (<SrvGameAccessEdit ref={this.editor} reload={() => this.reload()} ></SrvGameAccessEdit>);
    }

    getPath() { return RoutePath.SRV_GAME_ACCESS_INDEX; }

    async reloadForData(query: any) {
        let data = await srvGameAccessApi.list(query) as any;
        return data;
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1391,'你确定要删除')+'('+item.id+')' + item.srvCode + "?",
            onOk() {
                srvGameAccessApi.delete(item.id).then(d => that.reload());
            }
        });
    }

}