import React, { CSSProperties } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Carousel, Row, Col, Divider } from 'antd';

import { HomeLayout } from '../../layout/HomeLayout';
import { RoutePath } from '../../route/path';
import MasterQuery from '../../part/master/MasterQuery';
import ServerInfoIndex from '../other/ServerInfoIndex';
import TimeInfoIndex from '../other/TimeInfoIndex';

export default class HomeIndex extends React.Component<RouteComponentProps> {

    render() {
        console.log("render");
        return (
            <HomeLayout path={RoutePath.HOME_INDEX}>
                {this.renderBanner()}
                <Divider />
                {this.renderApp()}
                <Divider />
            </HomeLayout>
        );
    }

    renderBanner() {
        const contentStyle: CSSProperties = {
            height: '160px',
            color: '#fff',
            lineHeight: '160px',
            textAlign: 'center',
            background: '#364d79',
        };
        return (<Carousel autoplay>
            <div>
                <h3 style={contentStyle}>
                    <span role='img' aria-labelledby="panda1">欢迎使用最新版本🤠GMC</span>
                </h3>
            </div>
            <div>
                <h3 style={contentStyle}>
                    <span role='img' aria-labelledby="panda1">充值20210816元可升级至尊享版👮</span>
                </h3>
            </div>
            <div>
                <h3 style={contentStyle}>
                    <span role='img' aria-labelledby="panda1">点击此处进入👩‍🎓‍[智商税缴纳入口]</span>
                </h3>
            </div>
            <div>
                <h3 style={contentStyle}>
                    <span role='img' aria-labelledby="panda1">🤡-.-🙃</span>
                </h3>
            </div>
        </Carousel>);
    }

    renderApp() {
        const appStyle: CSSProperties = {
            minWidth: 100,
            minHeight: 80,
            backgroundColor: '#969ba9',
            textAlign: 'center',
        }
        return (<div>
            <Row gutter={[16, 16]}>
                <Col span={6} > 
                    <div style={appStyle}><br/>
                        <MasterQuery onSelect={(r:any)=>console.log('选择了:',r)}/><br/>
                        <span role='img' aria-labelledby="master-query">🤡高频使用的功能🙃</span><br/><br/>
                    </div> 
                </Col>
                <Col span={6} > 
                    <div style={appStyle}><br/>
                        <ServerInfoIndex/><br/>
                        <span role='img' aria-labelledby="server-info">😎运维部署观察👀</span><br/><br/>
                    </div> 
                </Col>
                <Col span={6} > 
                    <div style={appStyle}><br/>
                        <TimeInfoIndex/><br/>
                        <span role='img' aria-labelledby="time-info">🧙时区时间调试🤠</span><br/><br/>
                    </div> 
                </Col>
                <Col span={6} > 
                    <div style={appStyle}>app-4</div> 
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col span={6} > 
                    <div style={appStyle}>app-5</div> 
                </Col>
                <Col span={6} > 
                    <div style={appStyle}>app-6</div> 
                </Col>
                <Col span={6} > 
                    <div style={appStyle}>app-7</div> 
                </Col>
                <Col span={6} > 
                    <div style={appStyle}>app-8</div> 
                </Col>
            </Row>
        </div>);
    }

    componentDidMount() {
        console.log("HomeIndex::componentDidMount");
        //let d: Date = new Date("2020-12-03T20:43:05.123+00:00");
        // import moment from 'moment';
        //let m: moment.Moment = moment.parseZone("2020-12-03T20:43:05.123+08:00");
        //console.log("日期d=", d, ", m=", m);
    }

}