import { Button } from 'antd';
import Search from 'antd/lib/input/Search';
import Modal from 'antd/lib/modal/Modal';
import Table, { ColumnsType } from 'antd/lib/table';
import { TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import React from 'react';
import Configure from '../../api/Configure';
import { srvGameApi } from '../../api/console/srv.game';

export interface IProps {
    onSelect: Function
    initData: any[],
    readonly: boolean,
}

export interface SrvEntity {
    id: any,
    code: string;
    index: any;
    name: string;
    recommend: string;
    folder: string;
    platformCode: string;
}

export default class SrvSelectEdit extends React.Component<IProps> {
    state = {
        show: false,
        itemList:[] as any[],
        selectItems:[] as any[],
        itemKeyMap:{} as any,
        codeFilter: undefined as any,
    }

    getCols(): ColumnsType<SrvEntity> {
        return [
            {
                title: Configure.Gl(1055,'ID'),
                dataIndex: 'id',
                width: 80,
                sorter: (a,b) => a.id - b.id
            },
            {
                title: Configure.Gl(1056,"编码"),
                dataIndex: 'code',
                width: 80,
                sorter: (a,b) => (a.code as any) > (b.code as any) ? 1 : -1,
            },
            {
                title: Configure.Gl(1471,"序号"),
                dataIndex: 'index',
                width: 80,
                sorter: (a,b) => a.index - b.index,
            },
            {
                title: Configure.Gl(1499,'名称'),
                dataIndex: 'name',
                width: 80,
            },
            {
                title: Configure.Gl(1473,"推荐"),
                dataIndex: 'recommend',
                width: 80,
            },
            {
                title: Configure.Gl(1500,'服务器分类'),
                dataIndex: 'folder',
                width: 80,
            },
            {
                title: Configure.Gl(1501,'所属平台编码'),
                dataIndex: 'platformCode',
                width: 80,
            },
        ]
    }

    render() {
        const columns: ColumnsType<any> = this.getCols();
        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ["10","50","100","10000"],
        };
        const {selectItems,itemList} = this.state;
        const item = selectItems && selectItems.length > 0? selectItems: this.convert(this.props.initData);
        this.copyKeyToData(item);
        const rowSelection: TableRowSelection<any> = {
            type: 'checkbox',
            selectedRowKeys: item.map(x => {return x.key}),
            onChange: (keys, rows) =>this.onSelectItemChanged(keys, rows),
        };
        const filteredList = this.filterList(itemList);
        return (<span>
            <div style={{display : this.props.readonly? "none": "block"}}>
                <Button type="primary" onClick={() => this.setState({show: true})}>{Configure.Gl(1502,'选择游戏服务器')}</Button>
                <Button type="default" onClick={() => this.clearSrvs()}>{Configure.Gl(1532,'清空服务器')}</Button>
            </div>
            <Table dataSource={item} columns={columns}/>
            <Modal title={Configure.Gl(1502,'选择游戏服务器')} visible={this.state.show} width="90%"
            onOk={() => this.handleModalOk()} onCancel={() => this.handleModalCancel()} footer={null} destroyOnClose>
                <Search placeholder={Configure.Gl(1503,"输入要搜索的服务器编码")} onSearch={v=>{this.onSearch(v)}} style={{ width: 250 }} />
                <Table dataSource={filteredList} columns={columns} rowSelection={rowSelection} pagination={pagination}/>
                <Button type="primary" onClick={() => this.ok()}>{Configure.Gl(1228,'确定')}</Button>
                <Button type="default" onClick={() => this.handleModalCancel()}>{Configure.Gl(1229,'取消')}</Button>
            </Modal>
        </span>
        );
    }

    filterList(v : any[]){
        const {codeFilter} = this.state;
        const l = [] as any[];
        if(codeFilter && codeFilter.length > 0){
            v.forEach( element => {
                if(element.code && (element.code as String).startsWith(codeFilter)){
                    l.push(element);
                }
            });
            return l;
        }
        return v;
    }

    onSearch(v: any){
        this.setState({codeFilter: v}, ()=> {
            this.reload();
        });
    }

    convert(data: any[]){
        if(!data){
            return [];
        }
        let a = data;
        a.forEach(element => {
            element.code = element.srvCode;
        });
        return a;
    }

    clearSrvs(){
        this.setState({ show: false, selectItems: [], codeFilter: undefined},
            ()=>{
                this.props.onSelect(this.state.selectItems);
            });
    }

    onSelectItemChanged(selectedRowKeys: React.Key[], selectedRows: any[]) {
        this.setState({selectItems: selectedRows});
    }

    ok(){
        this.setState({ show: false,codeFilter: undefined },()=>{
            this.props.onSelect(this.state.selectItems);
        });
    }

    copyKeyToData(data: any[]){
        data.forEach(v => {
            // if(!v.key){
                v.key = this.state.itemKeyMap[v.code];
                if(!v.key){
                    v.key = Math.random();
                }
            // }
        })
    }

    addKeyToData(data: any[]) {
        data.forEach(element => {
            if(element.key === undefined || element.key === null){
                element.key = Math.random();
            }
        });
    }

    async reload() {
        let data = await srvGameApi.listSnapshotWithoutEaten() as any[];
        console.log("请求到的服务器数据是：", data);
        this.addKeyToData(data);
        let map = {} as any;
        data.forEach(v =>{
            map[v.code] = v.key;
        })
        this.setState({ itemList: data, itemKeyMap: map});
    }

    componentDidMount() {
        this.reload();
    }

    handleModalOk() {

    }

    handleModalCancel() {
        this.setState({selectItems:[], show: false, codeFilter: undefined});
    }

}