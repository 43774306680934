import React, { RefObject } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Table, Space, Divider, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';
import { StdItemPage } from '../../part/StdItemPage';
import { HomeLayout } from '../../layout/HomeLayout';
import { RoutePath } from '../../route/path';
import { staffRoleApi } from '../../api/console/staff.role';
import Configure from '../../api/Configure';
import StaffRoleLimitEdit from './StaffRoleLimitEdit';
import StaffRoleLimitFilter from './StaffRoleLimitFilter';

export default class StaffRoleLimitIndex extends StdItemPage<any> {

    private editor: RefObject<StaffRoleLimitEdit>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
    }

    state = {
        showEdit: false,
        itemList: [],
        query: {}
    };

    render() {
        const columns: ColumnsType<any> = [
            {
                title: Configure.Gl(1000, "角色"),
                dataIndex: 'roleId',
                fixed: 'left',
                width: 160,
                render: (value: any, record: any) => {

                    return (<Button type="link" onClick={() => this.editor.current?.showUpdate(record)}>{this.editor.current?.getRoleName(record.roleId)}</Button>);
                },
            },
            {
                title: "GameId",
                dataIndex: 'gameId',
                width: 150,
            },
            {
                title: Configure.Gl(1057, '操作'),
                width: 200,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117, "编辑")}</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133, '删除')}</Button>
                    </Space>);
                },
            }
        ];

        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
        };

        return (
            <HomeLayout path={RoutePath.STAFF_GAMEID_LIMIT_INDEX}>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389, '新增')}</Button>
                    <StaffRoleLimitFilter search={(f: any) => this.onFilterChange(f)}></StaffRoleLimitFilter>
                </Space>
                <Divider />
                <Table dataSource={this.state.itemList} columns={columns} pagination={pagination} scroll={{ x: 1500, y: 670 }} />
                <StaffRoleLimitEdit ref={this.editor} reload={() => this.reload()} ></StaffRoleLimitEdit>
            </HomeLayout>
        );
    }

    componentDidMount() {
        console.log("StaffRoleLimitIndex::componentDidMount");
        this.reload();
    }

    onFilterChange(filter: any) {
        console.log("沙雕 filter=", filter);
        this.setState({ query: filter }, () => {
            this.reload();
        });
    }

    async reload() {
        const { query } = this.state;
        let data = await staffRoleApi.listGameId(query) as any;
        console.log(Configure.Gl(1390, "请求到的数据是："), data);
        this.addKeyToData(data.records);
        this.setState({ itemList: data.records });
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066, '警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1391, '你确定要删除') + item.roleId + '(' + item.gameId + ')？',
            onOk() {
                staffRoleApi.deleteGameId(item.id).then(d => that.reload());
            }
        });
    }

}