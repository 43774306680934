import { consoleApi } from '../../util/http';
import Configure from '../Configure';

export class SrvGameExtApi {

    create(item: any) {
        return consoleApi.post({
            path: 'srv/game/ext/create',
            param: item,
            title: Configure.Gl(1740,'新建游戏服扩展配置')
        });
    }

    update(item: any) {
        return consoleApi.post({
            path: 'srv/game/ext/update',
            param: item,
            title: Configure.Gl(1741,'修改游戏服扩展配置')
        });
    }

    delete(id: number) {
        return consoleApi.get({
            path: 'srv/game/ext/delete?id='+id,
            title: Configure.Gl(1742,"删除游戏服扩展配置"),
            quiet: true
        });
    }

    list(query: any) {
        return consoleApi.get({
            path: 'srv/game/ext/list', 
            title: Configure.Gl(1743,'列表游戏服扩展配置'),
            param: query,
            quiet: true
        });
    }
}

export const srvGameExtApi: SrvGameExtApi = new SrvGameExtApi();