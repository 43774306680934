import React from 'react';
import { Form, Input, Button } from 'antd';
import { StdItemEdit, IProps, IState } from '../../part/StdItemEdit';
import { cloudDeivceApi } from '../../api/console/cloud.device';
import Configure from  '../../api/Configure';

export default class CloudDeviceEdit extends StdItemEdit<IProps, IState> {

    state = {
        ...super.buildInitState(),
    }

    renderForm() {
        const { createMode, item } = this.state;
        console.log("拿到的item=", item);
        return (
            <Form 
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="basic"
                initialValues={item}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}
            >
                <Form.Item label={Configure.Gl(1055,"ID")} name="id">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={Configure.Gl(1056,"编码")} name="code">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1058,"服务器名")} name="name">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1059,"内网地址")} name={['host','LAN']}>
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1060,"外网地址")} name={['host','WAN']}>
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1061,"域名")} name={['host','domain']}>
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1062,"SSH端口")} name={['ssh','port']}>
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1063,"SSH用户名")} name={['ssh','user']}>
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1064,"SSH密码")} name={['ssh','password']}>
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1065,"备注")} name="note">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit"> {createMode ? Configure.Gl(1387,"保存新增")  : Configure.Gl(1388,"保存编辑")} </Button>
                </Form.Item>
            </Form>
        );
    }


    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        //if(true) return;
        const { createMode } = this.state;
        if (createMode) {
            cloudDeivceApi.create(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            });
        } else {
            cloudDeivceApi.update(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            })
        }
    }

}