import { consoleApi } from '../../util/http';
import Configure from '../Configure';

class SrvGameAccessApi {

    create(item: any) {
        return consoleApi.post({
            path: 'srv/game/access/create',
            param: item,
            title: Configure.Gl(1116,"新建")
        });
    }

    update(item: any) {
        return consoleApi.post({
            path: 'srv/game/access/update',
            param: item,
            title: Configure.Gl(1132,'修改')
        });
    }

    delete(id: any) {
        return consoleApi.get({
            path: 'srv/game/access/delete?id=' + id,
            title: Configure.Gl(1133,'删除'),
            quiet: true
        });
    }

    list(filter?:any) {
        return consoleApi.get({
            path: 'srv/game/access/list',
            title: Configure.Gl(1704,'列表'),
            param: filter,
            quiet: true
        });
    }
}

export const srvGameAccessApi: SrvGameAccessApi = new SrvGameAccessApi();