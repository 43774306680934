import { metaSrvApi } from "../api/meta/all";
import { SelectOpt } from "./CommonDef";

export default class SrvEnum {

    public static instance:SrvEnum = new SrvEnum();

    public async load() {
        console.log("从服务器加载枚举");
        let data: any = await metaSrvApi.gmc_enum();
        console.log("从meta-server获取到", data);
        this.note = data.note;
        this.payRedirect = this.defaultArr(data.payRedirect);
        this.timezone = this.defaultArr(data.timezone);
        this.lang = this.defaultArr(data.lang);
        this.gameVer = this.defaultArr(data.gameVer);
        this.vipStatTar = this.defaultArr(data.vipStatTar);
        this.currencyCode = this.defaultArr(data.currencyCode);
        this.productVer = this.defaultArr(data.productVer);
    }

    private defaultArr(value: any) {
        if(value === null || value === undefined) {
            return [];
        }
        return value;
    }

    public note:string = "";
    public payRedirect:SelectOpt[] = [];
    public timezone:SelectOpt[] = [];
    public lang:SelectOpt[] = [];
    public gameVer:SelectOpt[] = [];
    public vipStatTar:SelectOpt[] = [];
    public currencyCode:SelectOpt[] = [];
    public productVer:SelectOpt[]=[];
}