import React from 'react';
import { Button, Select } from 'antd';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Configure from '../../../../api/Configure';

export interface IProps {
    search: Function
}

export default class SrvCrossPlanFilter extends React.Component<IProps, any> {

    state = {
        changed: undefined as string | undefined,
        enabled: undefined as string | undefined,
    };

    private filter = {
        changed: undefined as string | undefined,
        enabled: undefined as string | undefined,
        clear() {
            this.changed = undefined;
            this.enabled = undefined;
        }
    }

    setChanged(v: string) {
        this.filter.changed = v;
        this.setState({ changed: v });
    }

    setEnabled(v: string) {
        this.filter.enabled = v;
        this.setState({ enabled: v });
    }

    render() {
        return (
            <span>
                <Select placeholder={Configure.Gl(1398,"执行状况")} value={this.state.changed} onChange={v => this.setChanged(v)}>
                    <Select.Option key="1" value="true">{Configure.Gl(1399,"已执行")} </Select.Option>
                    <Select.Option key="2" value="false">{Configure.Gl(1400,"待执行")} </Select.Option>
                </Select>
                <Select placeholder={Configure.Gl(1358,'启用')} value={this.state.enabled} onChange={v => this.setEnabled(v)}>
                    <Select.Option key="1" value="true">{Configure.Gl(1358,'启用')}</Select.Option>
                    <Select.Option key="2" value="false">{Configure.Gl(1401,'禁用')} </Select.Option>
                </Select>
                <Button icon={<SearchOutlined />} onClick={this.doSearch}>{Configure.Gl(1152,'查找')}</Button>
                <Button type="dashed" icon={<CloseCircleOutlined />} onClick={this.doClear}>{Configure.Gl(1153,'清空')}</Button>
            </span>
        );
    }

    doSearch = () => {
        this.props.search(this.filter);
    }

    doClear = () => {
        this.setState({ changed: null, enabled: null });
        this.filter.clear();
        this.props.search();
    }
}