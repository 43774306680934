import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { HomeLayout } from '../../layout/HomeLayout';
import { RoutePath } from '../../route/path';
import { paySrvApi } from '../../api/pay/all';
import Configure from '../../api/Configure';

export default class PayStatGsLifeIndex extends React.Component<RouteComponentProps> {

    render() {
        return (
            <HomeLayout path={RoutePath.PAY_STAT_GS_LIFE_INDEX}>
                {Configure.Gl(1383,'支付life统计')}
            </HomeLayout>
        );
    }

    componentDidMount() {
        console.log("PayStatGsLifeIndex::componentDidMount")
        paySrvApi.statGsLife({}).then(d => {
            console.log("支付life统计返回", d);
        })
    }

}