import React from 'react';
import { Input, DatePicker } from 'antd';
import { AbsFilter, StdItemFilter } from '../../../part/StdItemFilter';
import Configure from '../../../api/Configure';

class Filter extends AbsFilter {
    srvCode?: string = undefined;
    updateTimeBegin?: moment.Moment = undefined;
    updateTimeEnd?: moment.Moment = undefined;
}

export default class StatHeroLineupFilter extends StdItemFilter {

    state = {
        srvCode: undefined as string | undefined,
        updateTimeBegin: undefined as moment.Moment | undefined,
        updateTimeEnd: undefined as moment.Moment | undefined,
    };

    filter: Filter = new Filter();

    getFilter() { 
        return this.filter;
    }

    render() {
        return (
            <span>
                <Input placeholder={Configure.Gl(1079,'游戏服编码')} value={this.state.srvCode} onChange={e => this.setValue('srvCode', e.target.value)} style={{width: 180}}/>
                <DatePicker placeholder={Configure.Gl(1578,"数据开始时间")} value={this.state.updateTimeBegin} showTime onChange={e => this.setValue('updateTimeBegin', e)} />
                <DatePicker placeholder={Configure.Gl(1579,"数据结束时间")} value={this.state.updateTimeEnd} showTime onChange={e => this.setValue('updateTimeEnd', e)} />
                { this.renderCmd()}
            </span>
        );
    }

}