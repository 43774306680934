import { consoleApi } from '../../util/http';
import Configure from '../Configure';

export class SrvMergeApi {

    create(item: any) {
        return consoleApi.post({
            path: 'srv/merge/create',
            param: item,
            title: Configure.Gl(1730,'新建合服配置')
        });
    }

    update(item: any) {
        return consoleApi.post({
            path: 'srv/merge/update',
            param: item,
            title: Configure.Gl(1731,'修改合服配置')
        });
    }

    delete(id: number) {
        return consoleApi.get({
            path: 'srv/merge/delete?id='+id,
            title: Configure.Gl(1732,"删除合服配置"),
            quiet: true
        });
    }

    list(filter: any) {
        return consoleApi.get({
            path: 'srv/merge/list', 
            title: Configure.Gl(1733,'列表合服配置'),
            param: filter,
            quiet: true
        });
    }

    migrateAccountMaster(fromSrvCode: string, toSrvCode: string) {
        return consoleApi.get({
            path: 'srv/merge/migrate/account/master',
            title: Configure.Gl(1734,"合服迁移账户角色"),
            param: { fromSrvCode, toSrvCode },
            quiet: true
        });
    }

    migrateGameMongo(fromSrvCode: string, toSrvCode: string) {
        return consoleApi.get({
            path: 'srv/merge/migrate/game/mongo',
            title: Configure.Gl(1735,"合服迁移游戏服mongo数据"),
            param: { fromSrvCode, toSrvCode },
            quiet: true
        });
    }
}

export const srvMergeApi: SrvMergeApi = new SrvMergeApi();