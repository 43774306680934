import React from 'react';
import { Input } from 'antd';
import { AbsFilter, StdItemFilter } from '../../../part/StdItemFilter';
import Configure from '../../../api/Configure';

class Filter extends AbsFilter {
    srvCode?: string = undefined;
    srvName?: string = undefined;
    openId?: string = undefined;
    roleId?: string = undefined;
}

export default class SuperviseReportFilter extends StdItemFilter {

    state = {
        srvCode: undefined as string | undefined,
        srvName: undefined as string | undefined,
        openId: undefined as string | undefined,
        roleId: undefined as string | undefined,
    };

    filter: Filter = new Filter();

    getFilter() { 
        return this.filter;
    }

    componentDidMount() {
        //
    }

    render() {
        return (
            <span>
                <Input placeholder={Configure.Gl(1079,"游戏服编码")} value={this.state.srvCode} onChange={e => this.setValue('srvCode', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1080,"游戏服名")} value={this.state.srvName} onChange={e => this.setValue('srvName', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1097,"OPEN-ID")} value={this.state.openId} onChange={e => this.setValue('openId', e.target.value)} style={{width: 120}}/>
                <Input placeholder={Configure.Gl(1098,"角色唯一ID")} value={this.state.roleId} onChange={e => this.setValue('roleId', e.target.value)} style={{width: 180}}/>
                { this.renderCmd()}
            </span>
        );
    }

}