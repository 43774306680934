import { consoleApi } from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

class SrvGameApi extends ApiBase {

    statusChangeAll(statusOld: any, statusNew: any) {
        return consoleApi.get({
            path: 'srv/game/status/change/all?statusOld=' + statusOld + "&statusNew=" + statusNew,
            title: Configure.Gl(1714,"改变所有服务器状态(执行后请手动刷新)"),
            quiet: false
        });
    }

    ct(srvCode: string) {
        return consoleApi.get({
            path: 'srv/game/ct?srvCode=' + srvCode,
            title: Configure.Gl(1715,"服务器CT"),
            quiet: true
        });
    }

    gc(srvCode: string) {
        return consoleApi.get({
            path: 'srv/game/gc?srvCode=' + srvCode,
            title: Configure.Gl(1716,"服务器GC"),
            quiet: true
        });
    }

    jvmInfo(srvCode: string) {
        return consoleApi.get({
            path: 'srv/game/jvm/info?srvCode=' + srvCode,
            title: Configure.Gl(1717,"服务器JVM状态"),
            quiet: true
        });
    }

    create(item: any) {
        return consoleApi.post({
            path: 'srv/game/create',
            param: item,
            title: Configure.Gl(1116,"新建")
        });
    }

    update(item: any) {
        return consoleApi.post({
            path: 'srv/game/update',
            param: item,
            title: Configure.Gl(1132,'修改')
        });
    }

    delete(id: number) {
        return consoleApi.get({
            path: 'srv/game/delete?id=' + id,
            title: Configure.Gl(1133,'删除'),
            quiet: true
        });
    }

    list(filter?:any) {
        return new Promise((resolve, reject) => {
            consoleApi.get({
                path: 'srv/game/list',
                title: Configure.Gl(1713,'游戏服列表'),
                quiet: true,
                param: filter
            }).then(list => {
                (list as any[]).forEach(item => {
                    item.openTime = this.parseZoneTime(item.openTime);
                    item.mergeTime = this.parseZoneTime(item.mergeTime);
                });
                resolve(list);
            });
        });
    }

    listSnapshot() {
        return consoleApi.get({
            path: 'srv/game/list/snapshot',
            title: Configure.Gl(1718,'游戏服预览列表'),
            quiet: true
        });
    }

    listSnapshotWithoutEaten() {
        return consoleApi.get({
            path: 'srv/game/list/snapshot',
            title: Configure.Gl(1718,'游戏服预览列表'),
            quiet: true,
            param: {"filterEaten":true},
        });
    }

    listRealtime() {
        return consoleApi.get({
            path: 'srv/game/realtime/list',
            title: Configure.Gl(1719,'游戏服实时数据'),
            quiet: true
        });
    }

    translate(item: any) {
        return consoleApi.get({
            path: 'srv/game/translate',
            title: Configure.Gl(1691,"翻译奖励"),
            quiet: true,
            param: item
        });
    }

    gameStatusAll() {
        return consoleApi.get({
            path: 'srv/game/status/all',
            title: Configure.Gl(1720,'切换全部跨服编码'),
            quiet: true
        })
    }
}

export const srvGameApi: SrvGameApi = new SrvGameApi();