import React from 'react';
import { Form, Input, Button, Switch, DatePicker } from 'antd';
import { StdItemEdit, IProps, IState } from '../../../part/StdItemEdit';
import { labApi } from '../../../api/console/lab';
import Configure from '../../../api/Configure';

export default class DemoEntityEdit extends StdItemEdit<IProps, IState> {

    state = {
        ...super.buildInitState(),
    }

    renderForm() {
        const { createMode, item } = this.state;
        console.log("转化前:dtDate=", item.dtDate);
        item.dtDate = this.parseZone(item.dtDate);
        console.log("转化后:dtDate=", item.dtDate);
        item.tsDate = this.parseZone(item.tsDate);
        return (
            <Form
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 20 }}
                name="basic"
                initialValues={item}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}
            >
                <Form.Item label={Configure.Gl(1055,"ID")} name="id">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={Configure.Gl(1499,"名称")} name="name">
                    <Input />
                </Form.Item>
                <Form.Item label="double值" name="valDouble">
                    <Input />
                </Form.Item>
                <Form.Item label="float值" name="valFloat">
                    <Input />
                </Form.Item>
                <Form.Item label="int值" name="valInt">
                    <Input />
                </Form.Item>
                <Form.Item label="long值" name="valLong">
                    <Input />
                </Form.Item>
                <Form.Item label="short值" name="valShort">
                    <Input />
                </Form.Item>
                <Form.Item label="byte值" name="valByte">
                    <Input />
                </Form.Item>
                <Form.Item label="boolean值" name="valBoolean" valuePropName="checked">
                    <Switch />
                </Form.Item>
                {/* <Form.Item label="boolean值" name="valBoolean">
                    <Select>
                        <Select.Option value={0}>假</Select.Option>
                        <Select.Option value={1}>真</Select.Option>
                    </Select>
                </Form.Item> */}
                <Form.Item label="dtDate" name="dtDate">
                    <DatePicker showTime />
                </Form.Item>
                <Form.Item label="tsDate" name="tsDate">
                    <DatePicker showTime />
                </Form.Item>
                
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit"> {createMode ? Configure.Gl(1387,"保存新增")  : Configure.Gl(1388,"保存编辑")} </Button>
                </Form.Item>
            </Form>
        );
    }


    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        //if(true) return;
        const { createMode } = this.state;
        if (createMode) {
            labApi.createDemo(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            });
        } else {
            labApi.updateDemo(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            })
        }
    }

}