import React from 'react';
import { IPagingState, StdItemPageLimit } from '../../../part/StdItemPageLimit';
import { ColumnsType } from 'antd/lib/table';
import { Card, Form, message, Space, Tag } from 'antd';
import DateTool from '../../../util/DateTool';
import Configure from '../../../api/Configure';
import { RoutePath } from '../../../route/path';
import { briefingdailyReportApi } from '../../../api/stat/daily.report';
import DailyBriefingClear from './DailyBriefingClear';
import NewDailyBriefingFilter from './NewDailyBriefingFilter';

interface IState extends IPagingState {
    sth: string;
}

export default class NewDailyBriefingIndex extends StdItemPageLimit<IState> {

    state = {
        ...this.getDefaultState(),
        sth: '',
        loginAccountNum: 0,
        loginDeviceNum: 0,
        loginIpNum: 0,
        loginRoleNum: 0,
        newAccountNum: 0,
        newDeviceNum: 0,
        newIpNum: 0,
        newRoleNum: 0,
        payNum: 0,
        payTimes: 0,
        error: '',
        message: "",
        groupBySrv: false,
        workerTodoNum: 0
    };

    getCols(): ColumnsType<any> {
        return [
            { title: Configure.Gl(1079, "游戏服编码"), dataIndex: 'srvCode', fixed: 'left', width: 120 },
            { title: Configure.Gl(1081, "游戏服序号"), dataIndex: 'srvIndex', fixed: 'left', width: 120, sorter: { multiple: 1, compare: (a, b) => 0 } },
            { title: Configure.Gl(1080, '游戏服名'), dataIndex: 'srvName', width: 120 },
            { title: Configure.Gl(1123, 'GameID'), dataIndex: 'gameId', width: 200 },
            { title: Configure.Gl(Configure.GL_UNDEFINED, 'Uid'), dataIndex: 'uid', width: 200 },
            { title: Configure.Gl(Configure.GL_UNDEFINED, 'GroupID'), dataIndex: 'groupId', width: 200 },
            { title: Configure.Gl(1384, '应用名称'), dataIndex: 'appName', width: 240 },
            { title: Configure.Gl(1852, '所属日期'), dataIndex: 'date', width: 120, render: DateTool.beautifyLocalDate },
            { title: Configure.Gl(1853, '充值数额'), dataIndex: 'payNum', width: 100, sorter: { multiple: 2, compare: (a, b) => 0 }, render: (v) => v.toFixed(2) },
            { title: Configure.Gl(1631, '充值次数'), dataIndex: 'payTimes', width: 100, sorter: { multiple: 3, compare: (a, b) => 0 } },
            { title: Configure.Gl(1896, '支付角色数'), dataIndex: 'payRoleNum', width: 120 },
            { title: Configure.Gl(1897, '支付账户数'), dataIndex: 'payAccountNum', width: 120 },
            { title: Configure.Gl(1854, '当日新增创角'), dataIndex: 'newRoleNum', width: 120, sorter: { multiple: 4, compare: (a, b) => 0 } },
            { title: Configure.Gl(1855, '当日新导入账号'), dataIndex: 'newAccountNum', width: 180 },
            { title: Configure.Gl(1856, '当日新导入设备'), dataIndex: 'newDeviceNum', width: 180, render: (v) => '' + Math.round(v), sorter: { multiple: 5, compare: (a, b) => 0 } },
            { title: Configure.Gl(1858, '当日新导入IP'), dataIndex: 'newIpNum', width: 180, render: (v) => '' + Math.round(v) },
            { title: Configure.Gl(1857, '今日登陆角色数'), dataIndex: 'loginRoleNum', width: 150, sorter: { multiple: 6, compare: (a, b) => 0 } },
            { title: Configure.Gl(1859, '今日登陆账号数'), dataIndex: 'loginAccountNum', width: 140 },
            { title: Configure.Gl(1860, '今日登陆设备数'), dataIndex: 'loginDeviceNum', width: 150 },
            { title: Configure.Gl(1861, '今日登陆IP数'), dataIndex: 'loginIpNum', width: 180, render: (v) => '' + Math.round(v) },
            { title: Configure.Gl(1629, '数据更新时间'), dataIndex: 'updateTime', width: 240, render: DateTool.beautifyLocal },
        ];
    }

    getFilterUI() {
        return (<Space>
            <NewDailyBriefingFilter search={(f: any) => this.onFilterChange(f)}></NewDailyBriefingFilter>
        </Space>
        );
    }

    getEditorUI() {
        return (

            <>
                <Space direction='horizontal'>
                    <Card title={Configure.Gl(1863, '总览')} bordered={true} style={{ width: 300 }}>
                        <Space direction="vertical">
                            <p>
                                <Form.Item name='loginAccountNum' label={Configure.Gl(1859, '今日登陆账号数')} style={{ height: 1 }}>
                                    <Tag color="#e15c1a">{this.state.loginAccountNum}</Tag>
                                </Form.Item>
                            </p>
                            <p>
                                <Form.Item name='loginDeviceNum' label={Configure.Gl(1860, '今日登陆设备数')} style={{ height: 1 }}>
                                    <Tag color="#b04611">{this.state.loginDeviceNum}</Tag>
                                </Form.Item>
                            </p>
                            <p>
                                <Form.Item name='loginIpNum' label={Configure.Gl(1861, '今日登陆IP数')} style={{ height: 1 }}>
                                    <Tag color="#d94b13">{this.state.loginIpNum}</Tag>
                                </Form.Item>
                            </p>
                            <p>
                                <Form.Item name='loginRoleNum' label={Configure.Gl(1857, '今日登陆角色数')} style={{ height: 1 }}>
                                    <Tag color="#c32424">{this.state.loginRoleNum}</Tag>
                                </Form.Item>
                            </p>
                            <p>
                                <Form.Item name='newAccountNum' label={Configure.Gl(1855, '当日新导入账号')} style={{ height: 1 }}>
                                    <Tag color="#7513e3">{this.state.newAccountNum}</Tag>
                                </Form.Item>
                            </p>
                            <p>
                                <Form.Item name='newDeviceNum' label={Configure.Gl(1856, '当日新导入设备')} style={{ height: 1 }}>
                                    <Tag color="#4e11c9">{this.state.newDeviceNum}</Tag>
                                </Form.Item>
                            </p>
                            <p>
                                <Form.Item name='newIpNum' label={Configure.Gl(1858, '当日新导入IP')} style={{ height: 1 }}>
                                    <Tag color="#1131d3">{this.state.newIpNum}</Tag>
                                </Form.Item>
                            </p>
                            <p>
                                <Form.Item name='newRoleNum' label={Configure.Gl(1854, '当日新增创角')} style={{ height: 1 }}>
                                    <Tag color="#128734">{this.state.newRoleNum}</Tag>
                                </Form.Item>
                            </p>
                            <p>
                                <Form.Item name='payNum' label={Configure.Gl(1853, '充值数额')} style={{ height: 1 }}>
                                    <Tag color="#dbb314">{this.state.payNum ? this.state.payNum.toFixed(2) : "0"}</Tag>
                                </Form.Item>
                            </p>
                            <p>
                                <Form.Item name='payTimes' label={Configure.Gl(1631, '充值次数')} style={{ height: 1 }}>
                                    <Tag color="#f4d500">{this.state.payTimes}</Tag>
                                </Form.Item>
                            </p>
                        </Space>
                    </Card>
                    <Card title={Configure.Gl(1899, '清理')} bordered={true} style={{ width: 300 }}>
                        <DailyBriefingClear search={(f: any) => this.onFilterChange(f)}> </DailyBriefingClear>
                    </Card>
                    <Card title={Configure.Gl(Configure.GL_UNDEFINED, '消息信息:')} bordered={true} style={{ width: 400 }}>
                        <p>
                            <Form.Item name='message' label={Configure.Gl(Configure.GL_UNDEFINED, 'message')}>
                                <Tag color="#218726">{this.state.message}</Tag>
                            </Form.Item>
                        </p>
                        <p>
                            <Form.Item name='workerTodoNum' label={Configure.Gl(Configure.GL_UNDEFINED, 'workerTodoNum')}>
                                <Tag color="#928f8f">{this.state.workerTodoNum}</Tag>
                            </Form.Item>
                        </p>
                        <p>
                            <Form.Item name='error' label={Configure.Gl(Configure.GL_UNDEFINED, 'error')} >
                                <Tag color="#f50e0e">{this.state.error}</Tag>
                            </Form.Item>
                        </p>
                    </Card>
                </Space>
            </>
        );
    }

    setattachment(data: any) {
        if (data) {
            this.state.loginAccountNum = data.loginAccountNum;
            this.state.loginDeviceNum = data.loginDeviceNum;
            this.state.loginIpNum = data.loginIpNum;
            this.state.loginRoleNum = data.loginRoleNum;
            this.state.newAccountNum = data.newAccountNum;
            this.state.newDeviceNum = data.newDeviceNum;
            this.state.newIpNum = data.newIpNum;
            this.state.newRoleNum = data.newRoleNum;
            this.state.payNum = data.payNum;
            this.state.payTimes = data.payTimes;
        }
    }

    setattachmentmsg(data: any) {
        this.state.error = data.error;
        this.state.message = data.message;
        this.state.workerTodoNum = data.workerTodoNum;
    }


    getPath() { return RoutePath.NEW_DAILY_BRIEFING_INDEX; }

    async reloadForData(query: any) {
        const { sorter } = this.state;
        console.log("查看排序参数:", sorter);
        this.writeSorter("srvIndex", query, "orderBySrvIndex");
        this.writeSorter("payNum", query, "orderByPayNum");
        this.writeSorter("payTimes", query, "orderByPayTimes");
        this.writeSorter("newRoleNum", query, "orderByNewRoleNum");
        this.writeSorter("newDeviceNum", query, "orderByNewDeviceNum");
        this.writeSorter("loginRoleNum", query, "orderByLoginRoleNum");
        let data = await briefingdailyReportApi.newlist(query) as any;
        if (data === "") {
            message.error(Configure.Gl(Configure.GL_UNDEFINED, "必须选择查询日期!"));
            let nulldata: any = {
                records: [],
            };
            return nulldata;
        }
        this.setattachmentmsg(data.attachment);
        let filter = {
            date: ""
        }
        filter.date = query.date;
        let attachment = await briefingdailyReportApi.newOverview(filter) as any;
        this.setattachment(attachment);
        return data;
    }
}