import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';
import { Modal } from 'antd';
import moment from 'moment';

export class StdItemPage<IS> extends React.Component<RouteComponentProps, IS> {

    getPaginationConfig(): TablePaginationConfig {
        return {
            showSizeChanger: true,
            showQuickJumper: true,
            //defaultPageSize: 3
        };
    }

    getColsWidth(columns: ColumnsType<any>) {
        let colAllWidth = 0;
        columns.forEach(col => {
            colAllWidth += col.width as number;
        })
        console.log("colAllWidth=", colAllWidth);
        return colAllWidth;
    }

    addKeyToData(data: any[]) {
        data.forEach(element => { element.key = Math.random() });
    }

    doAlert(title: string, content: any) {
        Modal.info({
            title: title,
            width: '80%',
            maskClosable: true,
            content: (<div dangerouslySetInnerHTML={{ __html: content as string }} />)
        })
    }

    formatDateTime(m: moment.Moment|null): string {
        if(!m) { return '--'; }
        if(!moment.isMoment(m)) {
            let tm = moment.parseZone(m);
            tm.locale('zh-cn');
            //console.log("此时此刻", tm);
            return tm.local().format("YYYY-MM-DD HH:mm:ss");
        }
        return m.format("YYYY-MM-DD HH:mm:ss");
    }
}