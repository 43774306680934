import React from 'react';
import { Input } from 'antd';
import { AbsFilter, StdItemFilter } from '../../../part/StdItemFilter';
import Configure from '../../../api/Configure';

class Filter extends AbsFilter {
    roleId?: string = undefined;
    roleName?: string = undefined;
    srvCode?: string = undefined;
    srvName?: string = undefined;
    srvIndex?: number = undefined;
}

export default class RoleKeyFilter extends StdItemFilter {

    state = {
        roleId: undefined as string | undefined,
        roleName: undefined as string | undefined,
        srvCode: undefined as string | undefined,
        srvName: undefined as string | undefined,
        srvIndex: undefined as number | undefined,
    };

    filter:Filter = new Filter();

    getFilter() { return this.filter; }

    async componentDidMount() {
        //const opt = await xxxApi.optXxx();
        //this.setState({optXxx: opt});
    }

    render() {
        return (
            <span>
                <Input placeholder={Configure.Gl(1461,"角色ID")} value={this.state.roleId} onChange={e => this.setValue('roleId', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1462,"角色名字")} value={this.state.roleName} onChange={e => this.setValue('roleName', e.target.value)} style={{width: 180}}/>
                <Input placeholder={ Configure.Gl(1079,'游戏服编码')} value={this.state.srvCode} onChange={e => this.setValue('srvCode', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1080,"游戏服名")} value={this.state.srvName} onChange={e => this.setValue('srvName', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1081,"游戏服序号")} value={this.state.srvIndex} onChange={e => this.setValue('srvIndex', e.target.value)} style={{width: 180}}/>
                { this.renderCmd() }
            </span>
        );
    }

}