import React from 'react';
import { Button, Table, Divider, Form, Select, Input } from 'antd';
import { SearchOutlined} from '@ant-design/icons';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';
import { FormInstance } from 'antd/lib/form';
import { StdItemPage } from '../../../part/StdItemPage';
import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import { giftcodeApi } from '../../../api/world/giftcode';
import { Key, TableCurrentDataSource } from 'antd/lib/table/interface';
import { SorterResult } from 'antd/es/table/interface';
import { formatDate } from '../../../util/toolbox';
import Configure from '../../../api/Configure';

export default class GiftcodeSendIndex extends StdItemPage<any> {
    formRef = React.createRef<FormInstance>();

    state = {
        itemList: [],
    };

    filter = {
        
    }

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1161,'礼包名称'),
                dataIndex: 'name',
                width: 120,
                fixed: 'left',
                render: (v, r) => r.name
            },
            {
                title: Configure.Gl(1162,'礼包描述'),
                dataIndex: 'description',
                width: 120,
                fixed: 'left',
                render: (v, r) => r.description
            },
            {
                title: Configure.Gl(1163,'礼包奖励内容'),
                dataIndex: 'reward',
                width: 120,
                fixed: 'left',
                render: (v, r) => r.reward
            },
            {
                title: Configure.Gl(1164,'礼包种类'),
                dataIndex: 'kind',
                width: 120,
                fixed: 'left',
                render: (v, r) => r.kind
            },
            {
                title: Configure.Gl(1172,'同种类礼包互斥'),
                dataIndex: 'kindExclusive',
                width: 120,
                fixed: 'left',
                render: (v, r) => r.kindExclusive?Configure.Gl(1185,"是"):Configure.Gl(1186,"否")
            },
            {
                title: Configure.Gl(1167,'渠道限定'),
                dataIndex: 'limitPlatform',
                width: 120,
                fixed: 'left',
                render: (v, r) => r.limitPlatform?r.limitPlatform:Configure.Gl(1184,"任何渠道都可用")
            },
            {
                title: Configure.Gl(1173,'只可使用一次'),
                dataIndex: 'limitOnce',
                width: 120,
                fixed: 'left',
                render: (v, r) => r.limitOnce?Configure.Gl(1185,"是"):Configure.Gl(1186,"否")
            },
            {
                title: Configure.Gl(1175,'礼包码实例数量'),
                dataIndex: 'instanceNum',
                width: 120,
                fixed: 'left',
                render: (v, r) => r.instanceNum
            },
            {
                title: Configure.Gl(1183,'礼包码被使用次数'),
                dataIndex: 'useTimes',
                width: 120,
                fixed: 'left',
                render: (v, r) => r.useTimes
            },
            {
                title: Configure.Gl(1182,'创建时间'),
                dataIndex: 'createTime',
                width: 120,
                fixed: 'left',
                render: (v, r) => r.createTime?formatDate(r.createTime):""
            },
            {
                title: Configure.Gl(1176,'到期时间'),
                dataIndex: 'expiryTime',
                width: 120,
                fixed: 'left',
                render: (v, r) => r.expiryTime?formatDate(r.expiryTime):""
            },
        ];
    }

    render() {

        const columns: ColumnsType<any> = this.getCols();

        return (
            <HomeLayout path={RoutePath.GIFT_CODE_SEND_INDEX}>
                <Form 
                    ref={this.formRef}
                    layout="inline"
                    name="basic"
                    onFinish={this.onFormFinish}
                    onFinishFailed={this.onFormFinishFailed}
                    initialValues={{
                        'name.c': 'like',
                        'description.c':'like',
                      }}>
                     <Divider>
                        <Form.Item label={Configure.Gl(1161,"礼包名称")}>
                            <Form.Item name='name.c' noStyle>
                                <Select>
                                    <Select.Option value='eq'>{Configure.Gl(1178,"等于")}</Select.Option>
                                    <Select.Option value='like'>{Configure.Gl(1179,"包含")}</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name='name.v' noStyle>
                                <Input/>
                            </Form.Item>
                        </Form.Item>
                        
                        <Form.Item label={Configure.Gl(1181,"礼包介绍")}>
                            <Form.Item name='description.c' noStyle >
                                <Select>
                                    <Select.Option value='eq'>{Configure.Gl(1178,"等于")}</Select.Option>
                                    <Select.Option value='like'>{Configure.Gl(1179,"包含")}</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name='description.v' noStyle>
                                <Input/>
                            </Form.Item>
                        </Form.Item>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">{Configure.Gl(1180,"查询")}</Button>
                     </Divider>
                </Form>
                <Divider />
                <Table dataSource={this.state.itemList} columns={columns}
                    scroll={{ x: this.getColsWidth(columns), y: 650 }} onChange={(p, f, s, e) => { this.onPageChange(p, f, s, e) }} />
            </HomeLayout>
        );
    }

    onPageChange(pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        this.search();
    }

    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        this.filter = values;
        this.search();
    }

    async search(){
        console.log("请求参数为：",this.filter);
        let data = await giftcodeApi.listSource(this.filter) as any;
        console.log(Configure.Gl(1390,"请求到的数据是："), data);
        this.addKeyToData(data);
        this.setState({ itemList: data});
    }

    onFormFinishFailed = (errorInfo: any) => {
        console.error('onFormFinishFailed:', errorInfo);
    }
}