import React from 'react';
import { Form, Input, Button, DatePicker, Select } from 'antd';
import { StdItemEdit, IProps, IState } from '../../../part/StdItemEdit';
import { statPartConfApi } from '../../../api/console/stat.part.conf';
import { getMysqlTimezoneOpt } from '../../../def/MysqlDef';
import Configure from '../../../api/Configure';

export default class StatPartConfEdit extends StdItemEdit<IProps, IState> {

    state = {
        ...super.buildInitState(),
    }

    renderForm() {
        const { createMode, item } = this.state;
        console.log("拿到的item=", item);
        const mysqlTimezoneOptUi: any[] = [];
        getMysqlTimezoneOpt().forEach(opt => {
            mysqlTimezoneOptUi.push(<Select.Option value={opt.value} key={opt.value}>{opt.label + ' ' + opt.value}</Select.Option>);
        })
        return (
            <Form 
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 20 }}
                name="basic"
                initialValues={item}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}
            >
                <Form.Item label={Configure.Gl(1055,"ID")} name="id">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={Configure.Gl(1599,"配置名")} name="name">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1119,'开始时间')} name="beginTime">
                    <DatePicker showTime />
                </Form.Item>
                <Form.Item label={Configure.Gl(1120,'结束时间')} name="endTime">
                    <DatePicker showTime />
                </Form.Item>
                <Form.Item label={Configure.Gl(1600,"mysql主机地址")} name="mysqlHost">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1601,"mysql端口")} name="mysqlPort">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1602,"mysql数据库名")} name="mysqlDatabase">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1603,"mysql用户名")} name="mysqlUser">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1604,"mysql密码")} name="mysqlPassword">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1605,"mysql时区")} name="mysqlTimezone">
                    <Select>{mysqlTimezoneOptUi}</Select>
                </Form.Item>
                <Form.Item label={Configure.Gl(1065,"备注")} name="note">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit"> {createMode ? Configure.Gl(1387,"保存新增")  : Configure.Gl(1388,"保存编辑")} </Button>
                </Form.Item>
            </Form>
        );
    }


    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        //if(true) return;
        const { createMode } = this.state;
        if (createMode) {
            statPartConfApi.create(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            });
        } else {
            statPartConfApi.update(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            })
        }
    }

}