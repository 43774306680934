/** 编码比较 */
export function compareCode(a:string, b:string): number {
    if(!a) return -1;
    if(!b) return 1;
    if(a.length === b.length) {
        return a.localeCompare(b);
    }
    return a.length - b.length;
}

/** 是否是移动端 */
export function isMobile(): boolean {
    try {
        if ( /mobile/i.test(navigator.userAgent) ) {  
            //alert("是移动端啦");    
            return true;
        }
    } catch(e) {
        console.error("判断是否是移动端出错", e);
    }
    return false;
}

/** 格式化日期 */
export function formatDate(d:any, fmt?:string) {
    if(!d) {
        return '--';
    }
    if(!(d instanceof Date)) {
        d = new Date(d);
    }
    if (!fmt) fmt = "yyyy-MM-dd hh:mm:ss";
    let o:any = {
        "M+": d.getMonth() + 1, //月份
        "d+": d.getDate(), //日
        "h+": d.getHours(), //小时
        "H+": d.getHours(), //小时
        "m+": d.getMinutes(), //分
        "s+": d.getSeconds(), //秒
        "q+": Math.floor((d.getMonth() + 3) / 3), //季度
        "S": d.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (d.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

/** 
 * 获取当日时间
 * return YYYY-MM-DD
*/
export function getNowDate(forSecond:boolean): string {
    const date = new Date();
    let month: string | number = date.getMonth() + 1;
    let strDate: string | number = date.getDate();
   
    if (month <= 9) {
      month = "0" + month;
    }
   
    if (strDate <= 9) {
      strDate = "0" + strDate;
    }
    if(forSecond){
        return date.getFullYear() + "-" + month + "-" + strDate+ " "+ date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    }
    return date.getFullYear() + "-" + month + "-" + strDate;
}

export function formatMoment(m: moment.Moment|null, fmt?:string): string {
    //console.log("传入进来的参数fmt=", fmt);
    if(!m) { return '--'; }
    if (!fmt) fmt = "YYYY-MM-DD HH:mm:ss";
    try {
        return m.format(fmt);
    } catch(e) {
        console.error("格式化moment错误 m=", m, ", fmt=", fmt);
        return "error";
    }
}