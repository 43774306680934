import React from 'react';
import { Input } from 'antd';
import { AbsFilter, StdItemFilter } from '../../../../part/StdItemFilter';
import Configure from '../../../../api/Configure';

class Filter extends AbsFilter {
    code?: string = undefined;
}

export default class SrvGameExtFilter extends StdItemFilter {

    state = {
        code: undefined as string | undefined,
    };

    filter:Filter = new Filter();

    getFilter() { return this.filter; }

    async componentDidMount() {
    }

    render() {
        return (
            <span>
                <Input placeholder={Configure.Gl(1079,"游戏服编码")} value={this.state.code} onChange={e => this.setValue('code', e.target.value)} style={{width: 180}}/>
                { this.renderCmd() }
            </span>
        );
    }

}