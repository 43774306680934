import { consoleApi } from '../../util/http';
import Configure from '../Configure';

class PayManualApi {

    manual(query: any) {
        return consoleApi.post({
            path: 'gm/pay/createApproval',
            param: query,
            title: Configure.Gl(1700,'手工支付操作')
        });
    }

    approve(query: any){
        return consoleApi.post({
            path: 'gm/pay/completeApproval',
            param: query,
            title: Configure.Gl(1701,'手工支付审核')
        });
    }

    list(filter: any){
        return consoleApi.get({
            path: 'gm/pay/approvalList',
            param: filter,
            title: Configure.Gl(1702,'手工支付审核列表'),
            quiet: true
        });
    }
}

export const payManualApi: PayManualApi = new PayManualApi();