import React from 'react';
import { Input, } from 'antd';
import { AbsFilter, StdItemFilter } from '../../part/StdItemFilter';
import Configure from '../../api/Configure';

class Filter extends AbsFilter {
    classify?: string = undefined;
    code?: string = undefined;
    deviceCode?: string = undefined;
    note?: string = undefined;
}

export default class SrvGroupConfFilter extends StdItemFilter {

    state = {
        classify: undefined as string | undefined,
        code: undefined as string | undefined,
        deviceCode: undefined as string | undefined,
        note: undefined as string | undefined,
        optClassify: []
    };

    filter:Filter = new Filter();

    getFilter() { return this.filter; }

    async componentDidMount() {

    }

    render() {
        return (
            <span>
                <Input placeholder={Configure.Gl(1951,"游戏服组编码")} value={this.state.code} onChange={e => this.setValue('code', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1065,"备注")} value={this.state.note} onChange={e => this.setValue('note', e.target.value)} style={{width: 180}}/>
                { this.renderCmd() }
            </span>
        );
    }

}