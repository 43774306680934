import React from 'react';
import { Form, Input, Button, Select } from 'antd';
import { StdItemEdit, IProps, IState } from '../../part/StdItemEdit';
import { cloudMongoApi } from '../../api/console/cloud.mongo';
import Configure from  '../../api/Configure';

export default class CloudMongoEdit extends StdItemEdit<IProps, IState> {

    state = {
        ...super.buildInitState(),
    }

    renderForm() {
        const { createMode, item } = this.state;
        console.log("拿到的item=", item);
        return (
            <Form 
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="basic"
                initialValues={item}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}
            >
                <Form.Item label={Configure.Gl(1056,"编码")} name="code">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(Configure.GL_UNDEFINED, "云服务器编码")} name={'serverCode'}>
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1074,"端口")} name={'port'}>
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1075,"username")} name={'username'}>
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1076,"password")} name={'password'}>
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1077,"source")} name={'source'}>
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(Configure.GL_UNDEFINED,"database")} name={'database'}>
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1451,"模式")} name={'mode'}>
                    <Select>
                        <Select.Option key="0" value={0}>{Configure.Gl(1778,"单实例")}</Select.Option>
                        <Select.Option key="1" value={1}>{Configure.Gl(1779,"副本集")}</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label={Configure.Gl(1078,"url")} name={'url'}>
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(Configure.GL_UNDEFINED,"urlPublic")} name={['urlPublic']}>
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1065,"备注")} name="note">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit"> {createMode ? Configure.Gl(1387,"保存新增")  : Configure.Gl(1388,"保存编辑")} </Button>
                </Form.Item>
            </Form>
        );
    }


    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        //if(true) return;
        const { createMode } = this.state;
        if (createMode) {
            cloudMongoApi.create(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            });
        } else {
            cloudMongoApi.update(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            })
        }
    }

}