import { consoleApi } from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

class StatPartConfApi extends ApiBase {

    create(item: any) {
        return consoleApi.post({
            path: 'stat/part/conf/create',
            param: item,
            title: Configure.Gl(1116,"新建")
        });
    }

    update(item: any) {
        return consoleApi.post({
            path: 'stat/part/conf/update',
            param: item,
            title: Configure.Gl(1132,'修改')
        });
    }

    delete(id: any) {
        return consoleApi.get({
            path: 'stat/part/conf/delete?id=' + id,
            title: Configure.Gl(1133,'删除'),
            quiet: true
        });
    }

    list() {
        return consoleApi.get({
            path: 'stat/part/conf/list',
            title: Configure.Gl(1704,'列表'),
            quiet: true,
            resultHandler: (data:any) => {
                (data as any[]).forEach(item => {
                    item.beginTime = this.parseZoneTime(item.beginTime);
                    item.endTime = this.parseZoneTime(item.endTime);
                });
            }
        });
    }

    // list() {
    //     return consoleApi.get({
    //         path: 'stat/part/conf/list',
    //         title: '列表',
    //         quiet: true
    //     });
    // }

    // list() {
    //     return new Promise((resolve, reject) => {
    //         consoleApi.get({
    //             path: 'stat/part/conf/list',
    //             title: '列表',
    //             quiet: true
    //         }).then((data:any) => {
    //             (data as any[]).forEach(item => {
    //                 item.changeTime = this.parseZoneTime(item.changeTime);
    //             });
    //             resolve(data);
    //         });
    //     });
    // }

}

export const statPartConfApi: StatPartConfApi = new StatPartConfApi();