import React from 'react';
import { Form, Input, Button, DatePicker, Switch } from 'antd';
import { StdItemEdit, IProps, IState } from '../../../../part/StdItemEdit';
import { srvCrossPlanApi } from '../../../../api/console/srv.cross.plan';
import Configure from '../../../../api/Configure';


export default class SrvCrossPlanEdit extends StdItemEdit<IProps, IState> {

    state = {
        ...super.buildInitState(),
    }

    renderForm() {
        const { createMode, item } = this.state;
        console.log("拿到的item=", item);
        return (
            <Form 
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
                name="basic"
                initialValues={item}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}
            >
                <Form.Item label={Configure.Gl(1055,"ID")} name="id">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={Configure.Gl(1079,'游戏服编码')} name="srvCode">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1392,"跨服编码")} name="crossCode">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1393,"切换时间")} name="changeTime">
                    <DatePicker showTime />
                </Form.Item>
                <Form.Item label={Configure.Gl(1394,"已执行变更")} name="changed" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={Configure.Gl(1395,"游戏服执行错误信息")} name="srvErr">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1396,"跨服执行错误信息")} name="crossErr">
                    <Input />
                </Form.Item>
                <Form.Item label={Configure.Gl(1396,"是否启用")} name="enabled" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit"> {createMode ? Configure.Gl(1387,"保存新增")  : Configure.Gl(1388,"保存编辑")} </Button>
                </Form.Item>
            </Form>
        );
    }


    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        //if(true) return;
        const { createMode } = this.state;
        if (createMode) {
            srvCrossPlanApi.create(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            });
        } else {
            srvCrossPlanApi.update(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            })
        }
    }

}