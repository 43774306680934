import React, { Key } from 'react';
import { Button, Modal, Space, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';

import { RoleRecordEntity } from '../../def/role/RoleDef';
import MasterQueryFilter from './MasterQueryFilter';
import { formatDate } from '../../util/toolbox';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import ClipBoard from '../../util/clipboard';
import Configure from '../../api/Configure';
import { newGmMasterApi } from '../../api/stat/NewGmMasterApi';
import TextArea from 'antd/lib/input/TextArea';

export interface IProps {
    onSelect: Function
}

export default class MasterQuery extends React.Component<IProps> {

    state = {
        show: false,
        itemList: [],
        itemTotal: undefined,
        queryPagination: {
            page: 1,
            pageSize: 10
        },
        queryFilter: {}
    }

    getCols(): ColumnsType<RoleRecordEntity> {
        return [
            {
                fixed: 'left',
                title: Configure.Gl(1151, '主角名称'),
                dataIndex: 'roleName',//'masterName',
                width: 180,
            },
            {
                title: Configure.Gl(1101, '主角唯一ID'),
                dataIndex: 'roleId',//'masterIdHex',
                width: 240,
            },
            {
                title: Configure.Gl(1057, '操作'),
                width: 240,
                render: (value: any, record: RoleRecordEntity, index: number) => {
                    return (<Space>
                        <Button onClick={() => {
                            if (this.props.onSelect != null) {
                                this.props.onSelect(record);
                            }
                        }}>{Configure.Gl(1646, '选择')}</Button>
                        <Button type="primary" onClick={() => {
                            // alert(JSON.stringify(record));
                            ClipBoard.setSrvCode(record.srvCode);
                            ClipBoard.setAccountUid(record.accountUid);
                            ClipBoard.setPlatformCode(record.platformCode);
                            ClipBoard.setMasterIdHex(record.roleId);
                            ClipBoard.setMasterName(record.roleName);
                            ClipBoard.setRoleName(record.roleName);
                            ClipBoard.setRoleId(record.roleId);
                            ClipBoard.setOpenId(record.openId);
                            ClipBoard.setOpenIdRaw(record.openIdRaw);
                            alert(Configure.Gl(1647, "已写入剪切板！"));
                        }}>{Configure.Gl(1648, '写入剪切板')}</Button>
                    </Space>);
                },
            },
            {
                title: Configure.Gl(1888, '账户唯一ID'),
                dataIndex: 'accountUid',
                width: 320,
            },
            {
                title: Configure.Gl(1121, '服务器编码'),
                dataIndex: 'srvCode',
                width: 160,
                render: (v, r) => '' + r.srvCode + '(' + r.srvName + ')'
            },
            {
                title: Configure.Gl(1887, '服务器序号'),
                dataIndex: 'srvIndex',
                width: 120,
            },
            {
                title: Configure.Gl(1187, '主角等级'),
                dataIndex: 'level',//'masterLevel',
                width: 120,
            },
            {
                title: Configure.Gl(1607, 'VIP等级'),
                dataIndex: 'vipLevel',
                width: 120,
            },
            {
                title: Configure.Gl(1626, 'VIP积分'),
                dataIndex: 'vipPoints',
                width: 120,
            },
            {
                title: Configure.Gl(1627, '战力'),
                dataIndex: 'power',
                width: 120,
            },
            {
                title: Configure.Gl(1628, '累计充值数额'),
                dataIndex: 'payNum',
                width: 120,
            },
            {
                title: Configure.Gl(1886, '支付次数'),
                dataIndex: 'payTimes',
                width: 120,
            },
            {
                title: Configure.Gl(1650, '最近登录时间'),
                dataIndex: 'lastLoginTime',//'lastActiveTime_m',
                width: 170,
                render: (v) => formatDate(v)
            },
            {
                title: Configure.Gl(1122, '平台编码'),
                dataIndex: 'platformCode',
                width: 120,
            },
            {
                title: Configure.Gl(1099, '渠道编码'),
                dataIndex: 'channelCode',//'channelCode',
                width: 120,
            },
            {
                title: Configure.Gl(1241, '平台openID'),
                dataIndex: 'openId',
                width: 260,
            },
            {
                title: Configure.Gl(1870, '原始平台openID'),
                dataIndex: 'openIdRaw',
                width: 260,
            },
            {
                title: Configure.Gl(1123, 'GameID'),
                dataIndex: 'gameId',
                width: 75,
            },
            {
                title: Configure.Gl(Configure.GL_UNDEFINED, '原始GameID'),
                dataIndex: 'gameIdRaw',
                width: 100,
            },
            {
                title: Configure.Gl(1874, '数值资源 m0'),
                dataIndex: 'm0',
                width: 260,
            },
            {
                title: Configure.Gl(1875, '数值资源 m1'),
                dataIndex: 'm1',
                width: 260,
            },
            {
                title: Configure.Gl(1876, '数值资源 m2'),
                dataIndex: 'm2',
                width: 260,
            },
            {
                title: Configure.Gl(1877, '数值资源 m3'),
                dataIndex: 'm3',
                width: 260,
            },
            {
                title: Configure.Gl(1878, '数值资源 m4'),
                dataIndex: 'm4',
                width: 260,
            },
            {
                title: Configure.Gl(1879, '公会ID'),
                dataIndex: 'guildId',
                width: 260,
                render: (v) => this.formatGuild(v)
            },
            {
                title: Configure.Gl(1880, '公会名称'),
                dataIndex: 'guildName',
                width: 260,
                render: (v) => this.formatGuild(v)
            },
            {
                title: Configure.Gl(1881, '公会等级'),
                dataIndex: 'guildLevel',
                width: 260,
            },
            {
                title: Configure.Gl(1882, '公会成员数量'),
                dataIndex: 'guildMemberNum',
                width: 260,
            },
            {
                title: Configure.Gl(1883, '好友数量'),
                dataIndex: 'friendNum',
                width: 260,
            },
            {
                title: Configure.Gl(1884, '邮件数量'),
                dataIndex: 'mailNum',
                width: 260,
            },
            {
                title: Configure.Gl(1885, '角色估值'),
                dataIndex: 'assessValue',
                width: 260,
            },
            {
                title: Configure.Gl(Configure.GL_UNDEFINED, '开发调试备注'),
                dataIndex: 'note',
                width: 100,
            },
            {
                title: Configure.Gl(Configure.GL_UNDEFINED, '扩展varchar值A'),
                dataIndex: 'extStrA',
                width: 300,
                render: (value: any) => {
                    return (<TextArea rows={1} defaultValue={value} />);
                },
            },
            {
                title: Configure.Gl(Configure.GL_UNDEFINED, '扩展varchar值B'),
                dataIndex: 'extStrB',
                width: 300,
                render: (value: any) => {
                    return (<TextArea rows={1} defaultValue={value} />);
                },
            },
            {
                title: Configure.Gl(Configure.GL_UNDEFINED, '扩展varchar值C'),
                dataIndex: 'extStrC',
                width: 300,
                render: (value: any) => {
                    return (<TextArea rows={1} defaultValue={value} />);
                },
            },
            {
                title: Configure.Gl(Configure.GL_UNDEFINED, '扩展text值A'),
                dataIndex: 'extTextA',
                width: 300,
                render: (value: any) => {
                    return (<TextArea rows={1} defaultValue={value} />);
                },
            },
            {
                title: Configure.Gl(Configure.GL_UNDEFINED, '扩展int值A'),
                dataIndex: 'extIntA',
                width: 300,
                render: (value: any) => {
                    return (<TextArea rows={1} defaultValue={value} />);
                },
            },
            {
                title: Configure.Gl(Configure.GL_UNDEFINED, '扩展long值A'),
                dataIndex: 'extLongA',
                width: 300,
                render: (value: any) => {
                    return (<TextArea rows={1} defaultValue={value} />);
                },
            },
            {
                title: Configure.Gl(Configure.GL_UNDEFINED, '扩展double值B'),
                dataIndex: 'extDoubleA',
                width: 300,
                render: (value: any) => {
                    return (<TextArea rows={1} defaultValue={value} />);
                },
            }
        ];
    }

    render() {
        return (<span>
            <Button type="primary" icon={<SearchOutlined />} onClick={() => this.setState({ show: true })}>
                {Configure.Gl(1651, '查找主角')}
            </Button>
            {this.renderSearchPanel()}

        </span>
        );
    }

    renderSearchPanel() {
        const { show, itemTotal, queryPagination } = this.state;
        const columns = this.getCols();
        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            defaultPageSize: queryPagination.pageSize,
            total: itemTotal
        };
        return (
            <Modal title={Configure.Gl(1652, "主角查询（非实时）")} visible={show} width="90%"
                onOk={() => this.handleModalOk()} onCancel={() => this.handleModalCancel()} footer={null} destroyOnClose>
                <MasterQueryFilter
                    search={(f: any) => this.onFilterChange(f)}
                    empty={(f: any) => this.onDoClear(f)}>
                </MasterQueryFilter>
                <Table dataSource={this.state.itemList} columns={columns} pagination={pagination}
                    scroll={{ x: 1200, y: 420 }} onChange={(p, f, s, e) => { this.onPageChange(p, f, s, e) }} />
            </Modal>

        );
    }

    formatGuild(guildname: any) {
        if (guildname === null) {
            return Configure.Gl(1862, '无');
        }
        return guildname;
    }

    onFilterChange(filter: any) {
        this.setState({ queryFilter: filter }, () => {
            this.reload();
        });
    }

    onDoClear(filter: any) {
        this.setState({ itemList: [] });
    }

    onPageChange(pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        console.log("沙雕 onPageChange pagination=", pagination, ", filters=", filters, ", sorter=", sorter, ", extra=", extra);
        this.setState({
            queryPagination: {
                page: pagination.current,
                pageSize: pagination.pageSize
            }
        }, () => {
            this.reload();
        });
    }
    async reload() {
        const { queryFilter, queryPagination } = this.state;
        const query = { ...queryFilter, ...queryPagination };

        let data = await newGmMasterApi.search(query) as any;
        console.log(Configure.Gl(1390, "请求到的新版数据是："), data);

        if (data.records.length > 0) {
            for (let i = 0; i < data.records.length; i++) {
                data.records[i].masterIdHex = data.records[i].roleId;
                data.records[i].masterName = data.records[i].roleName;
            }
        }

        this.addKeyToData(data.records);
        this.setState({ itemList: data.records, itemTotal: data.total });
    }

    addKeyToData(data: any[]) {
        data.forEach(element => { element.key = Math.random() });
    }


    handleModalOk() {

    }

    handleModalCancel() {
        this.setState({ show: false });
    }

}