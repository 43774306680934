import { consoleApi } from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

class GmAnnounceApi extends ApiBase {
    
    list() {
        return consoleApi.get({
            path: 'gm/announce/list',
            title: Configure.Gl(1671,'拉取游戏公告'),
            quiet: true
        });
    }

    create(item: any) {
        return consoleApi.post({
            path: 'gm/announce/create',
            param: item,
            title: Configure.Gl(1672,"新建游戏公告"),
            quiet: true
        });
    }

    update(item: any) {
        return consoleApi.post({
            path: 'gm/announce/update', 
            title: Configure.Gl(1673,'更新游戏公告'),
            param: item,
            quiet: true
        });
    }

    delete(id: any) {
        return consoleApi.get({
            path: 'gm/announce/delete?id='+id, 
            title: Configure.Gl(1674,'删除游戏公告'),
            quiet: true
        });
    }
}

export const gmAnnounceApi: GmAnnounceApi = new GmAnnounceApi();