import { consoleApi } from '../../util/http';
import Configure from '../Configure';

class SrvCrossApi {

    create(item: any) {
        return consoleApi.post({
            path: 'srv/cross/create',
            param: item,
            title: Configure.Gl(1116,"新建")
        });
    }

    update(item: any) {
        return consoleApi.post({
            path: 'srv/cross/update',
            param: item,
            title: Configure.Gl(1132,'修改')
        });
    }

    delete(id: any) {
        return consoleApi.get({
            path: 'srv/cross/delete?id=' + id,
            title: Configure.Gl(1133,'删除'),
            quiet: true
        });
    }

    list(filter?:any) {
        return consoleApi.get({
            path: 'srv/cross/list',
            title: Configure.Gl(1704,'列表'),
            param: filter,
            quiet: true
        });
    }

    optClassify() {
        return consoleApi.get({
            path: 'srv/cross/opt/classify',
            title: Configure.Gl(1721,'分类选项'),
            quiet: true
        });
    }

    async optCode() {
        let data: any = await this.list() as any;
        let opts = [{ text: "--", value: "" }];
        data.records.forEach((element: any) => {
            opts.push({
                text: "(" + element.id + ") " + element.code + " : " + element.note,
                value: element.code
            });
        });
        return opts;
    }

    fails(){
        return consoleApi.get({
            path: 'heartbeat/fails',
            title: Configure.Gl(1722,'故障列表'),
            quiet: true
        });
    }

    ct(crossCode: string) {
        return consoleApi.get({
            path: 'srv/cross/cmd/ct?crossCode=' + crossCode,
            title: Configure.Gl(1715,"服务器CT"),
            quiet: true
        });
    }
}

export const srvCrossApi: SrvCrossApi = new SrvCrossApi();