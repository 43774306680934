import React, { RefObject } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Table, Space, Divider, Modal } from 'antd';
import { SearchOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';

import { StdItemPage } from '../../part/StdItemPage';
import { HomeLayout } from '../../layout/HomeLayout';
import { RoutePath } from '../../route/path';
import { srvStatusPlanApi } from '../../api/console/srv.status.plan';
import SrvStatusPlanEdit from './SrvStatusPlanEdit';
import { SrvGameEntity } from '../../def/srv/SrvGameEntity';
import Configure from '../../api/Configure';

export default class SrvStatusPlanIndex extends StdItemPage<any> {

    private editor: RefObject<SrvStatusPlanEdit>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
    }

    state = {
        showEdit: false,
        itemList: [],
    };

    render() {
        const columns: ColumnsType<any> = [
            {
                title: Configure.Gl(1055,'ID'),
                dataIndex: 'id',
                width: 80,
                fixed: 'left',
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: Configure.Gl(1079,'游戏服编码'),
                dataIndex: 'srvCode',
                width: 150,
            },
            {
                title: Configure.Gl(1533,'变更状态'),
                dataIndex: 'status',
                width: 80,
                render: (v) => SrvGameEntity.statusToString(v)
            },
            {
                title: Configure.Gl(1463,"要取消推荐的游戏服编码"),
                dataIndex: 'cancelRecommendSrvCode',
                width: 150,
            },
            {
                title: Configure.Gl(1402,'变更时间'),
                dataIndex: 'changeTime',
                width: 170,
                render: this.formatDateTime
            },
            {
                title: Configure.Gl(1057,'操作'),
                width: 200,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117,"编辑")}</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133,'删除')}</Button>
                    </Space>);
                },
            },
            {
                title: Configure.Gl(1464,"是否已执行过变更"),
                width: 120,
                dataIndex: 'changed',
                render: (v) => v ? '√':'×'
            },
            {
                title: Configure.Gl(1358,'启用'),
                width: 80,
                dataIndex: 'enabled',
                render: (v) => v ? '√':'×'
            },
        ];

        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
        };

        return (
            <HomeLayout path={RoutePath.SRV_STATUS_PLAN_INDEX}>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389,'新增')}</Button>
                    <Button type="primary" icon={<SearchOutlined />} onClick={() => this.reload()}>{Configure.Gl(1152,'查找')}</Button>
                </Space>
                <Divider />
                <Table dataSource={this.state.itemList} columns={columns} pagination={pagination} scroll={{ x: 1500, y: 512 }} />
                <SrvStatusPlanEdit ref={this.editor} reload={() => this.reload()} ></SrvStatusPlanEdit>
            </HomeLayout>
        );
    }

    componentDidMount() {
        console.log("SrvStatusPlanIndex::componentDidMount");
        this.reload();
    }

    async reload() {
        let data = await srvStatusPlanApi.list() as any[];
        console.log(Configure.Gl(1390,"请求到的数据是："), data);
        data.forEach(element => { element.key = Math.random() });
        this.setState({ itemList: data });
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1391,'你确定要删除') + item.srvCode + '？',
            onOk() {
                srvStatusPlanApi.delete(item.id).then(d => that.reload());
            }
        });
    }

}