import React from 'react';
import { Modal, Progress } from 'antd';
import { sysProgressApi } from '../../../api/console/sys.progress';
import Configure from '../../../api/Configure';

export interface IState {
    visible: boolean;
    item: any;
    query: any;
}

export default class SrvMergeGameMongoProgress extends React.Component<any, IState> {

    state = {
        visible: false,
        item: {} as any,
        query: {} as any,
    }

    componentDidMount = () => {
        console.log("SrvMergeGameMongoProgress::componentDidMount");
        let that = this;
        setInterval(() => {
            const { item, visible } = that.state;
            if (visible) {
                //console.log("查询进度", item);
                sysProgressApi.query("migrate.game.mongo." + item.id).then((d: any) => {
                    that.setState({ query: d });
                });
            }
        }, 1000);
    }

    render() {
        const { visible, query } = this.state;
        return (
            <Modal title={Configure.Gl(1528,"进度")} visible={visible}
                onOk={this.handleModalOk} onCancel={this.handleModalCancel} footer={null} destroyOnClose>
                {Configure.Gl(1529,"任务编码：")}{query.code} <br/>
                {Configure.Gl(1530,"当前任务：")} {query.title} <br/>
                {Configure.Gl(1531,"详情：")} {query.detail} 
                <hr/>
                <Progress
                    strokeColor={{
                        from: '#108ee9',
                        to: '#87d068',
                    }}
                    percent={query.total <= 0 ? 100 : Math.round(100 * query.current / query.total)}
                    status="active"
                />
                {query.current} / {query.total}
            </Modal>
        );
    }

    handleModalOk = () => {
        this.setState({ visible: false });
    }

    handleModalCancel = () => {
        this.setState({ visible: false });
    }

    show = (item?: any) => {
        this.setState({ visible: true, item: item });
    }
}