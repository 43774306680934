import React from 'react';
import { Space, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table/Table';

import { RoutePath } from '../../../route/path';
import { statResApi } from '../../../api/stat/res';
import RoleKeyNumFilter from './RoleKeyNumFilter';
import { IPagingState, StdItemPageLimit } from '../../../part/StdItemPageLimit';
import DateTool from '../../../util/DateTool';
import Configure from '../../../api/Configure';

interface IState extends IPagingState {
    calc: any;
}

export default class RoleKeyNumIndex extends StdItemPageLimit<IState> {

    state = {
        ...this.getDefaultState(),
        calc: {} as any,
    };

    getCols(): ColumnsType<any> {
        return [
            { title: Configure.Gl(1461,"角色ID"), dataIndex: 'roleId', width: 220, fixed: 'left' },
            { title: Configure.Gl(1462,"角色名字"), dataIndex: 'roleName',  width: 180, fixed: 'left' },
            { title: Configure.Gl(1079,'游戏服编码'), dataIndex: 'srvCode',  width: 120 },
            { title: Configure.Gl(1080,"游戏服名"), dataIndex: 'srvName',  width: 100 },
            { title: Configure.Gl(1081,"游戏服序号"), dataIndex: 'srvIndex',  width: 100 },
            { title: Configure.Gl(1606,'冒险等级'), dataIndex: 'i0',  width: 100 },
            { title: Configure.Gl(1607,'VIP等级'), dataIndex: 'i1',  width: 100 },
            { title: Configure.Gl(1608,'进阶石数量'), dataIndex: 'i2',  width: 110 },
            { title: Configure.Gl(1609,'好友数量'), dataIndex: 'i3',  width: 100 },
            { title: Configure.Gl(1610,'英雄总数量'), dataIndex: 'i4',  width: 120 },
            { title: 'i5', dataIndex: 'i5',  width: 50 },
            { title: 'i6', dataIndex: 'i6',  width: 50 },
            { title: 'i7', dataIndex: 'i7',  width: 50 },
            { title: 'i8', dataIndex: 'i8',  width: 50 },
            { title: 'i9', dataIndex: 'i9',  width: 50 },
            { title: 'i10', dataIndex: 'i10',  width: 50 },
            { title: Configure.Gl(1611,'金币'), dataIndex: 'l0',  width: 130 },
            { title: Configure.Gl(1612,'元宝'), dataIndex: 'l1',  width: 120 },
            { title: Configure.Gl(1110,'英雄经验'), dataIndex: 'l2',  width: 150 },
            { title: Configure.Gl(1613,'友情点'), dataIndex: 'l3',  width: 100 },
            { title: Configure.Gl(1614,'魂精'), dataIndex: 'l4',  width: 100 },
            { title: Configure.Gl(1615,'先祖结晶'), dataIndex: 'l5',  width: 100 },
            { title: Configure.Gl(1616,'槐荫叶'), dataIndex: 'l6',  width: 100 },
            { title: Configure.Gl(1617,'乾坤币'), dataIndex: 'l7',  width: 100 },
            { title: Configure.Gl(1618,'声望'), dataIndex: 'l8',  width: 100 },
            { title: Configure.Gl(1619,'仙盟贡献'), dataIndex: 'l9',  width: 100 },
            { title: Configure.Gl(1620,'寻宝积分'), dataIndex: 'l10',  width: 100 },
            { title: Configure.Gl(1621,'荣誉'), dataIndex: 'l11',  width: 100 },
            { title: Configure.Gl(1622,'跨服天梯赛积分'), dataIndex: 'l12',  width: 100 },
            { title: Configure.Gl(1623,'跨服竞技场功勋'), dataIndex: 'l13',  width: 100 },
            { title: Configure.Gl(1624,'跨服段位赛积分'), dataIndex: 'l14',  width: 100 },
            { title: Configure.Gl(1625,'银两'), dataIndex: 'l15',  width: 100 },
            { title: Configure.Gl(1626,'VIP积分'), dataIndex: 'l16',  width: 100 },
            { title: Configure.Gl(1627,'战力'), dataIndex: 'l17',  width: 150 },
            { title: 'l18', dataIndex: 'l18',  width: 50 },
            { title: 'l19', dataIndex: 'l19',  width: 50 },
            { title: 'l20', dataIndex: 'l20',  width: 50 },
            { title: Configure.Gl(1628,'累积充值金额(元)'), dataIndex: 'd0',  width: 100 },
            { title: 'd1', dataIndex: 'd1',  width: 50 },
            { title: 'd2', dataIndex: 'd2',  width: 50 },
            { title: 'd3', dataIndex: 'd3',  width: 50 },
            { title: 'd4', dataIndex: 'd4',  width: 50 },
            { title: 'd5', dataIndex: 'd5',  width: 50 },
            { title: 'd6', dataIndex: 'd6',  width: 50 },
            { title: 'd7', dataIndex: 'd7',  width: 50 },
            { title: 'd8', dataIndex: 'd8',  width: 50 },
            { title: 'd9', dataIndex: 'd9',  width: 50 },
            { title: 'd10', dataIndex: 'd10',  width: 60 },
            { title: Configure.Gl(1629,'数据更新时间'), dataIndex: 'updateTime',  width: 200, render: DateTool.beautifyIgnMsTimezone },
        ];
    }

    getFilterUI() {
        return (<Space>
            <RoleKeyNumFilter search={(f: any) => this.onFilterChange(f)}></RoleKeyNumFilter>
        </Space>);
    }

    getEditorUI() {
        const { calc } = this.state;
        return (<div>
            <Tag color="#128734">{Configure.Gl(1606,'冒险等级')} sum = {calc.i0Sum} avg = { calc.i0Avg} max = { calc.i0Max}</Tag>
            <Tag color="#322241">{Configure.Gl(1607,'VIP等级')} sum = {calc.i1Sum} avg = { calc.i1Avg} max = { calc.i1Max}</Tag>
            <Tag color="#654511">{Configure.Gl(1608,'进阶石数量')} sum = {calc.i2Sum} avg = { calc.i2Avg} max = { calc.i2Max}</Tag>
            <Tag color="#872342">{Configure.Gl(1609,'好友数量')} sum = {calc.i3Sum} avg = { calc.i3Avg} max = { calc.i3Max}</Tag>
            <Tag color="#587843">{Configure.Gl(1610,'英雄总数量')} sum = {calc.i4Sum} avg = { calc.i4Avg} max = { calc.i4Max}</Tag>

            <Tag color="#55f342">{Configure.Gl(1611,'金币')} sum = {calc.l0Sum} avg = { calc.l0Avg} max = { calc.l0Max}</Tag>
            <Tag color="#125432">{Configure.Gl(1612,'元宝')} sum = {calc.l1Sum} avg = { calc.l1Avg} max = { calc.l1Max}</Tag>
            <Tag color="#555008">{Configure.Gl(1110,'英雄经验')} sum = {calc.l2Sum} avg = { calc.l2Avg} max = { calc.l2Max}</Tag>
            <Tag color="#973421">{Configure.Gl(1613,'友情点')} sum = {calc.l3Sum} avg = { calc.l3Avg} max = { calc.l3Max}</Tag>
            <Tag color="#111434">{Configure.Gl(1614,'魂精')} sum = {calc.l4Sum} avg = { calc.l4Avg} max = { calc.l4Max}</Tag>
            <Tag color="#554342">{Configure.Gl(1615,'先祖结晶')} sum = {calc.l5Sum} avg = { calc.l5Avg} max = { calc.l5Max}</Tag>
            <Tag color="#121132">{Configure.Gl(1616,'槐荫叶')} sum = {calc.l6Sum} avg = { calc.l6Avg} max = { calc.l6Max}</Tag>
            <Tag color="#503f78">{Configure.Gl(1617,'乾坤币')} sum = {calc.l7Sum} avg = { calc.l7Avg} max = { calc.l7Max}</Tag>
            <Tag color="#973421">{Configure.Gl(1618,'声望')} sum = {calc.l8Sum} avg = { calc.l8Avg} max = { calc.l8Max}</Tag>
            <Tag color="#110434">{Configure.Gl(1619,'仙盟贡献')} sum = {calc.l9Sum} avg = { calc.l9Avg} max = { calc.l9Max}</Tag>
            <Tag color="#550342">{Configure.Gl(1620,'寻宝积分')} sum = {calc.l10Sum} avg = { calc.l10Avg} max = { calc.l10Max}</Tag>
            <Tag color="#15ca32">{Configure.Gl(1621,'荣誉')} sum = {calc.l11Sum} avg = { calc.l11Avg} max = { calc.l11Max}</Tag>
            <Tag color="#555678">{Configure.Gl(1622,'跨服天梯赛积分')} sum = {calc.l12Sum} avg = { calc.l12Avg} max = { calc.l12Max}</Tag>
            <Tag color="#97f421">{Configure.Gl(1623,'跨服竞技场功勋')} sum = {calc.l13Sum} avg = { calc.l13Avg} max = { calc.l13Max}</Tag>
            <Tag color="#1ac334">{Configure.Gl(1624,'跨服段位赛积分')} sum = {calc.l14Sum} avg = { calc.l14Avg} max = { calc.l14Max}</Tag>
            <Tag color="#122a34">{Configure.Gl(1625,'银两')} sum = {calc.l15Sum} avg = { calc.l15Avg} max = { calc.l15Max}</Tag>
            <Tag color="#13f434">{Configure.Gl(1626,'VIP积分')} sum = {calc.l16Sum} avg = { calc.l16Avg} max = { calc.l16Max}</Tag>
            <Tag color="#114434">{Configure.Gl(1627,'战力')} sum = {calc.l17Sum} avg = { calc.l17Avg} max = { calc.l17Max}</Tag>

            <Tag color="#133734">{Configure.Gl(1628,'累积充值金额(元)')} sum = {calc.d0Sum} avg = { calc.d0Avg} max = { calc.d0Max}</Tag>
        </div>);
    }

    getPath() { return RoutePath.STAT_ROLE_KEY_NUM_INDEX; }

    async reloadForData(query: any) {
        let data = await statResApi.roleKeyNumList(query) as any;
        this.setState({calc: data.attachment});
        return data;
    }

}