import React from 'react';
import { Button, Tag, Divider, Switch, Form, Input, Select } from 'antd';
import { TwitterOutlined, YoutubeOutlined, FacebookOutlined, LinkedinOutlined } from '@ant-design/icons';

import { HomeLayout } from '../../layout/HomeLayout';
import { RoutePath } from '../../route/path';
import { confApi } from '../../api/console/conf';
import { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import SrvEnum from '../../def/SrvEnum';
import { selectOptionFilter } from '../../util/ui';
import Configure from '../../api/Configure';
import { SelectOpt } from '../../def/CommonDef';

export default class GlobalConfIndex extends React.Component {

    formRef = React.createRef<FormInstance>();

    state = {
        data: {
            tcg_mysql_conf: { host: '', port: 0, username: '', password: '', database: '' },
            tcg_mongo_conf: { mode: 0, url: '', host: '', port: 0, username: '', password: '', database: '', source: '' },
            loginNP: { scheme: '', host: { LAN: '', WAN: '', domain: '' }, port: 0, ssl: false, enabled: false },
            statNP: { scheme: '', host: { LAN: '', WAN: '', domain: '' }, port: 0, ssl: false, enabled: false },
            payNP: { scheme: '', host: { LAN: '', WAN: '', domain: '' }, port: 0, ssl: false, enabled: false },
            worldNP: { scheme: '', host: { LAN: '', WAN: '', domain: '' }, port: 0, ssl: false, enabled: false },
            helperMongo: { mode: 0, url: '', host: '', port: 0, username: '', password: '', database: '', source: '' },
        }
    }

    protected optUI(optList: SelectOpt[]) {
        let ui: any[] = [];
        optList.forEach((opt: SelectOpt) => ui.push(<Select.Option key={opt.value} value={opt.value}>{opt.label}</Select.Option>));
        return ui;
    }

    render() {
        const { data } = this.state;

        const vipStatTarOptUI = this.optUI(SrvEnum.instance.vipStatTar);
        const currencyCodeOptUI = this.optUI(SrvEnum.instance.currencyCode);
        //

        console.log("SrvEnum.instance", SrvEnum.instance);

        return (
            <HomeLayout path={RoutePath.GLOBAL_CONF_INDEX}>
                <Form
                    ref={this.formRef}
                    layout="inline"
                    name="basic"
                    initialValues={data}
                    onFinish={this.onFormFinish}
                    onFinishFailed={this.onFormFinishFailed}
                >
                    <Divider >
                        <Tag icon={<TwitterOutlined />} color="#55acee">tcg_mysql_conf : </Tag>
                    </Divider>
                    <Form.Item label="host" name={['tcg_mysql_conf', 'host']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="port" name={['tcg_mysql_conf', 'port']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="username" name={['tcg_mysql_conf', 'username']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="password" name={['tcg_mysql_conf', 'password']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="database" name={['tcg_mysql_conf', 'database']}>
                        <Input />
                    </Form.Item>
                    <Divider />

                    <Divider >
                        <Tag icon={<YoutubeOutlined />} color="#cd201f">tcg_mongo_conf : </Tag>
                    </Divider>
                    <Form.Item label="mode" name={['tcg_mongo_conf', 'mode']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="url" name={['tcg_mongo_conf', 'url']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="host" name={['tcg_mongo_conf', 'host']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="port" name={['tcg_mongo_conf', 'port']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="username" name={['tcg_mongo_conf', 'username']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="password" name={['tcg_mongo_conf', 'password']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="database" name={['tcg_mongo_conf', 'database']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="source" name={['tcg_mongo_conf', 'source']}>
                        <Input />
                    </Form.Item>
                    <Divider />

                    <Divider >
                        <Tag icon={<LinkedinOutlined />} color="#55acee">stat.mq : </Tag>
                    </Divider>
                    <Form.Item label="host" name={['stat', 'mq', 'host']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="username" name={['stat', 'mq', 'username']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="password" name={['stat', 'mq', 'password']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1358, '启用')} name={['stat', 'mq', 'enabled']} valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    <Divider />

                    <Divider >
                        <Tag icon={<LinkedinOutlined />} color="#55acee">game.mqShare : </Tag>
                    </Divider>
                    <Form.Item label="host" name={['game', 'mqShare', 'host']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="username" name={['game', 'mqShare', 'username']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="password" name={['game', 'mqShare', 'password']}>
                        <Input />
                    </Form.Item>
                    <Divider />

                    <Divider >
                        <Tag icon={<LinkedinOutlined />} color="#12acbd">{Configure.Gl(1938, 'stat.heroRecordEnabled - 英雄记录统计 :')}</Tag>
                    </Divider>
                    <Form.Item label={Configure.Gl(1358, '启用')} name={['stat', 'heroRecordEnabled']} valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    <Divider />

                    <Divider >
                        <Tag icon={<FacebookOutlined />} color="#3b5900">{Configure.Gl(1357, 'loginNP - 登录服网络进程 :')} </Tag>
                    </Divider>
                    <Form.Item label="scheme" name={['loginNP', 'scheme']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="host.LAN" name={['loginNP', 'host', 'LAN']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="host.WAN" name={['loginNP', 'host', 'WAN']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="host.domain" name={['loginNP', 'host', 'domain']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="port" name={['loginNP', 'port']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="ssl" name={['loginNP', 'ssl']} valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1358, '启用')} name={['loginNP', 'enabled']} valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    <Divider />

                    <Divider >
                        <Tag icon={<FacebookOutlined />} color="#3b5999">{Configure.Gl(1374, 'statNP - 统计服网络进程 : ')}</Tag>
                    </Divider>
                    <Form.Item label="scheme" name={['statNP', 'scheme']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="host.LAN" name={['statNP', 'host', 'LAN']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="host.WAN" name={['statNP', 'host', 'WAN']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="host.domain" name={['statNP', 'host', 'domain']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="port" name={['statNP', 'port']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="ssl" name={['statNP', 'ssl']} valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1358, '启用')} name={['statNP', 'enabled']} valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    <Divider />

                    <Divider >
                        <Tag icon={<LinkedinOutlined />} color="#55acee">{Configure.Gl(1375, 'payNP - 支付服网络进程 : ')}</Tag>
                    </Divider>
                    <Form.Item label="scheme" name={['payNP', 'scheme']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="host.LAN" name={['payNP', 'host', 'LAN']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="host.WAN" name={['payNP', 'host', 'WAN']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="host.domain" name={['payNP', 'host', 'domain']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="port" name={['payNP', 'port']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="ssl" name={['payNP', 'ssl']} valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1358, '启用')} name={['payNP', 'enabled']} valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    <Divider />

                    <Divider >
                        <Tag icon={<LinkedinOutlined />} color="#33bbee">{Configure.Gl(1376, 'worldNP - 世界服网络进程 : ')}</Tag>
                    </Divider>
                    <Form.Item label="scheme" name={['worldNP', 'scheme']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="host.LAN" name={['worldNP', 'host', 'LAN']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="host.WAN" name={['worldNP', 'host', 'WAN']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="host.domain" name={['worldNP', 'host', 'domain']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="port" name={['worldNP', 'port']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="ssl" name={['worldNP', 'ssl']} valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1358, '启用')} name={['worldNP', 'enabled']} valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    <Divider />

                    <Form.Item label={Configure.Gl(1378, '支付重定向')} name={['payRedirect']}>
                        <Select options={SrvEnum.instance.payRedirect}></Select>
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1379, "VIP统计对象")} name={['vipStatTar']}>
                        <Select style={{ minWidth: 240 }}>{vipStatTarOptUI}</Select>
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1786, "VIP统计对象(仅作兼容使用)")} name={['payVipStatTar']}>
                        <Select style={{ minWidth: 240 }}>
                            <Select.Option value={0}>{Configure.Gl(1787, "以货币量为计数对象(0)")}</Select.Option>
                            <Select.Option value={1}>{Configure.Gl(1788, "以VIP积分为计数对象(1)")}</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1789, "默认货币编码")} name={['currencyCode']}>
                        <Select style={{ minWidth: 220 }}>{currencyCodeOptUI}</Select>
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1380, "默认时区")} name={['timezoneCode']} style={{ minWidth: 300 }}>
                        <Select showSearch filterOption={selectOptionFilter} options={SrvEnum.instance.timezone}></Select>
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1381, "默认语言")} name={['langCode']} style={{ minWidth: 300 }}>
                        <Select showSearch filterOption={selectOptionFilter} options={SrvEnum.instance.lang}></Select>
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1382, "默认游戏版本")} name={['gameVer']} style={{ minWidth: 300 }}>
                        <Select showSearch filterOption={selectOptionFilter} options={SrvEnum.instance.gameVer}></Select>
                    </Form.Item>
                    <Form.Item label={Configure.Gl(1914, "默认产品版本")} name={['productVer']} style={{ minWidth: 300 }}>
                        <Select showSearch filterOption={selectOptionFilter} options={SrvEnum.instance.productVer}></Select>
                    </Form.Item>

                    <Divider >
                        <Tag icon={<YoutubeOutlined />} color="#fd991f">{Configure.Gl(1377, 'helperMongo - 小助手MongoDB : ')}</Tag>
                    </Divider>
                    <Form.Item label="mode" name={['helperMongo', 'mode']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="url" name={['helperMongo', 'url']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="host" name={['helperMongo', 'host']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="port" name={['helperMongo', 'port']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="username" name={['helperMongo', 'username']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="password" name={['helperMongo', 'password']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="database" name={['helperMongo', 'database']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="source" name={['helperMongo', 'source']}>
                        <Input />
                    </Form.Item>
                    <Divider />

                    <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                        <Button type="primary" htmlType="submit">{Configure.Gl(1373, '保存')}</Button>
                    </Form.Item>
                </Form>
            </HomeLayout>
        );
    }

    componentDidMount() {
        console.log("GlobalConfIndex::componentDidMount");
        this.reload();
    }

    async reload() {
        let data = await confApi.getGlobal();
        console.log(Configure.Gl(1390, "请求到的数据是："), data);
        // data.forEach(element => { element.key = Math.random() });
        this.setState({ data: data });
        this.formRef.current?.setFieldsValue(data as Store);
    }

    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        confApi.setGlobal(values).then(d => {
            this.reload();
        });
    }

    onFormFinishFailed = (errorInfo: any) => {
        console.error('onFormFinishFailed:', errorInfo);
    }


}