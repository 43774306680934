import React from 'react';
import { Input, Select } from 'antd';
import { AbsFilter, StdItemFilter } from '../../../part/StdItemFilter';
import { srvGroupApi } from '../../../api/console/srv.group';
import Configure from '../../../api/Configure';

class Filter extends AbsFilter {
    srvCodeOrigin?: string = undefined;
    srvCodeNow?: string = undefined;
    groupIdFrom?: number = undefined;
}

export default class SrvMergeFilter extends StdItemFilter {

    state = {
        srvCodeOrigin: undefined as string | undefined,
        srvCodeNow: undefined as string | undefined,
        groupIdFrom: undefined as number | undefined,
        optGroup: []
    };

    filter:Filter = new Filter();

    getFilter() { return this.filter; }

    async componentDidMount() {
        srvGroupApi.optionList().then(d => this.setState({ optGroup: d }))
    }

    render() {
        const { optGroup } = this.state;
        const optGroupUI: any[] = this.optUI(optGroup);
        return (
            <span>
                <Input placeholder={Configure.Gl(1505,"原始游戏服编码")} value={this.state.srvCodeOrigin} onChange={e => this.setValue('srvCodeOrigin', e.target.value)} style={{width: 180}}/>
                <Input placeholder={Configure.Gl(1526,"迁移至游戏服编码")} value={this.state.srvCodeNow} onChange={e => this.setValue('srvCodeNow', e.target.value)} style={{width: 180}}/>
                <Select placeholder={Configure.Gl(1527,"from原始游戏服务器组")} value={this.state.groupIdFrom} onChange={v => this.setValue('groupIdFrom', v)} style={{minWidth: 180}}>
                    {optGroupUI}
                </Select>
                { this.renderCmd() }
            </span>
        );
    }

}