import React, { RefObject } from 'react';
import { Button, Table, Space, Divider, Input, Select } from 'antd';
import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import { StdItemPage } from '../../../part/StdItemPage';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';
import { payManualApi } from '../../../api/console/gm.pay';
import { formatDate } from '../../../util/toolbox';
import MasterQuery from '../../../part/master/MasterQuery';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Key, TableCurrentDataSource } from 'antd/lib/table/interface';
import { SorterResult } from 'antd/es/table/interface';
import { excelApi } from '../../../api/excel/excel';
import { RouteComponentProps } from 'react-router-dom';
import Configure from '../../../api/Configure';


export default class GmPayManualIndex extends StdItemPage<any> {
    editor: RefObject<MasterQuery>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
    }

    state = {
        itemTotal: undefined,
        queryPagination: {
            page: 1,
            pageSize: 10
        },
        itemList: [],
        masterSelected: [] as any[],
        orderItem: undefined,
        orderId: undefined,
        orderType: undefined,
        orderName: undefined,
        orderRmb: undefined,
        payItemOpts: [] as any[],
    }

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1231, '订单类型'),
                dataIndex: 'orderType',
                width: 80,
                render: (value: any) => value === 2 ? Configure.Gl(1260, "玩家补单") : Configure.Gl(1261, "内部订单（给托号）")
            },
            {
                title: Configure.Gl(1262, '审核状态'),
                dataIndex: 'status',
                width: 80,
                render: (value: any) => value === 1 ? Configure.Gl(1201, "未审批") : value === 2 ? Configure.Gl(1202, "同意") : Configure.Gl(1203, "拒绝")
            },
            {
                title: Configure.Gl(1237, '支付项模板ID'),
                dataIndex: 'itemTid',
                width: 80,
            },
            {
                title: Configure.Gl(1239, '支付项名称'),
                dataIndex: 'itemName',
                width: 120,
            },
            {
                title: Configure.Gl(1238, '支付项购买数量'),
                dataIndex: 'itemCount',
                width: 60,
            },
            {
                title: Configure.Gl(1240, '付款总金额(元)'),
                dataIndex: 'payAmount',
                width: 60,
            },
            {
                title: Configure.Gl(1233, '平台订单号'),
                dataIndex: 'platformOrderId',
                width: 100,
            },
            {
                title: Configure.Gl(1234, '服务器'),
                dataIndex: 'srvCode',
                width: 100,
            },
            {
                title: Configure.Gl(1264, '主角姓名'),
                dataIndex: 'masterName',
                width: 120,
            },
            {
                title: Configure.Gl(1101, '主角唯一ID'),
                dataIndex: 'masterIdHex',
                width: 170,
            },
            {
                title: Configure.Gl(1235, '账号uid'),
                dataIndex: 'accountUid',
                width: 170,
            },
            {
                title: Configure.Gl(1122, '平台编码'),
                dataIndex: 'platformCode',
                width: 80,
            },
            {
                title: Configure.Gl(1099, '渠道编码'),
                dataIndex: 'channelCode',
                width: 80,
            },
            {
                title: Configure.Gl(1241, '平台openId'),
                width: 170,
                dataIndex: 'platformOpenId',
            },
            {
                title: Configure.Gl(1205, '提交人用户名'),
                width: 80,
                dataIndex: 'submitUsername',
            },
            {
                title: Configure.Gl(1206, '提交人昵称'),
                width: 120,
                dataIndex: 'submitNickname',
            },
            {
                title: Configure.Gl(1207, '提交时间'),
                width: 120,
                dataIndex: 'submitTime',
                render: (v, r) => formatDate(v)
            },
            {
                title: Configure.Gl(1208, '审核人用户名'),
                width: 80,
                dataIndex: 'approveUsername',
            },
            {
                title: Configure.Gl(1209, '审核人昵称'),
                width: 120,
                dataIndex: 'approveNickname',
            },
            {
                title: Configure.Gl(1210, '审核时间'),
                width: 120,
                dataIndex: 'approveTime',
                render: (v, r) => formatDate(v)
            }
        ];
    }

    getColsMaster(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1265, '唯一ID'),
                dataIndex: 'roleId',
                width: 80,
            },
            {
                title: Configure.Gl(1151, '主角名'),
                dataIndex: 'roleName',
                width: 80,
            },
            {
                title: Configure.Gl(1187, '主角等级'),
                dataIndex: 'masterLevel',
                width: 80,
            },
            {
                title: Configure.Gl(1014, '游戏服'),
                dataIndex: 'srvCode',
                width: 120,
                render: (v, r) => '' + r.srvCode + '(' + r.srvName + ')'
            },
            {
                title: Configure.Gl(1122, '平台编码'),
                dataIndex: 'platformCode',
                width: 60,
            },
            {
                title: Configure.Gl(1099, '渠道编码'),
                dataIndex: 'channelCode',
                width: 60,
            },
            {
                title: Configure.Gl(1266, 'IMEI'),
                dataIndex: 'IMEI',
                width: 100,
            },
            {
                title: Configure.Gl(1267, 'IP'),
                dataIndex: 'ip',
                width: 180,
            },
        ];
    }

    render() {
        const { itemTotal, queryPagination, payItemOpts } = this.state;
        const columns: ColumnsType<any> = this.getCols();
        const columnsMaster: ColumnsType<any> = this.getColsMaster();
        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            defaultPageSize: queryPagination.pageSize,
            total: itemTotal
        };
        const payItemOptUi: any[] = [];
        payItemOpts.forEach(opt => {
            payItemOptUi.push(<Select.Option value={opt.text} key={opt.tid} item={opt.tid} name={opt.name} rmb={opt.rmb}>{opt.text}</Select.Option>);
        })
        return (
            <HomeLayout path={RoutePath.GM_PAY_MANUAL_INDEX}>
                <Space>
                    <MasterQuery ref={this.editor} onSelect={(r: any) => this.handleSelect(r)} /><br />
                    <Button type="primary" icon={<CloseCircleOutlined />} onClick={() => this.setState({ masterSelected: [] })}>{Configure.Gl(1268, '清空主角')}</Button>
                </Space>
                <Table dataSource={this.state.masterSelected} columns={columnsMaster} />
                <Divider />
                <Select showSearch placeholder={Configure.Gl(1248, "支付项")} style={{ width: '70%' }} onChange={(e, option) => this.handleChange(e, option)} >
                    {payItemOptUi}
                </Select>
                <Select placeholder={Configure.Gl(1231, "订单类型")} style={{ width: '30%' }} onChange={v => this.setState({ orderType: v })}>
                    <Select.Option value="2">{Configure.Gl(1260, '玩家补单')}</Select.Option>
                    <Select.Option value="3">{Configure.Gl(1261, '内部订单（给托号）')}</Select.Option>
                </Select>
                <br />
                <Input placeholder={Configure.Gl(1233, "平台订单号")} style={{ width: '70%' }} value={this.state.orderId} onChange={v => this.setState({ orderId: v.target.value })} />
                <Button type="primary" style={{ width: '30%' }} onClick={() => this.execute()}>{Configure.Gl(1269, '立即执行')}</Button>
                <Divider />
                <Table dataSource={this.state.itemList} columns={columns}
                    pagination={pagination} scroll={{ x: this.getColsWidth(columns), y: 512 }} onChange={(p, f, s, e) => { this.onPageChange(p, f, s, e) }} />
            </HomeLayout>
        );
    }

    searchhangye(v: any) {
        console.log("value2=", v);
    }

    handleSelect(r: any) {
        this.setState({ masterSelected: [r] });
        this.editor.current?.setState({ show: false });
    }

    handleChange(e: any, option: any) {
        this.setState({ orderItem: option.item, orderName: option.name, orderRmb: option.rmb });
    }

    onPageChange(pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        this.setState({
            queryPagination: {
                page: pagination.current,
                pageSize: pagination.pageSize
            }
        }, () => {
            this.reload();
        });
    }

    componentDidMount() {
        this.reload();
    }

    execute() {
        if (this.state.masterSelected.length <= 0) {
            alert(Configure.Gl(1270, "请选择一个主角啊！"));
            return;
        }
        if (!this.state.orderType) {
            alert(Configure.Gl(1271, "请选择订单类型啊！"));
            return;
        }
        if (!this.state.orderItem) {
            alert(Configure.Gl(1272, "请选择支付项啊！"));
            return;
        }
        if (!this.state.orderId) {
            alert(Configure.Gl(1273, "请输入平台订单号啊！"));
            return;
        }
        payManualApi.manual({
            orderType: this.state.orderType,
            platformOrderId: this.state.orderId,
            itemTid: this.state.orderItem,
            itemCount: 1,
            itemName: this.state.orderName,
            payAmount: this.state.orderRmb,
            srvCode: this.state.masterSelected[0].srvCode,
            accountUid: this.state.masterSelected[0].accountUid,
            platformCode: this.state.masterSelected[0].platformCode,
            channelCode: this.state.masterSelected[0].channelCode,
            platformOpenId: "",
            masterIdHex: this.state.masterSelected[0].roleId,
            masterName: this.state.masterSelected[0].roleName,
            note: Configure.Gl(1274, "来自GM手工支付")
        }).then(() => { this.reload() });
    }

    async reload() {
        const { queryPagination } = this.state;
        const query = { ...queryPagination };
        console.log("请求参数为：", query);
        let data = await payManualApi.list(query) as any;
        console.log(Configure.Gl(1390, "请求到的数据是："), data);
        this.addKeyToData(data.records);
        this.setState({ itemList: data.records, itemTotal: data.total });
        this.loadPayItems();
    }

    async loadPayItems() {
        excelApi.pay_item().then(data => {
            (data as any[]).forEach(element => {
                element.text =
                    "" + element.id + " " + element.name + " " + element.rmb + "元";
            });
            console.log("data =", data);
            this.setState({ payItemOpts: data });
        });
    }
}