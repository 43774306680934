import React, { RefObject } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Table, Space, Divider, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { TablePaginationConfig, ColumnsType } from 'antd/lib/table/Table';

import { StdItemPage } from '../../../../part/StdItemPage';
import { HomeLayout } from '../../../../layout/HomeLayout';
import { RoutePath } from '../../../../route/path';
import { srvCrossPlanApi } from '../../../../api/console/srv.cross.plan';
import SrvCrossPlanEdit from './SrvCrossPlanEdit';
import SrvCrossPlanFilter from './SrvCrossPlanFilter';
import { compareCode } from '../../../../util/toolbox';
import { Key, TableCurrentDataSource } from 'antd/lib/table/interface';
import { SorterResult } from 'antd/es/table/interface';
import Configure from '../../../../api/Configure';

export default class SrvCrossPlanIndex extends StdItemPage<any> {

    private editor: RefObject<SrvCrossPlanEdit>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.editor = React.createRef();
    }

    state = {
        showEdit: false,
        itemList: [],
        itemTotal: undefined,
        queryPagination: {
            page: 1,
            pageSize: 3
        },
        queryFilter: {}
    };

    getCols(): ColumnsType<any> {
        return [
            {
                title: Configure.Gl(1055,'ID'),
                dataIndex: 'id',
                width: 80,
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: Configure.Gl(1079,'游戏服编码'),
                dataIndex: 'srvCode',
                width: 120,
                sorter: (a, b) => compareCode(a.code, b.code)
            },
            {
                title: Configure.Gl(1392,"跨服编码"),
                dataIndex: 'crossCode',
                width: 120,
            },
            {
                title: Configure.Gl(1402,'变更时间'),
                dataIndex: 'changeTime',
                width: 170,
                render: this.formatDateTime
            },
            {
                title: Configure.Gl(1403,'已执行过'),
                dataIndex: 'changed',
                width: 60,
                render: (value: any) => value ? '√' : Configure.Gl(1404,'等待执行')
            },
            {
                title: Configure.Gl(1395,"游戏服执行错误信息"),
                dataIndex: 'srvErr',
                width: 200,
            },
            {
                title: Configure.Gl(1396,"跨服执行错误信息"),
                dataIndex: 'crossErr',
                width: 200,
            },
            {
                title: Configure.Gl(1358,'启用'),
                dataIndex: 'enabled',
                width: 60,
                render: (value: any) => value ? '√' : Configure.Gl(1401,'禁用')
            },
            {
                title: Configure.Gl(1057,'操作'),
                width: 180,
                render: (value: any, record: any, index: number) => {
                    return (<Space>
                        <Button type="primary" onClick={() => this.editor.current?.showUpdate(record)}>{Configure.Gl(1117,"编辑")}</Button>
                        <Button danger onClick={() => this.deleteItem(record)}>{Configure.Gl(1133,'删除')}</Button>
                    </Space>);
                },
            }
        ];
    }

    render() {
        const { itemTotal, queryPagination } = this.state;

        const columns: ColumnsType<any> = this.getCols();

        const pagination: TablePaginationConfig = {
            showSizeChanger: true,
            showQuickJumper: true,
            defaultPageSize: queryPagination.pageSize,
            total: itemTotal
        };

        return (
            <HomeLayout path={RoutePath.SRV_CROSS_PLAN_INDEX}>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.editor.current?.showCreate()}>{Configure.Gl(1389,'新增')}</Button>
                    <SrvCrossPlanFilter search={(f:any) => this.onFilterChange(f)}></SrvCrossPlanFilter>
                </Space>
                <Divider />
                <Table dataSource={this.state.itemList} columns={columns} pagination={pagination}
                    scroll={{ x: this.getColsWidth(columns), y: 650 }} onChange={(p, f, s, e) => { this.onPageChange(p, f, s, e) }} />
                <SrvCrossPlanEdit ref={this.editor} reload={() => this.reload()} ></SrvCrossPlanEdit>
            </HomeLayout>
        );
    }

    componentDidMount() {
        console.log("SrvCrossPlanIndex::componentDidMount");
        this.reload();
    }

    onFilterChange(filter: any) {
        console.log("沙雕 filter=", filter);
        this.setState({queryFilter: filter}, ()=>{
            this.reload();
        }); 
    }

    onPageChange(pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        console.log("沙雕 onPageChange pagination=", pagination, ", filters=", filters, ", sorter=", sorter, ", extra=", extra);
        this.setState({queryPagination: {
            page: pagination.current,
            pageSize: pagination.pageSize
        }}, ()=>{
            this.reload();
        });
    }

    async reload() {
        const { queryPagination, queryFilter } = this.state; 
        const query = { ...queryPagination, ...queryFilter };
        let data = await srvCrossPlanApi.list(query) as any;
        console.log(Configure.Gl(1390,"请求到的数据是："), data);
        this.addKeyToData(data.records);
        this.setState({ itemList: data.records, itemTotal: data.total });
    }

    deleteItem(item: any) {
        const that = this;
        Modal.confirm({
            title: Configure.Gl(1066,'警告'),
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            content: Configure.Gl(1391,'你确定要删除') + item.code + ' (' + item.note + ')？',
            onOk() {
                srvCrossPlanApi.delete(item.id).then(d => that.reload());
            }
        });
    }

}