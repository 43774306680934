import { consoleApi } from '../../util/http';
import Configure from '../Configure';

class GmVipApi {

    create(item: any) {
        return consoleApi.post({
            path: 'gm/vipService/create',
            param: item,
            title: Configure.Gl(1847,'VIP创建')
        });
    }

    list(filter: any){
        return consoleApi.post({
            path: 'gm/vipService/list',
            param: filter,
            title: Configure.Gl(1848,'VIP服务列表'),
            quiet: true
        });
    }

    update(filter: any){
        return consoleApi.post({
            path: 'gm/vipService/update',
            param: filter,
            title: Configure.Gl(1849,'VIP更新'),
            quiet: true
        });
    }

    delete(filter: any){
        return consoleApi.post({
            path: 'gm/vipService/delete',
            param: filter,
            title: Configure.Gl(1850,'VIP删除'),
            quiet: true
        });
    }

}

export const gmVipApi: GmVipApi = new GmVipApi();