import React from 'react';
import { List } from 'antd';
import { HomeLayout } from '../../layout/HomeLayout';
import { RoutePath } from '../../route/path';
import Configure from '../../api/Configure';

export default class UrlMapIndex extends React.Component {

    state = {
        links: [
            {name: Configure.Gl(1361,'国内')+"-"+Configure.Gl(1371,'首发')+'·'+Configure.Gl(1366,'环境'), href: 'http://gmc.fs.yezixigame.com/gmc'},
            {name: Configure.Gl(1361,'国内')+'-'+Configure.Gl(1370,'小程序正式')+'·'+Configure.Gl(1366,'环境'), href: 'http://gmc-wx-fs.yezixigame.com/wx_gmc/gmc'},
            {name: Configure.Gl(1361,'国内')+'-'+Configure.Gl(1369,'小程序审核')+'·'+Configure.Gl(1366,'环境'), href: 'http://gmc-wxsh-fs.yezixigame.com/wx_exam_gmc/gmc'},
            {name: Configure.Gl(1361,'国内')+'-'+Configure.Gl(1368,'测试体验')+'·'+Configure.Gl(1366,'环境'), href: 'http://gmc-test-fs.yezixigame.com/test_gmc/gmc'},
            {name: Configure.Gl(1361,'国内')+'-'+Configure.Gl(1367,'IOS审核')+'·'+Configure.Gl(1366,'环境'), href: 'http://gmc-iossh-fs.yezixigame.com/ios_exam_gmc/gmc'},
            {name: Configure.Gl(1362,'港澳台')+'-'+Configure.Gl(1363,'正式')+'·'+Configure.Gl(1366,'环境'), href: 'http://gat-fs.yezixigame.com/gat_gmc/gmc'},
            {name: Configure.Gl(1362,'港澳台')+'-'+Configure.Gl(1364,'审核')+'·'+Configure.Gl(1366,'环境'), href: 'http://gat-sh-fs.yezixigame.com/gat_exam_gmc/gmc'},
            {name: Configure.Gl(1362,'港澳台')+'-'+Configure.Gl(1365,'测试')+'·'+Configure.Gl(1366,'环境'), href: 'http://gat-test-fs.yezixigame.com/gat_demo_gmc/gmc'},
            {name: Configure.Gl(1372,'越南')+'-'+Configure.Gl(1365,'测试')+'·'+Configure.Gl(1366,'环境'), href: 'http://yn-test-fs.yezixigame.com/yn_test_gmc/gmc2'},
        ]
    }

    render() {
        const { links } = this.state;
        return (<HomeLayout path={RoutePath.URL_MAP_INDEX}>
            <List
                size="small"
                header={<div>{Configure.Gl(1049,"网址大全")}</div>}
                footer={<div>{Configure.Gl(1360,"方便快捷")}</div>}
                bordered
                dataSource={links}
                renderItem={item => 
                    <List.Item>
                    <a href={item.href} target="_blank" rel="noopener noreferrer">{item.name}</a>
                    </List.Item>}
                />
        </HomeLayout>);
    }

    componentDidMount() {
        console.log("UrlMapIndex::componentDidMount()");
    }

}