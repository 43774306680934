import React from 'react';
import { Route, Switch } from 'react-router';
//import { BrowserRouter } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import './App.css';

import { getRoutes } from './route/index';

import 'moment/locale/zh-cn';
import localeCN from 'antd/es/locale/zh_CN';
//import locale from 'antd/es/locale/ja_JP';
import localeUS from 'antd/es/locale/en_US';
import localeKO from 'antd/es/locale/ko_KR';
import { ConfigProvider } from 'antd';
import Configure from './api/Configure';

function App() {
  let locales;
  if (Configure.LocalWord === "zh_CN") {
    locales = localeCN;
  } else if (Configure.LocalWord === "ko_KR") {
    locales = localeKO;
  } else {
    locales = localeUS;
  }
  return (
    <ConfigProvider locale={locales}>
      <HashRouter>
        <Switch>
          {
            getRoutes().map((item: any, index: any) => {
              return <Route key={index} exact path={item.path} component={item.component} />
            })
          }
          <Route exact path="/inbox" component={Inbox} />
          <Route path="/inbox/messages/:id" component={Message} />
        </Switch>
      </HashRouter>
    </ConfigProvider>

  );
}

class Inbox extends React.Component {
  render() {
    return (<div>
      <h2>Inbox</h2>
      {this.props.children || "Welcome to your Inbox"}
    </div>);
  }
}

interface IProp {
  match: any
}

class Message extends React.Component<IProp> {

  render() {
    const { params } = this.props.match;
    console.log('params=', params);
    return (<h3>Message= {params.id}</h3>);
  }
}

export default App;

