import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Table } from 'antd';
import { HomeLayout } from '../../../layout/HomeLayout';
import { RoutePath } from '../../../route/path';
import FunParticipateFilter from './FunParticipateFilter';
import { statSrvApi } from '../../../api/stat/all';
import Configure from '../../../api/Configure';

export default class FunParticipateIndex extends React.Component<RouteComponentProps> {
    state = {
        filter: {},
        rank: [],
    }
    render() {
        const { rank } = this.state;
        const columns = [
            { title: Configure.Gl(1766,"功能代号"), dataIndex: 'funcId', sorter: (a: any, b: any) => a.funcId - b.funcId,  },
            { title: Configure.Gl(1767,"功能名称"), dataIndex: 'name', sorter: (a: any, b: any) => a.name - b.name,  },
            { title: Configure.Gl(1768,"参与次数"), dataIndex: 'times', sorter: (a: any, b: any) => a.times - b.times,  },
            { title: Configure.Gl(1769,"参与人数"), dataIndex: 'num', sorter: (a: any, b: any) => a.num - b.num,  },
        ]
        return (
            <HomeLayout path={RoutePath.STAT_FUNC_PART_INDEX}>
                <FunParticipateFilter search={(f: any) => this.onFilterChange(f)}></FunParticipateFilter>
                <hr/>
                <Table columns={columns} dataSource={rank} />
            </HomeLayout>
        );
    }

    onFilterChange(filter: any) {
        this.setState({filter}, ()=>{
            this.reload();
        }); 
        
    }

    selectSvr() {
       
    }

    async reload() {
        //const { filter } = this.state;
        let data = await statSrvApi.participate_sum(this.state.filter) as any[];
        console.log(Configure.Gl(1390,"请求到的数据是："), data);
        if(data===undefined){
            return;
        }
        for(let i = 0; i < data.length; i++) {
            data[i].name = Configure.getParticipate(data[i].funcId);
        }
        this.setState({rank: data});
    }

}