import { consoleApi } from '../../util/http';
import { ApiBase } from '../api';
import Configure from '../Configure';

class CloudMongoApi extends ApiBase {
    
    create(item: any) {
        return consoleApi.post({
            path: 'cloud/mongo/v2/create',
            param: item,
            title: Configure.Gl(1656,'新建Mongo')
        });
    }

    update(item: any) {
        return consoleApi.post({
            path: 'cloud/mongo/v2/update',
            param: item,
            title: Configure.Gl(1657,'修改Mongo')
        });
    }

    delete(code: string) {
        return consoleApi.get({
            path: 'cloud/mongo/v2/delete?code='+code,
            title: Configure.Gl(1658,"删除Mongo"),
            quiet: true
        });
    }

    list(query: any) {
        return consoleApi.get({
            path: 'cloud/mongo/v2/list',
            title: Configure.Gl(1659,"列出Mongo"),
            param: query,
            quiet: true
        });
    }

}

export const cloudMongoApi: CloudMongoApi = new CloudMongoApi();