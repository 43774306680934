import React from 'react';
import { Form, Button, Select, Input } from 'antd';
import { StdItemEdit, IProps, IState } from '../../part/StdItemEdit';
import { staffRoleApi } from '../../api/console/staff.role';
import { srvGroupApi } from '../../api/console/srv.group';
import Configure from '../../api/Configure';

interface ThisState extends IState {
    roleOpt: any[],
    groupOpt: any[],
}

export default class StaffRoleSrvGroupEdit extends StdItemEdit<IProps, ThisState> {

    state = {
        ...super.buildInitState(),
        roleOpt: [] as any[],
        groupOpt: [] as any[],
    }

    constructor(props: IProps, context: any) {
        super(props, context);
        staffRoleApi.optionList().then(d => this.setState({ roleOpt: d }));
        srvGroupApi.optionList().then(d => this.setState({ groupOpt: d }));
    }

    renderForm() {
        const { createMode, item } = this.state;
        console.log("拿到的item=", item);
        const roleOptUi: any[] = [];
        const { roleOpt } = this.state;
        roleOpt.forEach(opt => {
            roleOptUi.push(<Select.Option value={opt.value} key={opt.value}>{opt.label}</Select.Option>);
        });
        const groupOptUi: any[] = [];
        const { groupOpt } = this.state;
        groupOpt.forEach(opt => {
            groupOptUi.push(<Select.Option value={opt.value} key={opt.value}>{opt.label}</Select.Option>);
        });
        return (
            <Form 
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                name="basic"
                initialValues={item}
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFinishFailed}
            >
                <Form.Item label={Configure.Gl(1055,"ID")} name="id">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={Configure.Gl(1000,"角色")} name="roleId">
                    <Select>{roleOptUi}</Select>
                </Form.Item>
                <Form.Item label={Configure.Gl(1083,"服务器组")} name="srvGroupId">
                    <Select>{groupOptUi}</Select>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary" htmlType="submit"> {createMode ? Configure.Gl(1387,"保存新增")  : Configure.Gl(1388,"保存编辑")} </Button>
                </Form.Item>
            </Form>
        );
    }


    onFormFinish = (values: any) => {
        console.log('onFormFinish:', values);
        //if(true) return;
        const { createMode } = this.state;
        if (createMode) {
            staffRoleApi.createSrvGroup(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            });
        } else {
            staffRoleApi.updateSrvGroup(values).then(d => {
                this.setState({ visible: false });
                this.props.reload();
            })
        }
    }

}